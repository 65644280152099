import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { defaultMarker } from 'pages/Orders/components/DialogHotel/components/Map/DefaultIcon/marker';
import Modal from 'components/ui/Modal';

import TextComponent from './TextContainer';

import { MapContainer as Map, TileLayer, Marker, ZoomControl } from 'react-leaflet';

const MapContainer = styled.div`
  width: 829px;
`;

const TextContainer = styled.div`
  width: 400px;
`;

const Content = styled.div`
  display: flex;
`;

const ModalContainer = styled.div`
  transition: all linear 0.2s;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-right .leaflet-control {
    margin-right: 16px;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 16px;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out:hover {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 34px !important;
    color: #656665;
    box-sizing: border-box;
    font-weight: 600;
    border-radius: 50%;
  }

  .leaflet-bar a {
    width: 40px;
    height: 40px;
  }

  .leaflet-control-zoom-out {
    margin-top: 8px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .leaflet-control-zoom-in {
    border-bottom: 0;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 15%;
      width: 70%;
      top: 100%;
      height: 1px;
      background-color: #ebebeb;
    }
  }

  .leaflet-bar {
    box-shadow: inherit;
    border: 0;
    border-radius: 0;
  }

  .leaflet-container {
    height: 100%;
  }

  .leaflet-bottom {
    z-index: 8;
  }

  img.leaflet-marker-icon {
    cursor: pointer;
    margin-left: -21px !important;
    margin-top: -21px !important;
    width: 36px !important;
    height: 43px !important;
  }

  .leaflet-marker-shadow {
    display: none;
  }
`;

const Wrapper = styled(Modal)`
  padding: 0;
`;

function MapModal({ className, tileBaseUrl, hotel, hotel: { address, distances }, onCloseClick, withDescription }) {
  const coordinates = [...address.coordinates].reverse();

  return (
    <Wrapper className={className} onClose={onCloseClick} opened>
      <ModalContainer>
        <Content>
          <MapContainer>
            <Map animate={true} minZoom={1} useFlyTo={true} center={coordinates} zoom={7} zoomControl={false}>
              <TileLayer url={tileBaseUrl} />

              <ZoomControl position='bottomright' />

              <Marker icon={defaultMarker} draggable={false} position={coordinates} />
            </Map>
          </MapContainer>

          <TextContainer>
            <TextComponent hotel={hotel} withDescription={withDescription} />
          </TextContainer>
        </Content>
      </ModalContainer>
    </Wrapper>
  );
}

MapModal.propTypes = {
  hotel: PropTypes.object,
  onCloseClick: PropTypes.func,
  className: PropTypes.string,
  tileBaseUrl: PropTypes.string,
  withDescription: PropTypes.bool
};

MapModal.defaultProps = {
  // eslint-disable-next-line no-use-before-define
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok/ru/{z}/{x}/{y}.png'
};

export default MapModal;
