import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ForgotPasswordForm from './ForgotPasswordForm';
import schema, { defaultValues } from './ForgotPasswordForm.schema';
import { Form } from 'components/v2/forms';

function FormWrapper({ onSubmit, onError, onReset, initialValues, className, ...rest }) {
  const values = { ...defaultValues, ...initialValues };

  return (
    <Form
      schema={schema}
      defaultValues={values}
      onSubmit={onSubmit}
      onError={onError}
      onReset={onReset}
      className={className}
    >
      <ForgotPasswordForm {...rest} />
    </Form>
  );
}

FormWrapper.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
  initialValues: {}
};

FormWrapper.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func
};

export default styled(FormWrapper)``;
