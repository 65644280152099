import React from 'react';
import { observer } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import CustomRoute from 'components/common/CustomRoute';

import { Login, Profile, ResetPassword, ForgotPassword, Register, SuccessRegister } from './containers';

@withRouter
@observer
class Auth extends React.Component {
  render() {
    return (
      <>
        <CustomRoute exact path='/profile' component={Profile} />

        <CustomRoute exact path='/login' component={Login} />

        <Route exact path='/register' component={Register} />

        <Route exact path='/success_register' component={SuccessRegister} />

        <Route exact path='/reset_password' component={ResetPassword} />

        <Route exact path='/forget' component={ForgotPassword} />
      </>
    );
  }
}

export default Auth;
