import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Add as AddIcon } from 'components/icons';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('inline-flex', 'center')};
    color: ${theme.colors.button.primaryHover};
    cursor: pointer;
    ${typography(13, 16, 500)};
  `}

  ${AddIcon} {
    margin-right: 6px;
  }
`;

@withTheme
class AddTraveller extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <AddIcon />

        {children}
      </Wrapper>
    );
  }
}

export default styled(AddTraveller)``;
