import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getTabs from '../utils/getTabs';

import Modal from 'components/ui/Modal';
import Amenity from './Amenity';
import AmenityGroup from './AmenityGroup';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const PopularAmenitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 558px;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 672px;
  max-width: 672px;
  min-width: 672px;
  min-height: 300px;
  background: #FFF;
`;

const ModalContainer = styled.div``;

const Wrapper = styled(Modal)`
  padding: 28px 30px;
  box-sizing: border-box;

  ${Amenity} {
    min-width: 270px;
    max-width: 270px;
  }

  ${AmenityGroup} {
    margin-top: 32px;
  }
`;

function FullAmenities({ className, onCloseClick, roomType }) {
  const { t } = useTranslation();

  const { popularAmenities, amenities } = useMemo(() => {
    return getTabs({ roomType });
  }, [roomType]);

  return (
    <Wrapper
      opened
      className={className}
      onClose={onCloseClick}
    >
      <MainContainer>
        <ModalContainer>
          <HeaderContainer>
            <Title>
              {t('Hotel.RoomTypes.Modal.Amenities.Title')}
            </Title>
          </HeaderContainer>

          <PopularAmenitiesContainer>
            {popularAmenities.map((tab, idx) => (
              <Amenity
                key={idx}
                tab={tab}
              />
            ))}
          </PopularAmenitiesContainer>

          {amenities.map(a => (
            <AmenityGroup key={a.tag} amenity={a} />
          ))}
        </ModalContainer>
      </MainContainer>
    </Wrapper>
  );
}

FullAmenities.propTypes = {
  className: PropTypes.string,
  roomType: PropTypes.object,
  onCloseClick: PropTypes.func
};

FullAmenities.defaultProps = {
  amenities: []
};

export default styled(FullAmenities)``;
