import _isString from 'lodash/isString';

const DEFAULT_CONTRACT = {
  id: '',
  number: '',
  price_prefix: '',
  insured_name: '',
  insured_inn: '',
  insured_kpp: '',
  begin_date: '',
  end_date: ''
};

function isValidContract(obj) {
  return _isString(obj.contract?.id);
}

function assignSlotsContract({ slots, reservation_contract, order_contract }) {
  const orderHasContract = isValidContract({
    contract: order_contract
  });

  const reservationHasContract = isValidContract({
    contract: reservation_contract
  });

  return slots.map(slot => {
    const slotHasContract = isValidContract(slot);

    const contract = slotHasContract
      ? slot.contract
      : reservationHasContract
        ? reservation_contract
        : orderHasContract
          ? order_contract
          : DEFAULT_CONTRACT;

    return { ...slot, contract };
  });
}

export default assignSlotsContract;
