import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';
import getDuration from 'utils/duration';

import InstantBooking from 'components/ui/InstantBooking';

import { Badge } from 'components/ui';
import { typography } from 'theme/mixins';
import { tariffsRestriction } from 'utils/helpers';

const DescriptionContainer = styled.div`
  color: #979BA0;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  display: flex;
  margin-top: 6px;
  align-items: center;

  ${InstantBooking} {
    margin-left: 4px;
    font-size: 15px;
    height: 15px;
    width: 15px;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${typography(14, 17, 500)};
  `}
`;

const AgentDescription = styled.div`
  color: #3AA6D2;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const AgentPrice = styled.div`
  color: #3AA6D2;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
`;

const ComissionDescription = styled.div`
  height: 18px;
  padding: 0 5px;
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #E3F4FC;
  display: flex;
  align-items: center;
  color: #6B707B;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
`;

const ComissionPrice = styled.div`
  color: #6B707B;
  font-size: 10px;
  margin-top: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: right;
`;

const TouristDescription = styled.div`
  color: #6B707B;
  margin-top: 5px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  
  &:first-letter {
    text-transform: uppercase;
  }
`;

const TouristPrice = styled.div`
  color: #6B707B;
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  ${typography(13, 15)};
`;

const DaysLabel = styled(Label)`
  ${({ theme }) => css`
    margin-top: 12px;
    color: ${theme.colors.text.disabled};
  `}
`;

const Days = styled(Label)`
  ${({ theme }) => css`
    margin-top: 12px;
    color: ${theme.colors.text.primary};
    opacity: 0.6;
  `}
`;

const BadgeWrapper = styled(Badge)`
  top: -24px;
  position: absolute;
`;

const Wrapper = styled.div`
  margin-top: 13px;
`;

const Content = styled.div`
  position: relative;
`;

@withTheme
@inject('commissionVisibility')
@observer
class Base extends React.Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired,
    t: PropTypes.func,
    commissionVisibility: PropTypes.object.isRequired,
    room_type: PropTypes.object.isRequired,
    hotel: PropTypes.object
  }

  @computed get withCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isAmountVisible;
  }

  @computed get withPercentCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isPercentVisible;
  }

  @computed get minDays() {
    const { t, tariff } = this.props;

    const restriction = tariffsRestriction(tariff);
    if (!restriction) return null;

    return t('Tariffs.FromDays', restriction);
  }

  render() {
    const { tariff: { name, price_formation, is_available }, t, tariff, room_type, hotel: { instant_booking } } = this.props;
    const duration = getDuration({ ...tariff });
    const descriptionText = [
      t('Orders.Reservations.ForDuration', { ...duration }),
      t('UI.Guests.ParentCase', { count: tariff.slots.length })
    ].join(' / ');

    const { net_price, gross_price, discount_in_percent, discount } = price_formation;

    return (
      <Content>
        {(!is_available || !room_type.is_available) &&
          <BadgeWrapper variant='secondary'>
            {t('Tariff.IsNotAvailable')}
          </BadgeWrapper>
        }

        <Title>
          {name}
        </Title>

        <DescriptionContainer>
          {descriptionText}

          {instant_booking && <InstantBooking variant='small' />}
        </DescriptionContainer>

        <Wrapper>
          {this.withCommission && (
            <FlexContainer>
              <AgentDescription>
                {t('UI.HotelCard.Price.Agent')}
              </AgentDescription>

              <AgentPrice>
                {t('Tariff.PriceFrom', { price: net_price.amount })}
              </AgentPrice>
            </FlexContainer>
          )}

          <FlexContainer>
            <TouristDescription>
              {t('Orders.Hotel.NetPrice.Tourist')}
            </TouristDescription>

            <TouristPrice>
              {t('Tariff.PriceFrom', { price: gross_price.amount })}
            </TouristPrice>
          </FlexContainer>

          {this.withCommission && (
            <FlexContainer>
              <ComissionDescription>
                {t('Common.Commission', { value: discount_in_percent })}
              </ComissionDescription>

              <ComissionPrice>
                {t('Tariff.PriceFrom', { price: discount.amount })}
              </ComissionPrice>
            </FlexContainer>
          )}

          {!!this.minDays && (
            <FlexContainer>
              <DaysLabel>
                {t('Tariff.Booking')}
              </DaysLabel>

              <Days>
                {this.minDays}
              </Days>
            </FlexContainer>
          )}
        </Wrapper>
      </Content>
    );
  }
}

export default styled(withTranslation()(Base))``;
