import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TreatmentProfiles from './TreatmentProfiles';
import HotelTypes from './HotelTypes';
import MealOptions from './MealOptions';
import Amenities from './Amenities';
import TreatmentFactors from './TreatmentFactors';
import LocationTypes from './LocationTypes';
import AccommodationFeatures from './AccommodationFeatures';
import InstantBooking from './InstantBooking';

const Filter = styled.div`
  margin-top: 16px;
  
  & + & {
    padding-top: 21px;
    margin-top: 13px;
    border-top: 2px solid #e9e8eb;
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  box-sizing: border-box;
  padding: 16px;
  overflowX: true;
`;

const Line = styled.div`
  width: calc(100% + 32px);
  margin-left: -16px;
  height: 1px;
  background: #D1D5DB;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
  box-sizing: border-box;
`;

function Filters({ query, onChange, className }) {
  const handleUpdate = (value) => {
    onChange(value);
  };

  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <InstantBooking
          value={query.filter.instant_booking}
          onChange={(value) => handleUpdate({ filter: { instant_booking: value } })}
        />

        <Line />

        <Filter
          as={TreatmentProfiles}
          value={query.filter.treatment_profiles}
          onChange={(value) => handleUpdate({ filter: { treatment_profiles: value } })}
        />

        <Filter
          as={HotelTypes}
          value={query.filter.hotel_types}
          onChange={(value) => handleUpdate({ filter: { hotel_types: value } })}
        />

        <Filter
          as={MealOptions}
          value={query.filter.meal_options}
          onChange={(value) => handleUpdate({ filter: { meal_options: value } })}
        />

        <Filter
          as={Amenities}
          value={query.filter.amenities}
          onChange={(value) => handleUpdate({ filter: { amenities: value } })}
        />

        <Filter
          as={TreatmentFactors}
          value={query.filter.treatment_factors}
          onChange={(value) => handleUpdate({ filter: { treatment_factors: value } })}
        />

        <Filter
          as={LocationTypes}
          value={query.filter.location_types}
          onChange={(value) => handleUpdate({ filter: { location_types: value } })}
        />

        <Filter
          as={AccommodationFeatures}
          value={query.filter.accommodation_features}
          onChange={(value) => handleUpdate({ filter: { accommodation_features: value } })}
        />
      </InnerWrapper>
    </Wrapper>
  );
}

Filters.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

Filters.defaultProps = {
  query: {},
  onChange: () => { }
};

export default styled(Filters)``;
