import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { FilterPicker } from '.';
import { Close } from 'components/icons';

import { display } from 'theme/mixins';

const FilterInput = styled.div`
  position: relative;
  margin: 4px 28px 4px 4px;

  ${Close} {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const StyledFilterPicker = styled(FilterPicker)`
  margin: 4px;
`;

const Controls = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')};
    position: absolute;
    right: 0;
    height: calc(100% - 8px);
    margin-left: auto;
    padding: 0 8px;
    border-left: 1px solid ${theme.colors.popup.primary};
  `}
`;

const ClearAllFiltersControl = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')};
    width: 30px;
    height: 30px;
    color: ${theme.colors.active.secondary};
    cursor: pointer;
    transition: color 0.25s;
    user-select: none;
  `}

  ${Close} {
    width: 12px;
    height: 12px;
    fill: currentColor;
  }
`;

@withRouter
@withTheme
@inject('filtersState')
@observer
class FiltersControls extends React.Component {
  static propTypes = {
    filtersState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable filter = null;

  handleFiltersChange = (filter) => {
    this.filter = filter;
  }

  handleFilterCancel = () => {
    this.filter = null;
  }

  handleFilterAdd = (filter) => {
    const { filtersState } = this.props;

    filtersState.addFilter(filter);
    this.filter = null;
  }

  handleClearAllFilters = () => {
    const { filtersState } = this.props;
    filtersState.clearAll();
  }

  get renderComponent() {
    const Component = this.filter.component;

    return (
      <Component
        filter={this.filter}
        onSubmit={this.handleFilterAdd}
      />
    );
  }

  render() {
    const { filtersState } = this.props;

    return (
      <>
        {this.filter
          ? <FilterInput>
              {this.renderComponent}

              <Close onClick={this.handleFilterCancel} />
            </FilterInput>
          : <StyledFilterPicker
              selectedFilters={filtersState.$('filters').value}
              onChange={this.handleFiltersChange}
            />
        }

        {filtersState.$('filters').value.length > 0 && (
          <Controls>
            <ClearAllFiltersControl onClick={this.handleClearAllFilters}>
              <Close />
            </ClearAllFiltersControl>
          </Controls>
        )}
      </>
    );
  }
}

export default FiltersControls;
