import React from 'react';
import { computed } from 'mobx';
import { Provider, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

import Empty from './Empty';
import Body from './Body';
import Loader from './Loader';

import ShowState from 'forms/Orders/ShowState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.active.primary};
  `}
`;

@withRouter
@observer
class Details extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    // Initialize state
    const { history } = this.props;
    this.showState = new ShowState({ history });
  }

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder() {
    const { order: { id } } = this.props;
    this.showState.fetch({ id });
  }

  @computed get isPending() {
    const { showState: { isPending } } = this;
    return isPending;
  }

  @computed get isFetched() {
    const { showState: { isFetched, order } } = this;
    return isFetched || order;
  }

  @computed get isEmpty() {
    const { showState } = this;
    return showState.isEmpty;
  }

  render() {
    const { className } = this.props;

    return (
      <Provider showState={this.showState}>
        <Wrapper className={className}>
          {this.isPending && (
            <Loader />
          )}

          {this.isFetched && (
            this.isEmpty ? <Empty /> : <Body />
          )}
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(withTranslation()(Details))``;
