import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setDisplayName } from 'recompose';
import { Provider, inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { Link, Redirect, withRouter } from 'react-router-dom';

import LoginState, { fields } from 'forms/Auth/LoginState';
import LoginForm from './components/LoginForm';
import Heading from '../../components/Heading';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
  justify-content: center;
  align-content: center;
`;

const LoginWrapper = styled.div`
  align-self: center;
  padding: 70px 128px 81px;
  width: 640px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;
`;

const Title = styled(Heading)`
  margin: 0 0 90px;
`;

const Subtitle = styled.p`
  margin: 60px 0 0 0;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #4a515c;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: #3c6f9d;
`;

@setDisplayName('Login')
@withRouter
@inject('authStore')
@observer
class Login extends React.Component {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.onLoginSuccess,
      onError: this.onLoginError
    };

    this.loginState = new LoginState(fields, { hooks });
  }

  @computed get isAuthenticated() {
    const { authStore } = this.props;
    return authStore.isAuthenticated;
  }

  onLoginSuccess = (form) => {
    const { authStore } = this.props;
    const values = form.values();

    authStore.login(values);
  };

  onLoginError = (form) => {
    console.log(form.errors());
  };

  render() {
    const { className, authStore, location, ...rest } = this.props;

    if (this.isAuthenticated) {
      const fromUrl = location.state?.from || '/orders/new';
      return <Redirect to={fromUrl} />;
    }

    if (location.state?.user) {
      const { email } = location.state.user;
      this.loginState.update({ email });
    }

    return (
      <Provider loginState={this.loginState}>
        <Wrapper className={className} {...rest}>
          <span style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: '<!-- Логин в Метрике -->' }} />
          <Title />

          <LoginWrapper>
            <LoginForm />

            <Subtitle>
              У меня нет аккаунта. <StyledLink to='/register'>Зарегистрироваться</StyledLink>
            </Subtitle>
          </LoginWrapper>
        </Wrapper>
      </Provider>
    );
  }
}

export default Login;
