import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Attention = styled.div`
`;

const TitleContainer = styled.div`

`;

const RoomTypeTitle = styled.div`
  color: #4A515C;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const TariffTitle = styled.div`
  margin-top: 6px;
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  ${TitleContainer} {
    flex-grow: 0;
  }

  ${TitleContainer} + ${Attention} {
    margin-left: 28px;
  }
`;

@withTranslation()
@inject('bookingState')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingState: PropTypes.object.isRequired,
    t: PropTypes.func
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingState, t, ...rest } = this.props;

    const { room_type, tariff } = bookingState;

    return (
      <Wrapper {...rest}>
        <TitleContainer>
          <RoomTypeTitle>
            {[t('Common.RoomType'), room_type.name].join(': ')}
          </RoomTypeTitle>

          <TariffTitle>
            {[t('Common.Tariff'), tariff.name].join(': ')}
          </TariffTitle>
        </TitleContainer>
      </Wrapper>
    );
  }
}

export default styled(Header)``;
