import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import YandexMetrika from './YandexMetrika';

function Analitics({ timeout }) {
  const [isTimerEnd, setIsTimerEnd] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsTimerEnd(true), timeout);
  }, []);

  const isProduction = process.env.NODE_ENV === 'production';

  if (!isProduction) return null;

  if (!process.env.REACT_APP_ANALITICS_ENABLED) return null;

  if (!isTimerEnd) return null;

  return (
    <React.Fragment>
      <YandexMetrika />
    </React.Fragment>
  );
}

Analitics.propTypes = {
  timeout: PropTypes.number
};

Analitics.defaultProps = {
  timeout: 3000
};

export default Analitics;
