import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { SearchQueryProvider } from 'hooks/useQuery';

import List from '../Orders/containers/List';

@withRouter
@observer
class Resale extends Component {
  render() {
    return (
      <SearchQueryProvider>
        <Switch>
          <Route exact path='/resale' component={List} />
        </Switch>
      </SearchQueryProvider>
    );
  }
}

export default Resale;
