function transform(input, callback) {
  if (!input || typeof input !== 'object') return input;
  if (Array.isArray(input)) return input.map(item => transform(item, callback));

  return Object.keys(input).reduce((acc, key) => {
    const val = input[key];
    acc[callback(key)] = (typeof val === 'object') ? transform(val, callback) : val;
    return acc;
  }, {});
}

export default transform;
