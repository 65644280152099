import { types } from 'mobx-state-tree';

const Version = types.model('Version', {
  url: types.string
});

const Image = types.model('Image', {
  url: types.string,
  thumb: types.maybeNull(Version),
  landscape_big: types.maybeNull(Version),
  landscape_small: types.maybeNull(Version),
  encoded_url: types.string
});

export default Image;
