import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import OrderNumber from './OrderNumber';
import Status from './Status';
import Creator from './Creator';
import PrimaryActions from './PrimaryActions';
import NotAvailableActions from './NotAvailableActions';

import Resale from './Resale';

const Title = styled.div`
  color: #6b707b;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:first-child {
    margin-borrom: 14px;
  }

  &:last-child {
    margin-top: 30px;
  }

  ${Status} {
    max-width: 396px;
    flex-grow: 1;
  }

  ${Creator} {
  }

  ${OrderNumber} + ${Status} {
    margin-left: 24px;
  }
`;

const RightContent = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
  padding: 20px 26px 26px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
`;

@inject('showState')
@withRouter
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get isCancelled() {
    const {
      showState: { order }
    } = this.props;
    return order.provider_state.code === 'cancelled';
  }

  handleSwap = (id) => {
    this.props.showState.swapId(id);
  };

  updateOrder = () => {
    this.props.showState.fetch({ id: this.props.showState?.order?.id });
  };

  render() {
    const {
      showState: { order },
      t,
      ...rest
    } = this.props;

    const {
      provider_state: { code }
    } = order;

    return (
      <Wrapper {...rest}>
        <Row>
          <Title>{t('Order.Title')}</Title>

          <RightContent>
            {code === 'confirmed' && <Resale order={order} onClick={this.handleSwap} updateOrder={this.updateOrder} />}

            <Creator order={order} />
          </RightContent>
        </Row>

        <Row>
          <OrderNumber order={order} />

          <Status order={order} />
        </Row>

        <Row>{this.isCancelled ? <NotAvailableActions /> : <PrimaryActions />}</Row>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
