import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/forms/Button';
import Badge from 'components/ui/Badge';

const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

const ListButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  border-radius: 24px;
  height: 36px;
  font-size: 13px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 18px 8px;
  background-color: #FFF;
  border-radius: 9px;
  box-sizing: border-box;

  ${Badge} {
    margin-left: 9px;
  }
`;

function Selected({ total, onClear, className }) {
  const { t } = useTranslation();

  const handleClear = () => {
    onClear();
  };

  return (
    <Wrapper className={className}>
      <HeaderActions>
        <ActionTitle>
          {t('Filters.Apply')}
        </ActionTitle>

        <Badge>
          {total}
        </Badge>
      </HeaderActions>

      <ListButton
        // eslint-disable-next-line no-use-before-define
        size='large'
        type='primary'
        onClick={handleClear}
      >
        {t('Filters.Clear')}
      </ListButton>
    </Wrapper>
  );
}

Selected.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  onClear: PropTypes.func
};

Selected.defaultProps = {
  total: 0,
  onClear: () => {}
};

export default styled(Selected)``;
