import { t } from 'utils/localization';

const fields = [
  'hotel',
  'hotel.id',
  'hotel.name',
  'hotel.address',
  'hotel.address.location',
  'hotel.address.coordinates'
];

const placeholders = {
  hotels: t('Orders.Filters.Hotels.Label')
};

export default {
  fields,
  placeholders
};
