import instance from 'connection/instance';

const SEARCH_URL = '/api/hotels/search';

class LazyStore {
  constructor(options = {}) {
    this.data = [];
  }

  // Hooks
  onSetStatus = () => {};
  onSetTotal = () => {};
  onSetData = () => {};
  onSetAvailable = () => {};

  setHooks(options = {}) {
    const defaultCb = () => {};

    this.onSetStatus = options.onSetStatus || defaultCb;
    this.onSetTotal = options.onSetTotal || defaultCb;
    this.onSetData = options.onSetData || defaultCb;
    this.onSetSeo = options.onSetSeo || defaultCb;
    this.onSetAvailable = options.onSetAvailable || defaultCb;
  }

  setData(value) {
    this.data = [...this.data, ...value];
    this.onSetData(this.data);
  }

  resetData(value) {
    this.data = value;
    this.onSetData(this.data);
  }

  // Pagination
  currentPage = 1

  perPage = 50

  totalPages = 0

  // Total
  total = 0

  setTotal(value) {
    this.total = value;
    this.onSetTotal(this.total);
  }

  // seo
  seo = {}

  setSeo(value) {
    this.seo = value;
    this.onSetSeo(this.seo);
  }

  // Available
  available = 0

  setAvailable(value) {
    this.available += Number(value);
    this.onSetAvailable(this.available);
  }

  // Store state
  status = undefined

  setStatus(value) {
    this.status = value;
    this.onSetStatus(this.status);
  }

  async fetch(params) {
    try {
      this.setStatus('pending');
      const { status, data } = await instance.get(SEARCH_URL, { params });

      if (status !== 200) {
        this.setStatus('error');
        return;
      }

      this.parseResponse(data);
      this.setStatus('done');
    } catch {
      this.setStatus('error');
    }
  }

  async fetchMore() {
    if (this.currentPage >= this.totalPages) return;

    const paginate = { page: this.currentPage + 1, limit: this.perPage };
    const params = { ...this.query, paginate };

    try {
      this.setStatus('pending');
      const { status, data } = await instance.get(SEARCH_URL, { params });

      if (status !== 200) {
        this.setStatus('error');
        return;
      }

      this.parseChunk(data);
      this.setStatus('done');

      this.currentPage++;
    } catch {
      this.setStatus('error');
    }
  }

  parseResponse = (response) => {
    const { meta: { total, paginate, seo }, hotels } = response;

    this.currentPage = Number(paginate.page);
    this.perPage = Number(paginate.limit);
    this.totalPages = Number(paginate.total_pages);

    this.setTotal(total);
    this.setSeo(seo);
    this.resetData(hotels);
    this.setAvailable(hotels.length);
  }

  parseChunk(response) {
    const { hotels } = response;
    this.setData(hotels);
    this.setAvailable(hotels.length);
  }
}

export default LazyStore;
