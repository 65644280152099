import axios from 'axios';
import Qs from 'qs';

import notAuthorized from './interceptors/notAuthorized.js';
import { applyMocks } from './mocks';

const instance = axios.create({
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' })
});

instance.interceptors.response.use(...notAuthorized);

applyMocks(instance);

export default instance;
