import { types, applySnapshot, resolveIdentifier, detach } from 'mobx-state-tree';
import instance from 'connection/instance';
import moment from 'utils/moment';

import DailyAvailability from './DailyAvailability';
import Meta from './Meta';

const AvailabilityStore = types.model('AvailabilityStore', {
  meta: types.maybeNull(Meta),
  data: types.optional(types.array(DailyAvailability), []),
  state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
}).views(self => ({
  get isFetched() {
    return self.state === 'done';
  },

  get isPending() {
    return self.state === 'pending';
  },

  get isError() {
    return self.state === 'error';
  },

  get disabledDays() {
    return [];
  }
})).actions(self => ({
  fetch(id, params = {}) {
    self.setState('pending');

    return instance.get(`/api/hotels/${id}/tariff_rates`, { params })
      .then(response => self.resetStore(response))
      .then(() => self.setState('done'))
      .catch(self.errorHandler);
  },

  nextMonth() {
    const { check_in } = self;

    // Set checkIn
    const nextCheckIn = moment(check_in)
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    self.check_in = nextCheckIn;

    // Set checkOut
    const nextCheckOut = moment(check_in)
      .add(2, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    self.check_out = nextCheckOut;
  },

  prevMonth() {
    const { check_in } = self;

    // Set checkIn
    const prevCheckIn = moment(check_in)
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    self.check_in = prevCheckIn;

    // Set checkOut
    const prevCheckOut = moment(check_in)
      .endOf('month')
      .format('YYYY-MM-DD');

    self.check_out = prevCheckOut;
  },

  errorHandler(error) {
    self.setState('error');
    return Promise.reject(error);
  },

  clear() {
    detach(self);
  },

  setState(state) {
    self.state = state;
    return self;
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) applySnapshot(self, data.data);

    return self;
  },

  findOne(day) {
    return resolveIdentifier(DailyAvailability, self, day);
  }
}));

export default AvailabilityStore;
