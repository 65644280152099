import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { IMaskMixin } from 'react-imask';

const PHONE_MASK = '+{7} (000) 000-00-00';

const Input = styled.input`
  flex-grow: 1;
  width: 100%;
  height: inherit;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  &::placeholder {
    color: #999999;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #D1D5DB;
  background: #FFFFFF;
  font-family: Helvetica Neue;
  color: #4A515C;
  box-sizing: border-box;
  font-size: 13px;
  padding: 0 12px;
  border-radius: 3px;

  ${({ size }) => size === 'medium' && css`
    height: 36px;
  `}

  ${({ size }) => size === 'large' && css`
    height: 50px;
  `}

  ${({ isFocused }) => isFocused && css`
    border: 1px solid #ABB3BF;
    z-index: 9;
  `}

  ${({ isError }) => isError && css`
    border: 1px solid #E26161;
    color: #E26161;
  `}

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    opacity: 0.7;
  `}
`;

const PhoneInput = IMaskMixin(({ error, disabled, inputRef, className, size, ...rest }) => {
  return (
    <Wrapper
      size={size}
      isError={error}
      isDisabled={disabled}
      className={className}
    >
      <Input
        ref={inputRef}
        mask={PHONE_MASK}
        {...rest}
      />
    </Wrapper>
  );
});

PhoneInput.defaultProps = {
  className: '',
  size: 'medium',
  value: undefined,
  onChange: () => {},
  error: false,
  disabled: false
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

export default styled(PhoneInput)``;
