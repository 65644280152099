import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  InvoiceExpiredIcon,
  InvoicePaidIcon,
  InvoiceRequiredIcon,
  InvoiceTriggeredIcon
} from 'components/icons';

const IconSVG = {
  triggered: InvoiceTriggeredIcon,
  required: InvoiceRequiredIcon,
  expired: InvoiceExpiredIcon,
  paid: InvoicePaidIcon
};

const Icon = styled.div`
  font-size: 10px;
  color: currentColor;
`;

const Text = styled.div`
  font-size: inherit;
  line-height: inherit;
  color: currentColor;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  font-size: 12px;
  line-height: 14px;

  ${Icon} + ${Text} {
    margin-left: 6px;
  }

  ${({ code }) => code === 'triggered' && css`
    color: #E26161;
  `}

  ${({ code }) => code === 'paid' && css`
    color: #6B707B;
  `}

  ${({ code }) => code === 'required' && css`
    color: #E9993A;
  `}

  ${({ code }) => code === 'expired' && css`
    color: #979BA0;
  `}
`;

function InvoiceState({ code, variant, className }) {
  const { t } = useTranslation();

  return (
    <Wrapper
      code={code}
      className={className}
    >
      {variant === 'short' && (
        <Text>
          {t('UI.InvoiceState.ShortText', { value: code })}
        </Text>
      )}

      {variant === 'long' && (
        <>
          <Icon
            as={IconSVG[code]}
          />

          <Text>
            {t('UI.InvoiceState.LongText', { value: code })}
          </Text>
        </>
      )}
    </Wrapper>
  );
}

InvoiceState.defaultProps = {
  variant: 'long'
};

InvoiceState.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['short', 'long']),
  code: PropTypes.oneOf(['not_defined', 'triggered', 'required', 'expired', 'paid'])
};

export default styled(InvoiceState)``;
