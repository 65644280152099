import { observable, action, computed, toJS } from 'mobx';
import _pick from 'lodash/pick';
import { orderSerializer } from './serializers';

import OrderStore from 'stores/OrdersStore/One';

const GROUP_BY = ['reservation', 'tariff', 'dates'];

class ShowState {
  constructor(props = {}) {
    this.history = props.history || window.history;
    this.orderStore = OrderStore.create();
  }

  @observable orderState = undefined;

  @computed get isFetched() {
    return !!this.order;
  }

  @computed get isPending() {
    return this.orderState === 'pending';
  }

  @computed get isCreated() {
    const { code } = this.order.provider_state;
    return code === 'created';
  }

  @computed get isBooked() {
    const { code } = this.order.provider_state;
    return code === 'booked';
  }

  @computed get isConfirmed() {
    const { code } = this.order.provider_state;
    return code === 'confirmed';
  }

  @computed get isCancelled() {
    const { code } = this.order.provider_state;
    return code === 'cancelled';
  }

  @computed get isArchived() {
    const { code } = this.order.provider_state;
    return code === 'archived';
  }

  @computed get isEmpty() {
    const {
      order: { reservations }
    } = this;
    return reservations.length === 0;
  }

  @computed get hasInvoice() {
    const {
      order: { has_invoice }
    } = this;
    return !!has_invoice;
  }

  @action swapId(id) {
    const order = { ...this.order };
    order.id = id;
    this.order = order;
  }

  @action
  setOrderState(state) {
    this.orderState = state;
  }

  @action
  unsetOrderState() {
    this.orderState = undefined;
  }

  async fetch(params) {
    this.setOrderState('pending');
    await this.orderStore.fetch(params);
    const order = toJS(this.orderStore.data);
    this.onFetch(order);
    this.setOrderState('done');
  }

  async updateContract(contract) {
    let params = _pick(this.order, ['id', 'check_in', 'check_out', 'address', 'hotel']);

    params = { contract, ...params };

    this.setOrderState('pending');
    await this.orderStore.update(params);
    const order = toJS(this.orderStore.data);
    this.onFetch(order);
    this.setOrderState('done');
  }

  async cancel() {
    this.setOrderState('pending');
    await this.orderStore.cancel();
    const order = toJS(this.orderStore.data);
    this.onFetch(order);
    this.setOrderState('done');
  }

  async cancelReservation({ id }) {
    this.setOrderState('pending');
    await this.orderStore.cancelReservation(id);
    const order = toJS(this.orderStore.data);
    this.onFetch(order);
    this.setOrderState('done');
  }

  async syncOnPlatform() {
    this.setOrderState('pending');
    await this.orderStore.syncOnPlatform();
    const order = toJS(this.orderStore.data);
    this.onFetch(order);
    this.setOrderState('done');
  }

  async edit() {
    const {
      order: { id, hotel },
      history,
      isConfirmed,
      isBooked,
      isCreated
    } = this;

    if (isCreated) {
      hotel ? history.push(`/orders/${id}/edit`) : history.push(`/orders/${id}/search`);
    }

    if (isBooked) {
      history.push(`/orders/${id}`);
    }

    if (isConfirmed) {
      history.push(`/orders/${id}`);
    }

    return Promise.resolve();
  }

  async addRoom() {
    const {
      history,
      order: { id, hotel }
    } = this;

    hotel ? history.push(`/orders/${id}/edit`) : history.push(`/orders/${id}`);

    return Promise.resolve();
  }

  async editReservation({ id }) {
    const {
      order: { id: order_id },
      history
    } = this;
    history.push(`/orders/${order_id}/edit/${id}`);

    return Promise.resolve();
  }

  async archive() {
    const {
      order: { id },
      history,
      orderStore
    } = this;

    this.setOrderState('pending');
    await orderStore.archive({ id });
    this.setOrderState('done');
    history.push('/orders');
  }

  async confirm() {
    const {
      order: { id },
      history,
      orderStore
    } = this;

    this.setOrderState('pending');
    await orderStore.confirm({ id });
    this.setOrderState('done');
    history.push('/orders');
  }

  async downloadVoucher() {
    const {
      order: { id },
      orderStore
    } = this;

    this.setOrderState('pending');
    await orderStore.downloadVoucher({ id });
    this.setOrderState('done');
  }

  async downloadInvoice() {
    const {
      order: { id },
      orderStore
    } = this;

    this.setOrderState('pending');
    await orderStore.downloadInvoice({ id });
    this.setOrderState('done');
  }

  @action
  onFetch(order) {
    this.setOrder(order);
  }

  @observable.ref order = undefined;

  @action
  setOrder(order) {
    this.order = order;
    this.onSetOrder(order);
  }

  @action
  unsetOrder() {
    this.order = undefined;
  }

  @action
  onSetOrder(order) {
    this.setValues(order);
  }

  // GroupBy
  @observable groupBy = GROUP_BY[0];

  @computed get groupViews() {
    return GROUP_BY;
  }

  @action
  setGroupBy(view) {
    const value = GROUP_BY.includes(view) ? view : GROUP_BY[0];
    this.groupBy = value;

    this.onSetGroupBy(value);
  }

  @action
  unsetGroupBy() {
    this.groupBy = undefined;
  }

  @action
  onSetGroupBy(value) {
    const { order } = this;
    this.setValues(order);
  }

  // View values
  @observable.ref values = undefined;

  @action
  setValues(order) {
    const { groupBy } = this;

    const data = orderSerializer(order, { view: groupBy });
    this.values = data;

    this.onSetValues(data);
  }

  @action
  onSetValues(data) {
    const { groups } = data;
    this.selectGroup(groups[0]);
  }

  // Groups selected
  @observable.ref selectedGroup = undefined;

  @action
  selectGroup(group) {
    this.selectedGroup = group;
  }

  @action
  deselectGroup() {
    this.selectedGroup = undefined;
  }

  @action
  groupIsSelected(group) {
    return this.selectedGroup?.id === group.id;
  }
}

export default ShowState;
