import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Redirect, useLocation, Link } from 'react-router-dom';

import Heading from '../../components/Heading';

const Title = styled.div`
  color: #9cb4ca;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.div`
  text-wrap: balance;
  color: #4f5660;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Email = styled(Description)`
  display: inline;
  color: rgba(90, 178, 214, 1);
`;

const Content = styled.div`
  align-self: center;
  padding: 70px 128px 81px;
  width: 640px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;
  gap: 20px;
`;

const Logo = styled(Heading)`
  margin: 0 0 90px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
  justify-content: center;
  align-content: center;
`;

const LinkComponent = styled(Link)`
  margin-top: 20px;
  background-color: #5ab2d6;
  border-radius: 4px;
  text-align: center;
  padding: 16px;
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
`;

function SuccessRegister({ className }) {
  const { search } = useLocation();

  if (!search) {
    return <Redirect to='/login' />;
  }

  const email = search.split('=')[1];

  return (
    <Wrapper className={className}>
      <Logo />
      <Content>
        <Title>Проверьте почту</Title>

        <Description>
          На вашу почту <Email>{email}</Email> отправлено <br /> письмо для завершения регистрации в <br /> системе.
        </Description>

        <Description>Для активации учетной записи перейдите по ссылке в письме и установите пароль.</Description>

        <LinkComponent to='/login'>На главную</LinkComponent>
      </Content>
    </Wrapper>
  );
}

SuccessRegister.propTypes = {
  className: PropTypes.string
};

export default styled(SuccessRegister)``;
