import { types, applySnapshot, getSnapshot, applyPatch, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';
import { OrderItem } from './OrderItem';

const Paginate = types
  .model('Paginate', {
    limit: types.number,
    page: types.number,
    total: types.number
  });

const OrdersListMeta = types
  .model('OrdersListMeta', {
    paginate: Paginate
  });

const OrdersStore = types
  .model('OrdersStore', {
    data: types.optional(types.array(OrderItem), []),
    meta: types.maybeNull(OrdersListMeta),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get selectOptions() {
      return getSnapshot(self.data);
    }
  }))
  .actions(self => ({
    fetch(options = {}) {
      self.setState('pending');

      const { page = 1 } = options;

      const paginate = {
        page,
        limit: 100
      };

      const params = { ...options, paginate };

      return instance.get('/api/orders', { params })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    fetchMore(options = {}) {
      self.setState('pending');

      const { page } = options;

      const paginate = {
        page,
        limit: 100
      };

      const params = { ...options, paginate };

      return instance.get('/api/orders', { params })
        .then(response => self.updateStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) applySnapshot(self, data);

      return self;
    },

    updateStore(response) {
      const { status } = response;

      if (status === 200) {
        const { data, meta } = response.data;

        const metaPatch = [{
          op: 'replace',
          path: '/meta',
          value: meta
        }];

        const ordersPatch = data.map(order => ({
          op: 'add',
          path: '/data/-',
          value: order
        }));

        applyPatch(self, metaPatch.concat(ordersPatch));
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    },

    patchItem(id, attrs) {
      const item = resolveIdentifier(OrderItem, self, id);

      const idx = self.data.findIndex(order => order.id === item.id);
      const value = Object.assign({}, item.toJSON(), attrs);

      applyPatch(self, {
        op: 'replace',
        path: `/data/${idx}`,
        value: value
      });
    },

    removeItem(id) {
      const item = resolveIdentifier(OrderItem, self, id);

      if (!item) {
        return;
      }

      const idx = self.data.findIndex(order => order.id === item.id);

      applyPatch(self, { op: 'remove', path: `/data/${idx}` });
    }
  }));

export default OrdersStore;
