import React from 'react';
import PropTypes from 'prop-types';
import { DefaultTemplate } from 'components/templates';

import { Header } from 'components/organisms';
import Body from './Body';

function Offer({ className }) {
  return (
    <DefaultTemplate
      header={<Header />}
      body={<Body />}
    />
  );
}

Offer.propTypes = {
  className: PropTypes.string
};

export default Offer;
