import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Label = styled.div`
  color: #6B707B;
  font-size: 12px;
  line-height: 14px;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Label} + ${Value} {
    margin-top: 4px;
  }

  ${({ variant }) => variant === 'secondary' && css`
    ${Value} {
      color: #4A515C;
    }
  `}

  ${({ variant }) => variant === 'primary' && css`
    ${Value} {
      font-weight: 500;
      color: #3AA6D2;
    }
  `}
`;

function Row({ label, value, variant, className }) {
  return (
    <Wrapper
      className={className}
      variant={variant}
    >
      {label && (
        <Label>{label}</Label>
      )}

      <Value>{value}</Value>
    </Wrapper>
  );
}

Row.defaultProps = {
  variant: 'secondary'
};

Row.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.any,
  value: PropTypes.any.isRequired
};

export default styled(Row)``;
