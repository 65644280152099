import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.span`
  line-height: 1.5em;
  white-space: nowrap;
`;

class Date extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired
  };

  render() {
    const { date, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {moment(date).format('D MMM YYYY')}
      </Wrapper>
    );
  }
}

export default styled(Date)``;
