import { types } from 'mobx-state-tree';

import { Address } from './Address';
import { Taxes } from './Taxes';

export const Hotel = types.model('Hotel', {
  id: types.identifier,
  name: types.string,
  address: Address,
  taxes: types.maybeNull(types.array(Taxes))
});
