import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import styled from 'styled-components';

import Number from './Number';
import Traveller from './Traveller';
import Rate from './Rate';
import Price from './Price';

import { Delete as DeleteIcon } from 'components/icons';

const Actions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${Rate} {
    margin-left: 62px;
    flex-grow: 1;
    width: 236px;
  }

  ${Price}, ${Actions} {
    width: 64px;
  }

  ${Traveller} {
    max-width: 546px;
    flex-grow: 0;
  }

  & > div {
    margin-left: 14px;

    &:first-child {
      margin-left: 0;
    }
  }

  & + & {
    margin-top: 10px;
  }
`;

@inject('bookingForm')
@observer
class Slot extends React.Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    field: PropTypes.object.isRequired,
    rateOptions: PropTypes.array,
    bookingForm: PropTypes.object.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    className: '',
    rateOptions: []
  }

  @observable hovered = false;

  @action
  setHovered() {
    this.hovered = true;
  }

  @action
  unsetHovered() {
    this.hovered = false;
  }

  @computed get hasRemove() {
    const { bookingForm: { selectedCount } } = this.props;
    return selectedCount > 1;
  }

  handleMouseEnter = (e) => {
    e.preventDefault();

    if (this.hasRemove) {
      this.setHovered();
    }
  }

  handleMouseLeave = (e) => {
    e.preventDefault();

    if (this.hasRemove) {
      this.unsetHovered();
    }
  }

  handleRemove = (e) => {
    e.preventDefault();

    const { field, bookingForm } = this.props;
    bookingForm.removeSlot(field);
  }

  render() {
    const { number, field, rateOptions, className } = this.props;

    const { hovered, handleMouseEnter, handleMouseLeave } = this;

    return (
      <Wrapper
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Number number={number} />

        <Traveller slot={field} />

        <Rate
          slot={field}
          rateOptions={rateOptions}
        />

        {hovered && (
          <Actions onClick={this.handleRemove}>
            <DeleteIcon />
          </Actions>
        )}

        {!hovered && (
          <Price slot={field} />
        )}
      </Wrapper>
    );
  }
}

export default styled(Slot)``;
