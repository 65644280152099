import React from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Prompt } from 'components/ui';
import { Modal } from 'components/molecules';
import { Button } from 'components/forms';

@observer
class RequestInfo extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    actionText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isLoading: false
  };

  render() {
    const { title, actionText, bodyText, handleClose, isLoading } = this.props;

    return (
      <Modal opened>
        <Prompt>
          <Prompt.Content>
            <Prompt.Title>
              {title}
            </Prompt.Title>
            {bodyText}
          </Prompt.Content>

          <Prompt.Footer>
            <Prompt.Buttons>
              <Button
                onClick={handleClose}
                loading={isLoading}
                rounded
                variant='primary'
              >
                {actionText}
              </Button>
            </Prompt.Buttons>
          </Prompt.Footer>
        </Prompt>
      </Modal>
    );
  }
}

export default styled(withTranslation()(RequestInfo))``;
