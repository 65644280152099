import { useState, useEffect } from 'react';

import Store from '../TreatmentProfileStore';

const getStatuses = (status) => {
  const isPending = status === 'pending';
  const isFetched = status === 'done';
  const isError = status === 'error';
  const isEmpty = status === 'empty';

  return { isPending, isFetched, isError, isEmpty };
};

const useTreatmentProfile = (params = {}) => {
  const store = new Store();

  // Status of request
  const [status, setStatus] = useState(undefined);

  // Data
  const [data, setData] = useState([]);

  const doFetch = async (params) => {
    try {
      setStatus('pending');
      const { data } = await store.fetch(params);
      setData(data);
      setStatus('done');
    } catch {
      setStatus('error');
      setData([]);
    }
  };

  useEffect(() => {
    doFetch(params);
  }, []);

  return [{ data, doFetch, ...getStatuses(status) }];
};

export default useTreatmentProfile;
