function normalizeAccommodationFeatures({ accommodation_features }) {
  return {
    accommodation_features: (accommodation_features || [])
  };
}

function normalize_amenities({ amenities }) {
  return {
    amenities: (amenities || [])
  };
}

function normalizeLocationTypes({ location_types }) {
  return {
    location_types: (location_types || [])
  };
}

function normalizeMealOptions({ meal_options }) {
  return {
    meal_options: (meal_options || [])
  };
}

function normalizeTreatmentFactors({ treatment_factors }) {
  return {
    treatment_factors: (treatment_factors || [])
  };
}

function normalizeTreatmentProfiles({ treatment_profiles }) {
  return {
    treatment_profiles: (treatment_profiles || [])
  };
}

function normalizeHotelTypes({ hotel_types }) {
  return {
    hotel_types: (hotel_types || [])
  };
}

function normalizePrice({ price }) {
  const values = Object.values(price).map(item => Number(item));

  const min = Math.min(...values);
  const max = Math.max(...values);

  return {
    price: {
      min: (min || 0),
      max: (max || 0)
    }
  };
}

function normalizeInstantBooking({ instant_booking }) {
  return {
    instant_booking: Number(instant_booking)
  };
}

function normalizeSort({ sort_by, direction }) {
  return {
    // eslint-disable-next-line no-use-before-define
    sort_by: (sort_by || 'rating'),
    direction: (direction || -1)
  };
}

function normalizeRating(rating) {
  return {
    rating: (rating || 0)
  };
}

function normalizeFiltersParams(params) {
  let { data: { filter, sort, rating } } = params;
  filter ||= { };
  sort ||= { };

  const filterData = {
    ...normalizeAccommodationFeatures(filter),
    ...normalize_amenities(filter),
    ...normalizeLocationTypes(filter),
    ...normalizeMealOptions(filter),
    ...normalizeTreatmentFactors(filter),
    ...normalizeTreatmentProfiles(filter),
    ...normalizeHotelTypes(filter),
    ...normalizePrice(filter),
    ...normalizeRating(rating),
    ...normalizeInstantBooking(filter)
  };

  const sortData = {
    ...normalizeSort(sort)
  };

  return { filterBy: filterData, sortBy: sortData };
}

export default normalizeFiltersParams;
