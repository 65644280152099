import { Field } from 'mobx-react-form';
import { computed } from 'mobx';

class ContractField extends Field {
  @computed get isExpired() {
    return new Date(this.$('end_date').value).getTime() < new Date().getTime();
  }
}

export default ContractField;
