import { css } from 'styled-components';

const transition = (props) => css`
  transition: all 0.2s ease-in-out;
`;

const size = ({ size }) => {
  if (size === 'small') {
    return css`
      width: auto;
      height: 36px;
      padding: 0 14px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  if (size === 'medium') {
    return css`
      width: auto;
      height: 42px;
      padding: 0 22px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  if (size === 'big') {
    return css`
      width: auto;
      height: 46px;
      padding: 0 24px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  if (size === 'large') {
    return css`
      width: auto;
      height: 50px;
      padding: 0 24px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 4px;
      text-transform: uppercase;
    `;
  }

  return css``;
};

const variant = ({ variant, loading }) => {
  if (variant === 'primary') {
    return css`
      color: #ffffff;
      background-color: #5ab2d6;
      border: 2px solid transparent;

      &:hover {
        background-color: #3aa6d2;
      }

      &:active {
        background-color: #2e96c1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #979ba0;
      background-color: #e4e8ee;
      border: 2px solid transparent;

      &:hover {
        background-color: #d1d8e3;
      }

      &:active {
        background-color: #c0c9d6;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #ffffff;
      background-color: #e26161;
      border: 2px solid transparent;

      &:hover {
        background-color: #be4040;
      }

      &:active {
        background-color: #9d2f2f;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #ffffff;
      background-color: #62cb94;
      border: 2px solid transparent;

      &:hover {
        background-color: #359965;
      }

      &:active {
        background-color: #257f50;
      }
    `;
  }

  return css``;
};

const outline = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #5ab2d6;
      border: 2px solid currentColor;

      &:hover {
        color: #ffffff;
        background-color: #3aa6d2;
        border-color: #3aa6d2;
      }

      &:active {
        color: #ffffff;
        background-color: #2e96c1;
        border-color: #2e96c1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #9cb4ca;
      border: 2px solid currentColor;

      &:hover {
        color: #6a8197;
      }

      &:active {
        color: #556b80;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #e26161;
      border: 2px solid currentColor;

      &:hover {
        color: #be4040;
      }

      &:active {
        color: #9d2f2f;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #62cb94;
      border: 2px solid currentColor;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257f50;
      }
    `;
  }

  return css``;
};

const compact = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      padding: 0;
      color: #3c6f9d;

      &:hover {
        color: #3c6f9d;
      }

      &:active {
        color: #3c6f9d;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      padding: 0;
      color: #9cb4ca;

      &:hover {
        color: #6a8197;
      }

      &:active {
        color: #556b80;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      padding: 0;
      color: #e26161;

      &:hover {
        color: #be4040;
      }

      &:active {
        color: #9d2f2f;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      padding: 0;
      color: #62cb94;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257f50;
      }
    `;
  }

  return css``;
};

const disabled = (props) => css`
  pointer-events: none;
  opacity: 0.5;
`;

const rounded = ({ size }) => {
  if (size === 'small') {
    return css`
      padding: 0 22px;
      border-radius: 9999px;
    `;
  }

  if (size === 'medium') {
    return css`
      padding: 0 26px;
      border-radius: 9999px;
    `;
  }

  if (size === 'big') {
    return css`
      padding: 0 32px;
      border-radius: 9999px;
    `;
  }

  return css``;
};

const loading = ({ loading }) => css`
  pointer-events: none;

  & > * {
    opacity: 0;
  }
`;

const defaults = (props) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
`;

const theme = (props) => [
  defaults(props),
  transition(props),
  size(props),
  props.outline ? outline(props) : css``,
  props.compact ? compact(props) : css``,
  props.disabled ? disabled(props) : css``,
  props.rounded ? rounded(props) : css``,
  props.loading ? loading(props) : css``,
  !props.compact && !props.outline ? variant(props) : css``
];

export default theme;
