import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useBalance } from 'components/ui/Balance';
import { useTranslation } from 'react-i18next';

import Statistic from 'components/ui/Statistic';
import formatPrice from 'utils/formatPrice';

const Header = styled.div`
  color: #4A515C;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
`;

const Cell = styled.div`
  padding: 5px 24px 15px;
  border-left: 1px solid #e8eaec;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${Cell}:first-child {
    padding-left: 0px;
    border-left: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${Header} + ${Body} {
    margin-top: 20px;
  }
`;

function Credit({ className }) {
  const { t } = useTranslation();

  const [balanceData] = useBalance();

  if (!balanceData) {
    return null;
  }

  let assetValue = Object.values(balanceData.asset_balance);
  assetValue = formatPrice(...assetValue);

  let creditValue = Object.values(balanceData.credit_limit);
  creditValue = formatPrice(...creditValue);

  let depositValue = Object.values(balanceData.debit_limit);
  depositValue = formatPrice(...depositValue);

  let balanceValue = Object.values(balanceData.balance);
  balanceValue = formatPrice(...balanceValue);

  return (
    <Wrapper className={className}>
      <Header>
        {t('Finances.Credit')}
      </Header>
      <Body>
        <Cell>
          <Statistic
            label={t('Finances.Balance.Liability')}
            value={balanceValue}
          />
        </Cell>
        <Cell>
          <Statistic
            label={t('Finances.Balance.Deposit')}
            value={depositValue}
            variant='muted'
          />
        </Cell>
        <Cell>
          <Statistic
            label={t('Finances.Balance.CreditLimit')}
            value={creditValue}
          />
        </Cell>
        <Cell>
          <Statistic
            label={t('Finances.Balance.Asset')}
            value={assetValue}
            variant='muted'
          />
        </Cell>
      </Body>
    </Wrapper>
  );
}

Credit.propTypes = {
  className: PropTypes.string
};

export default styled(Credit)``;
