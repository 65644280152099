import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Label = styled.div`
  color: #6B707B;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 34px;
  font-weight: bold;
  line-height: 22px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Label} + ${Value} {
    margin-top: 14px;
  }

  ${({ variant }) => variant === 'normal' && css`
    ${Value} {
      color: #4A515C;
    }
  `}

  ${({ variant }) => variant === 'muted' && css`
    ${Value} {
      color: #979BA0;
    }
  `}

  ${({ variant }) => variant === 'danger' && css`
    ${Value} {
      color: #E66D6D;
    }
  `}
`;

function Statistic({ label, value, variant, className }) {
  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      <Label>
        {label}
      </Label>
      <Value>
        {value}
      </Value>
    </Wrapper>
  );
}

Statistic.defaultProps = {
  variant: 'normal'
};

Statistic.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['normal', 'muted', 'danger'])
};

export default styled(Statistic)``;
