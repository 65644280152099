import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { DateRangePicker } from 'components/forms';
import { Form } from 'components/ui';

import { CheckRangeForm } from './forms';

import moment from 'utils/moment';
import { t } from 'utils/localization';

const Wrapper = styled(Form)``;

@observer
class CheckRangeFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.form = new CheckRangeForm({}, { hooks });
  }

  handleChange = (value) => {
    const [checkIn, checkOut] = value;

    this.form.$('check_in').set(moment(checkIn).format());
    this.form.$('check_out').set(moment(checkOut).format());

    this.form.submit();
  }

  handleSuccess = (form) => {
    const { onSubmit } = this.props;
    const checkIn = form.$('check_in').value;
    const checkOut = form.$('check_out').value;

    onSubmit(
      {
        type: 'check_range',
        value: `${checkIn},${checkOut}`,
        label: [
          t('Orders.Filters.CheckRangeDate.Label'),
          t('Orders.Filters.CheckRangeDate.Value', {
            from: moment(checkIn).format('D MMM YYYY'),
            to: moment(checkOut).format('D MMM YYYY')
          })
        ].join(': ')
      }
    );
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  }

  render() {
    const { filter, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        form={this.form}
      >
        <DateRangePicker
          checkIn={this.form.$('check_in')}
          checkOut={this.form.$('check_out')}
          autoFocus
          size='small'
          onClose={this.handleCancel}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          {...rest}
        />
      </Wrapper>
    );
  }
}

export default styled(CheckRangeFilter)``;
