import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useLegalOptions = () => {
  const [legalOptions, setLegalOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { data }
        } = await instance.get('/api/filters/legal_entities');

        setLegalOptions(data);
      } catch (e) {
        console.log('Legal options error', e);
      }
    };

    fetch();
  }, []);

  return legalOptions;
};

export default useLegalOptions;
