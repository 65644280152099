import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectElement, { Option } from 'rc-select';

import Wrapper from './Select.styled';
import { CornerDown } from 'components/icons';

const dropdownAlign = {
  offset: [-12, 4]
};

const Select = React.forwardRef(
  (
    {
      value: initialValue,
      placeholder,
      valueField,
      labelField,
      options,
      searchable,
      className,
      onChange,
      simpleValue,
      mode,
      showLabel,
      disabled
    },
    ref
  ) => {
    const wrapperRef = useRef();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const selectValue = useMemo(() => {
      if (value === null) return null;
      if (simpleValue && mode === 'multiple') return Array.isArray(value) ? [...value] : [];
      if (!simpleValue && mode === 'multiple') return Array.isArray(value) ? value.map((item) => item[valueField]) : [];
      if (simpleValue) return value;
      if (!simpleValue) return value[valueField];

      return null;
    }, [value, simpleValue, mode, valueField]);

    const handleSelect = (newValue, option) => {
      const selectedOption = options.find((item) => item[valueField] === newValue);

      if (mode === 'multiple') {
        const multipleValue = simpleValue ? newValue : { ...selectedOption };
        const multipleValueArr = Array.isArray(value) ? [...value, multipleValue] : [multipleValue];

        setValue(multipleValueArr);
        onChange(multipleValueArr);

        return;
      }

      const singleValue = simpleValue ? newValue : { ...selectedOption };

      setValue(singleValue);
      onChange(singleValue);
    };

    const handleDeselect = (deselectValue, option) => {
      if (mode === 'multiple') {
        const multipleValueArr = value.filter((item) => {
          if (simpleValue) {
            return deselectValue !== item;
          } else {
            return deselectValue !== item[valueField];
          }
        });

        setValue(multipleValueArr);
        onChange(multipleValueArr);
      }
    };

    return (
      <Wrapper ref={wrapperRef} isSearch={searchable} className={className}>
        <SelectElement
          getPopupContainer={() => wrapperRef.current}
          inputIcon={<CornerDown />}
          notFoundContent='Нет данных'
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          placeholder={placeholder}
          showSearch={searchable}
          value={showLabel ? value?.[labelField] : selectValue}
          dropdownAlign={dropdownAlign}
          mode={mode}
          disabled={disabled}>
          {options.map((option) => (
            <Option key={option[valueField]} title={option[labelField]} value={option[valueField]}>
              {option[labelField]}
            </Option>
          ))}
        </SelectElement>
      </Wrapper>
    );
  }
);

Select.defaultProps = {
  value: null,
  placeholder: '',
  options: [],
  valueField: 'key',
  labelField: 'value',
  searchable: false,
  onChange: () => {},
  simpleValue: false,
  showLabel: false,
  mode: 'single'
};

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  searchable: PropTypes.bool,
  onChange: PropTypes.func,
  simpleValue: PropTypes.bool,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf(['single', 'multiple'])
};

export default styled(Select)``;
