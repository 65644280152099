import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')}
    width: 100%;
    padding: 24px;
    border: 2px dashed ${theme.colors.active.primary};
  `}
`;

@withTheme
class ContentPlaceholder extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(ContentPlaceholder)``;
