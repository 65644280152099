import { types } from 'mobx-state-tree';

const Hotel = types.model('Hotel', {
  id: types.string
});

const Traveller = types.model('Traveller', {
  age_group: types.string,
  age: types.maybeNull(types.number)
});

const Meta = types.model('Meta', {
  hotel: Hotel,
  check_in: types.string,
  check_out: types.string,
  currency: types.string,
  travellers: types.array(Traveller)
});

export default Meta;
