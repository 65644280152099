import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

const LineS = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background: ${theme.colors.popup.primary};
  `}
`;

@withTheme
class Line extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { ...rest } = this.props;

    return (
      <LineS {...rest} />
    );
  }
}

export default styled(Line)``;
