import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/forms/Checkbox2/CheckboxElement';
import InstantBookingComponent from 'components/ui/InstantBooking';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  transition: all 0.25s;
  background-color: #5A87D6;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  height: 60px;
  background-color: #EFF3FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 5px;
  box-sizing: border-box;

  ${Checkbox} {
    label {
      line-height: 16px;
    }
  }

  ${({ checked }) => checked && css`
    background: #E6ECFD;
  `}
`;

function InstantBooking({ className, value, onChange }) {
  const { t } = useTranslation();

  // const handleChange = () => () => {
  //   onChange(value ? 0 : 1);
  // };

  return (
    <Wrapper className={className} checked={value}>
      <Checkbox checked>
        {t('Orders.Filters.InstantBooking')}
      </Checkbox>

      <IconContainer>
        <InstantBookingComponent variant='secondary' />
      </IconContainer>
    </Wrapper>
  );
}

InstantBooking.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

export default styled(InstantBooking)``;
