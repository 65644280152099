import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const Cell = styled.div`
  height: 36px;
  color: #6B707B;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 36px;
  vertical-align: middle;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #D1D5DB;
`;

@withTranslation()
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Cell>
          {t('Orders.TableHead.Code')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.Dates')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.Travellers')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.Hotel')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.Manager')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.Changed')}
        </Cell>

        <Cell>
          {t('Orders.TableHead.State')}
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(Header)``;
