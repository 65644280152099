// eslint-disable-next-line no-use-before-define
const ru = [
  {
    scope: 'city',
    label: 'Ялта',
    description: 'Ялта, Россия',
    location: [34.158688, 44.49707],
    slug: 'search/list',
    place_id: '5fbfaca99c78b20055d0cd8e'
  },
  {
    scope: 'city',
    label: 'Туапсе',
    description: 'Туапсе, Россия',
    location: [39.071888, 44.098476],
    slug: 'search/list',
    place_id: '5fbfad0c9c78b20055d2e11d'
  },
  {
    scope: 'city',
    label: 'Саки',
    description: 'Саки, Россия',
    location: [33.601753, 45.131092],
    slug: 'search/list',
    place_id: '5fbfacce9c78b20055d1be03'
  },
  {
    scope: 'city',
    label: 'Москва',
    description: 'Москва, Россия',
    location: [37.618706, 55.751633],
    slug: 'search/list',
    place_id: '5fbfaca29c78b20055d09773'
  },
  {
    scope: 'city',
    label: 'Кисловодск',
    description: 'Кисловодск, Россия',
    location: [42.7237, 43.91436],
    slug: 'search/list',
    place_id: '5fbfaca39c78b20055d0a2d9'
  },
  {
    scope: 'city',
    label: 'Ессентуки',
    description: 'Ессентуки, Россия',
    location: [42.85644, 44.046803],
    slug: 'search/list',
    place_id: '5fbfacd59c78b20055d1e1d3'
  },
  {
    scope: 'city',
    label: 'Евпатория',
    description: 'Евпатория, Россия',
    location: [33.367886, 45.1908],
    slug: 'search/list',
    place_id: '5fbfad0c9c78b20055d2e0ca'
  },
  {
    scope: 'city',
    label: 'Геленджик',
    description: 'Геленджик, Россия',
    location: [38.06109, 44.58092],
    slug: 'search/list',
    place_id: '5fbfaca19c78b20055d09395'
  },
  {
    scope: 'city',
    label: 'Белокуриха',
    description: 'Белокуриха, Россия',
    location: [84.99321, 51.999195],
    slug: 'search/list',
    place_id: '5fbfad0c9c78b20055d2e203'
  },
  {
    scope: 'city',
    label: 'Алушта',
    description: 'Алушта, Россия',
    location: [34.40954, 44.677113],
    slug: 'search/list',
    place_id: '5fbfacb19c78b20055d10942'
  },
  {
    scope: 'city',
    label: 'Анапа',
    description: 'Анапа, Россия',
    location: [37.316887, 44.89427],
    slug: 'search/list',
    place_id: '5fbfaca19c78b20055d08fe9'
  },
  {
    scope: 'city',
    label: 'Сочи',
    description: 'Сочи, Россия',
    location: [39.72311, 43.585484],
    slug: 'search/list',
    place_id: '5fbfaca39c78b20055d0a311'
  }
];

export default { ru };
