import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Input, Field, Select } from 'components/v2/forms';
import useCompanyInfo from '../../hooks/useCompanyInfo';
import useAgenciesOptions from '../../hooks/useAgenciesOptions';
import useLegalOptions from '../../hooks/useLegalOptions';
import useTaxOptions from '../../hooks/useTaxOptions';
import Loader from '../../Loader';

const InputComponent = styled(Input)`
  height: 40px;
`;

const SelectComponent = styled(Select)`
  .rc-select {
    height: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const FieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  background: ${(props) => props.error && '#fdf1f1'};
  padding: ${(props) => props.error && '16px'};
  border-radius: ${(props) => props.error && '4px'};
  flex-direction: ${(props) => (props.error ? 'column' : 'row')};
`;

const ErrorWrapper = styled.div`
  display: flex;
  gap: 8px;
  background: #fdf1f1;
  padding: 16px;
  border-radius: 4px;
  flex-direction: column;
`;

const ErrorText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #e26464;

  & + & {
    margin-top: -8px;
  }
`;

const ErrorLink = styled(Link)`
  text-decoration: underline;
`;

const CompanyInfoField = styled(Field)`
  width: 180px;
`;

const DirectorInfoField = styled(Field)`
  width: 280px;
`;

const AboutCompany = ({ className }) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  const { t } = useTranslation();
  const [{ data, loading, error }] = useCompanyInfo(getValues()?.legal_entity?.inn);
  const agenciesOptions = useAgenciesOptions();
  const legalOptions = useLegalOptions();
  const taxOptions = useTaxOptions();

  useEffect(() => {
    if (!data) return;

    setValue('legal_entity', data?.legal_entity);
  }, [data]);

  return (
    <Wrapper className={className}>
      {loading && <Loader />}

      <Title>О компании</Title>

      {error ? (
        <ErrorWrapper>
          <FieldWrapper>
            <Controller
              name='legal_entity.inn'
              control={control}
              render={({ field }) => (
                <CompanyInfoField
                  required
                  error={errors?.legal_entity?.inn}
                  label={t('Auth.Register.AboutCompany.Label')}>
                  <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
                </CompanyInfoField>
              )}
            />

            <Controller
              control={control}
              name='legal_entity.kpp'
              render={({ field }) => (
                <CompanyInfoField
                  required={getValues()?.legal_entity?.type?.tag === 'llc'}
                  error={errors?.legal_entity?.kpp}
                  label={t('Auth.Register.LegalEntity.Requisites.Label3')}>
                  <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
                </CompanyInfoField>
              )}
            />
          </FieldWrapper>

          <ErrorText>{error}</ErrorText>
          {error?.includes('ранее') && (
            <ErrorText>
              <ErrorLink to='/forget'>Восстановите пароль</ErrorLink> или напишите нам на{' '}
              <a href='mailto:support@sanatory.ru'>support@sanatory.ru</a>
            </ErrorText>
          )}
        </ErrorWrapper>
      ) : (
        <FieldWrapper>
          <Controller
            name='legal_entity.inn'
            control={control}
            render={({ field }) => (
              <CompanyInfoField
                required
                error={errors?.legal_entity?.inn}
                label={t('Auth.Register.AboutCompany.Label')}>
                <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
              </CompanyInfoField>
            )}
          />

          <Controller
            control={control}
            name='legal_entity.kpp'
            render={({ field }) => (
              <CompanyInfoField
                required={getValues()?.legal_entity?.type?.tag === 'llc'}
                error={errors?.legal_entity?.kpp}
                label={t('Auth.Register.LegalEntity.Requisites.Label3')}>
                <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
              </CompanyInfoField>
            )}
          />
        </FieldWrapper>
      )}

      <FieldWrapper>
        <Controller
          control={control}
          name='legal_entity.short_name'
          render={({ field }) => (
            <CompanyInfoField
              required
              error={errors?.legal_entity?.short_name}
              label={t('Auth.Register.AboutCompany.Label2')}>
              <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder2')} />
            </CompanyInfoField>
          )}
        />

        <Controller
          control={control}
          name='legal_entity.name'
          render={({ field }) => (
            <Field required error={errors?.legal_entity?.name} label={t('Auth.Register.AboutCompany.Label3')}>
              <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder2')} />
            </Field>
          )}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='legal_entity.type'
          render={({ field }) => (
            <CompanyInfoField
              required
              error={errors?.legal_entity?.type?.tag}
              label={t('Auth.Register.AboutCompany.Label4')}>
              <SelectComponent
                {...field}
                options={legalOptions}
                valueField='tag'
                labelField='name'
                showLabel
                placeholder={t('Auth.Register.AboutCompany.Placeholder4')}
              />
            </CompanyInfoField>
          )}
        />

        <Controller
          control={control}
          name='legal_entity.ogrn'
          render={({ field }) => (
            <CompanyInfoField
              required
              error={errors?.legal_entity?.ogrn}
              label={t('Settings.Contracts.Legal.RegNumber')}>
              <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
            </CompanyInfoField>
          )}
        />
        <Controller
          control={control}
          name='legal_entity.tax_type'
          render={({ field }) => (
            <CompanyInfoField
              required
              error={errors?.legal_entity?.tax_type?.tag}
              label={t('Auth.Register.AboutCompany.Label5')}>
              <SelectComponent
                {...field}
                options={taxOptions}
                valueField='tag'
                labelField='name'
                showLabel
                placeholder={t('Auth.Register.AboutCompany.Placeholder4')}
              />
            </CompanyInfoField>
          )}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='legal_entity.agency_types'
          render={({ field }) => (
            <CompanyInfoField error={errors?.legal_entity?.agency_types} label={t('Auth.Register.AboutCompany.Label9')}>
              <SelectComponent
                {...field}
                options={agenciesOptions}
                mode='multiple'
                valueField='tag'
                labelField='name'
                showLabel
                placeholder={t('Auth.Register.AboutCompany.Placeholder4')}
              />
            </CompanyInfoField>
          )}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='legal_entity.director.full_name'
          render={({ field }) => (
            <DirectorInfoField
              error={errors?.legal_entity?.director?.full_name}
              label={t('Auth.Register.AboutCompany.Label7')}>
              <InputComponent {...field} placeholder={t('Auth.Register.Manager.FIO.Placeholder')} />
            </DirectorInfoField>
          )}
        />

        <Controller
          control={control}
          name='legal_entity.director.post'
          render={({ field }) => (
            <DirectorInfoField
              error={errors?.legal_entity?.director?.post}
              label={t('Auth.Register.AboutCompany.Label8')}>
              <InputComponent {...field} placeholder={t('Auth.Register.AboutCompany.Placeholder3')} />
            </DirectorInfoField>
          )}
        />
      </FieldWrapper>
    </Wrapper>
  );
};

AboutCompany.propTypes = {
  className: PropTypes.string
};

export default AboutCompany;
