import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import buildLocation from '../lib/buildLocation';
import parseSearchQuery, { DEFAULT_QUERY } from '../lib/parseSearchQuery';
import convertSearchQuery from '../lib/convertSearchQuery';
import updateQuery from '../lib/updateQuery';
import calcQueryFilters from '../lib/calcQueryFilters';

import { useHistory } from 'react-router-dom';

import SearchQueryContext from '../context';

function SearchQueryProvider({ initialQuery, children }) {
  // Parse InitialQuery
  const { href, pathname } = window.location;
  const history = useHistory();
  const originSlug = pathname.split('?')[0].replace(/\/$/, '');

  const searchQuery = useMemo(() => {
    const value = parseSearchQuery(href, { initialQuery });

    return value;
  }, [pathname, initialQuery]);

  // Current filters
  const initialCurrentQuery = convertSearchQuery(searchQuery);
  const [currentQuery, setCurrentQuery] = useState(initialCurrentQuery);

  // Selected filters
  const total = useMemo(() => {
    const value = calcQueryFilters(DEFAULT_QUERY, currentQuery);
    return value;
  }, [currentQuery]);

  // Handlers
  const handleUpdate = (values) => {
    const queryValue = updateQuery(currentQuery, values);
    setCurrentQuery(queryValue);
  };

  const handleClear = () => {
    const { filter } = DEFAULT_QUERY;
    const queryValue = updateQuery(currentQuery, { filter });

    setCurrentQuery(queryValue);
  };

  const handleApply = (slug, options = {}) => {
    const currentSlug = (slug || originSlug).replace(/^\//, '');
    const { asPath } = buildLocation(currentSlug, currentQuery);

    const { target, replace } = options;

    if (target === '_blank') {
      window.open(asPath);
      return;
    }

    if (replace) {
      window.history.replaceState(null, null, asPath);
      return;
    }

    history.push(asPath);
  };

  const context = {
    currentQuery: currentQuery,
    searchQuery: searchQuery,
    total: total,
    updateQuery: handleUpdate,
    clearQuery: handleClear,
    applyQuery: handleApply
  };

  return (
    <SearchQueryContext.Provider value={context}>
      {children}
    </SearchQueryContext.Provider>
  );
}

SearchQueryProvider.propTypes = {
  children: PropTypes.any,
  initialQuery: PropTypes.object
};

SearchQueryProvider.defaultProps = {
  initialQuery: {}
};

export default SearchQueryProvider;
