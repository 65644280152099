import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dots as DotsIcon } from 'components/icons';
import { Menu } from 'components/ui';

const Icon = styled(DotsIcon)``;

const Control = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 24px;
  font-size: 4px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
`;

function UserActions({ actions, className }) {
  const [isOpened, setIsOpened] = useState(false);

  const handleClose = (e) => {
    setIsOpened(false);
  };

  const handleToggle = (e) => {
    setIsOpened(!isOpened);
  };

  const handleChange = ({ action }) => {
    setIsOpened(false);

    if (action) action();
  };

  return (
    <Wrapper className={className}>
      <Control onClick={handleToggle}>
        <Icon />
      </Control>

      <Menu
        isOpened={isOpened}
        onClose={handleClose}
        onChange={handleChange}
      >
        {actions.map((item, index) => (
          <Menu.Item
            key={index}
            value={item}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Wrapper>
  );
}

UserActions.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.array
};

export default styled(UserActions)``;
