import localStorage from 'store';

const success = (response) => {
  return response;
};

const error = (error) => {
  if (error.response.status === 401) {
    localStorage.remove('authStore');
    window.location = '/login';

    return;
  }

  return Promise.reject(error);
};

export default [success, error];
