import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/forms/Button';

import { defaultPrice } from 'utils/prices';

import FormattedInput from './FormattedInput';

const TitleContainer = styled.div`
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
`;

const InputContainer = styled.div`
  width: 50%;
`;

const RangeContainer = styled.div`
  margin-top: 14px;
  padding: 0 11px;
`;

const InputsContainer = styled.div`
  display: flex;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  height: 35px;
  margin-top: 20px;
  color: #4A515C;
  border-radius: 3px;
  
  input{
    color: #4A515C !important;
  };

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ApplyButton = styled(Button)`
  height: 34px;
  width: 115px;
  border-radius: 21px;
  background-color: #3AA6D2;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`;

const CancelButton = styled.div`
  color: #9CB4CA;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  ${TitleContainer} + ${RangeContainer} {
    margin-top: 17px;
  }

  .rc-slider-handle{
    box-sizing: border-box;
    height: 22px;
    width: 22px;
    border: 4px solid #00BB58;
    background-color: #FFFFFF;
  }

  .rc-slider-handle-dragging{
    border-color: #00BB58 !important;
    box-shadow: inherit !important;
  }

  .rc-slider{
    height: 22px;
  }

  .rc-slider-track, .rc-slider-rail{
    top: 9px;
  }

  .rc-slider-track {
    height: 4px;
    border-radius: 2px;
    background-color: #00BB58;
  }
`;

function RangeSelect({ className, value: initialValue, title, onClose, onChange }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChangeMax = ({ floatValue }) => {
    if (!floatValue) {
      return;
    }

    setValue([value[0], floatValue]);
  };

  const handleChangeMin = ({ floatValue }) => {
    if (!floatValue) {
      return;
    }

    setValue([floatValue, value[1]]);
  };

  const handleApply = () => {
    onChange(value);
    onClose();
  };

  const handleClear = () => {
    onChange([defaultPrice.min, defaultPrice.max]);
    onClose();
  };

  return (
    <Wrapper className={className}>
      <TitleContainer>
        {title}
      </TitleContainer>

      <InputsContainer>
        <InputContainer>
          <FormattedInput
            prefix={[t('Common.From'), ' '].join('')}
            onChange={handleChangeMin}
            value={value[0]}
            // eslint-disable-next-line no-use-before-define
            position='left'
          />
        </InputContainer>

        <InputContainer>
          <FormattedInput
            prefix={[t('Common.To'), ' '].join('')}
            onChange={handleChangeMax}
            value={value[1]}
            // eslint-disable-next-line no-use-before-define
            position='right'
          />
        </InputContainer>
      </InputsContainer>

      <ButtonsContainer>
        <CancelButton onClick={handleClear}>
          {t('Common.Clear')}
        </CancelButton>

        <ApplyButton onClick={handleApply}>
          {t('Common.Apply')}
        </ApplyButton>
      </ButtonsContainer>
    </Wrapper>
  );
}

RangeSelect.propTypes = {
  value: PropTypes.array,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string
};

RangeSelect.defaultProps = {
  showInputs: true,
  onChange: () => { }
};

export default styled(RangeSelect)``;
