import React from 'react';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import { Conversation } from 'components/ui';
import { ViewOrder } from '.';
import HotelsInfo from '../HotelsInfo';

import ShowState from 'forms/Orders/ShowState';

@withRouter
@observer
class Show extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired
  };

  constructor(props) {
    super(props);

    // Initialize state
    const { history } = this.props;
    this.showState = new ShowState({ history });
  }

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.showState.fetch({ id });
  }

  render() {
    const {
      showState,
      showState: { isFetched, order }
    } = this;

    const hasConversation = !!order?.conference;

    return (
      <Provider showState={showState}>
        <>
          <DefaultTemplate
            header={<Header />}
            body={isFetched && <ViewOrder />}
            conversation={isFetched && hasConversation && <Conversation order={order} />}
          />

          <HotelsInfo />
        </>
      </Provider>
    );
  }
}

export default Show;
