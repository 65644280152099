import { useState, useEffect } from 'react';
import useEvents from 'hooks/useEvents';

const useSelected = (item) => {
  const em = useEvents();

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    const over = em.on('map:marker:mouseover', id => {
      if (item.id === id) setIsSelected(true);
    });

    // eslint-disable-next-line no-use-before-define
    const leave = em.on('map:marker:mouseleave', id => {
      if (item.id === id) setIsSelected(false);
    });

    const deselect = () => setIsSelected(false);

    return () => {
      over();
      leave();
      deselect();
    };
  }, [item]);

  return [isSelected];
};

export default useSelected;
