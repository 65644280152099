import instance from 'connection/instance';

class HotelTypesStore {
  async fetch(params = {}) {
    const { data: { data: hotel_types } } = await instance.get('/api/filters/hotel_types', { params });
    const data = hotel_types.map(item => ({ ...item, visible: true }));

    return { meta: {}, data };
  }
}

export default HotelTypesStore;
