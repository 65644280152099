import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from 'components/v2/ui/Tooltip';
import { useTranslation } from 'react-i18next';

import { ProviderOstrovok, ProviderOstrovokV2 } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 14px;

  ${ProviderOstrovok}, ${ProviderOstrovokV2} {
    width: initial;
  }

  ${ProviderOstrovokV2} {
    font-size: 23px;
  }

  ${({ variant }) => variant !== 'hotelCard' && css`
    width: 23px;
    height: 23px;
  `}
`;

function ProviderIcon({ provider, className, variant }) {
  const { t } = useTranslation();

  if (provider !== 'ostrovok') {
    return null;
  }

  return (
    <Wrapper
      className={className}
      variant={variant}
    >
      <Tooltip
        placement='bottom'
        overlay={t('Orders.Filters.ProviderOstrovok')}
      >
        {variant === 'hotelCard'
          ? <ProviderOstrovok />
          : <ProviderOstrovokV2 />
        }
      </Tooltip>
    </Wrapper>
  );
}

ProviderIcon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  provider: PropTypes.string
};

export default styled(ProviderIcon)``;
