import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Content from './Content';
import { Triangle } from 'components/icons';

const TriangleIconWrapper = styled(Triangle)`
  width: 14px;
  height: 7.88px;
  transform: ${(props) => (props.opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  & path {
    fill: #4a515c;
  }
`;

const Title = styled.div`
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;
  border-bottom: ${(props) => (props.opened ? '2px solid transparent' : '2px solid #e4e8ee')};
`;

const ContentContainer = styled.div``;

const Text = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #4a515c;
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => (props.opened ? '#F2F4F6' : '#fff')};
  border-radius: 4px;
`;

function Dropdown({ className, program, onClick, opened }) {
  return (
    <Wrapper className={className} opened={opened}>
      <Title onClick={onClick} opened={opened}>
        <Text dangerouslySetInnerHTML={{ __html: program.title }} />
        <TriangleIconWrapper opened={opened} />
      </Title>

      {opened && (
        <ContentContainer>
          <Content program={program} />
        </ContentContainer>
      )}
    </Wrapper>
  );
}

Dropdown.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object,
  onClick: PropTypes.func,
  opened: PropTypes.bool
};

export default Dropdown;
