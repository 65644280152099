function buildSort({ sort }) {
  return { sort };
}

function buildFilters({ filter }) {
  if (!filter) return {};
  return { filter: filter };
}

function buildTravellers({ travellers }) {
  return { travellers };
}

function buildHotelIds({ hotel_ids }) {
  return { hotel_ids };
}

function buildDates({ check_in, check_out }) {
  return { check_in, check_out };
}

function buildAddress({ address }) {
  if (!address?.location) return { address: null };

  return { address };
}

function buildSearchQuery(query) {
  const value = {
    ...buildDates(query),
    ...buildAddress(query),
    ...buildHotelIds(query),
    ...buildTravellers(query),
    ...buildFilters(query),
    ...buildSort(query)
  };

  return value;
}

export default buildSearchQuery;
