import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { DemoLimitations } from 'components/ui';

import { typography, display } from 'theme/mixins';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;
`;

const InnerWrapper = styled.div`
  ${display('flex', 'flex-end', 'space-between')};
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.active.secondary};
    ${typography(40, 48, 700)};
  `}
`;

@withTheme
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <InnerWrapper>
          <Title>
            {t('Statistics.Title')}
          </Title>
        </InnerWrapper>

        <DemoLimitations />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
