import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { computed, reaction } from 'mobx';
import { flexCenterBetween } from 'theme/mixins';
import Loader from 'rambler-ui/Loader';

import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'utils/moment';

import NavBar from './NavBar';
import Day from './Day';

import './style.css';

const Wrapper = styled.div`
  ${flexCenterBetween()};
`;

@inject('bookingForm', 'bookingState', 'commissionVisibility')
@observer
class Calendar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired,
    commissionVisibility: PropTypes.object.isRequired,
    availability: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.calendarEl = React.createRef();
  }

  state = {
    currentMonth: this.initialMonth
  };

  handleMonthChange = (month) => {
    this.setState({ currentMonth: month });
  };

  componentDidMount() {
    const { bookingState } = this.props;

    this._changeDailyPeriodHandler = reaction(
      () => bookingState.currentDailyPeriod,
      (period) => period && this.showMonth(period)
    );
  }

  componentWillUnmount() {
    this._changeDailyPeriodHandler();
  }

  @computed get disabledDays() {
    const today = moment().startOf('day').toDate();
    return [...this.statesDisabledDays, ...this.formsDisabledDays, { before: today }];
  }

  @computed get statesDisabledDays() {
    const { bookingState } = this.props;
    return bookingState.disabledDays;
  }

  @computed get formsDisabledDays() {
    const { pricesDisabled } = this.pricesPerDayForRates;

    return pricesDisabled.map(({ day }) => moment(day).toDate());
  }

  @computed get pricesPerDayForRates() {
    const {
      rates,
      withCommission,
      props: { bookingState }
    } = this;

    const { pricesList, pricesMap, pricesDisabled } = bookingState.getDailyPricesForRates({
      rates,
      withCommission,
      range: [this.from, this.to]
    });

    return { pricesList, pricesMap, pricesDisabled };
  }

  @computed get rates() {
    const {
      bookingForm: { slots }
    } = this.props;

    const rates = slots.map((slot) => slot.$('rate.type').value);

    return rates;
  }

  @computed get selectedDays() {
    return [this.from, { from: this.from, to: this.to }];
  }

  @computed get initialMonth() {
    const { bookingForm, bookingState } = this.props;

    const check_in = bookingForm.$('check_in').value || bookingState.check_in;

    return moment(check_in).toDate();
  }

  @computed get fromMonth() {
    return moment().toDate();
  }

  @computed get toMonth() {
    return moment().add(11, 'month').toDate();
  }

  @computed get from() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_in');

    return value ? moment(value).toDate() : null;
  }

  @computed get to() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_out');

    return value ? moment(value).toDate() : null;
  }

  @computed get modifiers() {
    return { start: this.from, end: this.to };
  }

  @computed get withCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isAmountVisible;
  }

  showMonth = ({ check_in, check_out }) => {
    const date = moment(check_in).toDate();
    this.calendarEl.current.showMonth(date);
  };

  showPreviousMonth = () => {
    const { bookingState } = this.props;
    return bookingState.prevDailyPeriod();
  };

  showNextMonth = () => {
    const { bookingState } = this.props;
    return bookingState.nextDailyPeriod();
  };

  setDatesPeriod = ({ check_in, check_out }) => {
    const { bookingState, bookingForm } = this.props;

    if (!check_in || !check_out) {
      bookingForm.setDatesPeriod({ check_in, check_out });
      return;
    }

    const currentPeriood = bookingState.currentDailyPeriod;
    const coercedPeriod = bookingForm.coerceDatesByMinDays({ check_in, check_out });

    const isAfter = coercedPeriod.checkOut && moment(coercedPeriod.checkOut).isAfter(currentPeriood.check_out);

    if (isAfter) {
      this.showNextMonth().then((_) => bookingForm.setDatesPeriod({ check_in, check_out }));

      return;
    }

    const isBefore = coercedPeriod.checkIn && moment(coercedPeriod.checkIn).isBefore(currentPeriood.check_in);

    if (isBefore) {
      this.showPreviousMonth().then((_) => bookingForm.setDatesPeriod({ check_in, check_out }));

      return;
    }

    bookingForm.setDatesPeriod({ check_in, check_out });
  };

  renderDay = (rates) => (day) => {
    const {
      availability: {
        tariff: { type },
        room_type: { beds }
      }
    } = this.props;
    const { pricesMap } = this.pricesPerDayForRates;

    const date = moment(day).format('Y-MM-DD');
    const priceItem = pricesMap.get(date) || { price: -1, available: -1, stop_sales: false };

    return <Day type={type} beds={beds} {...{ ...priceItem, from: this.from, to: this.to }} />;
  };

  handleDayClick = (day) => {
    const { from, to } =
      !!this.from && !!this.to
        ? { from: day, to: null }
        : DateUtils.addDayToRange(day, { from: this.from, to: this.to });

    this.setDatesPeriod({ check_in: from, check_out: to });
  };

  render() {
    const { bookingState, bookingForm, ...rest } = this.props;
    const { currentMonth } = this.state;

    const rates = bookingForm.$('slots').value.map((slot) => slot.rate.type);

    const isCurrentMonth = moment(currentMonth).isSame(moment(), 'month');

    return (
      <Wrapper {...rest}>
        <Loader loading={false}>
          <DayPicker
            ref={this.calendarEl}
            locale='ru'
            localeUtils={MomentLocaleUtils}
            initialMonth={this.initialMonth}
            fromMonth={this.fromMonth}
            toMonth={this.toMonth}
            numberOfMonths={2}
            pagedNavigation={false}
            renderDay={this.renderDay(rates)}
            selectedDays={this.selectedDays}
            disabledDays={this.disabledDays}
            modifiers={this.modifiers}
            onDayClick={this.handleDayClick}
            onMonthChange={this.handleMonthChange}
            navbarElement={
              <NavBar
                disablePrevious={isCurrentMonth}
                handlePreviousClick={this.showPreviousMonth}
                handleNextClick={this.showNextMonth}
              />
            }
          />
        </Loader>
      </Wrapper>
    );
  }
}

export default styled(Calendar)``;
