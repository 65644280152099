import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'components/v2/ui/Tooltip';
import { t } from 'utils/localization';

const Overlay = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 17px;
  font-size: 8px;
  color: white;
  line-height: 8px;
  font-weight: 600;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  background-color: #E26161;
  padding: 0 3px;
`;

class RoomsFreeBeds extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    roomsWithFreeBeds: PropTypes.number,
    count: PropTypes.number
  };

  render() {
    const { className, roomsWithFreeBeds, count } = this.props;

    const overlaytext = (
      <Overlay dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.RoomsFreeBeds', { count, countBeds: roomsWithFreeBeds }) }} />
    );

    return (
      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <Wrapper className={className}>
          {count} +{roomsWithFreeBeds}
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(RoomsFreeBeds)``;
