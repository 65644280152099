import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import RoomType from './RoomType';

const Wrapper = styled.div`
  ${RoomType} {
    margin-top: 30px;
  }
`;

@observer
class RoomTypes extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    room_types: PropTypes.array.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { hotel, room_types, ...rest } = this.props;

    const listItems = room_types.map(item => (
      <RoomType
        key={item.id}
        hotel={hotel}
        room_type={item}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(RoomTypes)``;
