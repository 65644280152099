import React from 'react';
import { withTranslation } from 'react-i18next';
import { Add } from 'components/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  color: #3aa6d2;
  cursor: pointer;
  vertical-align: center;
  ${typography(14, 16, 500)};
  ${display('inline-flex', 'center')};

  ${Add} {
    margin-right: 6px;
  }
`;

class AddTraveller extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    travellers: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleClick = (event) => {
    const { travellers } = this.props;
    travellers.onAdd(event);
  }

  render() {
    const { t, travellers, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        onClick={this.handleClick}
      >
        <Add />

        {t('Orders.OrderForm.AddGuest')}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(AddTraveller))``;
