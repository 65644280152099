import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { display } from 'theme/mixins';
import { LinearLoader, BackLink } from 'components/ui';
import Button from 'components/forms/Button';

import {
  AddressField, DatesField, RatingField, SortField, GuestsField, PriceField, TreatmentField
} from './components';
import { useTranslation } from 'react-i18next';

const FormS = styled.div`
  width: 100%;
`;

const InnerS = styled.div`
  ${display('flex', 'flex-start', 'flex-start')};
`;

const InputsWrapper = styled.div`

`;

const ButtonsContainer = styled.div`
  margin-left: 15px;
  width: 192px;
`;

const Container = styled.div`
  ${display('flex', 'flex-start', 'flex-start')};
`;

const Actions = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;

  ${BackLink} + ${FormS} {
    margin-left: 28px;
  }

  ${SortField} {
    width: 200px;
  }

  ${RatingField} {
    width: 160px;
  }

  ${GuestsField} {
    width: 290px;
  }

  ${AddressField} {
    width: 480px;
  }

  ${DatesField} {
    width: 313px;
  }

  ${PriceField}{
    width: 250px;
  }

  ${TreatmentField}{
    width: 330px;
  }

  ${RatingField},
  ${PriceField},
  ${GuestsField},
  ${TreatmentField},
  ${SortField} {
    margin-left: 15px;
  }

  ${Container} + ${Container} {
    margin-top: 15px;
  }

  ${LinearLoader} {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
`;

function FilterBar({ addressFieldWidth, onSubmit, hasBackButton, ...rest }) {
  const { t } = useTranslation();

  const handleSubmit = (slug) => {
    onSubmit && onSubmit();
  };

  return (
    <Wrapper {...rest} addressFieldWidth={addressFieldWidth}>
      <InnerS>
        <FormS>
          <InputsWrapper>
            <Container>
              <AddressField
                placeholder={t('Filters.Places.Placeholder.Full')}
                {...rest}
              />

              <TreatmentField
                {...rest}
                placeholder={t('Filters.Disease.Placeholder.Full')}
              />
            </Container>

            <Container>
              <DatesField
                {...rest}
              />

              <GuestsField
                {...rest}
              />

              <ButtonsContainer>
                <Actions onClick={handleSubmit}>
                  {t('Orders.OrderForm.Add.SubmitButton')}
                </Actions>
              </ButtonsContainer>
            </Container>
          </InputsWrapper>
        </FormS>
      </InnerS>
    </Wrapper>
  );
}

FilterBar.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  addressFieldWidth: PropTypes.string,
  hasBackButton: PropTypes.bool
};

FilterBar.defaultProps = {
  className: '',
  hasBackButton: true
};

export default styled(FilterBar)``;
