import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CompanyForm from './CompanyForm';

import { Form } from 'components/v2/forms';
import { schema } from './schema';

const Wrapper = styled(Form)``;

function Company({ className }) {
  return (
    <Wrapper defaultValues={{}} onSubmit={(e) => e.preventDefault()} schema={schema} className={className}>
      <CompanyForm />
    </Wrapper>
  );
}

Company.propTypes = {
  className: PropTypes.string
};

export default styled(Company)``;
