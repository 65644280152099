import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Budget } from 'components/icons';

import useClickOutside from 'hooks/useClickOutside';

import Popover from './Popover';
import { useTranslation } from 'react-i18next';

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding-left: 44px;
  cursor: pointer;
  padding-right: 15px;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.25s;

  &:focus {
    color: #4a515c;
    border-color: #abb3bf;
    box-shadow: none;
    outline: 0;

    &::placeholder {
      color: #d6d9df;
    }
  }

  ${({ hasSelected }) => hasSelected && css`
    padding-right: 36px;
  `}
`;

const InputContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;

  ${Budget} {
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  ${Popover} {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 2;
  }
`;

function PriceField({ className, value, ...rest }) {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const [from, to] = value;

  const valueText = t('Price.Range', { from, to });
  const wrapperRef = useRef();

  useClickOutside(wrapperRef, () => setOpened(false));

  const handleClick = () => {
    setOpened(!opened);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <Wrapper className={className} ref={wrapperRef}>
      <InputContainer>
        <Budget />

        <Input
          readOnly
          icon={<Budget />}
          value={valueText}
          // eslint-disable-next-line no-use-before-define
          autoComplete='off'
          onClick={handleClick}
        />
      </InputContainer>

      {opened && (
        <Popover
          onClose={handleClose}
          value={value}
          {...rest}
        />
      )}
    </Wrapper>
  );
}

PriceField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array
};

export default styled(PriceField)``;
