import { useState, useMemo } from 'react';
import _range from 'lodash/range';
import getSelectedOccupations from './utils/getSelectedOccupations';
import setSelectedOccupations from './utils/setSelectedOccupations';
import setChilds from './utils/setChilds';

const DEFAULT_OCCUPATIONS = {
  adults: _range(1, 9).map(count => {
    return { count, available: true, selected: false };
  }),

  childs: _range(0, 5).map(count => {
    return { count, available: true, selected: false };
  })
};

function useValue({ initialValue } = { initialValue: [] }) {
  const [value, setValue] = useState(initialValue);

  const occupations = useMemo(() => {
    const occupationsValue = getSelectedOccupations({
      travellers: value,
      occupations: DEFAULT_OCCUPATIONS
    });

    return occupationsValue;
  }, [value]);

  const onSetValue = ({ ageGroup, count }) => {
    const nextValue = setSelectedOccupations({
      travellers: value,
      count,
      ageGroup
    });

    setValue(nextValue);
  };

  const onSetChilds = (childs) => {
    const nextValue = setChilds({
      travellers: value, childs
    });

    setValue(nextValue);
  };

  return [{ value, occupations }, { setValue: onSetValue, setChilds: onSetChilds }];
}

export default useValue;
