import { useState } from 'react';
import instance from 'connection/instance';
import cleanDeep from 'clean-deep';
import qs from 'qs';

function useOrder() {
  const [order, setOrder] = useState();

  const fetchOrder = async (id) => {
    const { data: { data } } = await instance.get(`/api/orders/${id}`);

    setOrder(data);

    return data;
  };

  const updateOrder = async (id, params) => {
    const data = cleanDeep({ ...order, ...params }, { emptyArrays: false });
    const { data: { data: response } } = await instance.put(`/api/orders/${id}`, { data });

    setOrder(response);

    return response;
  };

  const createOrder = async (values) => {
    const data = cleanDeep(values, { emptyArrays: false });
    const { data: { data: order } } = await instance.post('/api/orders', { data });

    return order;
  };

  const buildEditUrl = (id, query) => {
    const opts = {
      skipNulls: true
    };

    const { travellers } = query;
    const queryString = qs.stringify({ travellers }, opts);

    return `/orders/${id}/edit?${queryString}`;
  };

  const buildSearchUrl = (id, query) => {
    const opts = {
      skipNulls: true
    };

    const queryString = qs.stringify(query, opts);
    return `/orders/${id}/search?${queryString}`;
  };

  return [order, { updateOrder, createOrder, fetchOrder, buildEditUrl, buildSearchUrl }];
}

export default useOrder;
