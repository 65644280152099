import { types, applySnapshot, applyPatch, detach } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Meta } from './Meta';
import { HotelItem } from './HotelItem';

const List = types
  .model('List', {
    meta: types.optional(Meta, {}),
    data: types.optional(types.array(HotelItem), []),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get isLastPage() {
      const { paginate: { is_last } } = self.meta;
      return is_last;
    }
  }))
  .actions(self => ({
    fetch(options = {}) {
      self.setState('pending');

      const { paginate } = self.meta.toJSON();
      const data = { paginate, ...options };

      return instance.get('/api/hotels/search', { params: { data } })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    fetchMore(options = {}) {
      self.setState('pending');

      const { paginate: { page, limit } } = self.meta.toJSON();
      const paginate = { page: page + 1, limit };
      const data = { paginate, ...options };

      return instance.get('/api/hotels/search', { params: { data } })
        .then(response => self.updateStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    favorites(params = {}) {
      self.setState('pending');

      return instance.get('/api/hotels/favorites', { params })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      detach(self);
      applySnapshot(self, {});
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    updateStore(response) {
      const { status } = response;

      if (status === 200) {
        const { meta, data } = response.data;

        const metaPatch = [{ op: 'replace', path: '/meta', value: meta }];

        const hotelsPatch = data.map(order => {
          return { op: 'add', path: '/data/-', value: order };
        });

        applyPatch(self, metaPatch.concat(hotelsPatch));
      }
    }
  }));

export default List;
