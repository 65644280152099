import instance from 'connection/instance';

class MealOptionsStore {
  async fetch(params = {}) {
    const [
      { data: { data: meal_options } },
      { data: { data: feed_types } }
    ] = await Promise.all([
      instance.get('/api/filters/meal_options', { params }),
      instance.get('/api/filters/feed_types', { params })
    ]);

    const data = [...meal_options, ...feed_types];

    return { meta: {}, data };
  }
}

export default MealOptionsStore;
