import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Title = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #323232;
  margin-top: 13px;
  margin-bottom: 10px;
`;

const Element = styled.div`
  padding: 7px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
`;

const Wrapper = styled.div``;

export const titleClassName = css``;
export const groupClassName = css``;

function Group({ className, group }) {
  const elementsComponent = group?.children?.map((element) => <Element key={element.id}>{element.name}</Element>);

  const wrapperClassName = useMemo(() => cx(groupClassName, className), [className]);
  className = { wrapperClassName };
  return (
    <Wrapper>
      <Title className={titleClassName}>{group.name}</Title>

      {elementsComponent}
    </Wrapper>
  );
}

Group.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object
};

export default Group;
