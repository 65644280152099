import instance from 'connection/instance';

function parseBalanceData(response) {
  const { data: { data } } = response;
  return { balance: data.asset_balance };
}

function parseInvoicesData(response) {
  const { data } = response;
  return data;
}

async function fetchData() {
  const [balanceData, invoiceData] = await Promise.all([
    instance.get('/api/organizations/balance'),
    instance.get('/api/invoices')
  ]);

  return {
    ...parseBalanceData(balanceData),
    ...parseInvoicesData(invoiceData)
  };
}

export default fetchData;
