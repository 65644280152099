import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'connection/instance';

const blockUser = createAsyncThunk('users/block', async (user) => {
  const { data: response } = await client.post(`/api/users/${user.id}/block`);
  const { id, ...changes } = response.data;

  return { id, changes };
});

export default blockUser;
