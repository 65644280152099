import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';
import { Table } from 'components/ui';

class ReportsTable extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    reportCellSizes: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, reportCellSizes, t, ...rest } = this.props;

    return (
      <Table {...rest}>
        <Table.Head>
          <Table.HeadCell width={`${reportCellSizes.transactionType}%`}>
            {t('Reports.TableHead.TransactionType')}
          </Table.HeadCell>

          <Table.HeadCell width={`${reportCellSizes.transactionId}%`}>
            {t('Reports.TableHead.TransactionId')}
          </Table.HeadCell>

          <Table.HeadCell width={`${reportCellSizes.createdAt}%`}>
            {t('Reports.TableHead.CreatedAt')}
          </Table.HeadCell>

          <Table.HeadCell width={`${reportCellSizes.paymentType}%`}>
            {t('Reports.TableHead.PaymentType')}
          </Table.HeadCell>

          <Table.HeadCell width={`${reportCellSizes.amount}%`}>
            {t('Reports.TableHead.Amount')}
          </Table.HeadCell>

          <Table.HeadCell width={`${reportCellSizes.accountCode}%`}>
            {t('Reports.TableHead.AccountCode')}
          </Table.HeadCell>
        </Table.Head>

        <Table.Body>
          {children}
        </Table.Body>
      </Table>
    );
  }
}

export default styled(withTranslation()(ReportsTable))``;
