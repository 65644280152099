import BaseState from 'forms/BaseState';

import { fields } from '.';

class HotelsForm extends BaseState {
  setup() {
    return fields;
  }
}

export default HotelsForm;
