import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const DateTimeS = styled.span`
  line-height: 1.5em;
  white-space: nowrap;
`;

class DateTime extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    dateTime: PropTypes.string.isRequired
  };

  render() {
    const { dateTime, ...rest } = this.props;

    return (
      <>
        <DateTimeS {...rest}>
          {moment(dateTime).format('D MMM YYYY')}
        </DateTimeS>
        {', '}
        <DateTimeS {...rest}>
          {moment(dateTime).format('H:MM')}
        </DateTimeS>
      </>
    );
  }
}

export default styled(DateTime)``;
