import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { Offer } from './containers';

function Info({ className }) {
  return (
    <Switch>
      <Route
        exact
        path='/info/offer'
        component={Offer}
      />
    </Switch>
  );
}

Info.propTypes = {
  className: PropTypes.string
};

export default Info;
