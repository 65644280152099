import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import Item from './Item';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
`;

const Wrapper = styled.div`
  min-width: 100%;
`;

function List({ items, onSelect, className }) {
  return (
    <Wrapper className={className}>
      <Scrollbars
        universal
        autoHeight
        autoHeightMin={0}
        autoHeightMax={430}
      >
        <Body>
          {items.map((item, i) => (
            <Item
              key={i}
              item={item}
              onSelect={onSelect}
            />
          ))}
        </Body>
      </Scrollbars>
    </Wrapper>
  );
}

List.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

List.defaultProps = {
  items: [],
  onSelect: () => {}
};

export default styled(List)``;
