import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  FIRST_NAME,
  LAST_NAME,
  MIDDLE_NAME,
  EMAIL,
  PHONE,
  ROLE
} from './UserForm.schema';

import {
  FirstNameField,
  LastNameField,
  MiddleNameField,
  EmailField,
  PhoneField,
  RoleField
} from '../FormFields';

const Field = styled.div``;

const FieldsGroup = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }

  ${Field} {
    width: 260px;
  }

  ${Field} + ${Field} {
    margin-left: 8px;
  }
`;

const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  ${Body} {
    flex-grow: 1;
  }

  ${Body} + ${Actions} {
    margin-left: 16px;
  }
`;

function UserForm({ children, className }) {
  return (
    <Wrapper className={className}>
      <Body>
        <FieldsGroup>
          <Field>
            <LastNameField name={LAST_NAME} />
          </Field>

          <Field>
            <FirstNameField name={FIRST_NAME} />
          </Field>

          <Field>
            <MiddleNameField name={MIDDLE_NAME} />
          </Field>
        </FieldsGroup>

        <FieldsGroup>
          <Field>
            <EmailField name={EMAIL} />
          </Field>

          <Field>
            <PhoneField name={PHONE} />
          </Field>

          <Field>
            <RoleField name={ROLE} />
          </Field>
        </FieldsGroup>
      </Body>

      <Actions>
        {children}
      </Actions>
    </Wrapper>
  );
}

UserForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(UserForm)``;
