function getPricesRange(items) {
  const values = items
    .map(item => (item.tariff?.discount_price?.amount || -1))
    .filter(item => item > 0)
    .sort((a, b) => a < b ? -1 : 1);

  const min = values[0];
  const max = values[values.length - 1];

  return [min, max];
}

export default getPricesRange;
