import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import moment from 'utils/moment';

import { Form } from 'components/ui';
import Insurance from './Insurance';
import Slots from './Slots';
import Bottom from './Bottom';

const Title = styled.div`
  color: #4a515c;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const Wrapper = styled(Form)`
  box-sizing: border-box;

  ${Slots} {
    margin-top: 10px;
    padding-bottom: 28px;
    border-bottom: 1px solid #d1d5db;
  }

  ${Insurance} + ${Slots} {
    margin-top: 32px;
  }

  ${Bottom} {
    margin-top: 34px;
  }
`;

@withTranslation()
@inject('bookingForm', 'bookingState', 'commissionVisibility')
@observer
class TravellersForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    t: PropTypes.func,
    searchState: PropTypes.object,
    bookingState: PropTypes.object,
    commissionVisibility: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  @computed get rates() {
    const { bookingForm: { slots } } = this.props;

    const rates = slots
      .map(slot => slot.$('rate.type').value);

    return rates;
  }

  @computed get restrictionsData() {
    const { rates, withCommission, props: { bookingState } } = this;

    return bookingState.getRestrictions({ rates, withCommission, range: [this.from, this.to] });
  }

  @computed get from() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_in');

    return value ? moment(value).toDate() : null;
  }

  @computed get to() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_out');

    return value ? moment(value).toDate() : null;
  }

  @computed get hasStopSales() {
    const { bookingForm, bookingState } = this.props;

    const days = bookingState.getDailyPricesPerPeriod(bookingForm.values());

    return days.find(d => d.stop_sales);
  }

  render() {
    const { bookingForm, t, searchState, bookingState, commissionVisibility, ...rest } = this.props;
    const { order } = bookingForm;

    return (
      <Wrapper form={bookingForm} {...rest}>
        <Title>
          {t('Hotel.RoomType.Guests.Title')}
        </Title>

        <Slots />

        <Bottom
          order={order}
          restrictionsData={this.restrictionsData}
          formData={bookingForm.values()}
          showComission={commissionVisibility.isAmountVisible}
          isPending={bookingState.isPending}
          tariff={bookingState.tariff}
          hotel={bookingState.hotel}
          disabledAction={!bookingForm.isValid || this.hasStopSales}
        />
      </Wrapper>
    );
  }
}

export default styled(TravellersForm)``;
