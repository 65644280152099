import { types } from 'mobx-state-tree';

const PaymentInfo = types.model('PaymentInfo', {
  free_cancellation_until: types.maybeNull(types.string),
  payment_expired_at: types.maybeNull(types.string),
  payment_required_at: types.maybeNull(types.string),
  payment_method: types.string
});

export default PaymentInfo;
