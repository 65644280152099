import React from 'react';
import PropTypes from 'prop-types';
import reset from 'styled-reset';
import { Provider } from 'mobx-react';
import { ApplyTheme } from 'rambler-ui/theme';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Hotkeys from 'react-hot-keys';

import theme from 'theme';
import textCSS from 'theme/textCSS';
import globalCss from 'theme/css/global.css';

import AuthStore from 'stores/AuthStore';
import CommissionVisibility from 'stores/CommissionVisibility';

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${globalCss};
  ${textCSS}
`;

const stores = {
  authStore: AuthStore.create(),
  commissionVisibility: new CommissionVisibility()
};

class MainProvider extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  handleKeyDown = (keyName, e, handle) => {
    const { commissionVisibility } = stores;
    commissionVisibility.toggleCommission();
  }

  handleKeyUp = (keyName, e, handle) => {
    console.log('test:onKeyUp', keyName, e, handle);
  }

  render() {
    const { children } = this.props;

    return (
      <BrowserRouter>
        <Provider {...stores}>
          <ThemeProvider theme={theme}>
            <ApplyTheme theme={theme}>
              <Hotkeys
                keyName='shift+r'
                onKeyDown={this.handleKeyDown}
              >
                <>
                  <GlobalStyle />
                  {children}
                </>
              </Hotkeys>
            </ApplyTheme>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    );
  }
}

export default MainProvider;
