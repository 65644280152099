import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { Input } from '.';

@observer
class Address extends React.Component {
  @computed get hasError() {
    const { field } = this.props;
    return field.$('coordinates').error;
  }

  onFocus = () => {};

  onBlur = () => {};

  onChange = () => {};

  handleSuggestNoResults = () => {};

  handleSuggestSelect = (suggest) => {
    if (!suggest) {
      this.resetFieldValue();
      return;
    }

    const { field } = this.props;
    const { label, location } = suggest;

    field.set({
      location: label,
      coordinates: [location.lng, location.lat]
    });

    field.resetValidation();
  };

  resetFieldValue = () => {
    const { field } = this.props;

    field.set({
      location: '',
      coordinates: undefined
    });

    field.resetValidation();
  };

  render() {
    const { field, disabled, fixtures, ...rest } = this.props;

    return (
      <Input
        initialValue={field.$('location').value}
        {...field.bind({
          onChange: this.onChange,
          onFocus: this.onFocus,
          onBlur: this.onBlur
        })}
        onSuggestSelect={this.handleSuggestSelect}
        onSuggestNoResults={this.handleSuggestNoResults}
        inputClassName={this.hasError && 'geosuggest__input_has-error'}
        {...rest}
      />
    );
  }
}

Address.defaultProps = {
  disabled: false,
  fixtures: []
};

Address.propTypes = {
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fixtures: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      location: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      })
    })
  )
};

export default Address;
