import { types } from 'mobx-state-tree';
import { Categories } from './Categories';

export const Review = types.model('Review', {
  user_name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  geolocation: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  hotel_id: types.maybeNull(types.string),
  provider: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  description_original: types.maybeNull(types.string),
  language_original: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  avatar: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  categories: types.maybeNull(types.array(Categories))
});
