import { types } from 'mobx-state-tree';

import Tariff from '../Tariff';

import { Image, Amenity } from 'stores/models';

const RoomType = types.model('RoomType', {
  id: types.identifier,
  name: types.string,
  area: types.number,
  rm_count: types.number,
  beds: types.number,
  extra_beds: types.number,
  images: types.optional(types.array(Image), []),
  tariffs: types.optional(types.array(Tariff), []),
  amenities: types.maybeNull(types.array(Amenity)),
  is_available: types.boolean
});

export default RoomType;
