import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const pulse = keyframes`
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #e0e0e0;
  }
`;

const Line = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width};
  background-color: #e0e0e0;
  border-radius: ${(props) => props.borderRadius};
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextSkeleton = ({ className, width = '100%', height = 10, borderRadius, count = 3 }) => {
  return (
    <Wrapper className={className}>
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <Line key={`skeleton-${i}`} width={width} height={height} borderRadius={borderRadius} />
        ))}
    </Wrapper>
  );
};

TextSkeleton.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.number,
  count: PropTypes.number,
  borderRadius: PropTypes.string
};

export default TextSkeleton;
