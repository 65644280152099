import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Header from './Header';
import Actions from '../Actions';
import Reservations from '../Reservations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;

  ${Reservations} + ${Actions} {
    margin-top: 16px;
  }
`;

@inject('showState')
@observer
class Body extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    showState: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState, className } = this.props;

    const { selectedGroup: { reservations } } = showState;

    return (
      <Wrapper className={className}>
        <Header />

        <Reservations
          reservations={reservations}
        />

        <Actions />
      </Wrapper>
    );
  }
}

export default styled(Body)``;
