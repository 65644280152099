import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { DownloadIcon, Plus as PlusIcon } from 'components/icons';

import { Button } from 'components/forms';
import Cancel from 'pages/Orders/containers/Cancel';

import { dateFormat } from 'utils';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  ${DownloadIcon} {
    margin-right: 8px;
  }
`;

const Text = styled.div`
  color: #979ba0;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

const CancellationInfo = styled.div`
  text-align: right;
  margin-right: 15px;
`;

const CancellationTextInfo = styled.div`
  color: #6b707b;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
`;

const AddButton = styled.div`
  display: flex;
  align-itemss: center;
  cursor: pointer;
`;

const AddButtonText = styled.div`
  color: #3aa6d2;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 8px;
`;

const PlusIconContainer = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #3aa6d2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${PlusIcon} {
    font-size: 8px;
  }

  ${DownloadIcon} {
    font-size: 16px;

    path {
      fill: #fff;
    }
  }

  ${Button} + ${Button},
  ${Button} + ${Cancel} {
    margin-left: 20px;
  }

  ${Button} + ${AddButton} {
    margin-left: 24px;
  }
`;

@withRouter
@inject('showState')
@observer
class PrimaryActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get showDownloadVoucherBtn() {
    const { showState } = this.props;
    return showState.isConfirmed;
  }

  @computed get showDownloadBillBtn() {
    const {
      showState: { order }
    } = this.props;
    return order.has_invoice;
  }

  @observable isLoading = undefined;

  setLoading(value) {
    this.isLoading = value;
  }

  unsetLoading() {
    this.isLoading = undefined;
  }

  handleAddRoom = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    return showState.addRoom();
  };

  handleDownloadVoucher = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;

    this.setLoading('voucher');
    await showState.downloadVoucher();
    this.unsetLoading();
  };

  handleDownloadBill = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;

    this.setLoading('invoice');
    await showState.downloadInvoice();
    this.unsetLoading();
  };

  handleCancel = () => {
    const { showState } = this.props;
    return showState.cancel();
  };

  render() {
    const {
      t,
      showState: {
        order,
        values: {
          payment_info: { free_cancellation_until }
        }
      },
      ...rest
    } = this.props;

    const isActiveResale = order?.resale?.active;

    return (
      <Wrapper {...rest}>
        <LeftContainer>
          {this.showDownloadVoucherBtn && (
            <Button rounded onClick={this.handleDownloadVoucher} loading={this.isLoading === 'voucher'}>
              <ButtonContainer>
                <DownloadIcon />
                {t('Order.Voucher')}
              </ButtonContainer>
            </Button>
          )}

          {this.showDownloadBillBtn && (
            <Button rounded onClick={this.handleDownloadBill} loading={this.isLoading === 'invoice'}>
              <ButtonContainer>
                <DownloadIcon />
                {t('Order.Bill')}
              </ButtonContainer>
            </Button>
          )}

          <AddButton rounded outline onClick={this.handleAddRoom}>
            <PlusIconContainer>
              <PlusIcon />
            </PlusIconContainer>

            <AddButtonText>{t('Order.AddRoom')}</AddButtonText>
          </AddButton>
        </LeftContainer>

        <RightContainer>
          <CancellationInfo>
            <CancellationTextInfo>{t('Order.PaymentInfo.FreeCancel')}:</CancellationTextInfo>

            {free_cancellation_until ? (
              <Text>{dateFormat(free_cancellation_until, 'LL  HH:mm (Z)')}</Text>
            ) : (
              <Text>&mdash;</Text>
            )}
          </CancellationInfo>

          <Cancel
            title={t('Order.Remove.Caption')}
            acceptText={t('Order.Remove.Accept')}
            declineText={t('Order.Remove.Cancel')}
            onAccept={this.handleCancel}
            onDecline={() => {}}
            disableCancelBtn={isActiveResale}>
            <Button rounded size='small' outline variant='danger' disabled={isActiveResale}>
              {t('Order.Cancel')}
            </Button>
          </Cancel>
        </RightContainer>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PrimaryActions))``;
