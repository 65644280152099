import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moment from 'utils/moment';

const Wrapper = styled.span`
`;

class DateTime extends React.PureComponent {
  static propTypes = {
    format: PropTypes.string,
    value: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: '',
    format: 'D-MM-YYYY'
  };

  render() {
    const { value, format, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {moment(value).format(format)}
      </Wrapper>
    );
  }
}

export default styled(DateTime)``;
