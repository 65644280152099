import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';

import Pagination from 'components/ui/Pagination';
import { PagePlaceholder } from 'components/ui';
import { ReportsTable, ReportItem, Top } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 832px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

// const TabsS = styled(Tabs)`
//   margin: 29px 0 32px;
// `;

// const FilterBarS = styled(FilterBar)`
//   margin-bottom: 39px;
// `;

@withRouter
@withTheme
@inject('reportsStore')
@observer
class Reports extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    ),
    reportsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: '',
    filters: []
  };

  @computed get isFetched() {
    const { reportsStore } = this.props;
    return reportsStore.isFetched;
  }

  @computed get pagination() {
    const { reportsStore } = this.props;
    const { page, total } = reportsStore.meta.paginate;

    return {
      total,
      current: Number(page)
    };
  }

  @computed get reportsList() {
    const { reportsStore } = this.props;
    const reportsList = Object.values(reportsStore.data.toJSON()).map(item => item.toJSON());

    return reportsList;
  }

  render() {
    const { filters, t, ...rest } = this.props;

    const reportCellSizes = {
      accountCode: 20,
      amount: 12,
      paymentType: 24,
      transactionType: 14,
      transactionId: 16,
      createdAt: 14
    };

    return (
      <Wrapper {...rest}>
        {this.reportsList.length > 0 && (
          <>
            <Top />

            {/* {false && <TabsS />}

            {false && <FilterBarS filters={filters} />} */}

            <ReportsTable reportCellSizes={reportCellSizes}>
              {this.reportsList.map(report => (
                <ReportItem
                  key={report.id}
                  report={report}
                  reportCellSizes={reportCellSizes}
                />
              ))}
            </ReportsTable>

            <Pagination {...this.pagination} />
          </>
        )}

        {(this.isFetched && this.reportsList.length === 0) && (
          <PagePlaceholder.Container>
            <PagePlaceholder>
              <PagePlaceholder.Image />

              <PagePlaceholder.Content>
                {t('Reports.Empty')}
              </PagePlaceholder.Content>
            </PagePlaceholder>
          </PagePlaceholder.Container>
        )}

        {(!this.isFetched && this.reportsList.length === 0) && (
          <PagePlaceholder.Container>
            <PagePlaceholder>
              <PagePlaceholder.Fetch label={t('UI.Fetching')} />
            </PagePlaceholder>
          </PagePlaceholder.Container>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reports))``;
