import { Field } from 'mobx-react-form';
import { computed, reaction } from 'mobx';
import capitalize from 'utils/capitalize';
import { t } from 'utils/localization';

class TravellerField extends Field {
  constructor(props) {
    super(props);

    this.addHandlers();
  }

  @computed get fioFilled() {
    const errors = [];

    if (this.$('first_name').error) {
      errors.push(this.$('first_name').error);
    }

    if (this.$('last_name').error) {
      errors.push(this.$('last_name').error);
    }

    return errors.length === 0;
  }

  @computed get ageFilled() {
    const errors = [];

    if (this.$('age').error) {
      errors.push(this.$('age').error);
    }

    return errors.length === 0;
  }

  @computed get dateOfBirthFilled() {
    const errors = [];

    if (this.$('date_of_birth').error) {
      errors.push(this.$('date_of_birth').error);
    }

    return errors.length === 0;
  }

  @computed get genderFilled() {
    const errors = [];

    if (this.$('gender').error) {
      errors.push(this.$('gender').error);
    }

    return errors.length === 0;
  }

  @computed get isFilled() {
    const errors = [];

    if (this.$('first_name').blurred && this.$('first_name').error) {
      errors.push(this.$('first_name').error);
    }

    if (this.$('last_name').blurred && this.$('last_name').error) {
      errors.push(this.$('last_name').error);
    }

    if (this.$('age').blurred && this.$('age').error) {
      errors.push(this.$('age').error);
    }

    if (this.$('gender').blurred && this.$('gender').error) {
      errors.push(this.$('gender').error);
    }

    return errors.length === 0;
  }

  addHandlers() {
    this._setFullNameHandler = reaction(
      () => this.values(),
      (values) => this.setFullName(values),
      { fireImmediately: true }
    );
  }

  removeHandlers() {
    this._setFullNameHandler();
  }

  setFullName({ first_name, last_name, middle_name, age, gender }) {
    if (!first_name || !last_name || !middle_name) return;

    const fullName = this.formatFullName({ first_name, last_name, middle_name });
    const genderAndAge = this.formatGenderAndAge({ age, gender });

    const full_name = [fullName, genderAndAge].join(' ・ ');

    this.set({ full_name });
  }

  formatFullName({ first_name, middle_name, last_name }) {
    return [last_name, first_name, middle_name]
      .filter(item => !!item)
      .map(v => capitalize(v.trim()))
      .join(' ');
  }

  formatGenderAndAge({ gender, age }) {
    return t('Form.GenderAndAge.Value', { age, gender });
  }
}

export default TravellerField;
