import { t } from 'utils/localization';

const fields = [
  'order_code'
];

const placeholders = {
  order_code: t('Orders.Filters.OrderCode.Label')
};

export default {
  fields,
  placeholders
};
