import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Title } from '.';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${({ hasError, theme }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 2px solid ${theme.colors.active.secondary};
    padding: 16px 24px;
    background: rgba(156, 180, 202, 0.2);
    transition:
      background-color 0.25s,
      border-color 0.25s;

    ${hasError && css`
      background-color: ${theme.colors.error.secondary};
      border-color: ${theme.colors.danger};
    `}
  `}
`;

@withTheme
class Main extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    count: PropTypes.number
  };

  static defaultProps = {
    className: ''
  };

  static Title = Title;

  render() {
    const { count, children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Main)``;
