import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import HotelCard from './HotelCard';

import HotelsInfo from 'stores/HotelsStore/Info';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

@observer
class Hotel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.hotelStore = HotelsInfo.create();
  }

  componentDidMount() {
    const { hotel } = this.props;
    this.hotelStore.fetch(hotel);
  }

  componentWillUnmount() {
    this.hotelStore.clear();
  }

  render() {
    const { className } = this.props;

    const { hotelStore: { data: hotel, isFetched } } = this;

    return (
      <Wrapper className={className}>
        {isFetched && (
          <HotelCard
            hotel={hotel}
          />
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Hotel))``;
