import moment from 'utils/moment';

function normalizeAddress({ address }) {
  if (!address) return {};

  const { scope, coordinates, location } = address;
  return { address: { scope, location, coordinates } };
}

function normalizeTravellers({ travellers }) {
  if (!travellers) return {};

  const data = (travellers || []).map(t => {
    const { age_group, age } = t;
    return { age_group, age: (age || 0) };
  });

  return { travellers: data };
}

function normalizeDates({ check_in, check_out }) {
  if (!check_in || !check_out) return {};

  const checkIn = moment(check_in).format('YYYY-MM-DD');
  const checkOut = moment(check_out).format('YYYY-MM-DD');

  return { check_in: checkIn, check_out: checkOut };
}

function normalizeHotelIds({ hotel_ids }) {
  if (!hotel_ids) return {};

  return { hotel_ids };
}

function normalizeFetchParams({ data: params }) {
  return {
    ...normalizeAddress(params),
    ...normalizeTravellers(params),
    ...normalizeDates(params),
    ...normalizeHotelIds(params)
  };
}

export default normalizeFetchParams;
