import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createNanoEvents } from 'nanoevents';

import EventsContext from '../context';

function EventsContainer({ children }) {
  const context = useMemo(() => createNanoEvents(), []);

  return (
    <EventsContext.Provider value={context}>
      {children}
    </EventsContext.Provider>
  );
}

EventsContainer.propTypes = {
  children: PropTypes.any
};

export default EventsContainer;
