import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import { action, computed } from 'mobx';

import fields from './fields';

class ContractState extends Form {
  options() {
    return {
      showErrorsOnClear: false,
      showErrorsOnChange: true,
      showErrorsOnSubmit: true,
      validateOnChange: false
    };
  }

  plugins() {
    return {
      dvr: dvr(validatorjs)
    };
  }

  setup() {
    return fields;
  }

  @action
  setContract(contract) {
    this.update({ contract });
  }

  @action
  unsetContract() {
    this.update({ contract: undefined });
  }

  @computed get contractIsValid() {
    return this.isValid;
  }
}

export default ContractState;
