import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import formatPrice from 'utils/formatPrice';

const Wrapper = styled.div`
  color: #4A515C;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

function Header({ meta, className }) {
  const { t } = useTranslation();

  const { total: { net_price } } = meta;

  return (
    <Wrapper className={className}>
      {t('Reports.Invoices.Total', { value: formatPrice(net_price) })}
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default styled(Header)``;
