import { reaction, computed } from 'mobx';
import BaseState from 'forms/BaseState';
import { Field } from 'mobx-react-form';
import _range from 'lodash/range';

import fields from './fields';
import moment from 'utils/moment';

import TravellerField from './TravellerField';

import { getSelectedOccupations } from '.';

const ADULT = {
  age_group: 'adult',
  age: 0
};

const CHILD = {
  age_group: 'child',
  age: 8
};

const DEFAULT_OCCUPATIONS = {
  adults: _range(1, 9).map(count => ({
    count,
    available: true,
    selected: false
  })),

  childs: _range(0, 5).map(count => ({
    count,
    available: true,
    selected: false
  }))
};

class SearchForm extends BaseState {
  constructor(...props) {
    super(...props);

    reaction(
      () => this.$('hotel.address').value,
      address => this.setAddress(address)
    );
  }

  @computed get nights() {
    const check_in = this.$('check_in').value;
    const check_out = this.$('check_out').value;

    if (!check_in || !check_out) { return 1; }

    const checkIn = moment(check_in);
    const checkOut = moment(check_out);

    const diff = checkOut.diff(checkIn);

    return Math.ceil(diff / (86400 * 1000));
  }

  @computed get days() {
    const checkIn = this.$('check_in').value;
    const checkOut = this.$('check_out').value;

    const days = moment(checkOut).diff(moment(checkIn), 'days') + 1;

    return days;
  }

  @computed get travellers() {
    return this.$('travellers').value.length;
  }

  @computed get nightsStr() {
    const pluralize = () => '';
    const count = this.nights;
    const text = pluralize(count, 'ночь', 'ночи', 'ночей');

    return count > 1
      ? ['за', count, text].join(' ')
      : ['за', text].join(' ');
  }

  @computed get childCount() {
    return this.$('travellers').value.filter(traveller => traveller.age_group === 'child').length;
  }

  @computed get adultCount() {
    return this.$('travellers').value.filter(traveller => traveller.age_group === 'adult').length;
  }

  @computed get guestsOccupation() {
    const travellers = this.$('travellers').value;
    let occupations = DEFAULT_OCCUPATIONS;

    occupations = getSelectedOccupations({
      travellers: travellers,
      occupations: occupations
    });

    return occupations;
  }

  setAdultsSlots(count = 1) {
    let _travellers = _range(0, count).map(count => {
      return ADULT;
    });

    const travellers = this.$('travellers').value
      .filter(t => t.age_group !== 'adult');

    _travellers = _travellers.concat(travellers);

    this.update({ travellers: _travellers });
  }

  setChildsSlots(count = 0) {
    let _travellers = _range(0, count).map(count => {
      return CHILD;
    });

    const travellers = this.$('travellers').value
      .filter(t => t.age_group !== 'child');

    _travellers = _travellers.concat(travellers);

    this.update({ travellers: _travellers });
  }

  @computed get requestParams() {
    const { check_in, check_out, address, filter, sort } = this.values();

    const travellers = [
      { age_group: 'adult', age: 0 }
    ];

    const data = {
      check_in,
      check_out,
      sort,
      filter,
      travellers
    };

    if (address && address.location) {
      data.address = address;
    }

    return data;
  }

  setAddress = (address = undefined) => {
    if (address) {
      this.$('address').set(address);
    }
  }

  setup() {
    return fields;
  }

  options() {
    return {
      showErrorsOnClear: true,
      showErrorsOnChange: false,
      showErrorsOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true
    };
  }

  makeField(props) {
    const re = /^travellers.\d+$/;

    if (re.exec(props.path)) {
      return new TravellerField(props);
    }

    return new Field(props);
  }
}

export default SearchForm;
