import { types } from 'mobx-state-tree';

const Report = types.model('Report', {
  id: types.identifier,
  account_code: types.string,
  amount: types.maybeNull(types.number),
  created_at: types.string,
  payment_type: types.string,
  transaction_id: types.string,
  transaction_type: types.string
});

export { Report };
