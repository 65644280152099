import { types } from 'mobx-state-tree';
import { ReviewCategories } from './ReviewCategories';

export const ReviewBest = types.model('ReviewBest', {
  user_name: types.maybeNull(types.string),
  geolocation: types.maybeNull(types.string),
  title_original: types.maybeNull(types.string),
  description_original: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  avatar: types.maybeNull(types.string),
  categories: types.optional(types.array(ReviewCategories), [])
});
