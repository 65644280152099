import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Button from 'components/forms/Button';
import { Cancel } from 'pages/Orders/containers';
import { NotAvailable as NotAvailableIcon } from 'components/icons';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;
  font-size: 60px;
  border-radius: 50%;
  background-color: #E3EAF0;
`;

const Body = styled.div`
  font-size: 14px;
  color: #4C4C4C;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  ${Body}, ${Actions} {
    margin-top: 24px;
  }
`;

@withRouter
@inject('bookingState')
@observer
class EmptyContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.wrapperEl = React.createRef();
  }

  handleChooseOther = (e) => {
    e.preventDefault();

    const { order, history } = this.props;
    history.push(`/orders/${order.id}/search`);
  }

  handleCancel = () => {
    const { bookingState } = this.props;

    return bookingState.orderCancel()
      .then(_ => this.navigateTo());
  }

  navigateTo = () => {
    const { history } = this.props;
    history.replace('/orders');
  }

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper
        ref={this.wrapperEl}
        className={className}
      >
        <Icon>
          <NotAvailableIcon />
        </Icon>

        <Body>
          {t('Hotel.NotAvailable')}
        </Body>

        <Actions>
          <Button
            onClick={this.handleChooseOther}
          >
            {t('Hotel.ChooseOther')}
          </Button>

          <Cancel
            title={t('Order.Remove.Caption')}
            acceptText={t('Order.Remove.Accept')}
            declineText={t('Order.Remove.Cancel')}
            onAccept={this.handleCancel}
            onDecline={() => {}}
          >
            <Button
              compact
              variant='secondary'
            >
              {t('Order.Cancel')}
            </Button>
          </Cancel>
        </Actions>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(EmptyContent))``;
