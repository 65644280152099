import React from 'react';
import PropTypes from 'prop-types';
import { computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';

import { Field, SelectX } from 'components/forms';
import { Form } from 'components/ui';

import { ManagersForm } from './forms';
import { ManagersStore } from 'stores/FiltersStore';

const Wrapper = styled(Form)``;

@observer
class ManagersFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  };

  constructor(props) {
    super(props);

    const hooks = {
      onInit: this.handleInit
    };

    this.form = new ManagersForm({}, { hooks });
    this.managersStore = ManagersStore.create();
  }

  componentDidMount() {
    this.addHandlers();
  }

  componentWillUnmount() {
    this.removeHandlers();
  }

  handleInit = (form) => {
    form.$('manager').focus();
  }

  addHandlers() {
    this._changeHandler = reaction(
      () => this.form.$('manager').value,
      (manager) => this.handleSubmit(manager)
    );
  }

  removeHandlers() {
    this._changeHandler();
  }

  handleSubmit = (manager) => {
    const { filter: { label, type }, onSubmit } = this.props;

    const { id: value, name } = manager;

    onSubmit({ type, value, label: `${label}: ${name}` });
  }

  handleSearch = _debounce(
    (chars = '') => {
      const data = { chars };

      if (chars.length >= 3) {
        this.managersStore.fetch({ data });
      }
    },
    200
  );

  @computed get options() {
    return this.managersStore.isFetched
      ? this.managersStore.selectOptions
      : [];
  }

  render() {
    const { filter, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        form={this.form}
      >
        <Field
          field={this.form.$(filter.type)}
          component={SelectX}
          items={this.options}
          valueKey='id'
          labelKey='name'
          autoComplete='off'
          isLoading={this.managersStore.isPending}
          onSearch={this.handleSearch}
          {...rest}
        />
      </Wrapper>
    );
  }
}

export default styled(ManagersFilter)``;
