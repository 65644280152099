import { t } from 'utils/localization';

const fields = [
  'email',
  'first_name',
  'last_name',
  'middle_name',
  'full_name',
  'phone',
  'extra_phone',
  'login_1c'
];

const placeholders = {
  email: t('Profile.Email.Label'),
  full_name: t('Profile.FullName.Label'),
  phone: t('Profile.Phone.Label'),
  extra_phone: t('Profile.ExtraPhone.Label'),
  login_1c: t('Profile.1CLogin.Label')
};

const rules = {
  first_name: 'required|string|min:2|max:32',
  last_name: 'required|string|min:2|max:32',
  middle_name: 'required|string|min:2|max:32',
  full_name: 'required|string|min:2|max:100',
  email: 'required|string|min:5|max:100',
  phone: 'required|string|min:10|max:12'
};

const types = {};

const values = {};

export default {
  fields,
  placeholders,
  rules,
  types,
  values
};
