import BaseState from 'forms/BaseState';

import { fields } from '.';

class FiltersState extends BaseState {
  setup() {
    return fields;
  }

  addFilter(filter) {
    const filters = [
      ...this.$('filters').value,
      filter
    ];

    this.update({ filters });
  }

  removeFilter(filter) {
    filter.del();
  }

  clearAll() {
    this.update({ filters: [] });
  }
}

export default FiltersState;
