import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import styled, { css } from 'styled-components';

import { AgeField, GenderField } from 'components/forms';

const Label = styled.label`
  user-select: none;
`;

const Name = styled.input`
  flex-grow: 1;
  max-width: 33%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  & + & {
    border-left: 1px solid #D1D5DB;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 334px;
  padding: 6px 0;
  border: 1px solid #D1D5DB;
  border-radius: 3px;
  box-sizing: border-box;

  ${props => props.hasError && css`
    border: 1px solid #E26161;
  `}
`;

const GenderWrapper = styled.div`
  width: 112px;
  flex-shrink: 0;

  ${props => props.hasError && css`
    input {
      border: 1px solid #E26161;
    }
  `}
`;

const AgeWrapper = styled.div`
  ${props => props.hasError && css`
    input {
      border: 1px solid #E26161;
    }
  `}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 13px;

  ${InputWrapper} + ${GenderWrapper} {
    margin-left: 14px;
  }

  ${GenderWrapper} + ${AgeWrapper} {
    margin-left: 14px;
  }
`;

@observer
class TravellerField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object,
    onSelect: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: '',
    onSelect: () => null
  };

  componentDidMount() {
    const { field } = this.props;

    this._changeAgeReaction = reaction(
      () => field.$('date_of_birth').value,
      (dateOfBirth) => this.changeAgeHandler(dateOfBirth)
    );
  }

  componentWillUnmount() {
    this._changeAgeReaction();
  }

  changeAgeHandler = (dateOfBirth) => {
    if (!dateOfBirth) return;

    const { field, onSelect } = this.props;
    onSelect(field.values());
  }

  render() {
    const { field, className } = this.props;

    return (
      <Wrapper className={className}>
        {field.label && (
          <Label htmlFor={field.id}>
            {field.label}
          </Label>
        )}

        <InputWrapper hasError={!field.fioFilled}>
          <Name
            placeholder='Фамилия'
            {...field.$('last_name').bind()}
          />

          <Name
            placeholder='Имя'
            {...field.$('first_name').bind()}
          />

          <Name
            placeholder='Отчество'
            {...field.$('middle_name').bind()}
          />
        </InputWrapper>

        <GenderWrapper hasError={!field.genderFilled}>
          <GenderField
            field={field.$('gender')}
          />
        </GenderWrapper>

        <AgeWrapper hasError={!field.dateOfBirthFilled}>
          <AgeField
            field={field.$('date_of_birth')}
          />
        </AgeWrapper>
      </Wrapper>
    );
  }
}

export default styled(TravellerField)``;
