import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RangeSelect from 'components/forms/RangeSelect';

const Wrapper = styled.div`
  background: #FFF;
  padding: 15px;
  border: 1px solid #5ab2d6;
  border-radius: 4px;
  box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
  border-radius: 3px;
  box-sizing: border-box;
  min-width: 280px;
`;

function Popover({ className, onChange, ...rest }) {
  const handleChange = val => {
    const [min, max] = val;

    onChange({ filter: { price: { min, max } } });
  };

  return (
    <Wrapper className={className}>
      <RangeSelect
        onChange={handleChange}
        {...rest}
      />
    </Wrapper>

  );
}

Popover.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default styled(Popover)``;
