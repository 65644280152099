import L from 'leaflet';

import DefaultMarker from 'assets/images/marker.png?defaultExtension=png';

export const defaultMarker = L.icon({
  iconUrl: DefaultMarker,
  iconSize: [36, 43],
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});
