import { types } from 'mobx-state-tree';

import Price from '../Price';

const PriceFormation = types.model('PriceFormation', {
  discount: Price,
  discount_in_percent: types.number,
  extra_charge: Price,
  extra_charge_in_percent: types.number,
  net_price: Price,
  gross_price: Price
});

export default PriceFormation;
