import React from 'react';
import styled, { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Input, Field } from 'components/forms';
import { Form } from 'components/ui';

const Wrapper = styled.div``;

@withTheme
@inject('profileForm')
@observer
class Personal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    profileForm: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { profileForm, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Form.Row>
          <Form.RowCell>
            <Field
              field={profileForm.$('first_name')}
              component={Input}
            />
          </Form.RowCell>

          <Form.RowCell>
            <Field
              field={profileForm.$('last_name')}
              component={Input}
            />
          </Form.RowCell>
        </Form.Row>

        <Form.Row>
          <Form.RowCell>
            <Field
              field={profileForm.$('phone')}
              component={Input}
            />
          </Form.RowCell>

          <Form.RowCell>
            <Field
              field={profileForm.$('extra_phone')}
              component={Input}
            />
          </Form.RowCell>
        </Form.Row>

        <Form.Row>
          <Form.RowCell>
            <Field
              field={profileForm.$('email')}
              component={Input}
            />
          </Form.RowCell>

          <Form.RowCell>
            <Field
              field={profileForm.$('login_1c')}
              component={Input}
            />
          </Form.RowCell>
        </Form.Row>
      </Wrapper>
    );
  }
}

export default styled(Personal)``;
