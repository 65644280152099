import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EMAIL } from './ForgotPasswordForm.schema';
import { EmailField } from '../FormFields';

const Field = styled.div``;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Body = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 34px;
`;

function ForgotPasswordForm({ children, className }) {
  return (
    <Wrapper className={className}>
      <Body>
        <FieldsGroup>
          <Field>
            <EmailField size='large' name={EMAIL} />
          </Field>
        </FieldsGroup>
      </Body>

      <Actions>{children}</Actions>
    </Wrapper>
  );
}

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(ForgotPasswordForm)``;
