import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TooltipComponent from 'rc-tooltip';
import './Tooltip.css';

const Wrapper = styled(TooltipComponent)`
`;

function Tooltip({ children, className, ...rest }) {
  return (
    <Wrapper
      className={className}
      {...rest}
    >
      {children}
    </Wrapper>
  );
}

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(Tooltip)``;
