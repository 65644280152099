import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/v2/ui/Tooltip';

const Overlay = styled.div`

`;

const OverlayText = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  white-space: nowrap;
`;

const Header = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #E26464;
`;

const ToolTipWrapper = styled.div`
  width: fit-content;
  font-size: 13px;
  line-height: 16px;
  color: #3C6F9D;

  ${({ hasActiveRestriction }) => hasActiveRestriction && css`
    font-size: 13px;
    line-height: 16px;
    color: #979BA0;
    border-bottom: 1px dashed #999;
  `}
`;

const Wrapper = styled.div`
  max-width: 100%;

  ${Header} + ${Tooltip} {
    margin-top: 4px;
  }
`;

function Restrictions({ className, restrictionsData: { hasActiveRestriction, restrictions } }) {
  const { t } = useTranslation();

  const activeRestrictionsCount = restrictions.filter(r => !!r.active).length;

  const overlaytext = (
    <Overlay>
      {restrictions.map((r, index) => <OverlayText key={index}>{r.text}</OverlayText>)}
    </Overlay>
  );

  const getTooltipTitle = () => {
    if (activeRestrictionsCount === 1) {
      return hasActiveRestriction.text;
    }

    if (hasActiveRestriction) {
      return t('Order.PriceBlock.ActiveRestrictions.Title');
    }

    return t('Order.PriceBlock.Restrictions.Title');
  };

  return (
    <Wrapper className={className}>
      {hasActiveRestriction && (
        <Header>
          {t('Order.PriceBlock.Restrictions.Header')}
        </Header>
      )}

      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <ToolTipWrapper hasActiveRestriction={hasActiveRestriction}>
          {getTooltipTitle()}
        </ToolTipWrapper>
      </Tooltip>
    </Wrapper>
  );
}

Restrictions.propTypes = {
  className: PropTypes.string,
  restrictionsData: PropTypes.object
};

export default styled(Restrictions)``;
