import { types } from 'mobx-state-tree';

export const Attentions = types.model('Attentions', {
  type: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  visible: types.maybeNull(types.boolean),
  date: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string)
});
