export const distanceTextFormation = (name) => {
  const reg = /[0-9]+\.?[0-9]+/;
  const number = name.match(reg);
  const text = name.replace(/ [0-9.]* (км|km)/, '');

  return {
    value: number,
    text: text
  };
};
