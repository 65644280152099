import cleanDeep from 'clean-deep';
import _pick from 'lodash/pick';

const orderAttributes = [
  'id',
  'check_in',
  'check_out',
  'hotel',
  'address',
  'travellers'
];

function exportToQueryParams({ order, values }) {
  let data = _pick(order, ...orderAttributes);
  data = { ...data, reservations: [values] };

  return cleanDeep(data);
}

export default exportToQueryParams;
