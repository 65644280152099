import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Guest from './Guest';

const Text = styled.span`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #4A4A4A;
  font-size: 14px;
  letter-spacing: 2.2px;
  line-height: 28px;
  vertical-align: middle;
  margin-bottom: 8px;
  user-select: none;
`;

const Wrapper = styled.div``;

function Guests({ title, items, onSelect, className }) {
  const handleSelect = (item) => {
    onSelect(item);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Text>{title}</Text>
      </Header>

      <Items className='items'>
        {items.map((item, idx) => (
          <Guest
            key={idx}
            item={item}
            onSelect={handleSelect}
          />
        ))}
      </Items>
    </Wrapper>
  );
}

Guests.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};

Guests.defaultProps = {
  title: '',
  items: [],
  onSelect: () => {}
};

export default styled(Guests)``;
