import React from 'react';

import {
  Animator, Fitobar,
  Atm, Bar, BathHat,
  BeautySalon, Conference,
  HealtyCentr, Nurse,
  OxygenCocktail, Reception,
  Restaraunt, Sauna,
  Spa, Transfer, Safe,
  TreePark, Gym, Wifi, BathRoom
} from 'components/icons';

export const getIcon = icon => {
  const icons = {
    strongbox: <Safe />,
    wifi_throughout: <Wifi />,
    wifi: <Wifi />,
    bath_room: <BathRoom />,
    Animator: <Animator />,
    fitobar: <Fitobar />,
    atm: <Atm />,
    bar: <Bar />,
    mini_bar: <Bar />,
    bath_complex: <BathHat />,
    bathrobe_slippers: <BathHat />,
    beauty_saloon: <BeautySalon />,
    conference_hall: <Conference />,
    medicine_and_health: <HealtyCentr />,
    Nurse: <Nurse />,
    OxygenCocktail: <OxygenCocktail />,
    restaurant: <Restaraunt />,
    saunas: <Sauna />,
    spa_and_cosmetology: <Spa />,
    Transfer: <Transfer />,
    park: <TreePark />,
    sports_hall: <Gym />,
    suitable_for_children: <Nurse />,
    '24_hour_reception': <Reception />
  };

  return icons[icon] || null;
};
