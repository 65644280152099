import { t } from 'utils/localization';

export const contactsItems = [
  {
    name: 'legal_entity.email',
    label: t('Auth.Register.LegalEntity.Email.Label'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder'),
    required: true
  },
  {
    name: 'legal_entity.email2',
    label: t('Auth.Register.LegalEntity.Email.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder'),
    required: true
  },
  {
    name: 'legal_entity.phone',
    label: t('Auth.Register.Manager.Phone.Label'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder'),
    required: true
  }
];

export const accoutingItems = [
  {
    name: 'legal_entity.accountant.full_name',
    label: t('Auth.Register.LegalEntity.Accounting.Label'),
    placeholder: t('Auth.Register.Manager.FIO.Placeholder'),
    required: true
  },
  {
    name: 'legal_entity.accountant.email',
    label: t('Auth.Register.LegalEntity.Accounting.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder2'),
    required: true
  },
  {
    name: 'legal_entity.accountant.phone',
    label: t('Auth.Register.LegalEntity.Accounting.Label3'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder'),
    required: true
  }
];

export const actualAddressItems = [
  {
    name: 'legal_entity.actual_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6'),
    required: true
  },
  {
    name: 'legal_entity.actual_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label'),
    required: true
  },
  {
    name: 'legal_entity.actual_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2'),
    required: true
  },
  {
    name: 'legal_entity.actual_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2'),
    required: true
  },
  {
    name: 'legal_entity.actual_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2'),
    required: false
  },
  {
    name: 'legal_entity.actual_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder'),
    required: true
  }
];

export const legalAddressItems = [
  {
    name: 'legal_entity.legal_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6'),
    required: true
  },
  {
    name: 'legal_entity.legal_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label'),
    required: true
  },
  {
    name: 'legal_entity.legal_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2'),
    required: true
  },
  {
    name: 'legal_entity.legal_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2'),
    required: true
  },
  {
    name: 'legal_entity.legal_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2'),
    required: false
  },
  {
    name: 'legal_entity.legal_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder'),
    required: true
  }
];

export const defaultValues = {
  email: '', // Email менеджера, обязательное
  phone: '', // Телефон менеждера, обязательное
  first_name: '', // Имя менеджера, обязательное
  last_name: '', // Фамилия менеджера, обязательное
  middle_name: null, // Отчетсво менеджера, необязательное
  post: null, // Должность менеджера, необязательное
  equalAddress: false, // флаг для простановки равности адресов
  legal_entity: {
    // Юридическая информация
    name: '', // Полное название, обязательное
    type: {}, // Тип организации, обязательное
    inn: '', // ИНН, обязательное
    kpp: null, // КПП, для type == llc обязательное
    short_name: '', // Короткое название, обязательное
    ogrn: '', // ОГРН, обязательное
    tax_type: {}, // Налогообложение, обязательное
    agency_types: [], // Сеть агентств, необязательное
    email: '', // Контакты Email основной, обязательное
    email2: '', // Контакты Email для уведомлений, обязательное(заглушка), дублирует емейл из менегера
    phone: '', // Контакты Телефон, обязательный
    director: {
      // Руководитель необязательные
      full_name: null,
      post: null
    },
    accountant: {
      // Данные бухгалтерии
      full_name: '',
      post: 'Бухгалтер',
      email: '',
      phone: ''
    },
    legal_address: {
      // Юридический адрес, обязательный
      postal_code: '',
      country: '',
      city: '',
      street: '',
      building_number: '',
      office_number: null // не обязательный
    },
    actual_address: {
      // Фактический адрес, обязательный
      postal_code: '',
      country: '',
      city: '',
      street: '',
      building_number: '',
      office_number: null // не обязательный
    },
    bank_details: {
      // Банковские реквизиты, обязательный
      name: '',
      kpp: '',
      bik: '',
      kor_schet: '',
      ras_schet: ''
    }
  }
};
