import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Provider } from 'mobx-react';

import hotelMapper from 'utils/hotelMapper';

import { Block, Text } from 'components/atoms';
import { display, typography } from 'theme/mixins';
import { CarouselGallery } from 'components/ui';
import { Tabs, Stub } from './components';
import Button from 'components/forms/Button';

import HotelsInfo from 'stores/HotelsStore/Info';
import Map from './components/Map';
import { InstantBookingIcon } from 'components/icons';
import Reviews from './components/Reviews';

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TabsContainer = styled.div`
  margin-top: 28px;
`;

const MainContainer = styled.div`
  max-width: 490px;
`;

const ButtonContainer = styled.div``;

const NameContainer = styled.span`
  color: #4a515c;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Address = styled.div`
  margin-top: 16px;
  color: #6b707b;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;

const MapLink = styled.div`
  margin-top: 4px;
  color: #3c6f9d;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: 883px;
  height: 90vh;
  overflow: scroll;
  box-sizing: border-box;
  padding: 29px 30px;

  > ${Block}.flex-between {
    ${display('flex', 'center', 'space-between')};

    > ${Text}.name {
      color: #4a515c;
      margin-bottom: 20px;
      ${typography(20, 24, 700)};
    }
  }

  > ${Text}.address {
    color: #6b707b;
    ${typography(14, 16)};
  }

  ${CarouselGallery} {
    margin: 16px 0;
  }
`;

const MapContainer = styled.div`
  position: relative;
  height: 343px;
  max-height: 343px;
  background: #d8d8d8;
  border-radius: 4px;
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

function DialogHotel({ className, id, showHotelButton, onClick, ...rest }) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [hotelsInfo, _] = useState(HotelsInfo.create());

  const [hotel, setHotel] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [openReview, setOpenReview] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await hotelsInfo.fetch({ id });
      setHotel(hotelMapper(data.toJSON()));
    };
    fetch();
  }, []);

  const handleClick = (e) => {
    onClick(e, hotel);
  };

  if (!hotel) {
    return (
      <Wrapper>
        <Stub />
      </Wrapper>
    );
  }

  const handleOpenMap = () => {
    setOpenReview(false);
    setOpenMap(!openMap);
  };

  const { address, name, rating, images, star, review, instant_booking, treatment_factors, location_types } = hotel;

  return (
    <Provider className={className} hotelsInfo={hotelsInfo}>
      <Wrapper {...rest}>
        <Header>
          <MainContainer>
            <Title className='flex-between'>
              <NameContainer>{name}</NameContainer>
              {instant_booking && <InstantBookingIcon />}
            </Title>

            <Address>{address.location}</Address>
            <MapLink onClick={handleOpenMap}>{openMap ? t('Hotel.Details.CloseMap') : t('Hotel.Details.Map')}</MapLink>
          </MainContainer>

          {showHotelButton && (
            <ButtonContainer>
              <Button size={'small'} variant='primary' onClick={handleClick}>
                {t('Common.ShowRooms')}
              </Button>
            </ButtonContainer>
          )}
        </Header>

        <ContentWrapper>
          {openMap && !openReview && (
            <MapContainer>
              <Map hotel={hotel} setOpenMap={setOpenMap} />
            </MapContainer>
          )}
          {!openMap && !openReview && (
            <CarouselGallery
              items={images}
              rating={rating}
              star={star}
              review={review}
              setOpenReview={setOpenReview}
              id={hotel.id}
              setData={setData}
              location_types={location_types}
              treatment_factors={treatment_factors}
            />
          )}
          {openReview && !openMap && (
            <Reviews reviews={data} rating={rating} total={hotel?.review?.total} setOpenReview={setOpenReview} />
          )}
        </ContentWrapper>

        {!openMap && !openReview && (
          <TabsContainer>
            <Tabs hotel={hotel} />
          </TabsContainer>
        )}
      </Wrapper>
    </Provider>
  );
}

DialogHotel.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  showHotelButton: PropTypes.bool,
  onClick: PropTypes.func
};

DialogHotel.defaultProps = {
  showHotelButton: false,
  onClick: () => {}
};

export default styled(DialogHotel)``;
