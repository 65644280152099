import moment from 'utils/moment';
import { t } from 'utils/localization';

const fields = [
  'id',
  'address',
  'address.location',
  'address.coordinates',
  'hotel',
  'hotel.id',
  'hotel.name',
  'hotel.address',
  'hotel.address.location',
  'hotel.address.coordinates',
  'contract',
  'contract.number',
  'contract.begin_date',
  'contract.end_date',
  'check_in',
  'check_out',
  'travellers[]',
  'travellers[].id',
  'travellers[].first_name',
  'travellers[].last_name',
  'travellers[].middle_name',
  'travellers[].full_name',
  'travellers[].gender',
  'travellers[].age',
  'travellers[].age_group',
  'treatment',
  'sort',
  'sort.sort_by',
  'sort.direction',
  'sort.value',
  'sort.label',
  'filter',
  'filter.rating',
  'filter.rating.value',
  'filter.rating.label'
];

const placeholders = {
  address: t('Form.AddressSearch'),
  hotel: t('Form.HotelSearch'),
  'travellers[].gender': t('Form.Gender.Label'),
  'travellers[].full_name': t('Form.FullName.Label'),
  'travellers[].first_name': t('Form.FirstName.Label'),
  'travellers[].last_name': t('Form.LastName.Label'),
  'travellers[].middle_name': t('Form.MiddleName.Label'),
  'travellers[].age': t('Form.Age.Label'),
  sort: t('Hotel.Search.Sort'),
  'filter.rating': t('Hotel.Search.Rating')
};

const labels = {};

const genderItems = [
  {
    value: 'male',
    label: t('Form.Gender.Male')
  },
  {
    value: 'female',
    label: t('Form.Gender.Female')
  }
];

const sortItems = [
  {
    value: 'asc',
    direction: 1,
    sort_by: 'pricing.min',
    label: t('Hotel.Search.PriceAsc')
  },
  {
    value: 'desc',
    direction: -1,
    sort_by: 'pricing.min',
    label: t('Hotel.Search.PriceDesc')
  }
];

const treatmentItems = [
  {
    value: true,
    label: t('Orders.OrderForm.WithTreatment')
  },
  {
    value: false,
    label: t('Orders.OrderForm.WithoutTreatment')
  }
];

const extra = {
  'travellers[].gender': genderItems,
  treatment: treatmentItems,
  sort: sortItems
};

const rules = {
  address: 'required',
  'address.location': 'required|string',
  'address.coordinates': 'required|array',
  check_in: 'required',
  check_out: 'required'
};

const types = {
  'travellers[].age': 'number'
};

const values = {
  check_in: moment()
    .add(7, 'days')
    .format(),
  check_out: moment()
    .add(14, 'days')
    .format(),
  hotel: {},
  travellers: [
    {
      id: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      full_name: null,
      gender: genderItems[0],
      age: 18,
      age_group: null
    }
  ],
  treatment: treatmentItems[0],
  sort: sortItems[0],
  'filter.rating': 1
};

const output = {
  'travellers[].gender': ({ value }) => value,
  check_in: date => date ? moment(date).format() : date,
  check_out: date => date ? moment(date).format() : date,
  'filter.rating': ({ value }) => value
};

const input = {
  'travellers[].gender': (value) => {
    return value ? genderItems.find(item => item.value === value) : value;
  }
};

export default {
  fields,
  labels,
  placeholders,
  rules,
  types,
  values,
  extra,
  output,
  input
};
