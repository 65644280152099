import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Tab from './Tab';
import AmenitiesModal from './AmenitiesModal';

import getTabs from './utils/getTabs';
import { useTranslation } from 'react-i18next';

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MoreButton = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  margin-top: -9px;

  ${Tab} {
    margin-right: 9px;
    margin-top: 9px;
  }
`;

function Tabs({ className, roomType }) {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    return getTabs({ roomType });
  }, [roomType]);

  const handleMoreClick = () => {
    setOpened(true);
  };

  const handleCloseClick = () => {
    setOpened(false);
  };

  const tabElements = tabs.mainAmenities
    .slice(0, 6)
    .map((item, index) => <Tab key={index} tab={item} />);

  const hasMoreTabs = roomType.amenities.length > 4;

  return (
    <Wrapper className={className}>
      <TabsContainer>
        {tabElements}
      </TabsContainer>

      {hasMoreTabs && (
        <MoreButton onClick={handleMoreClick}>
          {t('Hotel.RoomTypes.Amenities.ShowButton', { count: roomType.amenities.length })}
        </MoreButton>
      )}

      {opened && <AmenitiesModal roomType={roomType} onCloseClick={handleCloseClick} />}
    </Wrapper>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  roomType: PropTypes.object.isRequired
};

export default styled(Tabs)``;
