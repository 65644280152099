import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ChildContainer = styled.div`
  margin-top: 12px;
  column-count: 2;
  display: inline-block;
`;

const Title = styled.div`
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

const Child = styled.div`
  min-width: 270px;
  max-width: 270px;
  color: #4A515C;
  letter-spacing: 0;
  line-height: 30px;
  padding: 7px 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 34px;
`;

function AmenityGroup({ className, amenity }) {
  return (
    <Wrapper className={className}>
      <Title>
        {amenity.name}
      </Title>

      <ChildContainer>
        {amenity.children.map(a => <Child key={a.tag}>{a.name}</Child>)}
      </ChildContainer>
    </Wrapper>
  );
}

AmenityGroup.propTypes = {
  className: PropTypes.string,
  amenity: PropTypes.object
};

export default styled(AmenityGroup)``;
