import _isEqual from 'lodash/isEqual';

function filtersCount(filter) {
  const keys = [
    'accommodation_features',
    'amenities',
    'hotel_types',
    'location_types',
    'meal_options',
    'treatment_factors',
    'treatment_profiles'
  ];

  return keys.reduce((acc, key) => {
    return filter[key].length + acc;
  }, 0);
}

function pricesCount(leftPrice, rightPrice) {
  const { min: lMin, max: lMax } = leftPrice;
  const { min: rMin, max: rMax } = rightPrice;

  const isEql = _isEqual(
    [lMin, lMax].map(n => Number(n)),
    [rMin, rMax].map(n => Number(n))
  );

  return isEql ? 0 : 1;
}

// function instantBooking(instant_booking) {
//   return Number(instant_booking);
// }

function calcQueryFilters(defaultQuery, query) {
  const { filter, filter: { price: leftPrice } } = query;
  const { filter: { price: rightPrice } } = defaultQuery;

  return filtersCount(filter) + pricesCount(leftPrice, rightPrice);
}

export default calcQueryFilters;
