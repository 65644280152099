import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

const Text = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 18px;

  ${({ alert }) => alert && css`
    color: #E68686;
  `}

  ${({ bold }) => bold && css`
    font-weight: 500;
  `}

  ${({ muted }) => muted && css`
    color: #979BA0;
  `}

  ${({ padded }) => padded && css`
    margin-bottom: 4px;
  `}
`;

const TouristPrice = styled.div`
  color: #979BA0;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: right;
  margin-top: 4px;
`;

const AgentPrice = styled.div`
  color: #3AA6D2;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
`;

const Cell = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & + & {
    margin-top: 6px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

@inject('showState')
@observer
class Slot extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    slot: PropTypes.object.isRequired,
    showCommission: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: '',
    showCommission: false
  };

  render() {
    const { slot, t, showCommission, ...rest } = this.props;

    const {
      traveller,
      net_price,
      gross_price,
      rate: { description },
      payment: { cash }
    } = slot;

    const hastTraveller = !!traveller;

    return (
      <Wrapper {...rest}>
        <Row>
          <Cell>
            {hastTraveller && (
              <Text padded>
                {t('Traveller.FullName', traveller)}, {t('Traveler.Age', { count: traveller.age })}
              </Text>
            )}

            <Text
              muted={hastTraveller}
              padded={!hastTraveller}
            >
              {description}
            </Text>
          </Cell>

          <Cell>
            <Text bold>
              <AgentPrice>
                {t('UI.Price', { price: gross_price })}
              </AgentPrice>

              {showCommission && (
                <TouristPrice>
                  {t('UI.Price', { price: net_price })}
                </TouristPrice>
              )}
            </Text>
          </Cell>
        </Row>

        {cash && (
          <Row>
            <Cell>
              <Text alert>
                {t('Order.OrderInfo.CashSurcharge')}
              </Text>
            </Cell>

            <Cell>
              <Text alert bold>
                {t('UI.Price', cash)}
              </Text>
            </Cell>
          </Row>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Slot))``;
