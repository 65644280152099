import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { DateRangePicker1 } from 'components/forms';

@observer
class DatesField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { query, handleUpdate, className } = this.props;

    return (
      <DateRangePicker1
        className={className}
        value={[query.check_in, query.check_out]}
        onChange={(value) => (
          handleUpdate({ check_in: value[0], check_out: value[1] })
        )}
      />
    );
  }
}

export default styled(DatesField)``;
