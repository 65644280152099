import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useResetPasswordToken } from 'pages/Auth/hooks';
import ResetPasswordForm from 'pages/Auth/components/ResetPasswordForm';
import { Button } from 'components/forms';
import Heading from '../../components/Heading';

const Title = styled.div`
  color: #9cb4ca;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.div`
  color: #4f5660;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Content = styled.div`
  align-self: center;
  padding: 70px 128px 81px;
  width: 640px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;

  ${Title} + ${Description} {
    margin-top: 20px;
  }

  ${Description} + ${ResetPasswordForm} {
    margin-top: 40px;
  }
`;

const Logo = styled(Heading)`
  margin: 0 0 90px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
  justify-content: center;
  align-content: center;
`;

function ResetPassword({ onSubmit, className }) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const [reset_password_token] = useResetPasswordToken();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const response = await onSubmit({ ...values, reset_password_token });
    setIsLoading(false);

    history.replace('/login', { user: response });
  };

  const handleReset = () => {
    history.replace('/login');
  };

  return (
    <Wrapper className={className}>
      <Logo />
      <Content>
        <Title>{t('Auth.ResetPassword.Title')}</Title>

        <Description />

        <ResetPasswordForm onSubmit={handleSubmit} onReset={handleReset}>
          <Button size='large' type='submit' loading={isLoading}>
            {t('Auth.ResetPassword.Actions.Submit')}
          </Button>

          <Button size='large' variant='secondary' type='reset' compact>
            {t('Auth.ResetPassword.Actions.Reset')}
          </Button>
        </ResetPasswordForm>
      </Content>
    </Wrapper>
  );
}

ResetPassword.defaultProps = {
  onSubmit: () => {}
};

ResetPassword.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func
};

export default styled(ResetPassword)``;
