import { types } from 'mobx-state-tree';

const Commission = types.model('Commission', {
  discount: types.number,
  discount_in_percent: types.number,
  extra_charge: types.number,
  extra_charge_in_percent: types.number,
  commission: types.number,
  commission_in_percent: types.number
});

export default Commission;
