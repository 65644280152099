import React from 'react';

import { Body } from './containers';
import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';

function Reports() {
  return (
    <DefaultTemplate
      header={<Header />}
      body={<Body />}
    />
  );
}

export default Reports;
