import React from 'react';
import PropTypes from 'prop-types';
import { action, observable, computed, reaction } from 'mobx';
import { Provider, observer } from 'mobx-react';
import styled from 'styled-components';
import _isFunction from 'lodash/isFunction';

import { EditMode, ViewMode } from './';

import { ContractState } from './forms';
import { ContractStore } from './stores';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

@observer
class ContractField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    prefixed: PropTypes.bool,
    bordered: PropTypes.bool,
    mode: PropTypes.oneOf(['edit', 'view']),
    dimension: PropTypes.oneOf(['small', 'medium', 'big', 'large']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
    field: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func
  };

  static defaultProps = {
    className: '',
    bordered: false,
    prefixed: false,
    focused: false,
    mode: 'view',
    dimension: 'small',
    variant: 'primary'
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.contractState = new ContractState({}, { hooks });
    this.contractStore = ContractStore.create();
  }

  componentDidMount() {
    this.setInitialMode();
    this.addHandlers();
    this.setCurrentContract();
  }

  componentWillUnmount() {
    this.removeHandlers();
  }

  @observable mode = undefined

  @computed get isEditMode() {
    return this.mode === 'edit';
  }

  @computed get isViewMode() {
    return this.mode === 'view';
  }

  @action
  setMode(value) {
    this.mode = value;
  }

  @action
  unsetMode() {
    this.mode = undefined;
  }

  @action
  toggleMode() {
    if (this.isEditMode) {
      this.setMode('view');
      return this.mode;
    }

    if (this.isViewMode) {
      this.setMode('edit');
      return this.mode;
    }

    return this.mode;
  }

  @computed get hasError() {
    const { field } = this.props;
    return !!field.$('number').error;
  }

  setInitialMode() {
    const { mode } = this.props;
    this.setMode(mode);
  }

  addHandlers() {
    const { field } = this.props;

    this._setCurrentContract = reaction(
      () => field.values(),
      (values) => this.setCurrentContract(),
      { fireImmediately: true }
    );
  }

  removeHandlers() {
    this._setCurrentContract();
  }

  setCurrentContract() {
    const { field } = this.props;

    const values = field.values();
    const { number } = values;

    this.contractState.set({ ...values, chars: number });
  }

  handleEditMode = (e) => {
    this.toggleMode();
  }

  handleSuccess = (form) => {
    const { field, onChangeHandler } = this.props;

    const values = form.$('contract').values();
    field.set(values);

    if (_isFunction(onChangeHandler)) {
      onChangeHandler(values);
    }

    this.handleEditMode();
  }

  handleError = (form) => {
    console.log('handleError');
  }

  render() {
    const { onChangeHandler, className, ...rest } = this.props;

    const { mode, hasError, handleEditMode } = this;
    const props = { ...rest, mode, hasError, handleEditMode };

    return (
      <Provider
        store={this.contractStore}
        form={this.contractState}
      >
        <Wrapper className={className}>
          {this.isEditMode && (
            <EditMode {...props} />
          )}

          {this.isViewMode && (
            <ViewMode {...props} />
          )}
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(ContractField)``;
