import _findIndex from 'lodash/findIndex';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _some from 'lodash/some';
import _every from 'lodash/every';

const CHILD_LIMIT = 14;

const AGE_GROUP_COMPARE = {
  adult: 1,
  child: 2
};

function rateCompareAttributes(rate) {
  const attributes = [
    'age_group',
    'age_period.from',
    'age_period.to'
  ];

  return _pick(rate, attributes);
}

function detectOccupancy({ lRate, rRate }) {
  const lAttrs = rateCompareAttributes(lRate);
  const rAttrs = rateCompareAttributes(rRate);

  return _isEqual(lAttrs, rAttrs);
}

function getSlotsRates({ occupation, slots }) {
  const result = [];

  occupation.forEach((ocp, o_idx) => {
    result[o_idx] = new Array(ocp.length);

    slots.forEach((slot, s_idx) => {
      const rRight = slot.rate;

      const rateIdx = _findIndex(
        ocp, lRate => detectOccupancy({ lRate, rRight })
      );

      if (rateIdx) {
        const removed = ocp.splice(rateIdx, 1);
        result[o_idx][s_idx] = removed[0];
      }
    });
  });

  return result;
}

function checkMultiSeat(slots) {
  const isMT = _sortBy(slots, 'updatedAt').reverse()
    .find(s => s.rate.count === 0 && s.rate.occupation === 'main');

  return !!isMT;
}

function variantsAsMT(variants) {
  const value = variants
    .filter(occupation => _every(occupation, o => o.count === 0));

  const hasValue = value.length > 0;

  return { hasValue, value };
}

function variantsAsNotMT(variants) {
  const value = variants
    .filter(occupation => _some(occupation, o => o.count > 0));

  const hasValue = value.length > 0;

  return { hasValue, value };
}

function getVariants({ occupation, slots }) {
  let variants = getSlotsRates({ slots, occupation });
  variants = variants.reverse();

  const prefferedMT = checkMultiSeat(slots);

  const { hasValue: hasMT, value: mtValue } = variantsAsMT(variants);
  const { hasValue: hasNotMT, value: notMTValue } = variantsAsNotMT(variants);

  if (hasMT & hasNotMT) {
    return prefferedMT ? mtValue : notMTValue;
  }

  if (hasMT) {
    return mtValue;
  }

  if (hasNotMT) {
    return notMTValue;
  }

  return [];
}

function detectRate({ rate, slot }) {
  const { age } = slot.traveller;
  const age_group = age > CHILD_LIMIT ? 'adult' : 'child';

  if (age_group === 'adult' && rate.age_group === 'adult') {
    return true;
  }

  if (age_group === 'child' && rate.age_group === 'child') {
    // eslint-disable-next-line
    const { from, to } = rate.age_period;
    return age < to;
  }

  if (age_group === 'child' && rate.age_group === 'adult') {
    return true;
  }

  return false;
}

function ratesComparator(rate) {
  const { age_group } = rate;
  return -1 * AGE_GROUP_COMPARE[age_group];
}

function slotsComparator(slot) {
  const { age_group } = slot.rate;
  return -1 * AGE_GROUP_COMPARE[age_group];
}

function detectVariant({ rates, slots }) {
  const c_rates = _sortBy([...rates].reverse(), ratesComparator);
  const c_slots = _sortBy([...slots], slotsComparator);

  const result = c_slots.map((slot, s_idx) => {
    const rateIdx = _findIndex(
      c_rates,
      rate => detectRate({ rate, slot })
    );

    const rate = rateIdx < 0
      ? null
      : c_rates.splice(rateIdx, 1)[0];

    return { ...slot, rate };
  });

  return result;
}

function detectVariants({ variants, slots }) {
  let data = variants
    .map(rates => detectVariant({ rates, slots }))
    .filter(slots => _every(slots, s => !!s.rate));

  data = _sortBy(data, slots => {
    const count = slots.reduce((sum, slot) => {
      const diff = slot.rate.age_period?.from || 999;
      const count = slot.rate.occupation === 'main' ? 2 : 1;

      return (sum + diff) * count;
    }, 0);

    return count;
  });

  return data;
}

function coerceSlotsRates(props) {
  // eslint-disable-next-line
  const { occupation, slots } = props;
  const variants = getVariants(props);

  // Find best variant for current choose
  const result = detectVariants({ variants, slots });

  // Sorted by age_period
  return result[0];
}

export default coerceSlotsRates;
