import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, withRouter } from 'react-router-dom';

@withRouter
@inject('authStore')
@observer
class CustomRoute extends React.Component {
  render() {
    const { isPrivate, authStore, location, ...rest } = this.props;

    if (isPrivate && !authStore.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      );
    }

    return <Route {...rest} />;
  }
}

CustomRoute.propTypes = {
  authStore: PropTypes.func,
  isPrivate: PropTypes.bool,
  location: PropTypes.any
};

export default CustomRoute;
