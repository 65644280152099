import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import OrderState from 'components/ui/OrderState';

const Wrapper = styled.div`
`;

@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      order: { provider_state, invoice_state },
      className
    } = this.props;

    return (
      <Wrapper className={className}>
        <OrderState
          direction='row'
          provider_state={provider_state}
          invoice_state={invoice_state}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
