import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

const Item = styled.div`
  color: #979BA0;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  cursor: pointer;

  ${({ active }) => active && css`
    color: #3AA6D2;
    cursor: default;
    pointer-events: none;

    &:before {
      content: '✓';
      font-size: 12px;
      margin-right: 7px;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 8px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  box-sizing: border-box;

  & > div {
    flex-grow: 1;
    flex-basis: 100%;
  }

  ${Item} + ${Item} {
    border-left: 1px solid #D1D5DB;;
  }
`;

@inject('showState')
@observer
class GroupBy extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleClick = (item) => (e) => {
    e.preventDefault();

    const { showState } = this.props;
    showState.setGroupBy(item);
  }

  render() {
    const { showState: { groupBy, groupViews }, t, className } = this.props;

    const listItems = groupViews.map((item, idx) => (
      <Item
        key={idx}
        active={groupBy === item}
        onClick={this.handleClick(item)}
      >
        {t('Order.Groups.Views', { view: item })}
      </Item>
    ));

    return (
      <Wrapper className={className}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(GroupBy))``;
