import React from 'react';
import ReactDOM from 'react-dom';
import { YMInitializer } from 'react-yandex-metrika';

function YandexMetrika() {
  const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
  };

  return ReactDOM.createPortal(
    <YMInitializer
      accounts={[84949711]}
      options={ymOptions}
      version="2"
    />
    ,
    document.getElementsByTagName('body')[0]
  );
}

export default YandexMetrika;
