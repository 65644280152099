import { useContext } from 'react';
import SearchQueryContext from './context';

function useQuery() {
  const context = useContext(SearchQueryContext);

  if (context == null) {
    throw new Error(
      // eslint-disable-next-line no-use-before-define
      'No context provided: useQuery() can only be used in a descendant of <SearchQueryProvider>'
    );
  }

  const { currentQuery, searchQuery, total, updateQuery, clearQuery, applyQuery } = context;

  return [
    { query: currentQuery, searchQuery, total },
    { updateQuery, clearQuery, applyQuery }
  ];
}

export default useQuery;
