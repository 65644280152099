import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ManipulationModal from './ManipulationModal';

import { useTranslation } from 'react-i18next';

import List from '../List';

const MoreButton = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  margin-top: 12px;
  cursor: pointer;
  letter-spacing: 0;
  line-height: 16px;
`;

const Wrapper = styled.div``;

function Manipulation({ className, services, ...rest }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const hasMoreButton = services.length > 3;

  const handleOpenClick = () => {
    setOpened(true);
  };

  const handleCloseClick = () => {
    setOpened(false);
  };

  return (
    <Wrapper className={className}>
      <List
        items={services.slice(0, 3)}
        title={t('Tariff.Manipulations.Title')}
        {...rest}
      />

      {hasMoreButton && (
        <MoreButton onClick={handleOpenClick}>
          {t('Hotel.RoomTypes.Services.ShowButton', { count: services.length })}
        </MoreButton>
      )}

      {opened && <ManipulationModal services={services} onCloseClick={handleCloseClick} />}
    </Wrapper>
  );
}

Manipulation.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array
};

export default styled(Manipulation)``;
