import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DemoLimitations } from 'components/ui';

const Wrapper = styled.div`
`;

function PaymentHistory({ className }) {
  return (
    <Wrapper className={className}>
      <DemoLimitations />
    </Wrapper>
  );
}

PaymentHistory.propTypes = {
  className: PropTypes.string
};

export default styled(PaymentHistory)``;
