import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'utils/moment';

import { Calendar as CalendarIcon } from 'components/icons';
import { Input } from 'components/forms';
import { Dropdown } from 'components/molecules';
import { Calendar } from 'components/organisms';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${Input} {
      background: ${theme.colors.light};

      .rui-Input-iconLeft {
        font-size: 22px;
        height: 18px;
      }

      .rui-Input-input {
        cursor: pointer !important;
        height: 42px !important;
      }
    }
  `}
`;

const Container = styled(Dropdown)`
  ${({ theme }) => css`
    &&& {
      position: relative;
      margin-top: 4px;
      border: 1px solid ${theme.colors.active.primary};
      border-radius: 4px;
    }
  `}

  ${CalendarIcon} {
    font-size: 22px;
  }
`;

function DateRangePicker({ value, onChange, autoFocus, format, ...rest }) {
  const [isOpened, setIsOpened] = useState(false);
  const today = moment().toDate();
  const [preventClose, setPreventClose] = useState(false);
  const [rangeValue, setRangeValue] = useState(value);

  const rangeString = useMemo(() => {
    return value
      .filter((date) => moment(date).isValid())
      .map((date) => moment(date).format(format))
      .join(' – ');
  }, [value, format]);

  const open = () => {
    setIsOpened(true);
  };

  const close = () => {
    setIsOpened(false);
  };

  const handleChange = (_, value) => {
    const [from, to] = value;

    setRangeValue(value);

    if (from && to) {
      close();

      onChange([moment(from).format(), moment(to).format()]);
    }
  };

  const handleCloseRequest = () => {
    if (!preventClose) close();

    setPreventClose(false);
  };

  const renderAnchor = () => {
    return (
      <Input
        autoFocus={autoFocus}
        value={rangeString}
        onFocus={() => {
          if (!isOpened) open();
        }}
        onMouseDown={() => {
          if (!isOpened) {
            open();
            setPreventClose(true);
          }
        }}
        iconLeft={<CalendarIcon />}
      />
    );
  };

  return (
    <Wrapper {...rest}>
      <Container anchor={renderAnchor()} isOpened={isOpened} onRequestClose={handleCloseRequest}>
        <Calendar
          onChange={handleChange}
          today={today}
          range
          value={rangeValue.map((v) => moment(v).toDate())}
          visibleMonths={2}
          minDate={today}
        />
      </Container>
    </Wrapper>
  );
}

DateRangePicker.propTypes = {
  className: PropTypes.string,
  checkIn: PropTypes.object.isRequired,
  checkOut: PropTypes.object.isRequired,
  format: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.array
};

DateRangePicker.defaultProps = {
  className: '',
  format: 'D MMMM'
};

export default styled(DateRangePicker)``;
