import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SelectX as Select } from 'components/forms';

import _range from 'lodash/range';
import { Dropdown } from 'components/icons';

const Text = styled.span`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 3px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #4A4A4A;
  font-size: 14px;
  line-height: 28px;
`;

const AgeSelect = styled(Select)`
  margin-right: 3px !important;

  input {
    height: 28px !important;
    padding-left: 10px !important;
    width: 52px !important;
    background-color: #F2F4F6 !important;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  ${Header} + ${Body} {
    margin-top: 8px;
  }

  ${Dropdown} {
    font-size: 8px;
  }
`;

function Ages({ title, items, onChange, className }) {
  const childs = items.filter(item => item.age_group === 'child');
  if (childs.length === 0) return null;

  const options = _range(1, 15).map(i => (
    { value: Number(i), label: String(i) }
  ));

  const initialValue = {
    label: '6',
    value: 6
  };

  const handleChange = (idx) => (age) => {
    const newItems = [...childs];
    newItems[idx] = { ...newItems[idx], age: age.label };

    onChange(newItems);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Text>{title}</Text>
      </Header>

      <Body>
        {childs.map((item, i) => (
          <AgeSelect
            key={i}
            value={options.find(o => o.label === item.age) || initialValue}
            onChange={handleChange(i)}
            arrowIcon={<Dropdown />}
            items={options}
          />
        ))}
      </Body>
    </Wrapper>
  );
}

Ages.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func
};

Ages.defaultProps = {
  title: '',
  items: [],
  onChange: () => {}
};

export default styled(Ages)``;
