function formatPrice(price, currency = 'RUB') {
  const formatter = new Intl.NumberFormat('ru-RU', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0
  });

  return formatter.format(parseFloat(price));
}

export default formatPrice;
