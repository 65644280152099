import { t } from 'utils/localization';

// TODO: Replace it
import moment from 'utils/moment';

function duration({ billing_hour, check_in, check_out }) {
  const billingShift = billing_hour === 'day' ? 1 : 0;

  // eslint-disable-next-line no-use-before-define
  const checkIn = moment(check_in).utcOffset(check_in).startOf('day');
  // eslint-disable-next-line no-use-before-define
  const checkOut = moment(check_out).utcOffset(check_out).startOf('day');

  // eslint-disable-next-line no-use-before-define
  let value = checkOut.diff(checkIn, 'days');
  value = value + billingShift;

  return { value, unit: billing_hour };
}

function durationText(...props) {
  const value = duration(...props);
  return t('Duration.Text', value);
}

export { durationText };

export default duration;
