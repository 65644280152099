import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PhoneInput, Field } from 'components/v2/forms';

import { Controller, useFormContext } from 'react-hook-form';

const Wrapper = styled(Field)``;

function PhoneField({ name, required, className }) {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <Wrapper required={required} error={!!errors?.[name]} name={name}>
      <Controller
        name={name}
        render={({ field }) => (
          <PhoneInput
            {...field}
            mask={'+{7} (000) 000-00-00'}
            error={!!errors?.[name]}
            placeholder={'+7 (999) 888-77-66'}
          />
        )}
      />
    </Wrapper>
  );
}

PhoneField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequire,
  required: PropTypes.bool
};

export default styled(PhoneField)``;
