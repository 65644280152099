import _orderBy from 'lodash/orderBy';

function availableRoomTypes(data, count) {
  const value = data.filter(rt => rt.is_available && rt.tariffs.find(t => t.is_available));
  return sortRoomTypes(value, count);
}

function notAvailableRoomTypes(data, count) {
  const value = data.filter(rt => !rt.is_available || !rt.tariffs.find(t => t.is_available));
  return sortRoomTypes(value, count);
}

const sortRoomTypes = (roomTypes, count) => {
  let data = _orderBy(roomTypes, tariffsMinPrice, 'asc');
  data = _orderBy(data, roomType => travellersDiff({ roomType, count }), 'asc');

  return data;
};

function tariffsMinPrice(roomType) {
  const values = roomType.tariffs
    .map(tariff => tariff.price_formation.gross_price.amount);

  return Math.min(...values);
}

function travellersDiff({ roomType, count }) {
  const tariffs = roomType.tariffs
    .map(t => Math.abs(t.slots.length - count));

  return Math.min(...tariffs);
}

function roomTypesSorting({ hotel, room_types, travellers }) {
  const count = travellers.length;

  const roomTypes = [
    ...availableRoomTypes(room_types, count),
    ...notAvailableRoomTypes(room_types, count)
  ];

  const availabilities = roomTypes.reduce((acc, room_type) => {
    const value = room_type.tariffs
      .sort((a, b) => b.is_available - a.is_available)
      .map(tariff => (
        { hotel, room_type, tariff }
      ));

    return [...acc, ...value];
  }, []);

  return [roomTypes, availabilities];
}

export default roomTypesSorting;
