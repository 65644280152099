import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useClickOutside from 'hooks/useClickOutside';

import useResale from './hook/useResale';

import { Toggle } from 'components/forms';

import Loader from 'rambler-ui/Loader';

import Popup from './Popup';
import DisabledPopup from './DisabledPopup';
import moment from 'utils/moment';

const Content = styled.div`
  border-radius: 64px;
  background: #f2f4f6;
  width: 166px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;

  ${({ resale }) =>
    resale &&
    css`
      background: #effaff;
    `}
`;

const ContentText = styled.div`
  color: #6b707b;
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const ButtonText = styled.div`
  color: #3c6f9d;
  font-family: Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  user-select: none;
  line-height: 14px;
  cursor: pointer;
`;

const TextContainer = styled.div``;

const Wrapper = styled.div`
  position: relative;

  .checkbox {
    input {
      display: none;
    }
  }

  .rui-Loader-overlay {
    background: transparent;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${Content} {
        background: #f2f4f6;
        opacity: 0.5;
      }
    `}
`;

const Resale = ({ order, onClick, updateOrder }) => {
  const ref = useRef();
  const [openedDate, setOpenedDate] = useState();
  const [hovered, setHovered] = useState();
  const [data, actions] = useResale(order, updateOrder);
  const disabled = order.reservations.length > 1;
  const { active, visible, isPending, available_from_date, openModal } = data;
  const { activate, deactivate, saveForm, showModal } = actions;

  const handleSave = () => {
    saveForm();

    showModal(false);
  };

  const handleOutside = () => {
    openModal && !openedDate && deactivate();

    !openedDate && showModal(false);
  };

  useClickOutside(ref, handleOutside);

  const handleChange = (e) => {
    const checked = e.target.checked;

    checked ? activate() : deactivate();
  };

  const handleOpenDatepicker = (flag) => {
    setOpenedDate(flag);
  };

  const contentText =
    visible && available_from_date ? moment(available_from_date).format('DD MMM YYYY').replace('.', '') : 'Настроить';

  return (
    <Wrapper ref={ref} disabled={disabled}>
      <Content resale={active} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <TextContainer>
          <ContentText>Перепродажа</ContentText>

          {active && !disabled && <ButtonText onClick={() => showModal(true)}>{contentText}</ButtonText>}
        </TextContainer>

        {!isPending && <Toggle defaultChecked={active} checked={active} onChange={handleChange} disabled={disabled} />}

        {isPending && <Loader loading={true} style={{ width: '40px' }} />}
      </Content>

      {openModal && (
        <Popup data={data} actions={actions} handleOpenDatepicker={handleOpenDatepicker} onSave={handleSave} />
      )}

      {disabled && hovered && <DisabledPopup />}
    </Wrapper>
  );
};

Resale.propTypes = {
  order: PropTypes.object,
  onClick: PropTypes.func,
  updateOrder: PropTypes.func
};

export default Resale;
