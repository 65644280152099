import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import ResetPassword from './ResetPassword';

@inject('authStore')
@observer
class MobxWrapper extends React.Component {
  handleSubmit = (values) => {
    const { authStore } = this.props;
    return authStore.resetPassword(values);
  }

  render() {
    const { authStore } = this.props;

    if (authStore.isAuthenticated) {
      return (
        <Redirect
          to={{ pathname: '/login' }}
        />
      );
    }

    return (
      <ResetPassword
        onSubmit={this.handleSubmit}
      />
    );
  }
}

MobxWrapper.propTypes = {
  authStore: PropTypes.object.isRequired
};

export default MobxWrapper;
