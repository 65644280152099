import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import _compact from 'lodash/compact';

import { useTranslation } from 'react-i18next';

const Paragraph = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
`;

const Wrapper = styled.div``;

function InformationBlock({ className, seats, room_type, room_type: { rm_count, area } }) {
  const { t } = useTranslation();

  const seatsText = useMemo(() => {
    const main = t('Orders.TableItem.Seats.Main.Full', seats.main);
    const extra = seats.extra.count > 0 ? t('Orders.TableItem.Seats.Extra.Full', seats.extra) : null;

    return _compact([main, extra]).join(' + ');
  }, [seats]);

  const roomValue = useMemo(() => {
    if (rm_count && area) {
      return [
        t('Hotel.RoomTypes.RoomCount', { count: rm_count }),
        t('Hotel.RoomTypes.RoomArea', { count: area })
      ].join(' ');
    }

    return '';
  }, [room_type]);

  return (
    <Wrapper className={className}>
      <Paragraph>
        {seatsText}
      </Paragraph>

      <Paragraph>
        {roomValue}
      </Paragraph>
    </Wrapper>
  );
}

InformationBlock.propTypes = {
  className: PropTypes.string,
  seats: PropTypes.object,
  room_type: PropTypes.object
};

export default styled(InformationBlock)``;
