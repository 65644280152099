import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _zipObject from 'lodash/zipObject';
import _first from 'lodash/first';
import _last from 'lodash/last';

import pricesToNights from './pricesToNights';
import pricesToDays from './pricesToDays';

function baseCalcPrice(items, { type, price_calculation_rule }) {
  const price = price_calculation_rule === 'float'
    ? items.reduce((sum, i) => sum + i.price, 0)
    : items[0].price * items.length;

  return price;
}

function packageCalcPrice(items, { type, price_calculation_rule }) {
  return items[0].price;
}

function calcPrice(items, { price_calculation_type, price_calculation_rule }) {
  switch (price_calculation_type) {
    case 'base':
      return baseCalcPrice(items, { price_calculation_rule });
    case 'package':
      return packageCalcPrice(items, { price_calculation_rule });
    default:
      return baseCalcPrice(items, { price_calculation_rule });
  }
}

function pricesPayment({ tariff, prices }) {
  const { type: price_calculation_type, billing_hour, price_calculation_rule } = tariff;
  const isDay = billing_hour === 'day';

  const items = isDay
    ? pricesToDays(prices)
    : pricesToNights(prices);

  if (items.length === 0) return { total: 0 };

  const total = {
    price: calcPrice(items, { price_calculation_type, price_calculation_rule }),
    check_in: isDay ? _first(items).day : _first(items).night[0],
    check_out: isDay ? _last(items).day : _last(items).night[1],
    duration: { value: items.length, unit: billing_hour }
  };

  let methods = _groupBy(items, 'payment_method.type');

  methods = _map(methods, (items, type) => ({
    type,
    value: {
      price: calcPrice(items, { price_calculation_type, price_calculation_rule }),
      check_in: isDay ? _first(items).day : _first(items).night[0],
      check_out: isDay ? _last(items).day : _last(items).night[1],
      duration: { value: items.length, unit: billing_hour }
    }
  }));

  methods = _zipObject(
    methods.map(item => item.type),
    methods.map(item => item.value)
  );

  return { total, ...methods };
}

export default pricesPayment;
