import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import Requisites from './Requisites';
import ContactsInfo from './ContactsInfo';
import AboutCompany from './AboutCompany';
import Addresses from './Addresses';
import CustomCheckbox from './CustomCheckbox';

import { accoutingItems, actualAddressItems, contactsItems, legalAddressItems } from '../formFieldsData';

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  color: #9cb4ca;
`;

const Description = styled.p`
  margin: 8px 0 28px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;

const EqualAddress = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #979ba0;
  line-height: 22px;
`;

const Separator = styled.div`
  margin: 28px 0;
  border-radius: 4px;
  height: 2px;
  background: #eeeef3;
`;

const Wrapper = styled.div`
  padding: 40px;
  width: 829px;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;
`;

const LegalEntityForm = ({ className }) => {
  const { control, getValues, setValue, watch } = useFormContext();

  const equalAddress = watch('equalAddress');

  useEffect(() => {
    if (!equalAddress) return;

    setValue('legal_entity.legal_address', getValues()?.legal_entity?.actual_address);
  }, [equalAddress]);

  return (
    <Wrapper className={className}>
      <Title>Регистрация юр. лица</Title>

      <Description>Введите свои данные, чтобы создать аккаунт.</Description>

      <AboutCompany />

      <Separator />

      <Addresses title='Фактический адрес' items={actualAddressItems} />

      <Separator />

      <Addresses title='Юридический адрес' items={legalAddressItems} />

      <EqualAddress>
        <Controller
          control={control}
          render={({ field: { value, ...other } }) => (
            <CustomCheckbox checked={value} {...other}>
              <Text>Фактический и юридический адреса совпадают</Text>
            </CustomCheckbox>
          )}
          name='equalAddress'
        />
      </EqualAddress>

      <Separator />

      <ContactsInfo title='Контакты' items={contactsItems} />

      <Separator />

      <ContactsInfo title='Данные бухгалтерии' items={accoutingItems} />

      <Separator />

      <Requisites />
    </Wrapper>
  );
};

LegalEntityForm.propTypes = {
  className: PropTypes.string
};

export default LegalEntityForm;
