import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

function getSelectedOccupations({ travellers, occupations }) {
  const data = _cloneDeep(occupations);
  // eslint-disable-next-line no-unused-vars
  let { adults, childs } = data;

  const adultsCount = travellers
    .filter(t => t.age_group === 'adult')
    .length;

  const childsCount = travellers
    .filter(t => t.age_group === 'child')
    .length;

  // eslint-disable-next-line no-unused-vars
  adults = adults.map(item => {
    const selected = item.count === adultsCount;

    return _merge(item, { selected });
  });

  // eslint-disable-next-line no-unused-vars
  childs = childs.map(item => {
    const selected = item.count === childsCount;

    return _merge(item, { selected });
  });

  return data;
}

export default getSelectedOccupations;
