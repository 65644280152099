import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Distance from '../Distances';

const DistancesContainer = styled.div`
  margin-top: 28px;
  padding-right: 34px;
  min-height: calc(100vh - 260px);
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
`;

const LocationContainer = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 16px;
`;

const Container = styled.div`
  padding-right: 12px;
`;

const DistanceComponent = styled(Distance)``;

const Wrapper = styled.div`
  height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 250);
  padding: 36px 27px;
  padding-right: 0;
  box-sizing: border-box;

  ${DistanceComponent} + ${DistanceComponent} {
    margin-top: 28px;
  }
`;

function TextContainer({
  className,
  hotel: {
    distances,
    address: { location }
  },
  withDescription
}) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Container>
        <TitleContainer>
          <Title>{t('Hotel.Map.HowToGet')}</Title>
        </TitleContainer>

        <LocationContainer>{location}</LocationContainer>
      </Container>

      <DistancesContainer>
        <Distance distances={distances} withDescription={withDescription} />
      </DistancesContainer>
    </Wrapper>
  );
}

TextContainer.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  withDescription: PropTypes.bool
};

export default TextContainer;
