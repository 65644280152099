import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Qs from 'qs';
import styled from 'styled-components';

import Modal from 'components/ui/Modal';
import { DialogHotel } from 'pages/Orders/components';

const Wrapper = styled(Modal)`
  padding: 0;
`;

@withRouter
@observer
class HotelsInfo extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showHotelButton: PropTypes.bool,
    updateOrder: PropTypes.func.isRequired
  };

  handleModalClose = (e) => {
    const { location: { pathname, search }, history } = this.props;

    const qs = Qs.parse(search, { ignoreQueryPrefix: true });
    delete qs.hotel_id;

    const queryParams = Object.keys(qs).length > 0
      ? Qs.stringify(qs)
      : '';

    history.push(`${pathname}?${queryParams}`);
  }

  handleClick = (e, hotel) => {
    e.preventDefault();

    const { updateOrder } = this.props;

    updateOrder({ hotel });
  }

  render() {
    const { showHotelButton } = this.props;
    const { search } = this.props.location;

    const queryParams = Qs.parse(search, { ignoreQueryPrefix: true });
    const { hotel_id } = queryParams;

    if (!hotel_id) return null;

    return (
      <Wrapper
        opened
        onClose={this.handleModalClose}
      >
        <DialogHotel id={hotel_id} onClick={this.handleClick} showHotelButton={showHotelButton} />
      </Wrapper>
    );
  }
}

export default HotelsInfo;
