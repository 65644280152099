import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from 'components/icons';
import { Breadcrumbs } from 'components/ui';
import { Center, Sidebar, Summary } from '.';

const BackContainer = styled.div`
  color: #979BA0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${ArrowLeft} {
    margin-right: 12px;

    path {
      fill: #979BA0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  width: 960px;
  margin: 34px auto;

  ${Breadcrumbs} {
    margin-bottom: 16px;
  }

  ${Summary} {
    margin-bottom: 20px;
    margin-top: 18px;
  }

  ${Sidebar} {
    flex-grow: 1;
    width: 256px;
  }

  ${Center} {
    flex-grow: 0;
    width: 620px;
    margin-left: 20px;
  }
`;

function ViewOrder({ ...rest }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper {...rest}>
      <BackContainer onClick={() => history.goBack()}>
        <ArrowLeft />

        {t('UI.Button.Back')}
      </BackContainer>

      <Summary />

      <Content>
        <Sidebar />
        <Center />
      </Content>
    </Wrapper>
  );
}

ViewOrder.propTypes = {
  className: PropTypes.string
};

ViewOrder.defaultProps = {
  className: ''
};

export default styled(ViewOrder)``;
