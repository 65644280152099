import React from 'react';
import { computed } from 'mobx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { SelectX } from 'components/forms';
import styled from 'styled-components';
import { CircleArrow } from 'components/icons';
import { withTranslation } from 'react-i18next';

const DescIcon = styled(CircleArrow)`
  margin-right: 10px;
`;

const AscIcon = styled(DescIcon)`
  transform: rotate(180deg);
`;

const StyledSelect = styled(SelectX)`
  width: inherit;
  height: 42px !important;
`;

@withTranslation()
@observer
class SortField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.object.isRequired,
    t: PropTypes.func,
    handleUpdate: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get icon() {
    const { query } = this.props;

    if (query.direction === 1) {
      return <AscIcon />;
    }

    if (query.direction === -1) {
      return <DescIcon />;
    }

    return null;
  }

  handleChange = sort => {
    const { handleUpdate } = this.props;

    handleUpdate({ sort });
  }

  render() {
    const { icon } = this;
    const { t, query, ...rest } = this.props;

    const sortItems = [
      {
        value: 'asc',
        direction: 1,
        sort_by: 'pricing.min',
        label: t('Hotel.Search.PriceAsc')
      },
      {
        value: 'desc',
        direction: -1,
        sort_by: 'pricing.min',
        label: t('Hotel.Search.PriceDesc')
      },
      {
        value: 'desc_rating',
        direction: -1,
        sort_by: 'rating',
        label: t('Hotel.Search.Rating.Title')
      }
    ];

    const sortValue = sortItems.find(s => (s.sort_by === query.sort.sort_by) && (s.direction === Number(query.sort.direction)));

    return (
      <StyledSelect
        {...rest}
        onChange={this.handleChange}
        value={sortValue}
        icon={icon}
        items={sortItems}
        labelKey='label'
        valueKey='direction'
      />
    );
  }
}

export default styled(SortField)``;
