import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Report } from './Report';

const Paginate = types
  .model('Paginate', {
    limit: types.number,
    page: types.number,
    total: types.number
  });

const ReportsMeta = types
  .model('ReportsMeta', {
    paginate: Paginate
  });

const ReportsStore = types
  .model('ReportsStore', {
    data: types.optional(types.array(Report), []),
    meta: types.maybeNull(ReportsMeta),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })

  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get selectOptions() {
      return getSnapshot(self.data);
    }
  }))

  .actions(self => ({
    fetch(options = {}) {
      self.setState('pending');

      const { page } = options;
      const paginate = {
        page,
        limit: 10
      };
      const params = { ...options, paginate };

      return instance.get('/api/reports', { params })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    }
  }));

export default ReportsStore;
