import { types } from 'mobx-state-tree';

const Contract = types.model('Contract', {
  id: types.string,
  number: types.string,
  price_prefix: types.maybeNull(types.string),
  begin_date: types.string,
  end_date: types.string,
  insured_name: types.maybeNull(types.string),
  insured_inn: types.maybeNull(types.string),
  insured_kpp: types.maybeNull(types.string)
});

export default Contract;
