import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  transition: all 0.1s ease-in-out;

  ${Header} + ${Body} {
    margin-top: 20px;
  }

  ${Footer} {
    margin-top: 16px;
  }
`;

function Invoice({ invoice, assetBalance, onPayFromAssetBalance, onInfo, onDownload, className }) {
  const hasAssetBalance = invoice.total.net_price <= assetBalance.amount;

  return (
    <Wrapper className={className}>
      <Header
        invoice={invoice}
      />

      <Body
        invoice={invoice}
      />

      <Footer
        assetBalance={assetBalance}
        hasAssetBalance={hasAssetBalance}
        onPayFromAssetBalance={onPayFromAssetBalance}
        onInfo={onInfo}
        onDownload={onDownload}
      />
    </Wrapper>
  );
}

Invoice.defaultProps = {
  onPayFromAssetBalance: () => {},
  onInfo: () => {},
  onDownload: () => {}
};

Invoice.propTypes = {
  className: PropTypes.string,
  assetBalance: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired,
  invoice: PropTypes.object.isRequired,
  onPayFromAssetBalance: PropTypes.func,
  onInfo: PropTypes.func,
  onDownload: PropTypes.func
};

export default styled(Invoice)``;
