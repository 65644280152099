import React from 'react';
import { modal } from 'components/molecules';
import PropTypes from 'prop-types';
import { CloseSmall } from 'components/icons';
import { BlockShadow } from 'components/atoms';
import styled, { css, withTheme } from 'styled-components';

import { display, typography } from 'theme/mixins';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const CloseButton = styled.div``;

const Wrapper = styled(BlockShadow)`
  ${({ theme }) => css`
    position: relative;
    ${typography(14, 16)}
    background: #FFFFFF;
    box-shadow: 0 2px 6px rgba(36, 95, 119, 0.211334);
    border-radius: 4px;

    ${CloseButton} {
      ${display('flex', 'center', 'center')};
      position: absolute;
      top: 0;
      right: -46px;
      width: 35px;
      height: 35px;
      background: ${theme.colors.light};
      border: 1px solid ${theme.colors.primaryTitle};
      border-radius: 50%;
      cursor: pointer;
    }
  `}
`;

@withTheme
@modal
class Modal extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    onClose: PropTypes.func,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, onClose, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <CloseButton onClick={onClose}>
          <CloseSmall />
        </CloseButton>

        {children}
      </Wrapper>
    );
  }
}

export default styled(Modal)``;
