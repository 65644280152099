import styled, { css } from 'styled-components';
import colors from 'theme/colors';

export default styled.div`
  position: relative;
  padding: 16px 12px;
  font-size: 14px;

  ${({ hasDelimiter, width }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: ${typeof width === 'number' ? `${width}px` : width};

    ${hasDelimiter && css`
      ::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${colors.controls.lightBackground};
      }

      :first-child::after {
        width: calc(100% - 12px);
        right: 0;
        left: auto;
      }

      :last-child::after {
        width: calc(100% - 12px);
      }
    `}
  `}
`;
