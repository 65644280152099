function mergeQuery(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  const leftObj = { ...target };
  const rightObj = { ...source };

  Object.keys(rightObj).forEach(key => {
    const targetValue = leftObj[key];
    const sourceValue = rightObj[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // Dont concat arrays
      // leftObj[key] = targetValue.concat(sourceValue);
      leftObj[key] = sourceValue;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      leftObj[key] = mergeQuery(Object.assign({}, targetValue), sourceValue);
    } else {
      leftObj[key] = sourceValue;
    }
  });

  return leftObj;
}

export default mergeQuery;
