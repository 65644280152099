import { t } from 'utils/localization';

const fields = [
  'state',
  'state.label',
  'state.value'
];

const placeholders = {
  state: t('Orders.Filters.State.Label')
};

const extra = {
  state: [
    {
      label: t('Orders.TableItem.Status.Booked'),
      value: 'booked'
    },
    {
      label: t('Orders.TableItem.Status.Cancelled'),
      value: 'cancelled'
    },
    {
      label: t('Orders.TableItem.Status.Confirmed'),
      value: 'confirmed'
    },
    {
      label: t('Orders.TableItem.Status.Created'),
      value: 'created'
    }
  ]
};

export default {
  fields,
  placeholders,
  extra
};
