import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { display } from 'theme/mixins';
import { LinearLoader, BackLink } from 'components/ui';
import Button from 'components/forms/Button';

import {
  AddressField, DatesField, RatingField, SortField, GuestsField, PriceField, TreatmentField
} from './components';
import { useTranslation } from 'react-i18next';

const FormS = styled.div`
  width: 100%;
`;

const InnerS = styled.div`
  ${display('flex', 'center', 'flex-start')};
  margin: 0 auto;
`;

const InputsWrapper = styled.div`
  ${display('flex', 'center', 'flex-start')};
`;

const ButtonsContainer = styled.div`
  margin-left: 8px;
  width: 100px;
`;

const Actions = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;

  ${BackLink} + ${FormS} {
    margin-left: 28px;
  }

  ${SortField} {
    width: 200px;
  }

  ${RatingField} {
    width: 160px;
  }

  ${GuestsField} {
    width: 220px;
  }

  ${AddressField} {
    width: ${({ addressFieldWidth }) => addressFieldWidth};
  }

  ${DatesField} {
    width: 211px;
  }

  ${PriceField}{
    width: 250px;
  }

  ${TreatmentField}{
    width: 250px;
  }

  ${DatesField},
  ${RatingField},
  ${PriceField},
  ${GuestsField},
  ${TreatmentField},
  ${SortField} {
    margin-left: 8px;
  }

  ${LinearLoader} {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
`;

function FilterBar({ addressFieldWidth, onSubmit, isPending, hasActions, hasBackButton, hasPrice, hasTreatments, ...rest }) {
  const { t } = useTranslation();

  const handleSubmit = (slug) => {
    onSubmit && onSubmit();
  };

  return (
    <Wrapper {...rest} addressFieldWidth={addressFieldWidth}>
      {isPending && (
        <LinearLoader />
      )}

      <InnerS>
        {hasBackButton && <BackLink />}

        <FormS>
          <InputsWrapper>
            <AddressField
              {...rest}
            />

            <DatesField
              {...rest}
            />

            <GuestsField
              {...rest}
            />

            {hasPrice && (
              <PriceField
                {...rest}
              />)}

            {hasTreatments && (
              <TreatmentField
                {...rest}
              />)}

            {hasActions && (
              <ButtonsContainer>
                <Actions onClick={handleSubmit}>
                  {t('Orders.OrderForm.SubmitButton')}
                </Actions>
              </ButtonsContainer>
            )}
          </InputsWrapper>
        </FormS>
      </InnerS>
    </Wrapper>
  );
}

FilterBar.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
  hasPrice: PropTypes.bool,
  hasTreatments: PropTypes.bool,
  addressFieldWidth: PropTypes.string,
  hasActions: PropTypes.bool,
  hasBackButton: PropTypes.bool
};

FilterBar.defaultProps = {
  className: '',
  hasBackButton: true,
  hasPrice: true,
  hasTreatments: true,
  addressFieldWidth: '280px'
};

export default styled(FilterBar)``;
