import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useEvents from 'hooks/useEvents';

import { CellMeasurer } from 'react-virtualized';

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;

  & > * {
    padding-bottom: 12px;
  }
`;

function HotelRow({
  index,
  rowKey,
  parent,
  style,
  cache,
  item,
  children
}) {
  const em = useEvents();

  const handleMouseOver = () => {
    em.emit('map:marker:mouseover', item.id);
  };

  const handleMouseLeave = () => {
    em.emit('map:marker:mouseleave', item.id);
  };

  return (
    <CellMeasurer
      key={rowKey}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <Wrapper
        style={style}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </Wrapper>
    </CellMeasurer>
  );
}

HotelRow.propTypes = {
  index: PropTypes.number.isRequired,
  rowKey: PropTypes.any,
  parent: PropTypes.any,
  style: PropTypes.object,
  cache: PropTypes.object,
  item: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default styled(HotelRow)``;
