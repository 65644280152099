import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';
import { TableBody, TableBodyCell, TableHead, TableHeadCell, TableRow } from '.';

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 8px 0 0;
    background: ${theme.colors.light};
    box-shadow: 0 0 8px 0px ${theme.colors.controls.activeBackground};
  `}
`;

const TableS = styled.div``;

@withTheme
class Table extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  static Body = TableBody;

  static BodyCell = TableBodyCell;

  static Head = TableHead;

  static HeadCell = TableHeadCell;

  static Row = TableRow;

  render() {
    const { children, ...rest } = this.props;
    return (
      <Wrapper {...rest}>
        <TableS>
          {children}
        </TableS>
      </Wrapper>
    );
  }
}

export default styled(Table)``;
