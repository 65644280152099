import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Manual from 'src/static/manual/manual.pdf';
import { useTranslation } from 'react-i18next';

import { QuestionIcon } from 'components/icons';
import Tooltip from 'components/v2/ui/Tooltip';

const IconContainer = styled.div`
  font-size: 15px;
  height: 15px;
  margin-right: 8px;

  path {
    fill: #9CB4CA;
  }
`;

const Wrapper = styled.div`
  height: 26px;
  border-radius: 13px;
  background-color: #FFFFFF;
  padding: 0 13px 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #9CB4CA;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
`;

function QuestionButton({ ...rest }) {
  const { t } = useTranslation();

  const handleClick = () => {
    const link = document.createElement('a');
    const filename = ['manual', '.pdf'].join('');
    link.href = Manual;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Wrapper onClick={handleClick}>
      <Tooltip
        placement='bottom'
        overlay={t('Manual.Button')}
      >
        <IconContainer
          {...rest}
        >
          <QuestionIcon />
        </IconContainer>
      </Tooltip>

        {t('Manual.Button.Text')}
    </Wrapper>
  );
}

QuestionButton.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default styled(QuestionButton)``;
