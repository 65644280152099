import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'components/v2/ui/Tooltip';
import { t } from 'utils/localization';

import { StopSalesIcon } from 'components/icons';

const Overlay = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  font-size: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  background-color: #E26161;
`;

class StopSales extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;
    const overlaytext = (
      <Overlay dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.StopSales') }} />
    );

    return (
      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <Wrapper className={className}>
          <StopSalesIcon />
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(StopSales)``;
