import { useTranslation } from 'react-i18next';

import symbols from './symbols';

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function getPaymentText({ payment }) {
  const { t } = useTranslation();

  return t(`Tax.Payments.${payment}`);
}

function getConditionsText({ condition }) {
  const { t } = useTranslation();

  return t(`Tax.Conditions.${condition}`);
}

function getTaxRule({ tax }) {
  return [
    tax?.name,
    `оплата ${getPaymentText(tax)}`,
    `${numberWithSpaces(tax?.amount)}${symbols[tax?.unit?.toUpperCase()]} ${getConditionsText(tax)}`
  ].join(', ');
}

function getTaxRules({ taxes = [] }) {
  if (taxes?.length === 0) return [];

  return taxes?.map((tax) => getTaxRule({ tax }));
}

export function getTaxObject({ taxes = [] }) {
  if (taxes?.length === 0) return null;

  const tax = taxes?.[0];

  return {
    name: tax?.name,
    price: `${numberWithSpaces(tax?.amount)} ${symbols[tax?.unit.toUpperCase()]}`,
    paymentDescription: `Оплата ${getPaymentText(tax)}`,
    conditionDescription: getConditionsText(tax) || 'em'
  };
}

export default getTaxRules;
