import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { flexChildActualWidth, typography } from 'theme/mixins';
import { observer } from 'mobx-react';

import { AddTraveller, Traveller } from '.';

const Wrapper = styled.div`
  ${({ theme }) => css`
    counter-reset: slot;

    ${AddTraveller} {
      margin-top: 8px;
    }

    ${Traveller} {
      ::before {
        content: counter(slot) '.';
        counter-increment: slot;
        ${flexChildActualWidth(24)}
        color: ${theme.colors.text.primary};
        ${typography(14, 16, 700)};
      }
    }
  `}
`;

@withTheme
@observer
class Travellers extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    travellers: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { travellers, ...rest } = this.props;

    const listItems = Array.from(travellers.fields.values()).map(traveller => (
      <Traveller
        key={traveller.id}
        traveller={traveller}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}

        <AddTraveller travellers={travellers} />
      </Wrapper>
    );
  }
}

export default styled(Travellers)``;
