import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { SelectX as Select } from 'components/forms';

const SelectWrapper = styled(Select)`
  width: 200px;
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

@inject('bookingForm')
@observer
class Rate extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    slot: PropTypes.object.isRequired,
    rateOptions: PropTypes.array.isRequired,
    bookingForm: PropTypes.object.isRequired
  }

  handleOnSelect = (rate) => {
    const { slot, bookingForm } = this.props;
    bookingForm.setRate({ slot, rate });
  }

  render() {
    const { slot, rateOptions, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <SelectWrapper
          field={slot.$('rate')}
          items={rateOptions}
          labelKey='description'
          valueKey='type'
          onChange={this.handleOnSelect}
        />
      </Wrapper>
    );
  }
}

export default styled(Rate)``;
