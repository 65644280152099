import React from 'react';
import styled, { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { display, flexChildActualWidth } from 'theme/mixins';
import { computed } from 'mobx';

const Wrapper = styled.div``;

const Row = styled.div`
  ${display('flex')}
  margin: 6px 0;
`;

const Label = styled.div`
  ${flexChildActualWidth(90)}
`;

const Value = styled.div``;

@withTheme
@inject('profileStore')
@observer
class Info extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    profileStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get profile() {
    const { profileStore } = this.props;
    return {
      ...profileStore.data.toJSON(),
      address: {
        coordinates: [43.597799, 39.716850],
        location: 'Центральный, Сочи, Краснодарский край, 354008'
      }
    };
  }

  render() {
    const { t, ...rest } = this.props;
    const { address, rules } = this.profile;

    return (
      <Wrapper {...rest}>
        {address && (
          <Row>
            <Label>
              {t('Profile.Department')}:
            </Label>

            <Value>
              {address.location}
            </Value>
          </Row>
        )}

        {rules && (
          <Row>
            <Label>
              {t('Profile.Rules')}:
            </Label>

            <Value>
              {rules}
            </Value>
          </Row>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Info))``;
