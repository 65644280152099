import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AttentionIcon } from 'components/icons';

import DocumentsModal from './DocumentsModal';

const IconContainer = styled.div`
  font-size: 28px;
`;

const TextContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #323232;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #4a515c;

  a {
    color: #3c6f9d;
  }
`;

const Wrapper = styled.div`
  background: #ebf8fd;
  border-radius: 2px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  gap: 12px;
`;

function Documents({ className, attention }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState();

  const handleOpenClick = () => {
    setOpened(true);
  };

  const handleCloseClick = () => {
    setOpened(false);
  };

  return (
    <>
      <Wrapper onClick={handleOpenClick} className={className}>
        <IconContainer>
          <AttentionIcon />
        </IconContainer>

        <TextContainer>
          <Text dangerouslySetInnerHTML={{ __html: t('Hotel.Attention.Title') }} />
        </TextContainer>
      </Wrapper>
      {opened && <DocumentsModal onCloseClick={handleCloseClick} attention={attention} />}
    </>
  );
}

Documents.propTypes = {
  className: PropTypes.string,
  attention: PropTypes.object
};

export default Documents;
