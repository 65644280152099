import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import theme from './Button.theme';
import Loader from './Loader';

const Body = styled.div`
`;

const Wrapper = styled.button`
  ${(props) => theme(props)}
`;

@observer
class Button extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    rounded: PropTypes.bool,
    loading: PropTypes.bool,
    compact: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'big', 'large']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
    children: PropTypes.any
  };

  static defaultProps = {
    className: '',
    disabled: false,
    outline: false,
    rounded: false,
    loading: false,
    compact: false,
    size: 'medium',
    variant: 'primary'
  };

  render() {
    const { children, loading, ...rest } = this.props;

    return (
      <Wrapper
        loading={loading ? 'true' : undefined}
        {...rest}
      >
        {loading && <Loader />}

        <Body>
          {children}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(Button)``;
