import { t } from 'utils/localization';

const fields = [
  'chars',
  'contract',
  'contract.number',
  'contract.id',
  'contract.price_prefix',
  'contract.insured_name',
  'contract.insured_inn',
  'contract.insured_kpp',
  'contract.begin_date',
  'contract.end_date'
];

const placeholders = {
  chars: t('Contract.Placeholder')
};

const labels = {};

const rules = {
  contract: 'required',
  'contract.id': 'required|string',
  'contract.number': 'required|string',
  'contract.begin_date': 'required|string',
  'contract.end_date': 'required|string'
};

const values = {};

const extra = {};

const output = {};

export default {
  fields,
  labels,
  placeholders,
  rules,
  values,
  extra,
  output
};
