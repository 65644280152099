import { Field } from 'mobx-react-form';
import { observable, action } from 'mobx';

class PricesField extends Field {
  // Editable mode
  @observable isEdit = false;

  @action
  setAsEdit() {
    this.isEdit = true;
  }

  @action
  unsetAsEdit() {
    this.isEdit = false;
  }
}

export default PricesField;
