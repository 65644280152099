import buildSearchQuery from './buildSearchQuery';

import qs from 'qs';

function queryPathname({ place }) {
  if (!place) return null;

  const { slug, scope } = place;

  return scope === 'hotel' ? slug : null;
}

function queryHash(slug) {
  const [pathname, hash] = slug.split('#');

  return { pathname, hash };
}

function queryAsPath({ pathname, search, hash }) {
  const url = ['/'];

  if (pathname) {
    url.push(pathname);
  }

  if (search) {
    url.push(`?${search}`);
  }

  if (hash) {
    url.push(`#${hash}`);
  }

  return url.join('');
}

function buildString(query) {
  const opts = {
    skipNulls: true
  };

  return qs.stringify(query, opts);
}

function buildSearchString(query) {
  let search = buildSearchQuery(query);
  search = buildString(search);

  return search;
}

function buldListLocation(slug, query) {
  const search = buildSearchString(query);
  const pathname = queryPathname(query) || slug;

  if (pathname !== slug) {
    return buildLocation(pathname, query);
  }

  const { pathname: _pathname, hash } = queryHash(pathname);
  const asPath = queryAsPath({ pathname: _pathname, search, hash });

  return { asPath, pathname: _pathname, search, hash };
}

function buildDefaultLocation(slug, query) {
  const search = buildSearchString(query);
  const { pathname, hash } = queryHash(slug);
  const asPath = queryAsPath({ pathname, search, hash });

  return { asPath, pathname, hash, search };
}

function buildLocation(slug, query) {
  if (/^(\/)*search\/list/.test(slug)) {
    return buldListLocation(slug, query);
  }

  return buildDefaultLocation(slug, query);
}

export default buildLocation;
