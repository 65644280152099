import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import Qs from 'qs';
import { observer, Provider } from 'mobx-react';

import { Header } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Reports } from '.';

import ReportsStore from 'stores/ReportsStore/List';

@withRouter
@observer
class List extends React.Component {
  static propTypes = {
    location: ReactRouterPropTypes.location
  };

  constructor(props) {
    super(props);
    this.reportsStore = ReportsStore.create();
  }

  componentDidMount() {
    const { location } = this.props;
    const qs = Qs.parse(location.search, { ignoreQueryPrefix: true });
    const page = qs.page || 1;

    this.reportsStore.fetch({ page });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const qs = Qs.parse(location.search, { ignoreQueryPrefix: true });
    const prevQs = Qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });
    const prevPage = prevQs.page || 1;
    let page = qs.page || 1;

    if (page !== prevPage) {
      const { paginate } = this.reportsStore.meta;

      if (page > paginate.pages) {
        page = paginate.pages;
      }

      this.reportsStore.fetch({ page });
    }
  }

  componentWillUnmount() {
    this.reportsStore.clear();
  }

  render() {
    return (
      <Provider reportsStore={this.reportsStore}>
        <DefaultTemplate
          header={<Header />}
          body={<Reports />}
        />
      </Provider>
    );
  }
}

export default List;
