import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Tab } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'align-items', 'space-between')}
    background: ${theme.colors.passive.primary};
    border-radius: 4px;
    padding: 7px 8px;
  `}
`;

class Tabs extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        active: PropTypes.bool,
        label: PropTypes.string
      })
    )
  };

  static defaultProps = {
    tabs: [],
    className: ''
  };

  render() {
    const { tabs, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {tabs.map(({ label, to, active, onClick }, key) => (
          <Tab key={key} to={to} active={active ? 'active' : undefined} onClick={onClick}>
            {label}
          </Tab>
        ))}
      </Wrapper>
    );
  }
}

export default styled(Tabs)``;
