import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  color: #4a515c;
  ${typography(13, 16, 700)};

  &:after {
    content: '.';
  }
`;

@observer
class Number extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    number: PropTypes.number.isRequired
  };

  render() {
    const { number, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {number}
      </Wrapper>
    );
  }
}

export default styled(Number)``;
