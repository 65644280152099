import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import Actions from '../Actions';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: #6B707B;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 1px solid #D1D5DB;
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;

  ${Content} + ${Actions} {
    margin-top: 16px;
  }
`;

@observer
class Empty extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Content>
          {t('Order.Empty')}
        </Content>

        <Actions />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Empty))``;
