import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { distanceTextFormation } from 'utils/distance';

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4a515c;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

function Distance({ className, distance }) {
  const { t } = useTranslation();
  const distancesUnit = {
    km: t('Common.Distance.Km'),
    m: t('Common.Distance.M'),
    min: t('Common.Distance.Min')
  };

  const [distanceText] = useMemo(() => {
    const { text, value } = distanceTextFormation(distance.name);
    return [text, value || distance.value];
  }, [distance]);

  return (
    <Wrapper className={className}>
      <Name>{`${distanceText} (${distance.value} ${distancesUnit[distance.unit]})`}</Name>

      <Description>{distance.description}</Description>
    </Wrapper>
  );
}

Distance.propTypes = {
  distance: PropTypes.object,
  className: PropTypes.string
};

export default Distance;
