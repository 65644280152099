import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import formatPrice from 'utils/formatPrice';

const Text = styled.div`
  color: #35A068;
  font-size: 12px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  background-color: #CBF5DF;
`;

function BalanceBadge({ balance, className }) {
  const { t } = useTranslation();

  const value = formatPrice(balance.amount, balance.currency);

  return (
    <Wrapper className={className}>
      <Text>
        {t('Balance.AssetValue', { value })}
      </Text>
    </Wrapper>
  );
}

BalanceBadge.propTypes = {
  className: PropTypes.string,
  balance: PropTypes.object.isRequired
};

export default styled(BalanceBadge)``;
