import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import Color from 'color';

import { CommissionVisibleIcon, CommissionHiddenIcon } from 'components/icons';
import Tooltip from 'components/v2/ui/Tooltip';

const Wrapper = styled.div`
  ${({ isVisible, theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 34px;
    border-radius: 9999px;
    background: ${theme.colors.button.secondary};
    cursor: pointer;
    transition: background-color 0.25s;

    :hover {
      background-color: ${Color(theme.colors.button.secondary).darken(0.1).string()};
    }

    ${isVisible && css`
      background-color: ${theme.colors.error.primary};

      :hover {
        background-color: ${Color(theme.colors.error.primary).darken(0.1).string()};
      }
    `}
  `}
`;

@withTranslation()
@inject('commissionVisibility')
@observer
class CommissionButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    commissionVisibility: PropTypes.object.isRequired
  };

  handleClick = () => {
    const { commissionVisibility } = this.props;
    commissionVisibility.toggleCommission();
  }

  render() {
    const { commissionVisibility: { isVisible }, t, ...rest } = this.props;

    const tooltipText = isVisible
      ? t('CommissionButton.Disable')
      : t('CommissionButton.Enable');

    return (
      <Tooltip
        placement='bottom'
        overlay={tooltipText}
      >
        <Wrapper
          isVisible={isVisible}
          onClick={this.handleClick}
          {...rest}
        >
          {isVisible
            ? <CommissionVisibleIcon />
            : <CommissionHiddenIcon />
          }
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(CommissionButton)``;
