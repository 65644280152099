import _uniqueId from 'lodash/uniqueId';

const SCOPE = 'region';

const PREFIX = 'place-';

function convertQueryPlace(address) {
  if (!address) return {};

  const { location, coordinates, scope } = address;

  return {
    label: location,
    description: location,
    scope: (scope || SCOPE),
    slug: '/',
    location: coordinates,
    place_id: _uniqueId(PREFIX)
  };
}

function convertSearchQuery(searchQuery) {
  const { address, ...rest } = searchQuery;
  const place = convertQueryPlace(address);

  return { ...rest, place, address };
}

export default convertSearchQuery;
