import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  color: #4A515C;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  height: 28px;
  width: 30px;
  border-radius: 4px;
  background-color: #F2F4F6;
  padding-top: 4px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  user-select: none;

  &:hover {
    background-color: #5AB2D6;
    color: #fff;
  }

  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: 0.4;
    background-color: #FFFFFF;
    color: #4C4C4C;
    pointer-events: none;

    &:hover {
      background-color: #fff;
      color: inherit;
    }
  `}

  ${({ selected }) => selected && css`
    background-color: #5AB2D6;
    color: #fff;
  `}

  & + & {
    margin-left: 3px;
  }
`;

function Guest({ item, onSelect, className }) {
  const { count, available, selected } = item;

  const handleSelect = () => {
    onSelect(item);
  };

  return (
    <Wrapper
      onClick={handleSelect}
      disabled={!available}
      selected={selected}
    >
      {count}
    </Wrapper>
  );
}

Guest.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    count: PropTypes.number.isRequired,
    available: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired
  }).isRequired,
  onSelect: PropTypes.func
};

Guest.defaultProps = {
  onSelect: () => {}
};

export default styled(Guest)``;
