import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PriceForm from 'components/forms/PriceForm';
import { useTranslation } from 'react-i18next';

function PriceField({ query, handleUpdate, className }) {
  const { t } = useTranslation();
  return (
    <PriceForm
      className={className}
      title={t('Common.Budget')}
      value={[query.filter.price.min, query.filter.price.max]}
      onChange={handleUpdate}
    />

  );
}

PriceField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func
};

export default styled(PriceField)``;
