import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, Field, Select } from 'components/v2/forms';

const InputComponent = styled(Input)`
  height: 40px;
`;

const SelectComponent = styled(Select)`
  .rc-select {
    height: 40px;
  }
`;

const Wrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  border-radius: 4px;
  background: #f2f4f6;
  padding: 16px;
`;

const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const FieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  background: ${(props) => props.error && '#fdf1f1'};
  padding: ${(props) => props.error && '16px'};
  border-radius: ${(props) => props.error && '4px'};
  flex-direction: ${(props) => (props.error ? 'column' : 'row')};
`;

const CompanyInfoField = styled(Field)`
  width: 180px;
`;

const DirectorInfoField = styled(Field)`
  width: 280px;
`;

const Text = styled.p`
  color: #979ba0;
  font-size: 14px;
  font-weight: 400;
`;

const Agency = styled(Text)`
  background: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  border-radius: 4px;
`;

const AgencyFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgencyWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const AboutCompany = ({ className }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();

  const agencyTypes = getValues()?.agency_types || [];

  return (
    <Wrapper className={className}>
      <Title>О компании</Title>

      <FieldWrapper>
        <Controller
          name='inn'
          control={control}
          render={({ field }) => (
            <CompanyInfoField error={errors?.inn} label={t('Auth.Register.AboutCompany.Label')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
            </CompanyInfoField>
          )}
        />

        <Controller
          control={control}
          name='kpp'
          render={({ field }) => (
            <CompanyInfoField error={errors?.kpp} label={t('Auth.Register.LegalEntity.Requisites.Label3')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
            </CompanyInfoField>
          )}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='short_name'
          render={({ field }) => (
            <CompanyInfoField error={errors?.short_name} label={t('Auth.Register.AboutCompany.Label2')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder2')} />
            </CompanyInfoField>
          )}
        />

        <Controller
          control={control}
          name='name'
          render={({ field }) => (
            <Field error={errors?.name} label={t('Auth.Register.AboutCompany.Label3')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder2')} />
            </Field>
          )}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='type'
          render={({ field }) => (
            <CompanyInfoField error={errors?.type} label={t('Auth.Register.AboutCompany.Label4')}>
              <SelectComponent
                {...field}
                valueField='tag'
                labelField='name'
                showLabel
                disabled
                placeholder={t('Auth.Register.AboutCompany.Placeholder4')}
              />
            </CompanyInfoField>
          )}
        />

        <Controller
          control={control}
          name='ogrn'
          render={({ field }) => (
            <CompanyInfoField error={errors?.ogrn} label={t('Settings.Contracts.Legal.RegNumber')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder')} />
            </CompanyInfoField>
          )}
        />
        <Controller
          control={control}
          name='tax_type'
          render={({ field }) => (
            <CompanyInfoField error={errors?.tax_type} label={t('Auth.Register.AboutCompany.Label5')}>
              <SelectComponent
                {...field}
                valueField='tag'
                labelField='name'
                showLabel
                disabled
                placeholder={t('Auth.Register.AboutCompany.Placeholder4')}
              />
            </CompanyInfoField>
          )}
        />
      </FieldWrapper>

      <AgencyFieldWrapper>
        <CompanyInfoField label={t('Auth.Register.AboutCompany.Label9')} />

        {agencyTypes.length > 0 && (
          <AgencyWrapper>
            {agencyTypes.length > 0 && agencyTypes.map(({ name, tag }) => <Agency key={tag}>{name}</Agency>)}
          </AgencyWrapper>
        )}

        {!agencyTypes.length && <Text>-</Text>}
      </AgencyFieldWrapper>

      <FieldWrapper>
        <Controller
          control={control}
          name='director.full_name'
          render={({ field }) => (
            <DirectorInfoField error={errors?.director?.full_name} label={t('Auth.Register.AboutCompany.Label7')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.Manager.FIO.Placeholder')} />
            </DirectorInfoField>
          )}
        />

        <Controller
          control={control}
          name='director.post'
          render={({ field }) => (
            <DirectorInfoField error={errors?.director?.post} label={t('Auth.Register.AboutCompany.Label8')}>
              <InputComponent {...field} disabled placeholder={t('Auth.Register.AboutCompany.Placeholder3')} />
            </DirectorInfoField>
          )}
        />
      </FieldWrapper>

      <Text>Данные о компании недоступны для редактирования</Text>
    </Wrapper>
  );
};

AboutCompany.propTypes = {
  className: PropTypes.string
};

export default AboutCompany;
