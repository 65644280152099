import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    height: 32px;
    padding: 0 8px;
    border-left: 1px solid ${theme.colors.popup.primaryHover};
    border-right: 1px solid ${theme.colors.popup.primaryHover};
  `}
`;

@withTheme
class PageItems extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(PageItems)``;
