import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GuestsPicker } from 'components/forms';

function GuestsField({ query, handleUpdate, className }) {
  return (
    <GuestsPicker
      className={className}
      value={query.travellers}
      onSelect={(travellers) => handleUpdate({ travellers })}
    />
  );
}

GuestsField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func
};

export default styled(GuestsField)``;
