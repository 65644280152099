import fonts from './fonts';
import colors from './colors';

export default {
  badge: {
    fontFamily: fonts.primary,
    text: colors.light,
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '14px',
    boxSizing: 'border-box',
    height: '14px',
    minWidth: '14px',
    border: `2px solid ${colors.light}`,
    borderRadius: '4px',
    background: '#5ab2d6'
  }
};
