import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';
import { withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { withTranslation } from 'react-i18next';

import RoomTypeDescription from './RoomTypeDescription';

import Form from '../Form';

import BookingForm from 'forms/Orders/BookingForm';

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

@withTranslation()
@withRouter
@inject('bookingState')
@observer
class Content extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired,
    onError: PropTypes.func,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.initBookingForm();
  }

  componentDidMount() {
    this.addHandlers();
  }

  componentWillUnmount() {
    this.delHandlers();
    this.disposeBookingForm();
  }

  initBookingForm() {
    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError,
      onReset: this.handlerReset
    };

    this.bookingForm = new BookingForm({}, { hooks });
  }

  disposeBookingForm() {
    this.bookingForm.dispose();
    this.bookingForm.unsetAvailability();
  }

  populateBookingForm(params) {
    this.bookingForm.setAvailability(params);
  }

  resetBookingForm() {
    this.bookingForm.resetSlots();
  }

  addHandlers() {
    const { bookingState } = this.props;

    this._stateFetchedHandler = reaction(
      () => bookingState.formParams,
      (params) => this.populateBookingForm(params)
    );

    this._bookingFormReaction = reaction(
      () => this.bookingForm.values(),
      () => this.bookingForm.validate()
    );

    this._datesChangeHandler = reaction(
      () => {
        const check_in = this.bookingForm.$('check_in').value;
        const check_out = this.bookingForm.$('check_out').value;

        return { check_in, check_out };
      },
      ({ check_in, check_out }) => {
        if (!!check_in && !!check_out) this.updateDailyPrices({ check_in, check_out });
      }
    );
  }

  delHandlers = () => {
    this._stateFetchedHandler();
    this._bookingFormReaction();
    this._datesChangeHandler();
  };

  updateDailyPrices = ({ check_in, check_out }) => {
    const { bookingState } = this.props;

    const dailyPrices = bookingState.getDailyPricesPerPeriod({ check_in, check_out });

    this.bookingForm.updateDailyPrices(dailyPrices);
  };

  handleSuccess = async (form) => {
    const params = form.values();
    const { bookingState, onError, t } = this.props;

    try {
      await bookingState.orderUpdate(params);

      this.handleNextStep();
    } catch (e) {
      onError(t('Orders.Notifications.BookedError'));
    }
  };

  handleNextStep = () => {
    const {
      history,
      bookingState: {
        order: { id }
      }
    } = this.props;

    history.replace(`/orders/${id}`);
  };

  handleError = (form) => {
    console.log(form.errors());
  };

  handlerReset = (form) => {
    this.navigateToOrder();
  };

  handleClear = (form) => {
    console.log('handleClear');
  };

  navigateToOrder = () => {
    const {
      history,
      bookingState: {
        order: { id }
      }
    } = this.props;

    history.replace(`/orders/${id}/edit`);
  };

  render() {
    const { className, bookingState } = this.props;
    const { hotel, room_type, tariff } = bookingState;

    return (
      <Provider bookingForm={this.bookingForm}>
        <Sticky top={20} className={className}>
          <Wrapper className={className}>
            <RoomTypeDescription
              bookingForm={this.bookingForm.values()}
              hotel={hotel}
              room_type={room_type}
              tariff={tariff}
            />

            <Form />
          </Wrapper>
        </Sticky>
      </Provider>
    );
  }
}

export default styled(Content)``;
