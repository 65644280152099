import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.div`
  opacity: 0.6;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  max-width: 237px;
`;

const Wrapper = styled.div`
  height: 92px;
  width: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

function Empty({ title, className }) {
  return (
    <Wrapper className={className}>
      <Text>{title}</Text>
    </Wrapper>
  );
}

Empty.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

Empty.defaultProps = {
  title: ''
};

export default styled(Empty)``;
