import { types } from 'mobx-state-tree';

const Amenity = types.model('Amenity', {
  tag: types.string,
  name: types.string,
  icon: types.maybeNull(types.string),
  parent_tag: types.maybeNull(types.string)
});

export { Amenity };
