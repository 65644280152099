import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';

import Header from './Header';
import Calendar from './Calendar';
import TravellersForm from './TravellersForm';

const Wrapper = styled.div`
  padding-top: 20px;
  background: #fff;
  border-radius: 4px;
  padding: 0 30px 20px;

  ${Calendar} {
    margin: 25px 0 0;
    padding-bottom: 35px;
    border-bottom: 1px solid #D1D5DB;
  }

  ${TravellersForm} {
    margin-top: 35px;
  }
`;

@inject('bookingForm')
@observer
class Form extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm: { availability, hasAvailability }, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {hasAvailability && (
          <>
            <Header />
            <Calendar
              availability={availability}
            />
          </>
        )}

        <TravellersForm />
      </Wrapper>
    );
  }
}

export default styled(Form)``;
