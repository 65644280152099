import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled, { css } from 'styled-components';
import getImage from 'utils/getImage';
import { useTranslation } from 'react-i18next';

import { ImageSetPropType } from 'types/customPropTypes';
import { Status, SlideControl } from './components';
import { Rating, Stars } from 'components/ui';
import { Provider } from 'mobx-react';

import ReviewsStore from 'stores/ReviewsStore/ReviewsStore';

const Wrapper = styled.div`
  position: relative;

  .image-gallery-thumbnail {
    width: 66px;
    border: 2px solid transparent !important;
  }

  .image-gallery-thumbnails-container {
    text-align: left;
  }

  .image-gallery-thumbnail.active {
    border: 2px solid #337ab7 !important;
  }

  .image-gallery-thumbnails-wrapper.bottom {
    margin-top: -2px;
  }

  .image-gallery-slide {
    border-radius: 4px;
    overflow: hidden;
  }
`;

const SlideS = styled.img`
  width: 100%;
  height: 336px;
  border-radius: 4px;
  object-fit: cover;
  position: relative;
`;

const RatingContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-flex;
  padding: 9px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 3px;
  border-radius: 4px;
  background: #dff7ea;
  cursor: pointer;
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 650px;
  gap: 8px;
`;

const Title = styled.div`
  color: #979ba0;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Badge = styled.div`
  color: #6b707b;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  white-space: nowrap;
  border-radius: 40px;
  background: #ebf8fd;
  gap: 4px;
  width: fit-content;
`;

const Review = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #3c6f9d;
`;

const ThumbS = styled.img`
  height: 40px;
  border-radius: 3px;
  object-fit: cover;
`;

const StatusS = styled(Status)`
  position: absolute;
  top: 296px;
  left: 50%;
  transform: translateX(-50%);
`;

const SlideControlS = styled(SlideControl)`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  ${({ direction }) =>
    direction &&
    css`
      ${direction === 'left' &&
      css`
        left: 16px;
      `}

      ${direction === 'right' &&
      css`
        right: 16px;
      `}
    `}
`;

function CarouselGallery({
  items,
  rating,
  star,
  review,
  setOpenReview,
  id,
  setData,
  treatment_factors,
  location_types,
  ...rest
}) {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [reviewsData, _] = useState(ReviewsStore.create());
  const treatmentFactors =
    treatment_factors &&
    treatment_factors?.length > 0 &&
    treatment_factors
      .map(({ name }, index) => name)
      .join(', ')
      .toLowerCase();

  const handleCurrentSlideSet = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };
  const showRaringContainer = (star, rating, review) => {
    return review?.total > 0 || (Number(star) > 1 && review?.total > 0) || rating > 0;
  };

  const renderItem = (image) => {
    const mainImageSize = { width: 960, height: 456 };
    const { src } = getImgPath(image, mainImageSize);

    return <SlideS src={src} />;
  };

  const renderThumbInner = (image) => {
    const thumbSize = { width: 264, height: 160 };
    const { src } = getImgPath(image, thumbSize);

    return <ThumbS src={src} />;
  };

  const renderLeftNav = (onClick, disabled) => {
    return <SlideControlS direction='left' disabled={disabled} onClick={onClick} />;
  };

  const renderRightNav = (onClick, disabled) => {
    return <SlideControlS direction='right' disabled={disabled} onClick={onClick} />;
  };

  const getImgPath = (item, size) => {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  };

  const galleryItems = items.map((item) => ({
    renderItem: () => renderItem(item),
    renderThumbInner: () => renderThumbInner(item)
  }));

  const handleOpenReview = () => {
    setOpenReview(true);

    const fetch = async () => {
      const { reviews } = await reviewsData.fetch(id);
      const { data } = reviews;
      setData(data);
    };
    fetch();
  };

  return (
    <Provider reviews={reviewsData}>
      <Wrapper {...rest}>
        <ImageGallery
          items={galleryItems}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          showFullscreenButton={false}
          showPlayButton={false}
          onSlide={handleCurrentSlideSet}
        />
        <BadgesWrapper>
          <BadgeContainer>
            {location_types &&
              location_types?.length > 0 &&
              location_types.map(({ name }, index) => <Badge key={index}>{name}</Badge>)}
          </BadgeContainer>
          <Badge>
            <Title>{t('Hotel.TreatmentFactors')}</Title>
            {treatmentFactors}
          </Badge>
        </BadgesWrapper>

        {showRaringContainer(star, rating, review?.total) && (
          <RatingContainer onClick={handleOpenReview}>
            <StarsContainer>
              <Stars stars={1} />
              {rating > 0 && <Rating rating={rating} isGreen />}
            </StarsContainer>
            {review?.total > 0 && <Review>{t('Common.Reviews', { count: review.total })}</Review>}
          </RatingContainer>
        )}

        <StatusS>{`${currentSlide + 1} из ${items.length}`}</StatusS>
      </Wrapper>
    </Provider>
  );
}

CarouselGallery.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(ImageSetPropType),
  star: PropTypes.number,
  rating: PropTypes.number,
  review: PropTypes.object,
  setOpenReview: PropTypes.func,
  setData: PropTypes.func,
  id: PropTypes.any,
  location_types: PropTypes.array,
  treatment_factors: PropTypes.array
};

CarouselGallery.defaultProps = {
  className: ''
};

export default styled(CarouselGallery)``;
