import * as yup from 'yup';

export const EMAIL = 'email';

const validationSchema = yup.object({
  [EMAIL]: yup.string().email().trim().required('Required')
});

export const defaultValues = {};

export default validationSchema;
