import { types, detach } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Review } from './models/Review';
import { Meta } from './models/Meta';

const ReviewsStore = types
  .model('ReviewsStore', {
    meta: types.maybeNull(Meta),
    data: types.optional(types.array(Review), []),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get hasSeoObject() {
      return self.isFetched && !!self.meta && !!self.meta.seo;
    }
  }))
  .actions((self) => ({
    fetch(id) {
      self.setState('pending');

      return instance
        .get(`/api/hotels/${id}/reviews`)
        .then((response) => self.resetStore(response))
        .then((store) => self.setState('done'))
        .catch((error) => self.errorHandler(error));
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        self.reviews = data.data;
      }

      return self;
    },

    clear() {
      detach(self);
    },

    setState(state) {
      self.state = state;
      return self;
    }
  }));

export default ReviewsStore;
