import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAccommodationFeatures } from 'stores/FiltersStore1/AccommodationFeaturesStore';

import Checkbox from 'components/forms/Checkbox2';

const Wrapper = styled.div`
`;

function AccommodationFeatures({ value, onChange, className }) {
  const { t } = useTranslation();

  const [{ data }] = useAccommodationFeatures();

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <Wrapper className={className}>
      <Checkbox
        value={value}
        options={data}
        onChange={handleChange}
        // eslint-disable-next-line no-use-before-define
        valueKey='tag'
        // eslint-disable-next-line no-use-before-define
        labelKey='name'
        title={t('Checkboxes.AccommodationFeatures.Title')}
        emptyMessage={t('Checkboxes.AccommodationFeatures.EmptyMessage')}
        placeholder={t('Checkboxes.AccommodationFeatures.Placeholder')}
        moreText={t('Checkboxes.AccommodationFeatures.MoreButtonText')}
        hideText={t('Common.HideList')}
      />
    </Wrapper>
  );
}

AccommodationFeatures.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func
};

AccommodationFeatures.defaultProps = {
  value: [],
  onChange: () => {}
};

export default styled(AccommodationFeatures)``;
