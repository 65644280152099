import moment from 'utils/moment';

function tariffsRestriction(tariff) {
  let { billing_hour, stay_period: { min_days } } = tariff;
  min_days = min_days || 1;

  if (min_days <= 1) return undefined;

  return { value: min_days, unit: billing_hour };
}

function duration({ billing_hour, check_in, check_out }) {
  const billingShift = billing_hour === 'day' ? 1 : 0;

  const checkIn = moment(check_in).utcOffset(check_in).startOf('day');
  const checkOut = moment(check_out).utcOffset(check_out).startOf('day');

  let value = checkOut.diff(checkIn);
  value = Math.ceil(value / (86400 * 1000));
  value = value + billingShift;

  return { value, unit: billing_hour };
}

export { tariffsRestriction, duration };
