import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Position from './Position';

const Wrapper = styled.div`
  ${Position} + ${Position} {
    margin-top: 16px;
  }
`;

function Body({ invoice, className }) {
  const { order, positions } = invoice;

  const orderLink = `/orders/${order.id}`;

  return (
    <Wrapper className={className}>
      {positions.map((position, idx) => (
        <Position
          key={idx}
          position={position}
          orderLink={orderLink}
        />
      ))}
    </Wrapper>
  );
}

Body.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default styled(Body)``;
