import { types, getParent } from 'mobx-state-tree';

import { Address } from './Address';
import { Image } from './Image';
import { Specialization } from './Specialization';
import { Contact } from './Contact';
import { Distance } from './Distance';
import { HotelsHint } from './HotelsHint';
import { Amenity } from './Amenity';
import { FeedingProgram } from './FeedingProgram';
import { TreatmentProgram } from './TreatmentProgram';
import { PriceFormation } from 'stores/models';
import { OrdersReview } from './Review';
import { Taxes } from './Taxes';
import { AccommodationFeatures } from './AccommodationFeatures';
import { ManipulationPrograms } from './ManipulationPrograms';
import { SiteDescription } from './SiteDescription';
import { Attentions } from './Attentions';
import { TreatmentFactor } from './TreatmentFactor';

export const Hotel = types
  .model('Hotel', {
    id: types.identifier,
    slug: types.string,
    instant_booking: types.boolean,
    address: Address,
    name: types.string,
    rating: types.number,
    price_formation: types.maybeNull(PriceFormation),
    description: types.string,
    email: types.maybeNull(types.string),
    check_in_time: types.optional(types.string, '12:00'),
    check_out_time: types.optional(types.string, '10:00'),
    children_from: types.optional(types.integer, 0),
    images: types.optional(types.array(Image), []),
    specializations: types.optional(types.array(Specialization), []),
    amenities: types.optional(types.array(Amenity), []),
    distances: types.optional(types.array(Distance), []),
    contacts: types.optional(types.array(Contact), []),
    hints: types.maybeNull(types.array(HotelsHint)),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error'])),
    feeding_programs: types.maybeNull(types.array(FeedingProgram)),
    treatment_programs: types.maybeNull(types.array(TreatmentProgram)),
    star: types.optional(types.number, 0, [null, undefined]),
    provider: types.maybeNull(types.string),
    review: types.maybeNull(OrdersReview),
    taxes: types.maybeNull(types.array(Taxes)),
    accommodation_features: types.maybeNull(types.array(AccommodationFeatures)),
    manipulation_programs: types.maybeNull(types.array(ManipulationPrograms)),
    site_description: types.maybeNull(SiteDescription),
    attentions: types.maybeNull(types.array(Attentions)),
    treatment_factors: types.maybeNull(types.array(TreatmentFactor)),
    location_types: types.maybeNull(types.array(TreatmentFactor))
  })
  .views((self) => ({
    get store() {
      return getParent(self);
    },

    get hasImages() {
      return self.images.length > 0;
    },

    get hasSpecializations() {
      return self.specializations.length > 0;
    },
    get price() {
      const { min, discount_price } = self.pricing;
      return discount_price ? discount_price.min : min;
    },
    get thumb() {
      const thumb = self.images[0];

      if (thumb && thumb.thumb) {
        return thumb.thumb.url;
      }

      return null;
    }
  }));
