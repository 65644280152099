import store from 'store';

const STORAGE_KEY = 'NOTIFICATIONS_STATE';

class Storage {
  list = () => {
    const items = store.get(STORAGE_KEY) || [];
    return Promise.resolve(items);
  }

  create = (data) => {
    const items = store.get(STORAGE_KEY) || [];
    const ids = items.map(item => item.id);

    const id = ids.length > 0
      ? Math.max(...ids) + 1
      : 0;

    const item = { ...data, id };

    store.set(STORAGE_KEY, [...items, item]);

    return Promise.resolve(item);
  }

  remove = (id) => {
    const items = store.get(STORAGE_KEY) || [];
    const newItems = items.filter(item => item.id !== id);

    store.set(STORAGE_KEY, newItems);

    return Promise.resolve(id);
  }
}

export default new Storage();
