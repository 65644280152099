import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MapContainer as Map, Marker, TileLayer, ZoomControl } from 'react-leaflet';
import { useTranslation } from 'react-i18next';

import { CloseSmall } from 'components/icons';
import { defaultMarker } from './DefaultIcon/marker';
import Distance from './Distance/distance';

const DistanceComponent = styled(Distance)``;

const MapInformationContainer = styled.div`
  margin-top: 24px;
  position: fixed;
  max-width: 600px;
`;

const Wrapper = styled.div`
  transition: all linear 0.2s;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-right .leaflet-control {
    margin-right: 16px;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 16px;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out:hover {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 34px !important;
    color: #656665;
    box-sizing: border-box;
    font-weight: 600;
    border-radius: 50%;
  }

  .leaflet-bar a {
    width: 40px;
    height: 40px;
  }

  .leaflet-control-zoom-out {
    margin-top: 8px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .leaflet-control-zoom-in {
    border-bottom: 0;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 15%;
      width: 70%;
      top: 100%;
      height: 1px;
      background-color: #ebebeb;
    }
  }

  .leaflet-bar {
    box-shadow: inherit;
    border: 0;
    border-radius: 0;
  }

  .leaflet-container {
    height: 100%;
  }

  .leaflet-bottom {
    z-index: 8;
  }

  img.leaflet-marker-icon {
    cursor: pointer;
    margin-left: -21px !important;
    margin-top: -21px !important;
    width: 36px !important;
    height: 43px !important;
  }

  .leaflet-marker-shadow {
    display: none;
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #4a515c;
`;

const DistancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 18px;
`;

function MapPage({ hotel, hotel: { address, distances }, className, tileBaseUrl, setOpenMap }) {
  const { t } = useTranslation();
  const coordinates = [...address.coordinates].reverse();
  const distanceElements = distances.map((d) => <DistanceComponent distance={d} key={d.id} />);
  const closeMap = () => {
    setOpenMap(false);
  };

  return (
    <Wrapper className={className}>
      <CloseButton onClick={closeMap}>
        <CloseSmall />
      </CloseButton>
      <Map animate={true} minZoom={1} useFlyTo={true} center={coordinates} zoom={7} zoomControl={false}>
        <TileLayer url={tileBaseUrl} />

        <ZoomControl position='bottomright' />

        <Marker icon={defaultMarker} draggable={false} position={coordinates} />
      </Map>

      <MapInformationContainer>
        <TitleContainer>
          <Title>{t('Hotel.Map.HowToGet')}</Title>
        </TitleContainer>

        <DistancesContainer>{distanceElements}</DistancesContainer>
      </MapInformationContainer>
    </Wrapper>
  );
}

MapPage.propTypes = {
  hotel: PropTypes.object,
  className: PropTypes.string,
  setOpenMap: PropTypes.func,
  tileBaseUrl: PropTypes.string
};

MapPage.defaultProps = {
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok/ru/{z}/{x}/{y}.png'
};

export default MapPage;
