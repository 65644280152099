import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { display } from 'theme/mixins';

const Wrapper = styled(Link)`
  ${({ active, theme }) => css`
    ${display('flex', 'center', 'center')}
    width: 24px;
    height: 24px;
    margin: 0 4px;
    cursor: pointer;
    font-size: 12px;
    line-height: 11px;
    transition: color 0.25s;

    ${active && css`
      background: ${theme.colors.button.primaryDisabled};
      border: 1px solid ${theme.colors.button.primaryHover};
      border-radius: 999px;
      color: ${theme.colors.button.primaryHover};
      cursor: default;
      font-weight: 500;
      pointer-events: none;
    `}

    ${active && css`
      :hover {
        color: ${theme.colors.button.primary};
      }
    `}
  `}
`;

@withTheme
class PageItem extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    isActive: PropTypes.bool,
    link: PropTypes.string,
    theme: PropTypes.object
  };

  render() {
    const { children, isActive, link, theme, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        active={isActive ? 1 : 0}
        to={link}
        theme={theme}
      >
        {children}
      </Wrapper>
    );
  }
}

export default styled(PageItem)``;
