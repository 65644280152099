import React from 'react';
import PropTypes from 'prop-types';
import { computed, observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { typography } from 'theme/mixins';

import { BlockShadow, Block, Text } from 'components/atoms';
import { Button } from 'components/forms';

import { dateFormat } from 'utils';

const Body = styled.div`
  ${Text} {
    color: #4a515c;
    ${typography(16, 20, 700)};
  }

  ${Block} {
    margin-top: 20px;

    + ${Block} {
      margin-top: 18px;
    }

    ${Text} {
      ${typography(14, 16, 500)};

      + ${Text} {
        margin-top: 8px;
        ${typography(14, 16)};
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;

  ${Button} + ${Button} {
    margin-left: 36px;
  }
`;

const Wrapper = styled(BlockShadow)`
  padding: 20px 16px;

  ${Body} + ${Footer} {
    margin-top: 8px;
  }
`;

@inject('showState')
@observer
class PaymentInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    showState: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @observable isLoading = undefined

  setLoading(value) {
    this.isLoading = value;
  }

  unsetLoading() {
    this.isLoading = undefined;
  }

  @computed get showDownloadVoucherBtn() {
    const { showState } = this.props;
    return showState.isConfirmed;
  }

  @computed get showDownloadBillButton() {
    const { showState } = this.props;
    return showState.hasInvoice;
  }

  handleDownloadVoucher = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;

    this.setLoading('voucher');
    await showState.downloadVoucher();
    this.unsetLoading();
  }

  handleDownloadBill = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;

    this.setLoading('invoice');
    await showState.downloadInvoice();
    this.unsetLoading();
  }

  render() {
    const {
      showState: { values },
      t,
      className
    } = this.props;

    const {
      gross_price,
      net_price,
      discount,
      discount_in_percent,
      payment_info: { payment_method, payment_expired_at, payment_required_at, free_cancellation_until }
    } = values;

    const paymentRequiredAt = payment_expired_at || payment_required_at;

    return (
      <Wrapper className={className}>
        <Body>
          <Text>
            {t('Order.PaymentInfo.Title')}
          </Text>

          <Block>
            <Text>
              {t('Order.PaymentMethod.Label')}
            </Text>

            <Text>
              {t('Order.PaymentMethod.Value', { type: payment_method })}
            </Text>
          </Block>

          <Block>
            <Text>
              {t('Order.PaymentInfo.PayTill')}
            </Text>

            {paymentRequiredAt
              ? <Text>{dateFormat(paymentRequiredAt, 'LL')}</Text>
              : <Text>&mdash;</Text>
            }
          </Block>

          <Block>
            <Text>
              {t('Order.PaymentInfo.GrossPrice')}
            </Text>

            <Text>
              {t('UI.Price', { price: gross_price })}
            </Text>
          </Block>

          <Block>
            <Text>
              {t('Order.PaymentInfo.Discount')}
            </Text>

            <Text>
              {t('UI.Price', { price: discount })}
              &nbsp;&nbsp;&nbsp;
              {t('UI.Percent', { value: discount_in_percent })}
            </Text>
          </Block>

          <Block>
            <Text>
              {t('Order.PaymentInfo.NetPrice')}
            </Text>

            <Text>
              {t('UI.Price.Sum', { price: net_price })}
            </Text>
          </Block>

          <Block>
            <Text>
              {t('Order.PaymentInfo.FreeCancel')}
            </Text>

            {free_cancellation_until
              ? <Text>{dateFormat(free_cancellation_until, 'LL  HH:mm (Z)')}</Text>
              : <Text>&mdash;</Text>
            }
          </Block>
        </Body>

        <Footer>
          {this.showDownloadVoucherBtn && (
            <Button
              compact
              loading={this.isLoading === 'voucher' ? 'true' : undefined}
              onClick={this.handleDownloadVoucher}
            >
              {t('Order.DownloadVoucher')}
            </Button>
          )}

          {this.showDownloadBillButton && (
            <Button
              compact
              loading={this.isLoading === 'invoice' ? 'true' : undefined}
              onClick={this.handleDownloadBill}
            >
              {t('Order.DownloadBill')}
            </Button>
          )}
        </Footer>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentInfo))``;
