import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

const Text = styled.div`
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Dates = styled.div`
`;

const Rooms = styled.div`
  width: auto;
  height: 17px;
  padding: 0 8px;
  border-radius: 9999px;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 500;
  background-color: #5AB2D6;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #6B707B;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 16px;
  border: 1px solid #BFC6D3;
  border-radius: 4px;
  background-color: #E5E8ED;
  box-sizing: border-box;
  cursor: pointer;

  ${({ isActive }) => isActive && css`
    border: 2px solid #5AB2D6;
    background-color: #E3EEF3;
    box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  `}

  ${Header} + ${Body} {
    margin-top: 12px;
  }
`;

@inject('showState')
@observer
class Group extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get isActive() {
    const { group, showState } = this.props;
    return showState.selectedGroup?.id === group.id;
  }

  handleSelect = (e) => {
    e.preventDefault();

    const { group, showState } = this.props;
    showState.selectGroup(group);
  }

  render() {
    const { view, group, t, className } = this.props;

    const { dates_of_stay, rooms_count, tariff, room_type } = group;

    return (
      <Wrapper
        className={className}
        isActive={this.isActive}
        onClick={this.handleSelect}
      >
        <Header>
          <Dates>
            {dates_of_stay}
          </Dates>
          <Rooms>
            {t('Orders.Rooms', { count: rooms_count })}
          </Rooms>
        </Header>

        {view === 'tariff' && (
          <Body>
            <Text>
              {room_type.name}
            </Text>
            <Text>
              {tariff.name}
            </Text>
          </Body>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Group))``;
