import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { display, typography } from 'theme/mixins';

import { Fetch, Image } from '.';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${display('flex', 'center')}
  flex-direction: column;
  padding: 8px;
`;

const Content = styled.div`
  padding: 12px 0;
  ${typography(14, 22)}
  text-align: center;
`;

const Control = styled.div`
  padding: 12px 0;
`;

const Container = styled.div`
  ${display('flex', 'center', 'center')}
  flex-direction: column;
  flex-grow: 1;
`;

@withTheme
class PagePlaceholder extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static Container = Container;

  static Content = Content;

  static Control = Control;

  static Fetch = Fetch;

  static Image = Image;

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(PagePlaceholder)``;
