import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import capitalize from 'utils/capitalize';

import { useTranslation } from 'react-i18next';

import List from '../List';

const Wrapper = styled.div``;

function Feed({ className, tariff: { meal_option, feed_type }, ...rest }) {
  const { t } = useTranslation();

  let feedOptions = [meal_option, feed_type]
    .filter(item => item)
    .map(item => item.name.toLowerCase())
    .join(', ');

  feedOptions = [{ name: capitalize(feedOptions) }];

  return (
    <Wrapper className={className}>
      <List
        items={feedOptions}
        title={t('Tariff.FeedType')}
        {...rest}
      />
    </Wrapper>
  );
}

Feed.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object
};

export default styled(Feed)``;
