import { t } from 'utils/localization';

export const contactsItems = [
  {
    name: 'email',
    label: t('Auth.Register.LegalEntity.Email.Label'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'email2',
    label: t('Auth.Register.LegalEntity.Email.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'phone',
    label: t('Auth.Register.Manager.Phone.Label'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];

export const accoutingItems = [
  {
    name: 'accountant.full_name',
    label: t('Auth.Register.LegalEntity.Accounting.Label'),
    placeholder: t('Auth.Register.Manager.FIO.Placeholder')
  },
  {
    name: 'accountant.email',
    label: t('Auth.Register.LegalEntity.Accounting.Label2'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder2')
  },
  {
    name: 'accountant.phone',
    label: t('Auth.Register.LegalEntity.Accounting.Label3'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];

export const actualAddressItems = [
  {
    name: 'actual_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6')
  },
  {
    name: 'actual_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label')
  },
  {
    name: 'actual_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2')
  },
  {
    name: 'actual_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'actual_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'actual_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder')
  }
];

export const legalAddressItems = [
  {
    name: 'legal_address.country',
    label: t('Auth.Register.Addresses.Label6'),
    placeholder: t('Auth.Register.Addresses.Label6')
  },
  {
    name: 'legal_address.city',
    label: t('Auth.Register.Addresses.Label'),
    placeholder: t('Auth.Register.Addresses.Label')
  },
  {
    name: 'legal_address.street',
    label: t('Auth.Register.Addresses.Label2'),
    placeholder: t('Auth.Register.Addresses.Label2')
  },
  {
    name: 'legal_address.building_number',
    label: t('Auth.Register.Addresses.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_address.office_number',
    label: t('Auth.Register.Addresses.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_address.postal_code',
    label: t('Auth.Register.Addresses.Label5'),
    placeholder: t('Auth.Register.Addresses.Placeholder')
  }
];
