import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Search } from 'components/icons';
import { typography, display, ruiClass } from 'theme/mixins';
import { Button, Input } from 'components/forms';

const SearchIcon = styled(Search)`
  font-size: 25px;
`;

const Wrapper = styled.div`
  ${display('flex', 'center', 'space-between')};
  padding: 36px 0 24px 0;
`;

const InnerWrapper = styled.div`
  ${display('flex', 'flex-end', 'space-between')};
`;

const Title = styled.div`
  color: #9cb4ca;
  ${typography(40, 48, 700)};
`;

const ButtonS = styled(Button)`
  ${ruiClass('rui-Button-button')} {
    ${ruiClass('rui-Button-content', 'span')} {
      height: 41px;
      padding: 0 22px;
      ${typography(13, 41, 500)};
    }
  }
`;

const SearchControl = styled(Input)`
  margin-left: 32px !important;

  ${ruiClass('rui-Input-root')} {
    margin-left: 51px;

    ${ruiClass('rui-Input-input', 'input')} {
      height: 42px;
      width: 317px;
    }
  }
`;

@withTranslation()
@withRouter
@inject('filtersState')
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    t: PropTypes.func,
    filtersState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleOrderCreate = (event) => {
    event.preventDefault();

    const { history } = this.props;
    history.push('/orders/new');
  };

  render() {
    const { filtersState, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <InnerWrapper>
          <Title>
            {t('Orders.Title')}
          </Title>

          <SearchControl
            iconLeft={<SearchIcon />}
            field={filtersState.$('chars')}
          />
        </InnerWrapper>

        <ButtonS onClick={this.handleOrderCreate}>
          {t('Orders.NewOrder')}
        </ButtonS>
      </Wrapper>
    );
  }
}

export default styled(Top)``;
