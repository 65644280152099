import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import { List } from 'react-content-loader';

const Message = styled.div`
  width: 90%;
  padding: 20px 10px;
  margin-bottom: 14px;

  ${({ to }) => !!to && css`
    align-self: flex-start;
    background-color: #5AB2D6;
    border-radius: 0 14px 14px 14px;
    box-shadow: 0 3px 5px 0 rgba(94,202,247,0.46);
  `}

  ${({ from }) => !!from && css`
    align-self: flex-end;
    border-radius: 14px 0 14px 14px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 14px;
`;

@observer
class Loader extends Component {
  render() {
    return (
      <Wrapper>
        <Message from>
          <List />
        </Message>
        <Message to>
          <List />
        </Message>
        <Message from>
          <List />
        </Message>
        <Message to>
          <List />
        </Message>
      </Wrapper>
    );
  }
}

export default Loader;
