import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { typography } from 'theme/mixins';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  color: #6b707b;
  ${typography(14, 16)};
`;

class Text extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

Text.propTypes = {
  className: PropTypes.string,
  children: ReactComponentChildrenPropType.isRequired
};

Text.defaultProps = {
  className: ''
};

export default styled(Text)``;
