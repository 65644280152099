import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import Top from './Top';
import Invoices from '../Invoices';
import Revise from '../Revise';
import PaymentHistory from '../PaymentHistory';
import ClosingDocuments from '../ClosingDocuments';
import ActsOfResidence from '../ActsOfResidence';

const Content = styled.div`
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 980px;
    margin: 0 auto;
    padding: 0 10px;
  `}
`;

function Body({ className }) {
  return (
    <Wrapper className={className}>
      <Top />

      <Content>
        <Switch>
          <Redirect
            exact
            from='/reports'
            to='/reports/invoices'
          />

          <Route
            exact
            path='/reports/invoices'
            component={Invoices}
          />

          <Route
            exact
            path='/reports/revise'
            component={Revise}
          />

          <Route
            exact
            path='/reports/payment_history'
            component={PaymentHistory}
          />

          <Route
            exact
            path='/reports/closing_documents'
            component={ClosingDocuments}
          />

          <Route
            exact
            path='/reports/acts_of_residence'
            component={ActsOfResidence}
          />
        </Switch>
      </Content>
    </Wrapper>
  );
}

Body.propTypes = {
  className: PropTypes.string
};

export default styled(Body)``;
