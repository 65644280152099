import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { computed } from 'mobx';
import { Provider, observer } from 'mobx-react';

import { BackLink, Line } from 'components/ui';
import SearchState from 'forms/Hotels/SearchState';
import OrderStore from 'stores/OrdersStore/One';
import HotelsStore from 'stores/HotelsStore/SearchStore';
import { OrderForm, FavoriteHotels } from './components';
import { withTranslation } from 'react-i18next';

const Title = styled.div`
  color: #9CB4CA;
  font-family: "Helvetica Neue";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 49px;
`;

const Wrapper = styled.div`
  width: 960px;
  height: 100%;
  margin: 0 auto;

  ${BackLink} {
    margin: 40px 0 0px;
    display: inline-block;
  }

  ${Line} {
    margin: 16px 0 22px;
  }

  ${Title} {
    margin-top: 8px;
    margin-bottom: 20px;
  }
`;

@withTranslation()
@withRouter
@observer
class NewOrder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    favoriteHotelsStore: PropTypes.object,
    history: ReactRouterPropTypes.history.isRequired,
    orderStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    // Initialize form
    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.searchState = new SearchState({}, { hooks });
    this.hotelsStore = HotelsStore.create();
    this.orderStore = OrderStore.create();
  }

  componentDidMount() {
    this.hotelsStore.favorites();
  }

  componentWillUnmount() {
    this.hotelsStore.clear();
  }

  @computed get hasError() {
    const { isValid, submitted } = this.searchState;
    return !isValid && submitted > 0;
  }

  @computed get isChanged() {
    return this.searchState.changed;
  }

  handleSuccess = (form) => {
    const values = form.values();

    this.orderStore.create(values)
      .then(store => this.navigateTo(store));
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  navigateTo = (store) => {
    const { history } = this.props;

    const url = this.searchState.$('hotel.id').value
      ? `/orders/${store.data.id}/edit`
      : `/orders/${store.data.id}/search`;

    history.replace(url);
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Provider
        hotelsStore={this.hotelsStore}
        orderStore={this.orderStore}
        searchState={this.searchState}
      >
        <Wrapper {...rest}>
          <BackLink />

          <Title>
            {t('Orders.NewOrder')}
          </Title>

          <OrderForm hasError={this.hasError} />

          <FavoriteHotels />
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(NewOrder)``;
