import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'connection/instance';

const unblockUser = createAsyncThunk('users/unblock', async (user) => {
  const { data: response } = await client.post(`/api/users/${user.id}/unblock`);
  const { id, ...changes } = response.data;

  return { id, changes };
});

export default unblockUser;
