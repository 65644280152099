import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Item } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    cursor: default;
    color: ${theme.colors.text.disabled};

    ${Item} + ${Item} {
      ::before {
        content: "/";
        margin: 0 8px;
      }
    }
  `}
`;

@withRouter
class Breadcrumbs extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string
    })),
    location: ReactRouterPropTypes.location
  };

  static defaultProps = {
    className: ''
  }

  get items() {
    const { items, location } = this.props;

    return items
      .reduce(
        (result, item) => {
          if (!result.find(item => item.isActive)) {
            result.push({
              ...item,
              ...(item.url === location.pathname && { isActive: true })
            });

            return result;
          }

          return result;
        },
        []
      );
  }

  render() {
    const { location, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {this.items.map(({ isActive, label, url }, index) => (
          <Item
            key={index}
            isActive={isActive}
            label={label}
            url={url}
          />
        ))}
      </Wrapper>
    );
  }
}

export default styled(Breadcrumbs)``;
