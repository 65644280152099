import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { dateFormat } from 'utils';

const Label = styled.div`
  color: #6B707B;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 16px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 134px 152px 160px 124px repeat(3, 1fr);
`;

@inject('showState')
@observer
class Reservations extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState, t, className } = this.props;

    const {
      order: {
        order_code,
        created_at,
        payment_info: { payment_expired_at, free_cancellation_until }
      },
      values
    } = showState;

    const {
      gross_price,
      net_price,
      discount,
      discount_in_percent
    } = values;

    return (
      <Wrapper className={className}>
        <Cell>
          <Label>
            {t('Orders.TableItem.OrderID')}
          </Label>
          <Value>
            {order_code}
          </Value>
        </Cell>

        <Cell>
          <Label>
            {t('Orders.TableItem.CreatedAt')}
          </Label>
          <Value>
            {dateFormat(created_at, 'D-MM-YYYY')}
          </Value>
        </Cell>

        <Cell>
          <Label>
            {t('Order.PaymentInfo.PayTill')}
          </Label>

          {payment_expired_at
            ? <Value>{dateFormat(payment_expired_at, 'DD-MM-YYYY')}</Value>
            : <Value>&mdash;</Value>
          }
        </Cell>

        <Cell>
          <Label>
            {t('Order.PaymentInfo.NetPrice')}
          </Label>
          <Value>
            {t('UI.Price.Sum', { price: net_price })}
          </Value>
        </Cell>

        <Cell>
          <Label>
            {t('Order.PaymentInfo.Discount')}
          </Label>
          <Value>
            {t('UI.Price', { price: discount })}
            &nbsp;&nbsp;
            {t('UI.Percent', { value: discount_in_percent })}
          </Value>
        </Cell>

        <Cell>
          <Label>
            {t('Order.PaymentInfo.GrossPrice')}
          </Label>
          <Value>
            {t('UI.Price', { price: gross_price })}
          </Value>
        </Cell>

        <Cell>
          <Label>
            {t('Order.PaymentInfo.FreeCancel')}
          </Label>

          {free_cancellation_until
            ? <Value>{dateFormat(free_cancellation_until, 'DD-MM-YYYY')}</Value>
            : <Value>&mdash;</Value>
          }
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservations))``;
