import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
`;

const Action = styled.div`
  cursor: pointer;
  padding: 4px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  ${Text} + ${Action} {
    margin-left: 2px;
  }
`;

const Badge = styled.div`
  display: inline-flex;
  width: auto;
  height: 28px;
  padding: 0 12px;
  line-height: 28px;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 14px;
  user-select: none;
`;

const variants = {
  created: (theme) => css`
    ${Badge} {
      background-color: #CCD5E1;
      color: #6B707B;
    }

    ${Message} {
      color: #6B707B;
    }
  `,
  booked: (theme) => css`
    ${Badge} {
      background-color: #DFF0FF;
      color: #76A6D3;
    }

    ${Message} {
      color: ${theme.colors.text.secondary};
    }
  `,
  confirmed: (theme) => css`
    ${Badge} {
      background-color: #CBF5DF;
      color: #519370;
    }

    ${Message} {
      color: #6B707B;
    }
  `,
  cancelled: (theme) => css`
    ${Badge} {
      background-color: transparent;
      color: #979BA0;
    }

    ${Message} {
      color: #E26161;
    }
  `,
  archived: (theme) => css`
    ${Badge} {
      background-color: transparent;
      color: #979BA0;
    }

    ${Message} {
      color: #E26161;
    }
  `,
  warning: (theme) => css`
    ${Badge} {
      background-color: #efd9ab;
      color: #977C3A;
    }

    ${Message} {
      color: #C48212;
    }
  `,
  error: (theme) => css`
    ${Badge} {
      background-color: #FFE7E7;
      color: #E26161;
    }

    ${Message} {
      color: #E26161;
    }
  `,
  'need-accept': (theme) => css`
    ${Badge} {
      background-color: ${theme.colors.neutral.secondary};
      color: ${theme.colors.neutral.primary};
    }

    ${Message} {
      color: #c48212;
    }
  `
};

const Wrapper = styled.div`
  ${({ direction }) => direction === 'row' && css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    ${Message} {
      padding: 0 14px;
    }
  `}

  ${({ direction }) => direction === 'column' && css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    ${Message} {
      margin: 8px 0 0 8px;
    }
  `}

  ${({ variant, theme }) => variant && variants[variant]?.(theme)}

  ${Badge} {
    flex-grow: 0;
  }
`;

@withTheme
@observer
class OrderState extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    state: PropTypes.shape({
      code: PropTypes.string.isRequired,
      message: PropTypes.string
    }).isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { state: { code }, t, className } = this.props;

    return (
      <Wrapper
        variant={code}
        className={className}
      >
        <Badge>
          {t('Orders.Status', { code })}
        </Badge>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(OrderState))``;
