import { t } from 'utils/localization';

const fields = [
  'manager',
  'manager.id',
  'manager.name'
];

const placeholders = {
  manager: t('Orders.Filters.Managers.Label')
};

export default {
  fields,
  placeholders
};
