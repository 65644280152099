import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #4a515c;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
  width: 465px;
  margin-top: 8px;

  .green {
    color: #3cc47d;
  }
`;

function CommonPart({ className, children, title, subtitle }) {
  return (
    <Wrapper className={className}>
      <div>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>
      <Content>{children}</Content>
    </Wrapper>
  );
}

CommonPart.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default CommonPart;
