import instance from 'connection/instance';

class AmenitiesStore {
  async fetch(params = {}) {
    const { data: { data } } = await instance.get('/api/filters/location_types', { params });
    return { meta: {}, data };
  }
}

export default AmenitiesStore;
