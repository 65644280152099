import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Badge from 'components/ui/Badge';

const Description = styled.div`
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AgentDescription = styled.div`
  color: #3AA6D2;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
`;

const AgentPrice = styled.div`
  color: #3AA6D2;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

const TouristDescription = styled.div`
  color: #4A515C;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 12px;
`;

const TouristPrice = styled.div`
  color: #4A515C;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 12px;
`;

const ComisionDescription = styled.div`
  color: #4A515C;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
`;

const ComissionPrice = styled.div`
  border-radius: 3px;
  background-color: #CDEFFF;
  padding: 0 2px;
  box-sizing: border-box;
  height: 18px;
  margin-top: 8px;
  color: #4A515C;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #3AA6D2;
  border-radius: 4px;
  background-color: #EFFAFF;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${Container} + ${Action} {
    margin-top: 12px;
  }

  ${Badge} + ${Description} {
    margin-left: 14px;
  }
`;

@inject('showState', 'commissionVisibility')
@observer
class Total extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      showState: { values: { net_price, gross_price, discount_in_percent } },
      t,
      className
    } = this.props;

    const discount = gross_price - net_price;

    return (
      <Wrapper className={className}>
        <Container>
          <Row>
            <AgentDescription>
              {t('Orders.Hotel.Badge.NetPrice.Agent')}
            </AgentDescription>

            <AgentPrice>
              {t('UI.Price', { price: net_price })}
            </AgentPrice>
          </Row>

          <Row>
            <TouristDescription>
              {t('Orders.Hotel.NetPrice.Tourist')}
            </TouristDescription>

            <TouristPrice>
              {t('UI.Price', { price: gross_price })}
            </TouristPrice>
          </Row>

          <Row>
            <ComisionDescription>
              {t('Orders.Hotel.NetPrice.Comission')}
            </ComisionDescription>

            <ComissionPrice>
              {discount_in_percent}% {t('UI.Price', { price: discount })}
            </ComissionPrice>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Total))``;
