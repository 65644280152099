import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useBankInfo = (value) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        setError(null);
        setLoading(true);
        const {
          data: { data }
        } = await instance.get(`/api/filters/banks?data[bik]=${value}`);

        setData(data);
      } catch (e) {
        console.log(e, 'err');
        setError(e?.response?.data?.messages?.[0]);
      } finally {
        setLoading(false);
      }
    };

    if (value && value?.length === 9) {
      fetch();
    }
  }, [value]);

  return { data, loading, error };
};

export default useBankInfo;
