import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useValue } from '../hooks';

import Guests from '../Guests';
import Ages from '../Ages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 327px;
  border: 1px solid #5ab2d6;
  border-radius: 4px;
  box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 15px;

  ${Guests} + ${Guests} {
    margin-top: 18px;
  }

  ${Guests} + ${Ages} {
    margin-top: 16px;
  }
`;

function GuestsPicker({ value: initialValue, onSelect, className }) {
  const { t } = useTranslation();

  const [
    { value, occupations: { adults, childs } },
    { setValue, setChilds }
  ] = useValue({ initialValue });

  const isMounted = useRef();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    onSelect(value);
  }, [value]);

  const handleSelect = (ageGroup) => (item) => {
    setValue({ ageGroup, count: item.count });
  };

  const handleAgeChange = (items) => {
    setChilds(items);
  };

  return (
    <Wrapper className={className}>
      <Guests
        title={t('Common.Adults')}
        items={adults}
        // eslint-disable-next-line no-use-before-define
        onSelect={handleSelect('adult')}
      />

      <Guests
        title={t('Common.Childs')}
        items={childs}
        // eslint-disable-next-line no-use-before-define
        onSelect={handleSelect('child')}
      />

      <Ages
        title={t('Common.SelectChildAge')}
        items={value}
        onChange={handleAgeChange}
      />
    </Wrapper>
  );
}

GuestsPicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line no-use-before-define
      age_group: PropTypes.oneOf(['adult', 'child']).isRequired,
      age: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onSelect: PropTypes.func
};

GuestsPicker.defaultProps = {
  value: [],
  onSelect: () => { }
};

export default styled(GuestsPicker)``;
