import React from 'react';
import styled from 'styled-components';

import { Map } from './components';
import FiltersBar from 'components/ui/FilterBar';

const FilterContainer = styled.div`
  padding: 0 30px;
  background: #fff;
  z-index: 1;
  position: relative;
`;

const Wrapper = styled.div`
  height: calc(100% - 72px);
`;

function Hotels({ ...rest }) {
  return (
    <Wrapper {...rest}>
      <FilterContainer>
        <FiltersBar {...rest} />
      </FilterContainer>

      <Map {...rest} />
    </Wrapper>
  );
}

export default styled(Hotels)``;
