import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Reservations from './Reservations';
import Total from './Total';
import { getTaxObject } from 'utils/getTaxesRules/getTaxesRules';

const Header = styled.div`
  color: #4a515c;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 30px;
`;

const Count = styled.div`
  height: 13px;
  margin-top: -2px;
  margin-left: 2px;
  border-radius: 4px;
  background-color: #5ab2d6;
  padding: 0 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
`;

const Body = styled.div`
  padding: 0 30px;
`;

const Footer = styled.div`
  width: inherit;
  padding: 24px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: 26px 0 0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
  box-sizing: border-box;

  ${HeaderContainer} + ${Body} {
    margin-top: 15px;
  }
`;

const Agreement = styled.div`
  color: #979ba0;
  margin-top: 15px;
  font-family: 'Helvetica Neue';
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  p + p {
    margin-top: 4px;
  }

  a {
    cursor: pointer;
    color: #3aa6d2;
  }

  b {
    font-weight: 600;
    color: #6b707b;
  }

  h4 {
    color: #e26161;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }

  h4 + p {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

const TaxBox = styled.div`
  padding: 16px 24px;
  background: #f6f8fb;
  border: 1px solid #d1d5db;
  border-radius: 4px;
`;

const TaxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #4a515c;
`;

const TaxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const DescriptionText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #4a515c;
`;

@inject('showState')
@observer
class Center extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object,
    hotel: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, showState, ...rest } = this.props;
    const {
      selectedGroup: { reservations },
      order: { hotel }
    } = showState;
    const isHotels = hotel?.taxes?.length > 0;
    const { conditionDescription, name, paymentDescription, price } = isHotels && getTaxObject(hotel);

    return (
      <Wrapper {...rest}>
        <Content>
          <HeaderContainer>
            <Header>{t('Order.OrderInfo.Reservations.Title')}</Header>

            {reservations.length > 1 && <Count>{reservations.length}</Count>}
          </HeaderContainer>

          <Body>
            <Reservations />
          </Body>

          <Footer>
            <Total />
            {isHotels && (
              <TaxBox>
                <TaxTitle>{name}</TaxTitle>
                <TaxContent>
                  <DescriptionText>{`${paymentDescription} ${conditionDescription}`}</DescriptionText>
                  <DescriptionText>{price}</DescriptionText>
                </TaxContent>
              </TaxBox>
            )}
          </Footer>
        </Content>

        <Agreement dangerouslySetInnerHTML={{ __html: t('Order.Agreement') }} />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Center))``;
