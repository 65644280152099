import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { BlockShadow } from 'components/atoms';
import { ReactComponent as Statistics11 } from './assets/statistics11.svg';
import { ReactComponent as Statistics12 } from './assets/statistics12.svg';
import { ReactComponent as Statistics13 } from './assets/statistics13.svg';
import { ReactComponent as Statistics21 } from './assets/statistics21.svg';
import { ReactComponent as Statistics22 } from './assets/statistics22.svg';
import { Top } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 1016px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

const Row = styled.div`
  ${display('flex', null, 'space-between')}

  :not(:first-child) {
    margin-top: 10px;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Column = styled(BlockShadow)`
  flex-grow: 1;

  :not(:first-child) {
    margin-left: 10px;
  }

  :not(:last-child) {
    margin-right: 10px;
  }
`;

const Statistics11S = styled(Statistics11)`
  width: 264px;
  height: 116px;
`;

const Statistics12S = styled(Statistics12)`
  width: 264px;
  height: 116px;
`;

const Statistics13S = styled(Statistics13)`
  width: 272px;
  height: 116px;
`;

const Statistics21S = styled(Statistics21)`
  width: 406px;
  height: 252px;
`;

const Statistics22S = styled(Statistics22)`
  width: 452px;
  height: 280px;
`;

@withTheme
class Statistics extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Top />

        <Row>
          <Column>
            <Statistics11S />
          </Column>

          <Column>
            <Statistics12S />
          </Column>

          <Column>
            <Statistics13S />
          </Column>
        </Row>

        <Row>
          <Column>
            <Statistics21S />
          </Column>

          <Column>
            <Statistics22S />
          </Column>
        </Row>
      </Wrapper>
    );
  }
}

export default styled(Statistics)``;
