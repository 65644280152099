import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import _compact from 'lodash/compact';

import Photos from './Photos';
import Feed from './Feed';
import Manipulation from './Manipulation';
import Cancellation from './Cancellation';
import Tabs from './Tabs';
import Header from './Header';
import Documents from './Documents';
import Map from './Map';

import { typography } from 'theme/mixins';

const SubTitle = styled.div`
  ${typography(14, 19, 500)};
`;

const InformationWrapper = styled.div`
  max-width: 565px;
  min-width: 540px;

  ${Photos} {
    flex-shrink: 0;
  }
`;

const Information = styled.div`
  color: #4c4c4c;
  padding-top: 7px;
  ${typography(13, 19)};
`;

const Special = styled.div`
  padding-top: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 28px;
`;

const Label = styled.div`
  color: #4c4c4c;
  width: 330px;
  ${typography(13, 16)};
`;

const Features = styled.div`
  margin-top: 16px;
`;

const PhotosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 261px;
  gap: 16px;
`;

const Content = styled.div`
  padding-bottom: 28px;
  border-bottom: 1px solid #d1d5db;
`;

const Wrapper = styled.div`
  width: inherit;
  height: auto;
  padding: 26px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  ${Feed}, ${Manipulation}, ${Cancellation} {
    margin-top: 20px;
  }
`;

function Description({
  tariff: { manipulation, service, rates, meal_disclaimer, meal_option },
  hotel,
  room_type,
  seats,
  tariff,
  bookingForm,
  ...rest
}) {
  const services = [...manipulation.options, ...service.options];
  const { t } = useTranslation();

  const ratesArray =
    rates.filter((rate) => rate.occupation === 'main' && rate.count === 0).length > 0
      ? [t('Tariff.Features.Sharing')]
      : [];
  const disclaimers = _compact([service?.disclaimer, manipulation?.disclaimer, meal_disclaimer]);

  const features = [...ratesArray, ...disclaimers];

  return (
    <Wrapper {...rest}>
      <Content>
        <Header />

        <ContentWrapper>
          <InformationWrapper>
            <Information>
              <Tabs roomType={room_type} />
            </Information>

            {services.length > 0 && <Manipulation services={services} />}

            {meal_option && <Feed tariff={tariff} />}

            <Cancellation tariff={tariff} bookingForm={bookingForm} checkInTime={hotel?.check_in_time} />

            {features.length > 0 && (
              <Features>
                <SubTitle>{t('Tariff.Features.Title')}</SubTitle>

                {features.map((label, idx) => (
                  <Special key={idx}>
                    <Label>{label}</Label>
                  </Special>
                ))}
              </Features>
            )}
          </InformationWrapper>

          <PhotosWrapper>
            {room_type.images.length > 0 && <Photos images={room_type.images} />}
            {hotel?.attentions?.length > 0 && <Documents attention={hotel?.attentions[0]} />}
            {hotel?.distances.length > 0 && <Map hotel={hotel} />}
          </PhotosWrapper>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
}

Description.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  room_type: PropTypes.object,
  tariff: PropTypes.object,
  t: PropTypes.func,
  seats: PropTypes.object,
  bookingForm: PropTypes.object
};

export default styled(Description)``;
