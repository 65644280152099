import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { Stub as CarouselGalleryStub } from 'components/ui/CarouselGallery/components';
import { Block } from 'components/atoms';

const Wrapper = styled.div``;

const GalleryStubS = styled(CarouselGalleryStub)`
  margin: 24px 0 8px;
`;

const TitleStubS = styled.div`
  width: 50%;
  height: 20px;

  ${({ theme }) => css`
    background: ${theme.colors.passive.secondary};
  `}
`;

const AddressStubS = styled.div`
  height: 20px;
  margin-top: 12px;

  ::before,
  ::after {
    content: "";
    display: block;
    height: 14px;
    margin: 2px 0;

    ${({ theme }) => css`
      background: ${theme.colors.passive.secondary};
    `}
  }

  ::before {
    width: 85%;
  }

  ::after {
    width: 67%;
  }
`;

@withTheme
class Stub extends React.Component {
  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Block>
          <TitleStubS />
        </Block>
        <AddressStubS />
        <GalleryStubS />
      </Wrapper>
    );
  }
}

Stub.propTypes = {
  className: PropTypes.string,
  thheme: PropTypes.object
};

export default styled(Stub)``;
