import BaseState from 'forms/BaseState';
import fields from './fields';

class ChatForm extends BaseState {
  setup() {
    return fields;
  }
}

export { fields };

export default ChatForm;
