import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  status: 'idle'
});

export { initialState };

export default adapter;
