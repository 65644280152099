import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';

import Hotel from './Hotel';
import { Groups, PaymentInfo } from 'pages/Orders/components';

const Wrapper = styled.div`
  ${Hotel} + ${PaymentInfo} {
    margin-top: 20px;
  }

  ${PaymentInfo} + ${Groups} {
    margin-top: 30px;
  }
`;

@inject('showState')
@observer
class Sidebar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get showGroups() {
    const { showState: { groupBy } } = this.props;
    return groupBy !== 'reservation';
  }

  render() {
    const { showState, ...rest } = this.props;

    const {
      values: { groups },
      order: { hotel },
      groupBy
    } = showState;

    return (
      <Wrapper {...rest}>
        <Hotel
          hotel={hotel}
        />

        <PaymentInfo />

        {this.showGroups && (
          <Groups
            view={groupBy}
            groups={groups}
          />
        )}
      </Wrapper>
    );
  }
}

export default styled(Sidebar)``;
