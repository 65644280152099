import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CommonPart from '../CommonPart';
import DropDown from './DropDown';

const DropdownComponent = styled(DropDown)``;

const Content = styled.div``;

const Wrapper = styled.div`
  ${DropdownComponent} + ${DropdownComponent} {
    margin-top: 20px;
  }
`;

function ProgramsContent({ className, hotel: { feeding_programs } }) {
  const [openedProgramId, setOpenedProgramId] = useState();

  const { t } = useTranslation();

  const handleOpen = (id) => () => {
    if (openedProgramId === id) {
      setOpenedProgramId(null);
      return;
    }

    setOpenedProgramId(id);
  };

  const meal = feeding_programs.map((program) => (
    <DropdownComponent
      program={program}
      key={program.id}
      onClick={handleOpen(program.id)}
      opened={program.id === openedProgramId}
    />
  ));

  return (
    <Wrapper className={className}>
      <CommonPart title={t('Hotel.Details.Tabs.Meal')}>
        <Content>{meal}</Content>
      </CommonPart>
    </Wrapper>
  );
}

ProgramsContent.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default ProgramsContent;
