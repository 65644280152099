import { createSlice } from '@reduxjs/toolkit';

import notificationsAdapter, { initialState } from './adapter';
import { fetchAll, addOne, removeOne } from './actions';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAll.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        notificationsAdapter.setAll(state, action.payload);
        state.status = 'idle';
      })
      .addCase(addOne.fulfilled, notificationsAdapter.addOne)
      .addCase(removeOne.fulfilled, notificationsAdapter.removeOne);
  }
});

export default notificationsSlice.reducer;
