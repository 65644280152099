import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  Check as CheckIcon,
  Close as CloseIcon
} from 'components/icons';

const Icons = {
  success: <CheckIcon />,
  error: <CloseIcon />
};

const Icon = styled.div`
  font-size: 10px;
  color: inherit;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.17);

  ${({ type }) => type === 'success' && css`
    background-color: #5AB2D6;
    color: #FFFFFF;
  `}

  ${({ type }) => type === 'error' && css`
    background-color: #FFB3B3;
    color: #4A515C;
  `}
`;

function Notification({ notification, onRemove, className }) {
  const { type, time, message } = notification;

  useEffect(() => {
    let _timer = null;

    if (time <= 0) {
      return null;
    }

    _timer = setTimeout(() => onRemove(notification), time * 1000);

    return () => clearTimeout(_timer);
  }, [time]);

  const handleClick = (e) => {
    onRemove(notification);
  };

  return (
    <Wrapper
      type={type}
      onClick={handleClick}
      className={className}
    >
      <Message>
        {message}
      </Message>

      <Icon>
        {Icons[type]}
      </Icon>
    </Wrapper>
  );
}

Notification.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.object.isRequired,
  onRemove: PropTypes.func
};

export default styled(Notification)``;
