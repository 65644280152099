import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

const Label = styled.div`
  height: 14px;
  color: #6B707B;
  font-size: 12px;
  line-height: 14px;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 16px;

  ${p => p.empty && css`
    color: #979BA0;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${Label} + ${Value} {
    margin-top: 6px;
  }
`;

const Img = styled.div`
  width: 50px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0 14px;
  border-bottom: 1px solid #D1D5DB;

  ${Content} {
    flex-grow: 1;
  }

  ${Img} + ${Content} {
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 284px 160px 124px repeat(4, 1fr);
`;

@observer
class Reservation extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  renderGuests(items) {
    const { t } = this.props;

    return items.length > 0
      ? items.map(item => <Value key={item}>{item}</Value>)
      : <Value empty>{t('Order.Traveller.NoTraveller')}</Value>;
  }

  render() {
    const {
      reservation: { room_type, tariff, seats },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <Cell>
          <Content>
            <Label>
              Тип номера
            </Label>
            <Value>
              {room_type.name}
            </Value>
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              Тариф
            </Label>
            <Value>
              {tariff.name}
            </Value>
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.Seats')}
            </Label>
            <Value>
              {t('Orders.TableItem.Seats.Main', seats.main)}
            </Value>
            <Value>
              {seats.extra.count > 0 && (
                t('Orders.TableItem.Seats.Extra', seats.extra)
              )}
            </Value>
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.AdultsMain')}
            </Label>
            {this.renderGuests(seats.main.adult)}
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.ChildMain')}
            </Label>
            {this.renderGuests(seats.main.child)}
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.AdultsExtra')}
            </Label>
            {this.renderGuests(seats.extra.adult)}
          </Content>
        </Cell>

        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.ChildExtra')}
            </Label>
            {this.renderGuests(seats.extra.child)}
          </Content>
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservation))``;
