import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import InvoiceState from 'components/ui/InvoiceState';
import ProviderState from 'components/ui/ProviderState';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${({ direction }) => direction === 'row' && css`
    flex-direction: row;
    align-items: center;

    ${ProviderState} + ${InvoiceState} {
      margin-left: 16px;
    }
  `}

  ${({ direction }) => direction === 'column' && css`
    flex-direction: column;
    align-items: flex-start;

    ${ProviderState} + ${InvoiceState} {
      margin-top: 8px;
    }
  `}
`;

function OrderState({ provider_state, invoice_state, direction, className }) {
  return (
    <Wrapper
      direction={direction}
      className={className}
    >
      <ProviderState
        {...provider_state}
      />

      {invoice_state.code !== 'not_defined' && (
        <InvoiceState
          variant={direction === 'row' ? 'long' : 'short'}
          {...invoice_state}
        />
      )}
    </Wrapper>
  );
}

OrderState.defaultProps = {
  inline: false
};

OrderState.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  provider_state: PropTypes.shape({
    code: PropTypes.oneOf(['created', 'booked', 'confirmed', 'cancelled'])
  }),
  invoice_state: PropTypes.shape({
    code: PropTypes.oneOf(['not_defined', 'triggered', 'required', 'expired', 'paid'])
  })
};

export default styled(OrderState)``;
