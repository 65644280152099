import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import HotelCard from 'components/ui/HotelCard';
import { Line } from 'src/components/ui';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 960px;
  margin-bottom: 48px;

  ${HotelCard} {
    width: calc(50% - 10px);
    margin: 10px 0;
  }
`;

const SubTitle = styled.div`
  margin-top: 52px;
  color: #979BA0;
  ${typography(20, 24, 700)};
`;

@inject('hotelsStore', 'searchState')
@observer
class FavoriteHotels extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    favoriteHotelsStore: PropTypes.object,
    searchState: PropTypes.object,
    hotelsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isPending() {
    const { hotelsStore } = this.props;
    return hotelsStore.isPending;
  }

  @computed get hotelsList() {
    const { hotelsStore } = this.props;
    return toJS(hotelsStore.data);
  }

  handleFavoriteHotelSubmit = (hotel) => (e) => {
    const { searchState } = this.props;

    searchState.$('hotel').update(hotel);
    searchState.submit();
  }

  render() {
    const { searchState, t, ...rest } = this.props;

    if (this.isPending) return null;
    if (this.hotelsList.length === 0) return null;

    const hotels = this.hotelsList.map((hotel, id) => (
      <HotelCard
        key={id}
        hotel={hotel}
        onClick={this.handleFavoriteHotelSubmit(hotel)}
      />
    ));

    return (
      <Wrapper {...rest}>
        <SubTitle>
          {t('Orders.Hotels.FavoriteHotels')}
        </SubTitle>

        <Line />

        {hotels}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(FavoriteHotels))``;
