import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Info from '../../Info';
import getTaxRules from 'utils/getTaxesRules/getTaxesRules';
import CommonPart from '../CommonPart';

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
`;

const GridWrapper = styled.div`
 display: grid;
  grid-template-columns: repeat(2, minmax(0, 312px));
  gap: 10px;
  margin-top: 10px;
`;

function Rules({ hotel }) {
  const { t } = useTranslation();
  const { taxes, check_in_time, check_out_time, accommodation_features, children_from } = hotel;
  const taxRules = getTaxRules({ taxes }).map((rule) => ({
    name: rule
  }));

  return (
    <CommonPart title={t('Hotel.Details.Tabs.Rules')}>
      <Line>
        <Info name={t('Hotel.CheckIn')} value={t('Hotel.CheckInTime', { time: check_in_time })} />
        <Info name={t('Hotel.CheckOut')} value={t('Hotel.CheckOutTime', { time: check_out_time })} />
        <Info name={t('Hotel.EstimatedTime')} value={check_out_time} />
      </Line>
      <Line>
        <Info name={t('Hotel.Taxes')} value={taxRules.map(({ name }) => name)} />
      </Line>
      <Line>
        <Info
          name={t('Hotel.Restrictions')}
          value={
            <>
              <Line>{t('Hotel.ChildrenFrom', { children_from })}</Line>

              <GridWrapper>
                {accommodation_features.map(({ name }) => (
                  <Line key={name}>{name}</Line>
                ))}
              </GridWrapper>

            </>
          }
        />
      </Line>
    </CommonPart>
  );
}

Rules.propTypes = {
  hotel: PropTypes.object
};

export default styled(Rules)``;
