import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Block } from 'components/atoms';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled(Block)`
  --padding: 16px;
  --border-width: 1px;
  --border-radius: 4px;

  --text-color: #6b707b;
  --value-color: #4c4c4c;
  --title-color: #3aa6d2;
  --border-color: #bfc6d3;
  --background-color: #e5e8ed;

  padding: var(--padding);
  background: var(--background-color);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
`;

class BlockDark extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

BlockDark.propTypes = {
  className: PropTypes.string,
  children: ReactComponentChildrenPropType.isRequired
};

BlockDark.defaultProps = {
  className: ''
};

export default styled(BlockDark)``;
