import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Error as ErrorIcon } from 'components/icons';

import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    padding: 8px 0;
    color: ${theme.colors.danger};
    ${typography(13, 16)}
  
    :not(:first-child) {
      margin-top: 8px;
    }
  
    ${ErrorIcon} {
      margin-right: 4px;
    }
  `}
`;

@withTheme
class DemoLimitations extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <ErrorIcon />

        <span>
          {t('UI.DemoLimitations')}
        </span>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(DemoLimitations))``;
