import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Modal from 'components/ui/Modal';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const ServicesContainer = styled.div`
  margin-top: 20px;
  column-count: 2;
  display: inline-block;
`;

const Service = styled.div`
  color: #4A515C;
  font-size: 16px;
  letter-spacing: 0;
  min-width: 290px;
  line-height: 18px;
  padding: 6px 0;
  
  &:first-letter {
    text-transform: uppercase;
  }
`;

const ModalContainer = styled.div`
  width: 732px;
  background-color: #FFFFFF;
  max-width: 732px;
  min-width: 732px;
  border-radius: 8px;
  padding: 28px 30px;
  box-sizing: border-box;
`;

const Wrapper = styled(Modal)`
  padding: 0;
`;

function ManipulationModal({ className, onCloseClick, services }) {
  const { t } = useTranslation();

  const servicesComponent = services.map((s, index) => <Service key={index}>{s.name}</Service>);

  return (
    <Wrapper className={className} onClose={onCloseClick} opened>
      <ModalContainer>
        <Title>
          {t('Hotel.RoomTypes.Modal.Services.Title')}
        </Title>

        <ServicesContainer>
          {servicesComponent}
        </ServicesContainer>
      </ModalContainer>
    </Wrapper>
  );
}

ManipulationModal.propTypes = {
  services: PropTypes.array,
  className: PropTypes.string,
  onCloseClick: PropTypes.func
};

ManipulationModal.defaultProps = {
  amenities: []
};

export default styled(ManipulationModal)``;
