import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import Qs from 'qs';

import { Container, PageControl, PageItems, PageItem } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
  padding: 32px 0;
`;

@withRouter
class Pagination extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    location: ReactRouterPropTypes.location,
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    const { current, onChange } = this.props;

    if (current && prevProps.current && onChange) {
      onChange({ page: current });
    }
  }

  render() {
    const { current, total, location, ...rest } = this.props;

    const qs = Qs.parse(location.search, { ignoreQueryPrefix: true });

    const items = Array(total).fill().map((item, index) => ({
      id: index,
      link: `${location.pathname}?${Qs.stringify({ ...qs, page: index + 1 })}`,
      page: index + 1
    }));

    const prevLink = current > 1 && total > 1
      ? `${location.pathname}?${Qs.stringify({ ...qs, page: current - 1 })}`
      : null;

    const nextLink = current < total
      ? `${location.pathname}?${Qs.stringify({ ...qs, page: current + 1 })}`
      : null;

    if (total > 1) {
      return (
        <Wrapper {...rest}>
          <Container>
            <PageControl
              disabled={!prevLink}
              direction='left'
              link={prevLink}
            />

            <PageItems>
              {items.map(({ id, page, link }) => (
                <PageItem
                  key={id}
                  isActive={page === current}
                  link={link}
                >
                  <>{page}</>
                </PageItem>
              ))}
            </PageItems>

            <PageControl
              disabled={!nextLink}
              direction='right'
              link={nextLink}
            />
          </Container>
        </Wrapper>
      );
    }

    return null;
  }
}

export default styled(Pagination)``;
