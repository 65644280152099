import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { selectById } from 'redux/stores/usersSlice/selectors';
import { updateUser } from 'redux/stores/usersSlice/actions';

import UserForm from '../components/UserForm';
import { Button } from 'components/forms';

const Header = styled.div`
  color: #9CB4CA;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
`;

const Body = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  border: 2px solid #9CB4CA;
  border-radius: 6px;
  background-color: rgba(156, 180, 202, 0.2);

  ${Header} + ${Body} {
    margin-top: 24px;
  }
`;

function Edit({ className }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => selectById(state, id));

  const loadingStatus = useSelector((state) => state.users.status);

  if (loadingStatus === 'loading') {
    return (
      <Wrapper>loading...</Wrapper>
    );
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await dispatch(updateUser({ id, ...values }));
    setIsLoading(false);

    history.push('/settings/users');
  };

  const handleReset = () => {
    history.push('/settings/users');
  };

  return (
    <Wrapper className={className}>
      <Header>
        {t('Settings.Users.Edit.Title')}
      </Header>

      <Body>
        <UserForm
          key={id}
          initialValues={user}
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Button
            size='small'
            type='submit'
            loading={isLoading}
          >
            {t('UI.Button.Save')}
          </Button>

          <Button
            compact
            size='small'
            type='reset'
          >
            {t('UI.Button.Cancel')}
          </Button>
        </UserForm>
      </Body>
    </Wrapper>
  );
}

Edit.propTypes = {
  className: PropTypes.string
};

export default styled(Edit)``;
