import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/forms/Button';
import BalanceBadge from 'components/ui/BalanceBadge';

const Left = styled.div`
  display: flex;

  ${Button} + ${Button} {
    margin-left: 16px;
  }
`;

const Right = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

function Footer({
  hasAssetBalance,
  assetBalance,
  onEdit,
  onPayFromAssetBalance,
  onInfo,
  onDownload,
  className
}) {
  const [loading, setLoading] = useState(undefined);

  const { t } = useTranslation();

  const handlePayFromAssetBalance = async (e) => {
    setLoading('pay');
    await onPayFromAssetBalance();
    setLoading(undefined);
  };

  const handleInfo = (e) => {
    onInfo();
  };

  const handleDownload = async (e) => {
    setLoading('download');
    await onDownload();
    setLoading(undefined);
  };

  return (
    <Wrapper className={className}>
      <Left>
        <Button
          outline
          rounded
          disabled={hasAssetBalance ? undefined : 'true'}
          size='small'
          variant='success'
          loading={loading === 'pay' ? 'true' : undefined}
          onClick={handlePayFromAssetBalance}
        >
          {t('Invoice.Footer.PayFromAssetBalance')}
        </Button>

        <Button
          outline
          rounded
          size='small'
          variant='primary'
          onClick={handleInfo}
        >
          {t('Invoice.Footer.OrderInfo')}
        </Button>

        <Button
          compact
          size='small'
          loading={loading === 'download' ? 'true' : undefined}
          onClick={handleDownload}
        >
          {t('Order.DownloadBill')}
        </Button>
      </Left>

      <Right>
        <BalanceBadge balance={assetBalance} />
      </Right>
    </Wrapper>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  assetBalance: PropTypes.object.isRequired,
  hasAssetBalance: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onPayFromAssetBalance: PropTypes.func,
  onInfo: PropTypes.func,
  onDownload: PropTypes.func
};

export default styled(Footer)``;
