import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Amenity = styled.div`

`;

const Wrapper = styled.div`
  color: #979BA0;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;

  ${Amenity} + ${Amenity} {

  }
`;

const AgentPrice = ({ className, hotel: { amenities } }) => {
  const amenitiesText = amenities
    .sort((a, b) => a.priority - b.priority)
    .slice(0, 3)
    .map(a => a.name)
    .join(' · ');

  return (
    <Wrapper className={className}>
      {amenitiesText}
    </Wrapper>
  );
};

AgentPrice.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default styled(AgentPrice)``;
