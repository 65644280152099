const ADULT_GROUP = 'adult';

const CHILD_GROUP = 'child';

function getSelectedOccupations({ travellers, occupations }) {
  const adultsCount = travellers
    .filter(t => t.age_group === ADULT_GROUP)
    .length;

  const childsCount = travellers
    .filter(t => t.age_group === CHILD_GROUP)
    .length;

  const adults = occupations.adults.map(item => {
    const selected = item.count === adultsCount;

    return { ...item, selected };
  });

  const childs = occupations.childs.map(item => {
    const selected = item.count === childsCount;

    return { ...item, selected };
  });

  return { adults, childs };
}

export default getSelectedOccupations;
