import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import MainProvider from 'components/common/MainProvider';
import { CookiesProvider } from 'react-cookie';

import store from 'redux/stores/core';

import { App } from 'pages';
import i18nConfig from 'config/i18n';

i18n
  .use(ICU)
  .use(initReactI18next)
  .init(i18nConfig);

ReactDOM.render(
  <CookiesProvider>
    <I18nextProvider i18n={i18n}>
      <MainProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </MainProvider>
    </I18nextProvider>
  </CookiesProvider>,
  document.getElementById('root')
);
