import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Wrapper = styled.input`
  font-size: 16px;
  padding: 0 14px;
  border: 1px solid #D1D5DB;
  background-color: #F7F8FB;

  ::placeholder {
    color: #979BA0;
  }
`;

@observer
class Input extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    className: PropTypes.string
  }

  render() {
    const { field, className } = this.props;

    return (
      <Wrapper
        className={className}
        {...field.bind()}
      />
    );
  }
}

export default styled(Input)``;
