import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';

import Message from './Message';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 14px;
`;

@observer
class Messages extends Component {
  static propTypes = {
    conversation: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.scrollBar = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    const { scrollBar } = this;
    scrollBar.current.scrollToBottom();
  }

  render() {
    const { conversation } = this.props;

    const listMsgs = conversation.messages
      .slice()
      .sort((before, after) => (
        new Date(before.created_at) - new Date(after.created_at)
      ))
      .map((message) => (
        <Message
          key={message.id}
          conversation={conversation}
          message={message}
        />
      ));

    return (
      <Scrollbars ref={this.scrollBar}>
        <Wrapper>
          {listMsgs}
        </Wrapper>
      </Scrollbars>
    );
  }
}

export default styled(Messages)``;
