import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/v2/ui/Tooltip';
import { AttentionIcon } from 'components/icons';

const PriceContainer = styled.div`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

const PriceDescription = styled.div`
  color: #6B707B;
  font-family: "Helvetica Neue";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const PriceContent = styled.div`

`;

const DiscountValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DiscountContent = styled.div`
  padding: 2px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 3px;
  height: 34px;
`;

const CashbackPopup = styled.div`

`;

const DiscountPercent = styled.div`
  color: #9CB0E0;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
`;

const DiscountValue = styled.div`
  color: #9CB0E0;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const Wrapper = styled.div`
  width: 161px;
  height: 41px;
  padding: 4px 4px 8px 14px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;

  ${AttentionIcon} {
    height: 10px;
    margin-left: 3px;
    width: 10px;

    path, rect {
      fill: #97B0E5 !important;
    }
  }

  ${({ variant }) => variant === 2 && css`
    border: 1px solid #3AA6D2;
    border-radius: 4px;
    background-color: #EFFAFF;
    padding: 3px 3px 7px 13px;
  `}
`;

const TuristPrice = ({ className, hotel, variant }) => {
  const { t } = useTranslation();
  const {
    price_formation: {
      gross_price
    },
    tariff: {
      promo
    }
  } = hotel;

  const handlePreventDefault = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const tooltipPrice = (
    <CashbackPopup onClick={handlePreventDefault} dangerouslySetInnerHTML={{ __html: t('Cashback.Popup') }} />
  );

  return (
    <Wrapper className={className} variant={variant}>
      <PriceContent>
        <PriceContainer>
          {t('UI.Discount.From', { value: gross_price.amount })}
        </PriceContainer>

        <PriceDescription>
          {t('UI.HotelCard.Price.Tourist')}
        </PriceDescription>
      </PriceContent>

      {promo.applied_percent_off > 0 && (
        <Tooltip
          placement='bottom'
          overlay={tooltipPrice}
        >
          <DiscountContent>
            <DiscountPercent>
              {promo.applied_percent_off}%
            </DiscountPercent>

            <DiscountValueContainer>
              <DiscountValue>
                {t('Common.Cashback')}
              </DiscountValue>

              <AttentionIcon />
            </DiscountValueContainer>
          </DiscountContent>
        </Tooltip>
      )}
    </Wrapper>
  );
};

TuristPrice.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  variant: PropTypes.number
};

export default styled(TuristPrice)``;
