import { useState, useEffect } from 'react';
import Store from '../PlacesStore';

const PENDING_STATUS = 'pending';
const DONE_STATUS = 'done';
const ERROR_STATUS = 'error';
const EMPTY_STATUS = 'empty';

const getStatuses = (status) => {
  const isPending = status === PENDING_STATUS;
  const isFetched = status === DONE_STATUS;
  const isError = status === ERROR_STATUS;
  const isEmpty = status === EMPTY_STATUS;

  return { isPending, isFetched, isError, isEmpty };
};

const usePlaces = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState(undefined);

  const [recents, setResents] = useState([]);

  const store = new Store();
  const { register, fetchRegistered } = store;

  useEffect(() => {
    setStatus(undefined);

    const getData = async () => {
      try {
        setStatus(PENDING_STATUS);

        const data = await store.fetch(query);
        setData(data);

        data.length > 0
          ? setStatus(DONE_STATUS)
          : setStatus(EMPTY_STATUS);
      } catch (error) {
        setStatus(ERROR_STATUS);
      }
    };

    getData();
  }, [query]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await fetchRegistered(null, { maxRecents: 3 });
        setResents(data);
      } catch (error) {
        setStatus(ERROR_STATUS);
      }
    };

    getData();
  }, [data]);

  return [{ data, recents, register, ...getStatuses(status) }, setQuery];
};

export default usePlaces;
