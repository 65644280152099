import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Triangle } from 'components/icons';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ disabled, theme }) => css`
    ${display('flex', 'center', 'center')}
    width: 24px;
    height: 24px;
    margin: 0 8px;
    color: ${theme.colors.primaryDark};
    cursor: pointer;
    transition:
      color 0.25s,
      padding-left 0.25s,
      padding-right 0.25s;
    
    ${disabled && css`
      cursor: default;
      opacity: 0.35;
    `}

    ${!disabled && css`
      :hover {
        color: ${theme.colors.button.primary};
      }
    `}
  `}
`;

const TriangleIcon = styled(Triangle)`
  ${({ direction }) => css`
    ${direction === 'left' && css`
      transform: rotate(90deg);
    `}

    ${direction === 'right' && css`
      transform: rotate(-90deg);
    `}

    path {
      fill: currentColor;
    }
  `}
`;

@withTheme
class PageControl extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    direction: PropTypes.oneOf([
      'left',
      'right'
    ]),
    link: PropTypes.string
  };

  static defaultProps = {
    className: '',
    direction: 'left'
  };

  render() {
    const { direction, disabled, link, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        as={!disabled && Link}
        disabled={disabled}
        to={link}
      >
        <TriangleIcon direction={direction} />
      </Wrapper>
    );
  }
}

export default styled(PageControl)``;
