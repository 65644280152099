import React from 'react';
import { PropTypes } from 'prop-types';
import { Header } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { observer } from 'mobx-react';

import NewOrder from './components/NewOrder';

@observer
class Add extends React.Component {
  static propTypes = {
    header: PropTypes.object
  };

  render() {
    const { header } = this.props;

    return (
      <>
        <DefaultTemplate
          header={<Header {...header} />}
          body={<NewOrder />}
        />
      </>
    );
  }
}

export default Add;
