import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Spinner } from 'components/ui';

const Body = styled.div`
  font-size: 14px;
  color: #4C4C4C;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  ${Body} {
    margin-top: 24px;
  }
`;

@withRouter
@inject('bookingState')
@observer
class LoadingContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Spinner />

        <Body>
          {t('UI.Fetching')}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(LoadingContent))``;
