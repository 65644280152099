import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { DateTime } from 'components/ui';
import ExpandableTextList from 'components/ui/ExpandableTextList';
import OrderState from 'components/ui/OrderState';

import { getNameInitials } from 'utils/localization';

const Text = styled.div`
  color: #4A515C;
  font-size: 13px;
  line-height: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  padding-bottom: 14px;
  color: #4A515C;
  font-size: 13px;
`;

@withTheme
class Caption extends React.PureComponent {
  static propTypes = {
    order: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    className: '',
    onSelect: () => {}
  };

  handleClick = () => {
    const { onSelect, order } = this.props;
    onSelect(order);
  };

  render() {
    const {
      t,
      className,
      order: {
        order_code,
        updated_at,
        check_in,
        check_out,
        creator,
        hotel,
        provider_state,
        invoice_state,
        travellers
      }
    } = this.props;

    const guests = travellers.length > 0
      ? travellers.map(t => getNameInitials(t))
      : [t('Orders.Guests', { count: 0 })];

    return (
      <>
        <Cell className={className}>
          <Text>
            {order_code}
          </Text>
        </Cell>

        <Cell className={className}>
          с&nbsp;
          <Text
            as={DateTime}
            value={check_in}
          />

          <br />

          по&nbsp;
          <Text
            as={DateTime}
            value={check_out}
          />
        </Cell>

        <Cell className={className}>
          <ExpandableTextList items={guests} />
        </Cell>

        <Cell className={className}>
          <Text>
            {hotel ? hotel.name : '—'}
          </Text>
        </Cell>

        <Cell className={className}>
          <Text>
            {getNameInitials(creator)}
          </Text>
        </Cell>

        <Cell className={className}>
          <List>
            <Text
              as={DateTime}
              value={updated_at}
            />

            <Text>
              в <DateTime value={updated_at} format='H:mm' />
            </Text>
          </List>
        </Cell>

        <Cell className={className}>
          <OrderState
            direction='column'
            provider_state={provider_state}
            invoice_state={invoice_state}
          />
        </Cell>
      </>
    );
  }
}

export default styled(withTranslation()(Caption))``;
