import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 40px;
  color: #323232;
  text-align: center;
  font-family: 'GT Eesti Pro Display', sans-serif;
`;

const Domain = styled.span`
  color: #1bc47d;
`;

const Heading = ({ className }) => {
  return (
    <Wrapper className={className}>
      Санаторий<Domain>.ру</Domain>
    </Wrapper>
  );
};

Heading.propTypes = {
  className: PropTypes.string
};

export default Heading;
