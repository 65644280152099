import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { QuestionIcon } from 'components/icons';
import Tooltip from 'components/v2/ui/Tooltip';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  width: auto;
  height: 19px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  color: #FFFFFF;

  ${QuestionIcon} {
    margin-left: 6px;
    ${({ variant }) => variant !== 'danger' && css`
      & path {
        fill: #E68686;
      }
    `}
  }

  ${({ variant }) => variant === 'danger' && css`
    background-color: #E68686;
  `}

  ${({ variant }) => variant === 'primary' && css`
    background-color: #5AB2D6;
  `}

  ${({ variant }) => variant === 'light' && css`
    background-color: #FFEFEF;
    color: #E68686;
  `}

  ${({ variant }) => variant === 'secondary' && css`
    background-color: #9CB4CA;
  `}
`;

class Badge extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success', 'light']),
    tooltipContent: PropTypes.node
  }

  static defaultProps = {
    className: '',
    variant: 'danger'
  }

  render() {
    const { children, tooltipContent, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}

        {tooltipContent &&
          <Tooltip
            placement='top'
            overlay={tooltipContent}
          >
            <QuestionIcon />
          </Tooltip>
        }
      </Wrapper>
    );
  }
}

export default styled(Badge)``;
