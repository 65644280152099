import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${({ theme, size, type }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    padding: 9px 16px;
    background: ${theme.colors.passive.primary};
    color: ${theme.colors.dark};
    font-size: 13px;
    white-space: nowrap;

    ${size === 'small' && css`
      height: 24px;
      padding-left: 12px;
      padding-right: 12px;
    `}

    ${type === 'error' && css`
      background-color: ${theme.colors.error.secondary};
      color: ${theme.colors.error.primary};
    `}

    ${type === 'success' && css`
      background-color: ${theme.colors.success.secondary};
      color: ${theme.colors.success.primary};
    `}
  `}
`;

@withTheme
class Chip extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    size: PropTypes.oneOf([
      'small',
      'medium'
    ]),
    theme: PropTypes.object,
    type: PropTypes.oneOf([
      'error',
      'success'
    ])
  };

  static defaultProps = {
    className: '',
    size: 'medium'
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Chip)``;
