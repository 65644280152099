import * as Yup from 'yup';

const legalEntitySchema = Yup.object().shape({
  name: Yup.string().required('Обязательное поле'),
  type: Yup.object()
    .shape({ tag: Yup.string().required('Обязательное поле') })
    .required('Обязательное поле'),
  inn: Yup.string()
    .matches(/^\d{10}$|^\d{12}$/, 'ИНН должен содержать 10 или 12 цифр')
    .required('Обязательное поле'),
  kpp: Yup.string().when('type', {
    is: (type) => type.tag === 'llc',
    then: Yup.string()
      .matches(/^\d{9}$/, 'КПП должен содержать 9 цифр')
      .required('Обязательное поле'),
    otherwise: Yup.string().nullable().default(null)
  }),
  short_name: Yup.string().required('Обязательное поле'),
  ogrn: Yup.string()
    .matches(/^\d{13}$|^\d{15}$/, 'ОГРН должен содержать 13 или 15 цифр')
    .required('Обязательное поле'),
  tax_type: Yup.object()
    .shape({ tag: Yup.string().required('Обязательное поле') })
    .required('Обязательное поле'),
  agency_types: Yup.array(Yup.object({ tag: Yup.string().optional() }))
    .optional()
    .nullable()
    .default(null),
  email: Yup.string().email('Некорректный email').required('Обязательное поле'),
  email2: Yup.string().email('Некорректный email').required('Обязательное поле'),
  phone: Yup.string()
    .test('phone', 'Некорректный номер телефона', (value) => {
      if (!value) return true;

      if (value.startsWith('+7')) {
        return /^\+?\d{11}$/.test(value);
      } else {
        return /^\+?\d{10}$/.test(value);
      }
    })
    .required('Обязательное поле'),
  director: Yup.object().shape({
    full_name: Yup.string()
      .transform((value) => (value === '' ? null : value))
      .optional()
      .nullable(),
    post: Yup.string()
      .transform((value) => (value === '' ? null : value))
      .optional()
      .nullable()
  }),
  accountant: Yup.object().shape({
    full_name: Yup.string().required('Обязательное поле'),
    post: Yup.string().required('Обязательное поле'),
    email: Yup.string().email('Некорректный email').required('Обязательное поле'),
    phone: Yup.string()
      .test('phone', 'Некорректный номер телефона', (value) => {
        if (!value) return true;

        if (value.startsWith('+7')) {
          return /^\+?\d{11}$/.test(value);
        } else {
          return /^\+?\d{10}$/.test(value);
        }
      })
      .required('Обязательное поле')
  }),
  legal_address: Yup.object().shape({
    postal_code: Yup.string()
      .matches(/^\d{6}$/, 'Индекс должен содержать 6 цифр')
      .required('Обязательное поле'),
    country: Yup.string().required('Обязательное поле'),
    city: Yup.string().required('Обязательное поле'),
    street: Yup.string().required('Обязательное поле'),
    building_number: Yup.string().required('Обязательное поле'),
    office_number: Yup.string().optional().nullable().default(null)
  }),
  actual_address: Yup.object().shape({
    postal_code: Yup.string()
      .matches(/^\d{6}$/, 'Индекс должен содержать 6 цифр')
      .required('Обязательное поле'),
    country: Yup.string().required('Обязательное поле'),
    city: Yup.string().required('Обязательное поле'),
    street: Yup.string().required('Обязательное поле'),
    building_number: Yup.string().required('Обязательное поле'),
    office_number: Yup.string().optional().nullable().default(null)
  }),
  bank_details: Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    kpp: Yup.string()
      .matches(/^\d{9}$/, 'КПП должен содержать 9 цифр')
      .required('Обязательное поле'),
    bik: Yup.string()
      .matches(/^\d{9}$/, 'БИК должен содержать 9 цифр')
      .required('Обязательное поле'),
    kor_schet: Yup.string()
      .matches(/^\d{20}$/, 'Корреспондетский счет должен содержать 20 цифр')
      .required('Обязательное поле'),
    ras_schet: Yup.string()
      .matches(/^\d{20}$/, 'Расчетный счет должен содержать 20 цифр')
      .required('Обязательное поле')
  })
});

export const schema = Yup.object().shape({
  email: Yup.string().email('Некорректный email').required('Обязательное поле'),
  phone: Yup.string()
    .test('phone', 'Некорректный номер телефона', (value) => {
      if (!value) return true;

      if (value.startsWith('+7')) {
        return /^\+?\d{11}$/.test(value);
      } else {
        return /^\+?\d{10}$/.test(value);
      }
    })
    .required('Обязательное поле'),
  first_name: Yup.string().required('Обязательное поле'),
  last_name: Yup.string().required('Обязательное поле'),
  middle_name: Yup.string().optional().nullable().default(null),
  post: Yup.string().optional().nullable().default(null),
  equalAddress: Yup.boolean().optional(),
  legal_entity: legalEntitySchema
});
