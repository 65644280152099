import { asyncify, mapLimit } from 'async';

class Queue {
  constructor(num = 3) {
    this.numInParallel = num;
    this.tasks = [];
    this.cancelled = false;
    this.executor = Promise.resolve();
  }

  addTask(exec, cb) {
    this.tasks.push([exec, cb]);
  }

  execute() {
    const context = this;

    this.executor = new Promise((resolve, reject) => {
      const iterator = async (task) => {
        if (context.cancelled) {
          return Promise.resolve([]);
        }

        const [exec, callback] = task;

        const { data } = await exec();
        callback(data);

        return data.hotels;
      };

      const callback = (err, results) => {
        if (err) reject(err);

        resolve(results);
      };

      mapLimit(this.tasks, this.numInParallel, asyncify(iterator), callback);
    });

    return this.executor;
  }

  async cancel() {
    if (!this.executor) {
      return;
    }

    this.cancelled = true;

    try {
      await this.executor;
      throw new Error();
    } catch {
      return Promise.resolve([]);
    } finally {
      this.cancelled = false;
      this.tasks = [];
      this.executor = Promise.resolve();
    }
  }
}

export default Queue;
