import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import dateFormat from 'utils/dateFormat';
import formatPrice from 'utils/formatPrice';
import { shortName } from 'utils/personName';

const Text = styled.div`
  color: #4A515C;
  font-size: 18px;
  line-height: 22px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
  "number invoices_at expired_at net_price manager";
  grid-auto-flow: row;
  grid-template-columns: 168px 135px 135px 150px 200px 1fr;
  column-gap: 6px;
`;

function Header({ invoice, className }) {
  const { number, invoiced_at, expired_at, total, manager } = invoice;

  return (
    <Wrapper className={className}>
      <Text>{number}</Text>
      <Text>{dateFormat(invoiced_at, 'DD-MM-YYYY')}</Text>
      <Text>{dateFormat(expired_at, 'DD-MM-YYYY')}</Text>
      <Text>{formatPrice(total.net_price)}</Text>
      <Text>{shortName(manager)}</Text>
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default styled(Header)``;
