import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useOrder } from 'stores/OrdersStore/hooks';
import useQuery from 'hooks/useQuery';

import { Main } from 'components/ui';
import { OrderFilterForm } from 'components/ui/FilterBar';

import { useTranslation } from 'react-i18next';

import { AttentionIcon } from 'components/icons';

const TextContainer = styled.div`
  margin-top: 25px;
  color: #768EA5;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  height: 18px;
  font-size: 18px;
`;

const Text = styled.div`
  margin-left: 8px;
  max-width: 511px;
`;

const Wrapper = styled(Main)`
  width: 960px;
  height: 268px;
  padding: 48px 84px 34px 51px;

  ${Container} + ${Container} {
    margin-top: 14px;
  }

  b {
    font-weight: 600;
  }
`;

function OrderForm({ ...rest }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [{ query }, { updateQuery }] = useQuery();
  const [, { createOrder, buildEditUrl, buildSearchUrl }] = useOrder();

  const handleSubmit = async () => {
    const { address } = query;

    const hotel = address?.scope === 'hotel'
      ? { id: address.hotel_ids[0], name: address.location, address }
      : null;

    const params = {
      ...query,
      ...(hotel ? { hotel } : {})
    };

    const { id } = await createOrder(params);

    const path = address?.hotel_ids?.length > 0
      ? buildEditUrl(id, query)
      : buildSearchUrl(id, query);

    history.push(path, query);
  };

  return (
    <Wrapper {...rest}>
      <OrderFilterForm
        query={query}
        handleUpdate={updateQuery}
        onSubmit={handleSubmit}
      />

      <TextContainer>
        <Container>
          <Icon>
            <AttentionIcon />
          </Icon>

          <Text dangerouslySetInnerHTML={{ __html: t('Order.OrderForm.Attention1') }} />
        </Container>

        <Container>
          <Icon>
            <AttentionIcon />
          </Icon>

          <Text dangerouslySetInnerHTML={{ __html: t('Order.OrderForm.Attention2') }} />
        </Container>
      </TextContainer>
    </Wrapper>
  );
}

OrderForm.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  orderStore: PropTypes.object.isRequired,
  searchState: PropTypes.object.isRequired,
  t: PropTypes.func
};

export default styled(OrderForm)``;
