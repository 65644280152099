const TRAVELLER = {
  adult: { age_group: 'adult', age: 0 },
  child: { age_group: 'child', age: 8 }
};

function setSelectedOccupations({ travellers, ageGroup, count }) {
  let modified = travellers
    .filter(t => t.age_group === ageGroup);

  const others = travellers
    .filter(t => t.age_group !== ageGroup);

  const added = Array.from({ length: count }, () => TRAVELLER[ageGroup]);

  modified = [...modified, ...added].slice(0, count);

  return [...modified, ...others];
}

export default setSelectedOccupations;
