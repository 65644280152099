import _omit from 'lodash/omit';

const DEFAULT_SLOT = {
  updatedAt: Date.now(),
  cash: true,
  rate: null,
  prices: [],
  traveller: null,
  contract: {
    number: null,
    platform_id: null,
    price_prefix: null,
    insured_name: null,
    insured_inn: null,
    insured_kpp: null,
    begin_date: null,
    end_date: null
  },
  _destroy: false
};

function coerceSlotsNumber({ slots, count }) {
  let data;

  const persistedSlots = slots.filter(s => !s._destroy);
  const deletedSlots = slots.filter(s => s._destroy);

  if (persistedSlots.length < count) {
    data = slots.slice(0, count);

    data = [
      ...data,
      ...new Array(count - data.length).fill(DEFAULT_SLOT)
    ];

    data = data.map(s => _omit(s, '_destroy'));
  }

  if (persistedSlots.length >= count) {
    data = persistedSlots.slice(0, count);

    let deleted = [
      ...deletedSlots,
      ...persistedSlots.slice(count, persistedSlots.length)
    ];

    deleted = deleted
      .filter(s => s.id)
      .map(s => ({ _destroy: true, ...s }));

    data = [...data, ...deleted];
  }

  return data;
}

export default coerceSlotsNumber;
