import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  max-width: 363px;
  color: #979ba0;
  margin-top: 17px;
`;

const Link = styled.a`
  color: #3c6f9d;
`;

const Hint = ({ className }) => {
  return (
    <Wrapper className={className}>
      Нажимая кнопку «Зарегистрироваться», я даю{' '}
      <Link rel='noopener noreferrer nofollow' target='_blank' href='https://sanatory.ru/info/legal_form/'>
        «Согласие на обработку и передачу персональных данных»
      </Link>
      , ознакомлен и принимаю условия{' '}
      <Link rel='noopener noreferrer nofollow' target='_blank' href='https://sanatory.ru/info/terms_of_use/'>
        пользовательского соглашения.
      </Link>
    </Wrapper>
  );
};

Hint.propTypes = {
  className: PropTypes.string
};

export default Hint;
