import { createAsyncThunk } from '@reduxjs/toolkit';

import storage from '../storage';

export const removeOne = createAsyncThunk('notifications/remove', async (notification) => {
  const { id } = notification;
  await storage.remove(id);

  return id;
});

export default removeOne;
