import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { dateFormat } from 'utils';
import styled, { css } from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 11px;
`;

const Author = styled.div`
`;

const Time = styled.div`
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const Text = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 10px;
  margin-bottom: 14px;

  ${Header} + ${Body} {
    margin-top: 14px;
  }

  ${({ to }) => !!to && css`
    color: #FFFFFF;
    align-self: flex-start;
    background-color: #5AB2D6;
    border-radius: 0 14px 14px 14px;
    box-shadow: 0 3px 5px 0 rgba(94,202,247,0.46);
  `}

  ${({ from }) => !!from && css`
    color: #4A515C;
    align-self: flex-end;
    border-radius: 14px 0 14px 14px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  `}
`;

@observer
class Message extends Component {
  static propTypes = {
    message: PropTypes.object.isRequired
  }

  @computed get klassMsg() {
    const { is_owner } = this.props.message;
    return is_owner ? 'msg from' : 'msg to';
  }

  render() {
    const { message: { created_at, text, author, is_owner } } = this.props;

    return (
      <Wrapper
        from={!is_owner}
        to={is_owner}
      >
        <Header>
          <Author>
            {author}
          </Author>
          <Time>
            {dateFormat(created_at)}
          </Time>
        </Header>

        <Body>
          <Text>{text}</Text>
        </Body>
      </Wrapper>
    );
  }
}

export default styled(Message)``;
