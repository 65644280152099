import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Marker from '../Marker';
import useSelected from './hooks/useSelected';

function SelectedWrapper({ item, ...props }) {
  const [opened, setOpened] = useState(false);
  const [isSelected] = useSelected(item);

  return (
    <Marker
      opened={opened}
      setOpened={setOpened}
      item={item}
      {...props}
      isActive={isSelected}
    />
  );
}

SelectedWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  item: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired
};

export default SelectedWrapper;
