import { t } from 'utils/localization';

const ADULT_GROUP = 'adult';

const CHILD_GROUP = 'child';

function getCount(travellers) {
  const adultsCount = travellers
    .filter(t => t.age_group === ADULT_GROUP)
    .length;

  const childsCount = travellers
    .filter(t => t.age_group === CHILD_GROUP)
    .length;

  return { adults: adultsCount, childs: childsCount, guests: travellers.length };
}

function guestText(travellers) {
  const { guests } = getCount(travellers);

  const text = t('Common.Guests', { count: guests });

  return text;
}

function adultsAndChildsText(travellers) {
  const { adults, childs } = getCount(travellers);

  const text = [
    (adults > 0 && t('Adult.Count', { count: adults })),
    (childs > 0 && t('Child.Count', { count: childs }))
  ];

  return text.filter(item => !!item).join(', ');
}

export { guestText, adultsAndChildsText };
