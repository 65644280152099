import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Duration = styled.div`
  width: fit-content;
  background: #9cb4ca;
  border-radius: 4px;
  color: #ffffff;
  padding: 4px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const ProgramContent = styled.div`
  column-count: 2;
  margin-top: 10px;
  display: inline-block;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #4a515c;
    padding: 3px 0;
    break-inside: avoid;
    page-break-inside: avoid;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4a515c;
  margin-top: 20px;
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #4a515c;
  margin-top: 22px;
`;

const Wrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
`;

function Content({ className, program }) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Duration dangerouslySetInnerHTML={{ __html: t('Common.Duration', { ...program }) }} />

      <Description dangerouslySetInnerHTML={{ __html: program.description }} />

      <ListTitle>{t('Hotel.TreatmentPrograms.IncludedText')}</ListTitle>

      <ProgramContent dangerouslySetInnerHTML={{ __html: program.content }} />
    </Wrapper>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object
};

export default Content;
