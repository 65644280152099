import BaseState from 'forms/BaseState';

import { fields } from '.';

class TravellerForm extends BaseState {
  setup() {
    return fields;
  }
}

export default TravellerForm;
