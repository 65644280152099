import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useCompany = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateInfo = async (values) => {
    try {
      setError(null);
      setLoading(true);

      await instance.patch('/api/organizations', { data: values });
    } catch (e) {
      setError(e?.response?.data?.messages?.[0]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { data }
        } = await instance.get('/api/organizations');

        setData(data);
      } catch (e) {
        console.log(e, 'err');
        setError(e?.response?.data?.messages?.[0]);
      }
    };

    fetch();
  }, []);

  return [
    { data, loading, error },
    { updateInfo, setError }
  ];
};

export default useCompany;
