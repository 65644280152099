import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spinner } from 'components/ui';

const Wrapper = styled.div`
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Loader = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Spinner />
    </Wrapper>
  );
};

Loader.propTypes = {
  className: PropTypes.string
};

export default Loader;
