import { useEffect, useState } from 'react';

import instance from 'connection/instance';

const useAgenciesOptions = () => {
  const [agenciesOptions, setAgenciesOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { data }
        } = await instance.get('/api/filters/agencies');

        setAgenciesOptions(data);
      } catch (e) {
        console.log('Agencies options error', e);
      }
    };

    fetch();
  }, []);

  return agenciesOptions;
};

export default useAgenciesOptions;
