import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, Field } from 'components/v2/forms';
import { customizePhoneValue } from 'pages/Auth/containers/Register/utils/customizePhoneNumber';

const InputComponent = styled(Input)`
  height: 40px;
`;

const FieldComponent = styled(Field)`
  &:last-child {
    width: 156px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Fields = styled.div`
  display: flex;
  gap: 16px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const ContactsInfo = ({ className, items, title }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Wrapper className={className}>
      <Title>{title}</Title>

      <Fields>
        {items.map(({ name, label, placeholder }) => {
          const [entity, key] = name.split('.');
          const err = key ? errors?.[entity]?.[key] : errors?.[entity];
          const isPhone = entity === 'phone' || key === 'phone';

          return (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { value, ...other } }) => (
                <FieldComponent required error={err} label={label}>
                  <InputComponent
                    {...other}
                    value={isPhone ? customizePhoneValue(value) : value}
                    placeholder={placeholder}
                  />
                </FieldComponent>
              )}
            />
          );
        })}
      </Fields>
    </Wrapper>
  );
};

ContactsInfo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array
};

export default ContactsInfo;
