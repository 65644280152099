import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { TravellerField } from 'components/forms';

@inject('bookingForm')
@observer
class Traveller extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    slot: PropTypes.object.isRequired,
    bookingForm: PropTypes.object.isRequired
  };

  handleTraveller = (traveller) => {
    const { slot, bookingForm } = this.props;
    bookingForm.setTraveller({ slot, traveller });
  }

  render() {
    const { slot, className } = this.props;

    return (
      <TravellerField
        className={className}
        field={slot.$('traveller')}
        onSelect={this.handleTraveller}
      />
    );
  }
}

export default styled(Traveller)``;
