import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CornerDown } from 'components/icons';

import Tariffs from '../../Tariffs';

const HeaderContainer = styled.div`
  background-color: #E8ECF1;
  margin-right: -20px;
  margin-left: -10px;
  padding: 8px 20px 8px 10px;
  border-radius: 3px;
  box-sizing: border-box;
`;

const Header = styled.div`
  color: #6B707B;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
`;

const DropdownButton = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  user-select: none;
  margin-top: 8px;
  cursor: pointer;
`;

const IconContainer = styled.span`
  font-size: 6px;
  margin-left: 6px;

  path {
    fill: #3C6F9D;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${HeaderContainer} + ${Tariffs} {
    margin-top: 15px;
  }

  ${({ opened }) => opened && css`
    ${IconContainer} {
      svg {
        transform: rotate(180deg);
      }
    }
  `}
`;

function RoomType({ hotel, room_type, room_type: { is_available }, ...rest }) {
  const [opened, setOpened] = useState();
  const { t } = useTranslation();

  const handleToggle = () => setOpened(!opened);
  const notAvailableTariffs = !room_type.tariffs.find(t => t.is_available);

  return (
    <Wrapper
      opened={opened}
      {...rest}
    >
      <HeaderContainer>
        <Header>
          {room_type.name}
        </Header>

        {(!is_available || notAvailableTariffs) && (
          <DropdownButton onClick={handleToggle}>
            {opened
              ? t('Order.RoomType.DropdownAction.Opened')
              : t('Order.RoomType.DropdownAction.Closed')
            }

            <IconContainer>
              <CornerDown />
            </IconContainer>
          </DropdownButton>
        )}
      </HeaderContainer>

      {((room_type.is_available && !notAvailableTariffs) || opened) && (
        <Tariffs
          hotel={hotel}
          room_type={room_type}
          notAvailableTariffs={notAvailableTariffs || !room_type.is_available}
        />
      )}
    </Wrapper>
  );
}

RoomType.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object.isRequired,
  room_type: PropTypes.object.isRequired
};

RoomType.defaultProps = {
  className: ''
};

export default styled(RoomType)``;
