import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LikeIcon } from 'components/icons';

const ValueContainer = styled.div`
  margin-left: 6px;
  color: #4A515C;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${LikeIcon} {
    color: #05A51C;
  }
`;

function Thumb({ className, rating }) {
  return (
    <Wrapper className={className}>
      <LikeIcon />

      <ValueContainer>
        {rating}
      </ValueContainer>
    </Wrapper>
  );
}

Thumb.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number
};

export default styled(Thumb)``;
