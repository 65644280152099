import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from 'utils/localization';

import { ArrowLeft } from 'components/icons';
import { display, typography } from 'theme/mixins';

const Link = styled(NavLink)`
  ${display('flex', 'center', 'center')};
  color: #7892aa;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  ${typography(12, 14, 700)};

  ${ArrowLeft} {
    margin-right: 12px;
  }
`;

class BackLink extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string
  };

  static defaultProps = {
    className: '',
    children: t('UI.Button.Back'),
    to: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Link {...rest}>
        <ArrowLeft />
        {children}
      </Link>
    );
  }
}

export default styled(BackLink)``;
