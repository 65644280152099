import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Modal } from 'components/ui';
import { Error } from 'components/icons';

const Title = styled.p`
  color: #e26464;
  font-size: 20px;
`;

const Description = styled.p``;

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  ${Error} {
    font-size: 50px;
  }
`;

const ErrorModal = ({ className, opened, onClose }) => {
  return (
    <Modal className={className} opened={opened} onClose={onClose}>
      <Wrapper>
        <Error />
        <Title>Произошла непредвиденная ошибка</Title>
        <Description>Попробуйте еще раз или обратитесь в техподдержку</Description>
      </Wrapper>
    </Modal>
  );
};

ErrorModal.propTypes = {
  className: PropTypes.string,
  opened: PropTypes.bool,
  onClose: PropTypes.func
};

export default ErrorModal;
