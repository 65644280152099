import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CommonPart from '../CommonPart';
import Treatment from './Treatment';

const Container = styled.div`
  column-count: ${(props) => (props.isColumn ? 2 : 0)};
  width: 100%;
  display: inline-block;
`;

function Treatments({ className, hotel }) {
  const { t } = useTranslation();
  const specializationElements = hotel.specializations.map((treatment, index) => (
    <Treatment key={index} treatment={treatment} />
  ));

  return (
    <CommonPart title={t('Hotel.Details.Tabs.TreatmentPrograms')}>
      <Container isColumn={specializationElements.length > 1}>{specializationElements}</Container>
    </CommonPart>
  );
}

Treatments.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default Treatments;
