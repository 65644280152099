import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BusIcon, TrainIcon, PlaneIcon } from 'components/icons';
import { distanceTextFormation } from 'utils/distance';

const TextContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6b707b;
  opacity: 0.6;
  white-space: nowrap;
`;

const Icon = styled.div`
  font-size: 16px;
  width: 20px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

function Distance({ className, distance, withDescription }) {
  const { t } = useTranslation();
  const distancesUnit = {
    km: t('Common.Distance.Km'),
    m: t('Common.Distance.M'),
    min: t('Common.Distance.Min')
  };

  const distances = {
    plane: {
      text: t('Distances.FromAirport'),
      description: distance.description
    },
    plain: {
      text: t('Distances.FromAirport'),
      description: distance.description
    },
    bus: {
      text: t('Distances.FromBusStop'),
      description: distance.description
    },
    transfer: {
      text: t('Distances.FromBusStop'),
      description: distance.description
    },
    train: {
      text: t('Distances.FromRailwayStation'),
      description: distance.description
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'plane':
      case 'plain':
        return <PlaneIcon />;
      case 'transfer':
      case 'bus':
        return <BusIcon />;
      case 'train':
        return <TrainIcon />;
      default:
        return null;
    }
  };

  const [distanceText] = useMemo(() => {
    const { text, value } = distanceTextFormation(distance.name);
    return [text, value || distance.value];
  }, [distance]);

  const distanceElement = distances[distance?.icon].description;

  return (
    <Content>
      <Wrapper className={className}>
        <IconContainer>
          <Icon>{getIcon(distance.icon)}</Icon>
          <Description>{distanceText}</Description>
        </IconContainer>

        <TextContainer>
          {distance.value} {distancesUnit[distance.unit]}
        </TextContainer>
      </Wrapper>
      {withDescription && <Description>{distanceElement}</Description>}
    </Content>
  );
}

Distance.propTypes = {
  distance: PropTypes.object,
  className: PropTypes.string,
  withDescription: PropTypes.bool
};

export default Distance;
