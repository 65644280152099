import { t } from 'utils/localization';

export const items = [
  {
    name: 'last_name',
    label: t('Profile.LastName.Label'),
    placeholder: t('Profile.LastName.Label')
  },
  {
    name: 'first_name',
    label: t('Profile.FirstName.Label'),
    placeholder: t('Profile.FirstName.Label')
  },
  {
    name: 'middle_name',
    label: t('Profile.MiddleName.Label'),
    placeholder: t('Profile.MiddleName.Label')
  },
  {
    name: 'post',
    label: t('Auth.Register.Manager.Position.Label'),
    placeholder: t('Auth.Register.Manager.Position.Placeholder')
  },
  {
    name: 'email',
    label: t('Auth.FormFields.Email.Label'),
    placeholder: t('Auth.Register.Manager.Email.Placeholder')
  },
  {
    name: 'phone',
    label: t('Auth.Register.Manager.Phone.Label'),
    placeholder: t('Auth.Register.Manager.Phone.Placeholder')
  }
];
