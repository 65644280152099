import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/ui/Modal';

const Description = styled.div`
  max-width: 495px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4a515c;
`;

const ModalContainer = styled.div`
  width: 732px;
  background-color: #ffffff;
  max-width: 732px;
  min-width: 732px;
  padding: 26px 30px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(36, 95, 119, 0.211334);
  border-radius: 4px;

  h2 {
    background: #3aa6d2;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #4a515c;
  }

  h3 {
    max-width: 428px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #4a515c;
    margin-top: 20px;
  }

  ul {
    padding: 0;
    margin-top: 12px;

    li {
      list-style: none;
      margin-top: 8px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: #4a515c;
    }
  }
`;

const Wrapper = styled(Modal)`
  padding: 0;
`;

function AttentionModal({ className, attention, onCloseClick }) {
  const { t } = useTranslation();

  const description =
    attention?.description && attention.description.split('</h2>').filter((el) => !el.includes('<h2>'));

  return (
    <Wrapper className={className} onClose={onCloseClick} opened>
      <ModalContainer>
        <Title dangerouslySetInnerHTML={{ __html: t('Hotel.Attention.Title') }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </ModalContainer>
    </Wrapper>
  );
}

AttentionModal.propTypes = {
  className: PropTypes.string,
  attention: PropTypes.object,
  onCloseClick: PropTypes.func
};

export default AttentionModal;
