import { types } from 'mobx-state-tree';
import { Address } from './Address';
import { Creator } from './Creator';
import { Hotel } from './Hotel';
import { Traveller } from './Traveller';
import { Reservation } from './Reservation';
import { Resale } from './Resale';
import { Conference } from './Conference';
import PaymentInfo from './models/PaymentInfo';

import { Contract, ProviderState, PlatformState, InvoiceState } from 'stores/models';

const Order = types.model('Order', {
  id: types.identifier,
  address: types.maybeNull(Address),
  check_in: types.string,
  check_out: types.string,
  resale: types.maybeNull(Resale),
  created_at: types.string,
  creator: Creator,
  treatment: types.maybeNull(types.boolean),
  hotel: types.maybeNull(Hotel),
  order_code: types.string,
  price: types.number,
  net_price: types.number,
  gross_price: types.number,
  provider_state: ProviderState,
  platform_state: PlatformState,
  invoice_state: InvoiceState,
  payment_info: PaymentInfo,
  conference: types.maybeNull(Conference),
  contract: types.maybeNull(Contract),
  reservations: types.optional(types.array(Reservation), []),
  travellers: types.optional(types.array(Traveller), []),
  has_invoice: types.optional(types.boolean, false)
});

export { Order };
