import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import Qs from 'qs';
import { NavLink } from 'react-router-dom';
import { typography } from 'theme/mixins';
import getImage from 'utils/getImage';

import ProviderIcon from 'components/ui/ProviderIcon';

import { Image as ImageComponent } from 'components/ui';

const Title = styled.div`
  color: #4a515c;
  margin-bottom: 12px;
  ${typography(16, 20, 700)};
`;

const HotelUrl = styled(NavLink)`
  color: #3c6f9d;
  text-decoration: none;
  ${typography(13, 16, 400)};
`;

const Image = styled(ImageComponent)`
  width: 100%;
  height: auto;
  min-height: 123px !important;
  font-size: 32px;
  border-radius: 4px 4px 0 0;
`;

const Header = styled.div`
  position: relative;
  display: flex;
`;

const Body = styled.div`
  padding: 12px 16px 12px 15px;
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
`;

const Address = styled.div`
  margin: 10px 0 13px;
  color: #6b707b;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

function HotelCard({ hotel, ...rest }) {
  const queryParams = Qs.stringify({ hotel_id: hotel.id });
  const { t } = useTranslation();
  const hotelUrl = [window.location.pathname, queryParams].join('?');

  const getImgPath = (item, size) => {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  };

  const thumbSize = { width: 480, height: 246 };
  const { src } = getImgPath(hotel.images[0], thumbSize);

  return (
    <Wrapper {...rest}>
      <Header>{hotel.images.length > 0 && <Image src={src} alt={hotel.name} />}</Header>

      <Body>
        <Title>{hotel.name}</Title>
        <Address>{hotel.address.location}</Address>

        <BottomContainer>
          <HotelUrl to={hotelUrl}>{t('Hotel.One.More')}</HotelUrl>

          <ProviderIcon provider={hotel.provider} />
        </BottomContainer>
      </Body>
    </Wrapper>
  );
}

HotelCard.propTypes = {
  className: PropTypes.string,
  history: ReactRouterPropTypes.history,
  match: ReactRouterPropTypes.match,
  location: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  t: PropTypes.func
};

HotelCard.defaultProps = {
  className: '',
  comission: 0
};

export default styled(HotelCard)``;
