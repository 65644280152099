import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import Base from './Base';
import Package from './Package';

import { SuccessSmall } from 'components/icons';

const PeriodBadge = styled.div`
  padding: 0 8px;
  box-sizing: border-box;
  position: absolute;
  height: 24px;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
  background: #9CB4CA;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  color: #FFFFFF;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  ${({ isSelected, theme }) => css`
    width: inherit;
    padding: 16px 15px 19px 18px;
    background-color: ${theme.colors.light};
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
    cursor: pointer;
    position: relative;

    &:hover {
      border: 2px solid ${theme.colors.active.primary};
      padding: 14px 13px 17px 16px;
      
      ${PeriodBadge} {
        top: -14px;
      }
    }

    ${isSelected && css`
      border: 2px solid ${theme.colors.active.primary};
      padding: 14px 13px 17px 16px;

      ${PeriodBadge} {
        top: -14px;
      }
    `};
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  right: -8px;
  bottom: -6px;
  z-index: 1;
`;

@withTheme
@inject('bookingState')
@observer
class Tariff extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    room_type: PropTypes.object.isRequired,
    tariff: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    bookingState: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    onClick: () => null
  };

  @computed get isSelected() {
    const { bookingState, hotel, tariff, room_type } = this.props;
    const availability = { hotel, tariff, room_type };

    return bookingState.availabilitySelected({ availability });
  }

  @computed get minStayPeriod() {
    const {
      t,
      tariff: {
        billing_hour,
        restrictions: {
          min_length_of_stay,
          min_length_of_stay_arrival_based
        }
      }
    } = this.props;

    const minLengthOfStay = Math.max(min_length_of_stay.value, min_length_of_stay_arrival_based.value);

    if (!minLengthOfStay) return null;

    return t('Tariffs.Restriction.MinStayPeriod', { value: minLengthOfStay, unit: billing_hour });
  }

  handleSelect = (e) => {
    const { onClick, hotel, room_type, tariff } = this.props;
    onClick({ hotel, room_type, tariff });
  };

  get tariffComponent() {
    const { tariff, tariff: { type }, room_type, hotel } = this.props;

    switch (type) {
      case 'base':
        return <Base tariff={tariff} room_type={room_type} hotel={hotel} />;

      case 'package':
        return <Package tariff={tariff} room_type={room_type} hotel={hotel} />;

      default:
        return null;
    }
  }

  render() {
    const { className } = this.props;

    return (
      <Wrapper
        className={className}
        onClick={this.handleSelect}
        isSelected={this.isSelected}
      >
        {this.tariffComponent}
        {this.isSelected && (
          <IconWrapper>
            <SuccessSmall />
          </IconWrapper>
        )}

        {this.minStayPeriod && (
          <PeriodBadge>
            {this.minStayPeriod}
          </PeriodBadge>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Tariff))``;
