import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moment from 'utils/moment';

import Button from 'components/forms/Button';

import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';

import { Calendar as CalendarIcon } from 'components/icons';

import Checkbox from 'components/forms/Checkbox2/CheckboxElement';

const VISIBLE_FORMAT = 'DD.MM.YYYY';

const SettingsTop = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SettingsText = styled.div`
  color: #6b707b;
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  line-height: 15px;
  font-weight: 400;
  user-select: none;
`;

const Input = styled.input`
  flex-grow: 1;
  border-radius: 4px;
  height: 100%;
  font-size: 13px;
  color: #4a515c;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  height: 34px;
  width: 100%;
`;

const Content2 = styled.div`
  position: relative;
  height: 100%;
  font-size: 12px;

  ${CalendarIcon} {
    position: absolute;
    font-size: 18px;
    right: 12px;
    top: 8px;
  }
`;

const SettingsBot = styled.div`
  margin-top: 16px;
`;

const PriceContainer = styled.div`
  margin-top: 16px;
  position: relative;
`;

const PriceDescription = styled.div`
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  position: absolute;
  color: #6b707b;
  font-size: 13px;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  padding: 16px 10px;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #5ab2d6;
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(90, 178, 214, 0.7);

  .checkbox {
    input {
      display: none;
    }
  }

  ${Button} {
    width: 98px;
    margin-top: 16px;
    height: 32px;
  }
`;

const Resale = ({ data, actions, handleOpenDatepicker, onSave }) => {
  const { available_from_date, visible, price } = data;
  const { setDate, setPrice, toggleVisible } = actions;

  const handleChange = (e) => {
    setDate(e);
  };

  const handleChangePrice = (e) => {
    setPrice(e.target.value);
  };

  const calendar = useMemo(
    () => <Calendar format={VISIBLE_FORMAT} defaultValue={moment(available_from_date)} showToday={false} />,
    []
  );

  return (
    <Wrapper>
      <SettingsTop>
        <Checkbox className='checkbox' checked={visible} onChange={toggleVisible}>
          <SettingsText>Показать на сайте</SettingsText>
        </Checkbox>
      </SettingsTop>

      {visible && (
        <SettingsBot>
          <DatePicker
            onOpenChange={handleOpenDatepicker}
            animation='slide-up'
            calendar={calendar}
            onChange={handleChange}>
            {() => (
              <Content2>
                <Input readOnly value={moment(available_from_date).format('DD.MM.YYYY')} placeholder='test' />

                <CalendarIcon />
              </Content2>
            )}
          </DatePicker>

          <PriceContainer>
            <Input value={price} onChange={handleChangePrice} />

            <PriceDescription>Цена, ₽</PriceDescription>
          </PriceContainer>
        </SettingsBot>
      )}

      <Button rounded onClick={onSave}>
        Сохранить
      </Button>
    </Wrapper>
  );
};

Resale.propTypes = {
  data: PropTypes.object,
  handleOpenDatepicker: PropTypes.func,
  actions: PropTypes.object,
  onSave: PropTypes.bool
};

export default Resale;
