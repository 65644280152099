import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import RangeSelect from '../RangeSelect';

@observer
class MobxWrapper extends React.Component {
  handleChange = (value) => {
    const { field } = this.props;

    const [min, max] = value;
    field.update({ min, max });
  }

  render() {
    const { field, min, max, title, showInputs } = this.props;

    const value = Object.values(field.value);

    return (
      <RangeSelect
        value={value}
        min={min}
        max={max}
        title={title}
        onChange={this.handleChange}
        showInputs={showInputs}
      />
    );
  }
}

MobxWrapper.propTypes = {
  field: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
  showInputs: PropTypes.bool
};

export default MobxWrapper;
