import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as EmptyIcon } from './assets/EmptyIcon.svg';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  margin: 8px 0;
`;

const Icon = styled(EmptyIcon)`
  width: 114px;
  height: 114px;
`;

class Image extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}

        {!children && (
          <Icon />
        )}
      </Wrapper>
    );
  }
}

export default styled(Image)``;
