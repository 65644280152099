import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import arrayToTree from 'array-to-tree';
import { useTranslation } from 'react-i18next';

import Group, { groupClassName, titleClassName } from './Group';
import CommonPart from '../CommonPart';

const Wrapper = styled.div`
  column-count: 2;

  ${groupClassName}:first-child {
    ${titleClassName} {
      margin-top: 7px;
      line-height: 20px;
    }
  }
`;

function Amenities({ className, hotel }) {
  const { t } = useTranslation();
  const amenities = useMemo(() => {
    return arrayToTree(hotel.amenities || [], {
      parentProperty: 'parent_tag',
      customID: 'tag'
    });
  }, [hotel]);

  const elements = amenities.map((group) => <Group group={group} key={group.id} />);

  return (
    <CommonPart title={t('Hotel.Details.Tabs.Infrastructure')}>
      <Wrapper className={className}>{elements}</Wrapper>
    </CommonPart>
  );
}

Amenities.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default Amenities;
