import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useInvoices from './hooks/useInvoices';

import Header from './Header';
import Invoice, { List } from 'components/ui/Invoice';

const Wrapper = styled.div`
  padding: 20px 0;

  ${Header} + ${List} {
    margin-top: 30px;
  }
`;

function Invoices({ className }) {
  const [{ meta, balance, data }, { doPayFromAssetBalance, doInfo, doDownload }] = useInvoices();

  const handlePayFromAssetBalance = (invoice) => () => {
    return doPayFromAssetBalance(invoice);
  };

  const handleInfo = (invoice) => () => {
    doInfo(invoice);
  };

  const handleDownload = (invoice) => () => {
    return doDownload(invoice);
  };

  return (
    <Wrapper className={className}>
      <Header meta={meta} />

      <List>
        {data.map((invoice) => (
          <Invoice
            key={invoice.number}
            assetBalance={balance}
            invoice={invoice}
            onPayFromAssetBalance={handlePayFromAssetBalance(invoice)}
            onInfo={handleInfo(invoice)}
            onDownload={handleDownload(invoice)}
          />
        ))}
      </List>
    </Wrapper>
  );
}

Invoices.propTypes = {
  className: PropTypes.string
};

export default styled(Invoices)``;
