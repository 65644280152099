import { types } from 'mobx-state-tree';
import { Categories } from './Categories';

export const BestReviews = types.model('BestReviews', {
  user_name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  categories: types.optional(types.array(Categories), [])
});
