import _some from 'lodash/some';
import { t } from 'utils/localization';

const stopSalesMessage = {
  type: 'alert',
  message: t('BookingForm.Messages.StopSales')
};

function hasStopSale(price) {
  return price.stop_sales;
}

function stopSalesAlertMessage({ tariff, prices }) {
  const { billing_hour } = tariff;

  const items = billing_hour === 'night'
    ? prices.slice(0, -1)
    : prices;

  const hasStopSales = _some(items, (p) => hasStopSale(p));
  if (hasStopSales) return stopSalesMessage;

  return undefined;
}

export default stopSalesAlertMessage;
