import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ active, theme }) => css`
    color: ${theme.colors.text.primary};
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    padding: 8.5px 10px;
    text-decoration: none;
    ${typography(14, 16, 400)};
    transition: color 0.25s;

    ${active &&
    css`
      color: ${theme.colors.light};
      cursor: default;
      background: ${theme.colors.primaryTitle};
      ${typography(14, 16, 700)};
    `};
  `}
`;

@withRouter
@withTheme
class Tab extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    location: ReactRouterPropTypes.location,
    onClick: PropTypes.func,
    active: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  get isActive() {
    const { active, to, location } = this.props;

    const re = new RegExp(`^${to}`);
    return !!location.pathname.match(re) || active;
  }

  render() {
    const { className, children, to, onClick } = this.props;

    return (
      <Wrapper
        className={className}
        as={to && NavLink}
        to={to}
        active={this.isActive ? 'active' : undefined}
        onClick={onClick}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Tab)``;
