import _uniqBy from 'lodash/uniqBy';
import { t } from 'utils/localization';

const STOR_ID = 'snt_recent_requests_data_v1';
const STOR_MAX_ITEMS = 20;

function registerData(value) {
  let data = fetchData();
  // eslint-disable-next-line no-use-before-define
  data = _uniqBy([value, ...data], 'label');
  data = data.slice(0, STOR_MAX_ITEMS);
  data = JSON.stringify(data);

  localStorage.setItem(STOR_ID, data);
}

function fetchData() {
  const data = localStorage.getItem(STOR_ID) || '[]';
  return JSON.parse(data);
}

function fetchRecentRequests(chars, { maxRecents }) {
  const title = t('Filter.Places.RecentQueries.Title');

  let data = fetchData();
  data = data.slice(0, maxRecents);

  return Promise.resolve({ title, data });
}

export { registerData, fetchData };

export default fetchRecentRequests;
