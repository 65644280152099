import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'components/v2/ui/Tooltip';
import { t } from 'utils/localization';

import { ArriveArrowIcon } from 'components/icons';

const OverlayTitle = styled.div`

`;

const OverlayContent = styled.div`

`;

const Overlay = styled.div``;

const Wrapper = styled.div``;

class ArrivalClose extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number
  };

  render() {
    const { className, max, min } = this.props;
    const overlaytext = (
      <Overlay>
        <OverlayTitle>
          {t('Order.Calendar.Tooltip.ClosedArrival.Enter')}
        </OverlayTitle>

        {(max || min) && (
          <OverlayContent dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.LengthOfStay', { max, min }) } } />
        )}
      </Overlay>
    );

    return (
      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <Wrapper className={className}>
          <ArriveArrowIcon />
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(ArrivalClose)``;
