import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Description from './Description';
import ModalDescription from './ModalDescription';

function RoomTypeDescription({ variant, ...rest }) {
  switch (variant) {
    case 'modal':
      return <ModalDescription {...rest} />;

    default:
      return <Description {...rest} />;
  }
}

RoomTypeDescription.propTypes = {
  variant: PropTypes.string
};

export default styled(RoomTypeDescription)``;
