import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Field, SelectX } from 'components/forms';

@withTranslation()
@observer
class GenderField extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { field, className } = this.props;

    return (
      <Field
        field={field}
        items={field.extra}
        component={SelectX}
        className={className}
      />
    );
  }
}

export default styled(GenderField)``;
