import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'connection/instance';

const updateUser = createAsyncThunk('users/update', async (user) => {
  const { data: response } = await client.put(`/api/users/${user.id}`, { data: user });
  const { id, ...changes } = response.data;

  return { id, changes };
});

export default updateUser;
