import { t } from 'utils/localization';
import { tariffsRestriction } from 'utils/helpers';
import _compact from 'lodash/compact';

function baseDailyRestriction(tariff) {
  const restriction = tariffsRestriction(tariff);
  if (!restriction) return undefined;

  return {
    type: 'error',
    message: t('Tariff.ReservationFromDays', restriction)
  };
}

function packageDailyRestriction(tariff) {
  const restriction = tariffsRestriction(tariff);
  if (!restriction) return undefined;

  return {
    type: 'error',
    message: t('Tariffs.CourseDuration', restriction)
  };
}

function dailyRestriction(tariff) {
  const { type } = tariff;

  switch (type) {
    case 'base':
      return baseDailyRestriction(tariff);

    case 'package':
      return packageDailyRestriction(tariff);

    default:
      return undefined;
  }
}

function parseTariffsRestriction(tariff) {
  const dailyHint = dailyRestriction(tariff);
  return _compact([dailyHint]);
}

export default parseTariffsRestriction;
