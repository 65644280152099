import { Field } from 'mobx-react-form';
import { action, observable, computed } from 'mobx';
import cleanDeep from 'clean-deep';

class SlotField extends Field {
  // Soft destroy
  @computed get isPersisted() {
    return this.has('id') && this.$('id').value.length > 0;
  }

  @computed get isDeleted() {
    return this.has('_destroy') && this.$('_destroy').value;
  }

  @action
  destroy() {
    this.isPersisted ? this.update({ _destroy: true }) : this.del();
  }

  // Editable mode
  @observable isEdit = false;

  @action
  setAsEdit() {
    this.isEdit = true;
  }

  @action
  unsetAsEdit() {
    this.isEdit = false;
  }

  // Traveller attributes
  @computed get hasTraveller() {
    return !!this.traveller;
  }

  @computed get traveller() {
    let traveller = this.$('traveller').value;
    traveller = cleanDeep(traveller);
    return traveller.id ? traveller : undefined;
  }
}

export default SlotField;
