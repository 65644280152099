import { types } from 'mobx-state-tree';

import { Price, PriceFormation } from 'stores/models';

const PricePeriod = types.model('PricePeriod', {
  value: types.number,
  unit: types.enumeration(['day', 'night'])
});

const FeedType = types.model('FeedType', {
  tag: types.string,
  name: types.string
});

const PenaltyRule = types.model('PenaltyRule', {
  amount: types.number,
  unit: types.enumeration(['rub', 'percent', 'first_day'])
});

const MealOption = types.model('MealOption', {
  tag: types.string,
  name: types.string,
  quantity: types.maybeNull(types.number)
});

const DeadlineRule = types.model('DeadlineRule', {
  amount: types.number,
  unit: types.enumeration(['day', 'month'])
});

const AgePeriod = types.model('AgePeriod', {
  from: types.number,
  to: types.number
});

const PolicyRule = types.model('PolicyRule', {
  deadline: DeadlineRule,
  penalty: PenaltyRule
  // _id: types.identifier
});

const CancellationPolicy = types.model('CancellationPolicy', {
  has_full_refund: types.optional(types.boolean, true),
  has_non_refund: types.optional(types.boolean, false),
  policy_rules: types.optional(types.array(PolicyRule), [])
});

const AvailablePeriod = types.model('AvailablePeriod', {
  from: types.string,
  to: types.string
});

const ManipulationOption = types.model('AvailablePeriod', {
  name: types.string,
  quantity: types.optional(types.array(types.number), [])
});

const Manipulation = types.model('AvailablePeriod', {
  options: types.optional(types.array(ManipulationOption), []),
  quantity: types.optional(types.array(types.number), []),
  disclaimer: types.maybeNull(types.string)
});

const ServiceObject = types.model('Service', {
  options: types.optional(types.array(ManipulationOption), []),
  disclaimer: types.maybeNull(types.string),
  quantity: types.optional(types.array(types.number), [])
});

const StayPeriod = types.model('StayPeriod', {
  min_days: types.number
});

const Service = types.model('Service', {
  id: types.identifier,
  name: types.string,
  description: types.string,
  pace: types.string,
  pace_name: types.string,
  quantity: types.number
});

const Traveller = types.model('Traveller', {
  age_group: types.string,
  age: types.maybeNull(types.number)
});

const Rate = types.model('Rate', {
  type: types.string,
  description: types.string,
  age_group: types.enumeration(['adult', 'child']),
  age_period: types.maybeNull(AgePeriod),
  occupation: types.enumeration(['main', 'extra', 'no']),
  count: types.number
});

const Slot = types.model('Slot', {
  traveller: Traveller,
  rate: Rate
});

const Tariff = types.model('Tariff', {
  id: types.identifier,
  check_in: types.string,
  check_out: types.string,
  tariff_id: types.string,
  name: types.string,
  type: types.enumeration(['base', 'package', 'tour']),
  description: types.string,
  multi_seat: types.boolean,
  meal_disclaimer: types.maybeNull(types.string),
  cancellation_policy: types.maybeNull(CancellationPolicy),
  price: Price,
  discount: Price,
  discount_price: Price,
  discount_percent: types.optional(types.number, 0),
  price_period: PricePeriod,
  price_formation: PriceFormation,
  feed_type: types.maybeNull(FeedType),
  meal_option: types.maybeNull(MealOption),
  available_period: AvailablePeriod,
  service: types.maybeNull(ServiceObject),
  stay_period: StayPeriod,
  rates: types.array(Rate),
  services: types.array(Service),
  billing_hour: types.enumeration(['day', 'night']),
  restrictions: types.frozen(),
  price_calculation_rule: types.enumeration(['float', 'fixed']),
  slots: types.array(Slot),
  manipulation: Manipulation,
  is_available: types.boolean
});

export default Tariff;
