import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Prompt } from 'components/ui';
import { Modal } from 'components/molecules';
import { Button } from 'components/forms';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

@inject('orderStore')
@observer
class OrderCancelDialog extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isOpened: PropTypes.bool,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    orderStore: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isLoading() {
    const { orderStore } = this.props;
    return orderStore.isPending;
  }

  handleOrderCancel = async () => {
    const { orderStore, onCancel } = this.props;

    await orderStore.cancel();

    if (onCancel) {
      onCancel();
    }

    this.handleClose();
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  render() {
    const { isOpened, t, ...rest } = this.props;

    return (
      <Modal
        opened={isOpened}
        onClose={this.handleClose}
      >
        <Prompt {...rest}>
          <Prompt.Content>
            <Prompt.Title>
              {t('Order.Remove.Caption')}
            </Prompt.Title>
          </Prompt.Content>

          <Prompt.Footer>
            <Prompt.Buttons>
              <Button
                onClick={this.handleOrderCancel}
                loading={this.isLoading}
                rounded
                variant='danger'
              >
                {t('Order.Remove.Accept')}
              </Button>

              <Button
                onClick={this.handleClose}
                rounded
                variant='secondary'
              >
                {t('Order.Remove.Cancel')}
              </Button>
            </Prompt.Buttons>
          </Prompt.Footer>
        </Prompt>
      </Modal>
    );
  }
}

export default styled(withTranslation()(OrderCancelDialog))``;
