import moment from 'utils/moment';
import _some from 'lodash/some';

function dailyPrice({ priceItem, rates, withCommission }) {
  const priceRates = rates.map((type) => priceItem.rates.find((r) => r.type === type));

  const errPriceRates = _some(priceRates, (item) => !item);
  if (errPriceRates) return -1;

  return priceRates.reduce((sum, r) => {
    const value = withCommission ? r.net_price : r.gross_price;
    return sum + value;
  }, 0);
}

function dailyAvailable({ priceItem, rates }) {
  return priceItem.available;
}

function convertPricesItem({ priceItem, rates, withCommission, range }) {
  const price = dailyPrice({ priceItem, rates, withCommission });
  const available = dailyAvailable({ priceItem, rates });
  const day = moment(priceItem.day).format('Y-MM-DD');
  const { restrictions, stop_sales, available_beds } = priceItem;

  const newRestrictions = {
    ...restrictions,
    closed_by_date_restrictions: {
      ...restrictions.closed_by_date_restrictions,
      closed_to_arrival:
        range?.filter((a) => a)?.length === 1 ? false : restrictions.closed_by_date_restrictions.closed_to_arrival
    }
  };
  return { day, price, available, stop_sales, available_beds, restrictions: newRestrictions };
}

function pricesPerDayForRates({ prices, rates, withCommission, range }) {
  const pricesList = prices.map((priceItem) => convertPricesItem({ priceItem, rates, withCommission, range }));

  let pricesMap = pricesList.map((priceItem) => [priceItem.day, priceItem]);
  pricesMap = new Map(pricesMap);

  const pricesDisabled = pricesList.filter(
    ({
      price,
      available,
      stop_sales,
      restrictions: {
        closed_by_date_restrictions: { closed_to_departure, closed_to_arrival }
      }
    }) => price <= 0 || available <= 0 || stop_sales || closed_to_departure || closed_to_arrival
  );

  return { pricesList, pricesMap, pricesDisabled };
}

export default pricesPerDayForRates;
