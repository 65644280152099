import parseSearchString from './parseSearchString';
import cleanQuery from './cleanQuery';
import mergeQuery from '../mergeQuery';

import { defaultPrice } from 'utils/prices';
import moment from 'utils/moment';

const DEFAULT_QUERY = {
  check_in: moment().add(7, 'days').format('YYYY-MM-DD'),
  check_out: moment().add(14, 'days').format('YYYY-MM-DD'),
  address: null,
  hotel_ids: [],
  travellers: [
    { age_group: 'adult', age: 0 }
  ],
  filter: {
    chars: null,
    accommodation_features: [],
    amenities: [],
    location_types: [],
    meal_options: [],
    treatment_factors: [],
    treatment_profiles: [],
    hotel_types: [],
    price: defaultPrice,
    instant_booking: 1
  },
  sort: {
    sort_by: 'rating',
    direction: -1
  },
  rating: 0,
  paginate: {
    page: 1,
    limit: 50
  }
};

function parseSearchQuery(asPath, { initialQuery }) {
  let value = parseSearchString(asPath);
  value = mergeQuery(DEFAULT_QUERY, value);
  value = mergeQuery(value, initialQuery);
  value = cleanQuery(value);

  return value;
}

export { DEFAULT_QUERY };

export default parseSearchQuery;
