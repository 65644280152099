import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { fetchAll, removeOne } from 'redux/stores/notificationsSlice/actions';
import { selectAll } from 'redux/stores/notificationsSlice/selectors';

import Notification from './Notification';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 310px;
  top: 84px;
  right: 36px;
  z-index: 9999;

  ${Notification} + ${Notification} {
    margin-top: 10px;
  }
`;

function Notifications({ className }) {
  const dispatch = useDispatch();

  const notifications = useSelector(selectAll);

  useEffect(() => {
    dispatch(fetchAll());
  }, []);

  const handleRemove = (notification) => {
    dispatch(removeOne(notification));
  };

  return (
    <Wrapper className={className}>
      {notifications.map((item, i) => (
        <Notification
          key={i}
          type={item.type}
          notification={item}
          onRemove={handleRemove}
        />
      ))}
    </Wrapper>
  );
}

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
