import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import withClickOutside from 'react-click-outside';

import { Field, SelectX } from 'components/forms';
import { Form } from 'components/ui';

import { StateForm } from './forms';

const Wrapper = styled(Form)``;

@observer
class StateFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.form = new StateForm({}, { hooks });
  }

  handleClickOutside() {
    this.handleCancel();
  }

  handleSuccess = (form) => {
    const { filter: { label: header, type }, onSubmit } = this.props;

    const { value, label } = form.$(type).value;

    onSubmit({ type, value, label: `${header}: ${label}` });
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  }

  handleChange = (value) => {
    const { filter } = this.props;

    this.form.$(filter.type).set(value);
    this.form.submit();
  }

  render() {
    const { filter, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        form={this.form}
      >
        <Field
          field={this.form.$(filter.type)}
          component={SelectX}
          items={this.form.$(filter.type).extra}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </Wrapper>
    );
  }
}

export default styled(withClickOutside(StateFilter))``;
