import React from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Caption from './Caption';
import Details from './Details';

const DetailsWrapper = styled.div`
  grid-column: span 7;
`;

const Wrapper = styled.div`
  ${({ theme, isActive }) => css`
    padding: 12px 20px 18px 20px;
    margin-left: -20px;
    margin-right: -20px;

    &:nth-child(even) {
      background-color: ${theme.colors.passive.secondary};
    }

    &:hover {
      cursor: pointer;
      background-color: #E8ECF1;
    }

    ${isActive && css`
      outline: 1px solid ${theme.colors.active.primary};
      box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
    `}
  `}
`;

@observer
class Row extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleClick = (e) => {
    e.preventDefault();

    const { onSelect, order } = this.props;
    onSelect(order);
  };

  render() {
    const { order, isActive, className } = this.props;

    return (
      <Wrapper
        isActive={isActive}
        className={className}
        onClick={this.handleClick}
      >
        <Caption
          order={order}
        />

        {isActive && (
          <DetailsWrapper>
            <Details order={order} />
          </DetailsWrapper>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Row))``;
