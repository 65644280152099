import { cartesianProduct } from 'js-combinatorics';
import _every from 'lodash/every';
import _some from 'lodash/some';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import _range from 'lodash/range';

const ENABLED_OCCUPATIONS = ['main', 'extra'];

let beds = 0;
let extraBeds = 0;

function filterByAdultOnExtraBeds(rates) {
  let cond;

  const mSeats = rates
    .filter(r => r.occupation === 'main');

  const eSeats = rates
    .filter(r => r.occupation === 'extra');

  cond = _some(eSeats, r => r.age_group === 'adult');
  if (!cond) return true;

  cond = _some(mSeats, r => r.age_group === 'child');
  if (cond) return false;

  return true;
}

function filterByAloneChild(rates) {
  const mainRates = rates
    .filter(r => r.occupation === 'main');

  const cond = _every(mainRates, r => r.age_group === 'child');
  return !cond;
}

function mainFilter({ rate }) {
  return rate.occupation === 'main';
}

function extraFilter({ rate }) {
  return rate.occupation === 'extra';
}

function notMsFilter({ rate, count }) {
  return rate.count === count || rate.age_group === 'child';
}

function msFilter({ rate, ms }) {
  return rate.count === 0;
}

function mainCombination({ rates, count }) {
  // MultiSeat occupation
  let msO = rates
    .filter(rate => mainFilter({ rate }))
    .filter(rate => notMsFilter({ rate, count }));

  msO = getCombination({ rates: msO, count });

  // Not MultiSeat occupation
  let notMsO = rates
    .filter(rate => mainFilter({ rate }))
    .filter(rate => msFilter({ rate, count }));

  notMsO = getCombination({ rates: notMsO, count });

  return [...msO, ...notMsO];
}

function extraCombination({ rates, count }) {
  const occupation = rates
    .filter(rate => extraFilter({ rate }));

  return getCombination({ rates: occupation, count });
}

function getCombination({ rates, count }) {
  if (count === 0 || rates.length === 0) return [];

  let occupation = Array.from({ length: count }, (v, i) => rates);
  occupation = cartesianProduct(...occupation).toArray();

  occupation = _uniqBy(occupation, o => {
    const value = o.map(r => r.type);
    return _sortBy(value).join('');
  });

  return occupation;
}

function filterAndSortOccupation(occupation) {
  let data = occupation
    .filter(rates => filterByAdultOnExtraBeds(rates))
    .filter(rates => filterByAloneChild(rates));

  data = _sortBy(data, ['count', 'age_group']);

  return data.reverse();
}

function getOccupationPer({ rates, count }) {
  const mainCount = Math.min(...[beds, count]);
  const mainC = mainCombination({ rates, count: mainCount });

  let extraCount = Math.max(...[0, count - mainCount]);
  extraCount = Math.min(...[extraBeds, extraCount]);
  const extraC = extraCombination({ rates, count: extraCount });

  const occupation = [];

  mainC.forEach(m => {
    if (extraC.length === 0) {
      occupation.push(m);
      return;
    }

    extraC.forEach(c => {
      occupation.push([...m, ...c]);
    });
  });

  return filterAndSortOccupation(occupation);
}

function getOccupation({ room_type, tariff }) {
  beds = room_type.beds;
  Object.freeze(beds);

  extraBeds = room_type.extra_beds;
  Object.freeze(extraBeds);

  const rates = tariff.rates.filter(r => ENABLED_OCCUPATIONS.includes(r.occupation));
  const count = beds + extraBeds;

  return _range(1, count + 1).reduce((obj, i) => {
    obj[i] = getOccupationPer({ rates, count: i });
    return obj;
  }, {});
}

export default getOccupation;
