import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Distances from './Distances';
import MapModal from './MapModal';

const DistancesContainer = styled.div``;

const ShowButton = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #3c6f9d;
  cursor: pointer;
`;

const MapInformationContainer = styled.div`
  border: 1px solid #e4e8ee;
  border-radius: 4px;
  padding: 20px 20px 27px 20px;
`;

const Wrapper = styled.div``;

function MapPage({ hotel, hotel: { distances }, className }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState();

  const distancesElement = distances.length > 0 && (
    <DistancesContainer>
      <Distances distances={distances} />
    </DistancesContainer>
  );

  const handleShowClick = () => {
    setOpened(true);
  };

  const handleCloseClick = () => {
    setOpened(false);
  };

  return (
    <Wrapper className={className}>
      <MapInformationContainer>
        {distancesElement}

        <ShowButton onClick={handleShowClick}>{t('Common.Distances.More')}</ShowButton>
      </MapInformationContainer>

      {opened && <MapModal hotel={hotel} onCloseClick={handleCloseClick} withDescription />}
    </Wrapper>
  );
}

MapPage.propTypes = {
  hotel: PropTypes.object,
  className: PropTypes.string
};

export default MapPage;
