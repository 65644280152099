import _mergeWith from 'lodash/mergeWith';

function mergePayments(payments) {
  const customizer = (sumValue, objValue) => {
    const sumPrice = sumValue?.price || 0;
    const objPrice = objValue?.price || 0;

    return { price: sumPrice + objPrice };
  };

  return payments.reduce((sum, value) => _mergeWith(sum, value, customizer), {});
}

export default mergePayments;
