import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Logo } from 'components/icons';
import { Menu } from 'components/molecules';
import { CommissionButton, Balance, QuestionButton } from 'components/ui';
import { UserBar } from './components';

import { flexCenter, display } from 'theme/mixins';

const InnerWrapper = styled.div`
  ${display('flex', 'center', 'space-between')};
  width: 100%;
  margin: 0 auto;
`;

const MenuContainer = styled.div`
  ${display('flex', 'center', 'flex-start')};
`;

const LogoWrapper = styled.div`
  ${flexCenter()};
  font-size: 38px;
  margin: 0 20px 0 0;
`;

const QuestionButtonWrapper = styled.div`
  margin-left: 4px;
  margin-top: 4px;
`;

const CommissionWrapper = styled.div``;

const BalanceWrapper = styled.div``;

const InnerS = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'space-between')};
    width: 100%;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}

  ${BalanceWrapper} + ${CommissionWrapper} {
    margin-left: 40px;
  }

  ${CommissionWrapper} + ${UserBar} {
    margin-left: 32px;
  }
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    background: ${theme.colors.active.secondary};
    color: ${theme.colors.light};
  `}

  ${MenuContainer} {
    flex-grow: 1;
  }
`;

@withRouter
class Header extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    location: ReactRouterPropTypes.location.isRequired,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ),
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { location, t, ...rest } = this.props;

    let navigation = [
      {
        id: '1',
        isActive: location.pathname.match(/^\/orders/),
        title: t('Pages.Orders'),
        to: '/orders',
        visible: process.env.REACT_APP_V_ORDERS
      },

      {
        id: '2',
        isActive: location.pathname.match(/^\/resale/),
        title: t('Pages.Resale'),
        to: '/resale',
        visible: process.env.REACT_APP_V_ORDERS
      },
      {
        id: '3',
        isActive: location.pathname.match(/^\/reports/),
        title: t('Pages.Reports'),
        to: '/reports',
        visible: process.env.REACT_APP_V_REPORTS
      },
      {
        id: '4',
        isActive: location.pathname.match(/^\/finances/),
        title: t('Pages.Finances'),
        to: '/finances',
        visible: process.env.REACT_APP_V_FINANCE
      },
      {
        id: '5',
        isActive: location.pathname.match(/^\/statistics/),
        title: t('Pages.Statistics'),
        to: '/statistics',
        visible: process.env.REACT_APP_V_STATISTICS
      },
      {
        id: '6',
        isActive: location.pathname.match(/^\/settings/),
        title: t('Pages.Settings'),
        to: '/settings',
        visible: process.env.REACT_APP_V_SETTINGS
      }
    ];

    navigation = navigation.filter((item) => item.visible === 'true');

    return (
      <Wrapper {...rest}>
        <InnerS>
          <InnerWrapper>
            <LogoWrapper>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </LogoWrapper>

            <MenuContainer>
              <Menu items={navigation} />

              <QuestionButtonWrapper>
                <QuestionButton />
              </QuestionButtonWrapper>
            </MenuContainer>
          </InnerWrapper>

          <BalanceWrapper>
            <Balance />
          </BalanceWrapper>

          <CommissionWrapper>
            <CommissionButton />
          </CommissionWrapper>

          <UserBar />
        </InnerS>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
