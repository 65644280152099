import { useContext } from 'react';
import EventsContext from './context';

const useEvents = () => {
  const context = useContext(EventsContext);

  if (context == null) {
    throw new Error(
      // eslint-disable-next-line no-use-before-define
      'No context provided: useEvents() can only be used in a descendant of <EventsContainer>'
    );
  }

  return context;
};

export default useEvents;
