import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Tabs as BaseTabs } from 'components/molecules';

const Wrapper = styled.div`
  ${BaseTabs} {
    margin-bottom: 2px;
  }
`;

const Content = styled.div``;

@observer
class Tabs extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        component: PropTypes.object
      })
    ),
    value: PropTypes.string
  };

  static defaultProps = {
    className: '',
    tabs: []
  };

  @observable value = null;

  constructor(props) {
    super(props);
    const { value, tabs } = this.props;

    this.value = value || tabs[0]?.value;
  }

  onClick = (value) => (e) => {
    e.preventDefault();

    this.value = value;
  };

  get tabs() {
    const { tabs } = this.props;
    const { onClick, value } = this;

    return tabs.map((tab) => ({
      label: tab.label,
      active: tab.value === value,
      onClick: onClick(tab.value)
    }));
  }

  get Component() {
    const { value } = this;
    const { tabs } = this.props;

    const tab = tabs.find((tab) => tab.value === value);

    return tab ? tab.component : null;
  }

  render() {
    const { tabs, Component } = this;
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <BaseTabs tabs={tabs} />

        <Content>
          <Component {...rest} />
        </Content>
      </Wrapper>
    );
  }
}

export default styled(Tabs)``;
