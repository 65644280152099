export default {
  primary: '#315efb',
  primaryDark: '#4a515c',
  primaryTitle: '#9cb4ca',
  danger: '#ff564e',
  warn: '#ffc000',
  black: '#000000',
  dark: '#262626',
  light: '#ffffff',
  fullDark: '#000000',
  blueDark: '#343b4c',
  blueLight: '#d9f3ff',
  arrowBlueDark: '#6674a6',
  snackbarBlueDark: '#3d4454',
  purpleDeep: '#3e217d',
  controls: {
    default: '#cccccc', // рамки кнопок
    disabled: '#dde0e8', // задизейбленные контролы
    icon: 'rgba(94, 94, 94, 0.4)', // иконки
    background: '#f5f7f8', // бэкграунд кнопок
    lightBackground: '#eef2f4', // бэкграунд иконки на нотификациях
    activeBackground: 'rgba(141, 150, 178, 0.2)', // бэкграунд при :active состоянии на checkbox
    outline: '#8d96b2', // обводка на outline контролах
    fieldOutline: '#dde0e8', // обводка на outline контролах
    iconBackground: '#f3f4f7',
    placeholder: '#a4a9b8',
    fieldIcon: '#b0b4c2',
    disabledText: '#afb5c9'
  },
  active: {
    primary: '#5ab2d6',
    secondary: '#9cb4ca'
  },
  passive: {
    primary: '#f2f4f6',
    secondary: '#f5f6f8'
  },
  success: {
    primary: '#62cb94',
    secondary: '#cbf5df'
  },
  error: {
    primary: '#e26161',
    secondary: '#fdf1f1'
  },
  button: {
    primary: '#5ab2d6',
    primaryHover: '#3aa6d2',
    primaryActive: '#2e96c1',
    primaryDisabled: '#add9eb',
    secondary: '#6a8dae'
  },
  popup: {
    primary: '#d1d5db',
    primaryHover: '#d1d8e3'
  },
  text: {
    primary: '#4a515c',
    secondary: '#6b707b',
    disabled: '#979ba0',
    green: '#51C086'
  },
  filter: {
    primary: '#eff2f5'
  },
  ui: {
    primary: '#3c6f9d'
  }
};
