import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroller from 'react-infinite-scroller';
import styled from 'styled-components';

import { Loader } from '.';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled(InfiniteScroller)``;

class InfiniteScroll extends React.Component {
  static propTypes = {
    children: ReactComponentChildrenPropType,
    pagination: PropTypes.shape({
      current: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }),
    onLoadMore: PropTypes.func.isRequired
  };

  get hasMore() {
    const {
      pagination: {
        current,
        total
      }
    } = this.props;

    return current < total;
  }

  onLoadMore = () => {
    const { onLoadMore, pagination } = this.props;

    if (this.hasMore) {
      onLoadMore(pagination.current + 1);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Wrapper
        pageStart={0}
        loadMore={this.onLoadMore}
        loader={<Loader key={0} />}
        hasMore={this.hasMore}
      >
        {children}
      </Wrapper>
    );
  }
}

export default styled(InfiniteScroll)``;
