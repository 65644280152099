import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Star } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 9px;
  
  path {
    fill: #F5B257;
  }

  ${Star} + ${Star} {
    margin-left: 4px;
  }
`;

class Stars extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    stars: PropTypes.number
  }

  renderStars = () => {
    const { stars } = this.props;

    return Array(stars).fill(1).map((item, index) => (
      <Star key={index} />
    ));
  }

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        {this.renderStars()}
      </Wrapper>
    );
  }
}

export default styled(Stars)``;
