import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import List from '../List';

import { calculatePenaltyPrice } from 'pages/Orders/containers/Edit/utils/calculatePenaltyPrice';

const format = 'dd MMMM yyyy г. HH:mm';

const Wrapper = styled.div``;

function Cancellation({ className, tariff, price, slots, check_in, ...rest }) {
  const { t } = useTranslation();

  const { cancellation_policy } = tariff;

  const cancellationPolicy = useMemo(() => {
    const cancellationDate = DateTime.fromISO(check_in, { locale: 'ru-RU' }).toFormat(format);

    if (!cancellation_policy) return [t('Tariffs.Cancellation.FullRefund', { date: cancellationDate })];

    if (cancellation_policy.has_full_refund) {
      return [t('Tariffs.Cancellation.FullRefund', { date: cancellationDate })];
    }

    if (cancellation_policy.has_non_refund) {
      return [t('Tariffs.Cancellation.NonRefund', { price })];
    }

    return cancellation_policy.policy_rules
      .sort((a, b) => b?.deadline?.amount - a?.deadline?.amount)
      .map((rule) => {
        const penaltyDate = DateTime.fromISO(check_in, { locale: 'ru-RU' })
          .minus({
            day: rule?.deadline?.amount
          })
          .toFormat(format);

        return t('Tariffs.CancellationPolicy.Deadline', {
          check_in: penaltyDate,
          price: calculatePenaltyPrice(rule, price, slots)
        });
      });
  }, [tariff, check_in, slots, price]);

  return (
    <Wrapper className={className}>
      <List title={t('Tariff.Cancellation.Title')} items={cancellationPolicy} {...rest} />
    </Wrapper>
  );
}

Cancellation.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object,
  price: PropTypes.number,
  slots: PropTypes.array,
  check_in: PropTypes.string
};

export default styled(Cancellation)``;
