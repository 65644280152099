import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import withClickOutside from 'react-click-outside';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Menu as MenuBase } from 'rambler-ui/Menu';

import { Item } from '.';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  position: relative;
  user-select: none;
`;

const Container = styled(MenuBase)`
  ${({ isOpened, isBottomPosition, isRightPosition, theme }) => css`
    position: absolute;
    z-index: 1;
    padding: 4px 0;
    background: ${theme.colors.light};
    border: 1px solid ${theme.colors.active.secondary};
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;
    transition:
      bottom 0.25s,
      top 0.25s,
      opacity 0.25s;
    
    ${isBottomPosition
      ? css`
        bottom: 8px;
      `
      : css`
        top: 8px;
      `}

    ${isRightPosition
      ? css`
        right: 0;
      `
      : css`
        left: 0;
      `}

    ${isOpened && css`
      overflow: visible;
      opacity: 1;
      pointer-events: all;

      ${isBottomPosition
        ? css`
          bottom: 0;
        `
        : css`
          top: 0;
        `}
    `}
  `}
`;

@withClickOutside
@withTheme
@observer
class Menu extends React.Component {
  static propTypes = {
    children: ReactComponentChildrenPropType.isRequired,
    className: PropTypes.string,
    isOpened: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  static Item = Item;

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  @observable isRightPosition = false;

  @observable isBottomPosition = false;

  componentDidMount() {
    this.handleMenuPosition();
    window.addEventListener('resize', this.handleMenuPosition);
    window.addEventListener('scroll', this.handleClose, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleMenuPosition);
    window.removeEventListener('scroll', this.handleClose);
  }

  handleMenuPosition = () => {
    if (this.menuRef) {
      const { height, left, top, width } = this.menuRef.current.menu.getBoundingClientRect();
      const bottomEdge = top + height;
      const rightEdge = left + width;

      this.isBottomPosition = bottomEdge > window.innerHeight;
      this.isRightPosition = rightEdge > window.innerWidth;
    }
  }

  handleClickOutside() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  handleChange = (item) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(item);
    }
  }

  render() {
    const { children, isOpened, className } = this.props;

    return (
      <Wrapper className={className}>
        <Container
          innerRef={this.menuRef}
          isOpened={isOpened}
          isBottomPosition={this.isBottomPosition}
          isRightPosition={this.isRightPosition}
          onChange={this.handleChange}
        >
          {children}
        </Container>
      </Wrapper>
    );
  }
}

export default styled(Menu)``;
