import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const IconContainer = styled.div`
  line-height: 15px;
  font-size: 17px;
  height: 16px;
`;

const TextContainer = styled.div`
  color: #4A515C;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 7px 0;
  letter-spacing: 0;

  ${IconContainer} + ${TextContainer}{
    margin-left: 15px;
  }
`;

function Amenity({ className, tab, tab: { icon, onClick } }) {
  const content = typeof tab === 'string' ? tab : tab.content;
  const attributes = typeof tab === 'string' ? {} : tab.attributes;
  const isFunction = !!onClick;

  return (
    <Wrapper
      {...attributes}
      className={className}
      isFunction={isFunction}
      onClick={onClick}
    >
      {icon && (
        <IconContainer>
          {icon}
        </IconContainer>
      )}

      <TextContainer>
        {content}
      </TextContainer>
    </Wrapper>
  );
}

Amenity.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.string,
      attributes: PropTypes.object
    })
  ])
};

export default styled(Amenity)``;
