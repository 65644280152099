import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Distance from './DistanceItem';

const DistancesContainer = styled.div``;

const DistanceComponent = styled(Distance)``;

const Wrapper = styled.div`
  ${DistanceComponent} + ${DistanceComponent} {
    margin-top: 12px;
  }
`;

function Distances({ className, distances, withDescription }) {
  const distancesElement = distances.map((distance) => (
    <DistanceComponent key={distance.id} distance={distance} withDescription={withDescription} />
  ));

  return (
    <Wrapper className={className}>
      <DistancesContainer>{distancesElement}</DistancesContainer>
    </Wrapper>
  );
}

Distances.propTypes = {
  distances: PropTypes.array,
  className: PropTypes.string,
  withDescription: PropTypes.bool
};

export default Distances;
