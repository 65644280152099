import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Triangle } from 'components/icons';

const Input = styled.input`
  width: 100%;
  height: 42px;
  cursor: pointer;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding-left: 44px;
  padding-right: 15px;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.25s;

  &::placeholder {
    color: #979BA0;
  }

  &:focus {
    color: #4a515c;
    border-color: #abb3bf;
    box-shadow: none;
    outline: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ${Triangle} {
    width: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
`;

const WrapperInput = forwardRef(
  function Places(props, ref) {
    return (
      <Wrapper>
        <Input
          ref={ref}
          {...props}
        />

        {props.hasDropdown && <Triangle />}
      </Wrapper>
    );
  });

WrapperInput.propTypes = {
  className: PropTypes.string,
  chars: PropTypes.string,
  onSelect: PropTypes.func,
  hasDropdown: PropTypes.bool
};

WrapperInput.defaultProps = {
  chars: '',
  onSelect: () => { }
};

export default styled(WrapperInput)``;
