import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Item from './Item';
import { TextSkeleton } from 'components/ui';

const TitleContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
`;

const ListContainer = styled.div`
  margin-top: 13px;
`;

const Wrapper = styled.div`
  ${Item} + ${Item} {
    margin-top: 6px;
  }

  ${({ size }) =>
    size === 'large' &&
    css`
      ${TitleContainer} {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
      }
    `}
`;

function List({ className, title, items, size, loading }) {
  return (
    <Wrapper className={className} size={size}>
      <TitleContainer>{title}</TitleContainer>

      <ListContainer>
        {loading && <TextSkeleton />}
        {!loading && items.map((item, index) => <Item key={index} item={item} size={size} />)}
      </ListContainer>
    </Wrapper>
  );
}

List.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  size: PropTypes.string,
  loading: PropTypes.bool
};

export default styled(List)``;
