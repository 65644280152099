import React from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Prompt } from 'components/ui';
import { Modal } from 'components/molecules';
import { Button } from 'components/forms';

const Wrapper = styled.div``;

@observer
class Cancel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    acceptText: PropTypes.string.isRequired,
    declineText: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    disableCancelBtn: PropTypes.bool
  };

  static defaultProps = {
    className: ''
  };

  @observable isLoading = false;

  @action
  setAsLoading() {
    this.isLoading = true;
  }

  @action
  unsetAsLoading() {
    this.isLoading = false;
  }

  @observable isOpenned = false;

  @action
  openModal() {
    this.isOpenned = true;
  }

  @action
  closeModal() {
    this.isOpenned = false;
  }

  handleAccept = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onAccept } = this.props;
    this.setAsLoading();

    try {
      await onAccept();
      this.unsetAsLoading();
      this.closeModal();
    } catch (e) {
      this.unsetAsLoading();
    }
  };

  handleDecline = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onDecline } = this.props;
    onDecline();

    this.closeModal();
  };

  handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { disableCancelBtn } = this.props;

    !disableCancelBtn && this.openModal();
  };

  handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.closeModal();
  };

  render() {
    const { children, title, acceptText, declineText, className } = this.props;

    return (
      <>
        <Wrapper className={className} onClick={this.handleOpen}>
          {children}
        </Wrapper>

        <Modal opened={this.isOpenned} onClose={this.handleClose}>
          <Prompt>
            <Prompt.Content>
              <Prompt.Title>{title}</Prompt.Title>
            </Prompt.Content>

            <Prompt.Footer>
              <Prompt.Buttons>
                <Button onClick={this.handleAccept} loading={this.isLoading} rounded variant='danger'>
                  {acceptText}
                </Button>

                <Button onClick={this.handleDecline} rounded variant='secondary'>
                  {declineText}
                </Button>
              </Prompt.Buttons>
            </Prompt.Footer>
          </Prompt>
        </Modal>
      </>
    );
  }
}

export default styled(withTranslation()(Cancel))``;
