import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

const StubS = styled.div`
  ${({ theme }) => css`
    height: 336px;
    background: ${theme.colors.passive.secondary};
    border-radius: 4px;
  `}
`;

@withTheme
class Stub extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <StubS {...rest} />
    );
  }
}

export default styled(Stub)``;
