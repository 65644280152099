import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { display } from 'theme/mixins';

import { Triangle } from 'components/icons';

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 999px;
  transform: translateY(-50%);

  ${({ disabled, theme }) => css`
    background: ${theme.colors.light};
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.25s;

    ${disabled && css`
      cursor: default;
      opacity: 0.5;
    `}

    ${!disabled && css`
      :hover {
        opacity: 1;
      }
    `}
  `}
`;

const TriangleIconS = styled(Triangle)`
  position: relative;
  width: 12px;
  height: 12px;

  ${({ direction }) => direction === 'left' && css`
    left: -1px;
    transform: rotate(90deg);
  `}

  ${({ direction }) => direction === 'right' && css`
    right: -1px;
    transform: rotate(-90deg);
  `}
`;

@withTheme
class SlideControl extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    direction: PropTypes.oneOf([
      'left',
      'right'
    ]),
    disabled: PropTypes.bool,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: '',
    direction: 'left',
    disabled: false
  };

  render() {
    const { direction, ...rest } = this.props;

    return (
      <Wrapper
        direction={direction}
        {...rest}
      >
        <TriangleIconS direction={direction} />
      </Wrapper>
    );
  }
}

export default styled(SlideControl)``;
