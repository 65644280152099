import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const Text = styled.div`
  color: #6B707B;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 16px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
  "number invoices_at expired_at net_price manager";
  grid-auto-flow: row;
  grid-template-columns: 168px 135px 135px 150px 200px 1fr;
  column-gap: 6px;
  padding: 10px 20px;
`;

function Header({ invoice, className }) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Text>{t('Invoice.List.Number')}</Text>
      <Text>{t('Invoice.List.InvoicedAt')}</Text>
      <Text>{t('Invoice.List.ExpiredAt')}</Text>
      <Text>{t('Invoice.List.NetPrice')}</Text>
      <Text>{t('Invoice.List.Manager')}</Text>
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default styled(Header)``;
