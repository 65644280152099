import { types } from 'mobx-state-tree';

const Creator = types.model('Creator', {
  id: types.identifier,
  avatar: types.maybeNull(types.string),
  email: types.string,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  role: types.string
});

export default Creator;
