import { types } from 'mobx-state-tree';

import { ReviewBest } from './ReviewBest';
import { ReviewCategories } from './ReviewCategories';

export const OrdersReview = types.model('OrdersReview', {
  best: types.maybeNull(ReviewBest),
  categories: types.optional(types.array(ReviewCategories), []),
  total: types.maybeNull(types.number)
});
