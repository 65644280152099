function hotelMapper(data) {
  const sortImages = [...data.images].sort((a, b) => a.priority - b.priority);

  return {
    ...data,
    images: sortImages
  };
}

export default hotelMapper;
