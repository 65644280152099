import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { blockUser, unblockUser } from 'redux/stores/usersSlice/actions';

import { BlockShadow } from 'components/atoms';
import { UserActions } from '.';
import { UserBlockIcon } from 'components/icons';
import Spinner from 'components/ui/Spinner';

import { typography } from 'theme/mixins';

const Section = styled.div`
  & + &:not(:last-child):not(:empty) {
    ${({ theme }) => css`
      padding-left: 12px;
      border-left: 1px solid ${theme.colors.popup.primary};
    `}
  }
`;

const Label = styled.div`
  transition: all 0.25s linear;

  ${({ theme }) => css`
    margin: 4px 0;
    color: ${theme.colors.text.secondary};
    ${typography(12, 16)}
  `}
`;

const Value = styled.div`
  margin: 4px 0;
  ${typography(14, 16)}
  overflow: hidden;
  max-width: 265px;
  text-overflow: ellipsis;
`;

const FullNameSection = styled(Section)`
  flex-grow: 1;
`;

const EmailSection = styled(Section)`
  width: 22%;
`;

const PhoneSection = styled(Section)`
  width: 18%;
`;

const RoleSection = styled(Section)`
  width: 20%;
`;

const BlockingSection = styled(Section)`
  display: flex;
  align-self: center;
  width: 48px;
  border-left: none !important;

  &:empty {
    border-left: none;
  }

  ${UserBlockIcon} {
    font-size: 20px;
  }
`;

const ActionsSection = styled(Section)`
  display: flex;
  align-self: center;
  width: 48px;

  ${Spinner} {
    width: 24px;
    height: 24px;
  }
`;

const Wrapper = styled(BlockShadow)`
  display: flex;

  ${({ isBlocked }) =>
    isBlocked &&
    css`
      ${Label}, ${Value} {
        color: #979ba0;
      }
    `}
`;

function UserCard({ user, className }) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const handleEdit = () => {
    history.push(`/settings/users/${user.id}/edit`);
  };

  const handleBlock = async () => {
    setIsLoading(true);
    await dispatch(blockUser(user));
    setIsLoading(false);
  };

  const handleUnblock = async () => {
    setIsLoading(true);
    await dispatch(unblockUser(user));
    setIsLoading(false);
  };

  const isBlocked = user.blocked;

  let actions = [
    {
      label: t('User.Action.Change'),
      action: handleEdit
    }
  ];

  actions = isBlocked
    ? [...actions, { label: t('User.Action.Unblock'), action: handleUnblock }]
    : [...actions, { label: t('User.Action.Block'), action: handleBlock }];

  return (
    <Wrapper isBlocked={isBlocked} className={className}>
      <FullNameSection>
        <Label>{t('Profile.FullNameAcronym.Label')}</Label>

        <Value>
          {user.last_name} {user.first_name} {user.middle_name}
        </Value>
      </FullNameSection>

      <EmailSection>
        <Label>{t('Profile.Email.Label')}</Label>

        <Value>{user.email}</Value>
      </EmailSection>

      <PhoneSection>
        <Label>{t('Profile.Phone.Label')}</Label>

        <Value>{user.phone}</Value>
      </PhoneSection>

      <RoleSection>
        <Label>{t('Profile.Role.Label')}</Label>

        <Value>{t('Profile.Role.Value', { value: user.role })}</Value>
      </RoleSection>

      <BlockingSection>
        {isBlocked && (
          <Value>
            <UserBlockIcon />
          </Value>
        )}
      </BlockingSection>

      <ActionsSection>{isLoading ? <Spinner /> : <UserActions actions={actions} />}</ActionsSection>
    </Wrapper>
  );
}

UserCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default styled(UserCard)``;
