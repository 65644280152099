import instance from 'connection/instance';
import datesRange from 'utils/datesRange';
import { t } from 'utils/localization';

function addressText(query) {
  const { address } = query;
  if (!address) return;

  return Promise.resolve(address.location);
}

async function treatmentProfileText(query) {
  const { filter: { treatment_profiles } } = query;

  const selectedId = treatment_profiles[0];
  if (!selectedId) return;

  // eslint-disable-next-line no-use-before-define
  const { data: { data } } = await instance.get('/api/filters/treatment_profiles');
  const selected = data.find(item => item.tag === selectedId);

  if (selected) return selected.short_name;
}

function defaultText() {
  return Promise.resolve(t('Filter.Main.Title'));
}

async function mainText(query) {
  const address = addressText(query);
  if (address) return address;

  const treatmentProfile = await treatmentProfileText(query);
  if (treatmentProfile) return treatmentProfile;

  const text = defaultText();
  return text;
}

function datesText(query) {
  const { check_in, check_out } = query;
  return datesRange(check_in, check_out);
}

function guestsText(query) {
  const { travellers } = query;

  return t('Filter.Main.Travellers.Guests', { count: travellers.length });
}

function subText(query) {
  const text = [
    datesText(query),
    guestsText(query)
  ];

  return text.join(', ');
}

async function getQueryText(query) {
  const main = await mainText(query);
  const guests = subText(query);

  return { main, guests };
}

export default getQueryText;
