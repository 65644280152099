import React from 'react';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';

import { Statistics } from '.';

class Show extends React.Component {
  render() {
    return (
      <DefaultTemplate
        header={<Header />}
        body={<Statistics />}
      />
    );
  }
}

export default Show;
