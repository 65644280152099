import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useEvents from 'hooks/useEvents';
import { List, CellMeasurerCache, WindowScroller } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import HotelRowComp from './HotelRow';

import HotelCard from './Hotel';

const ScrollWrapper = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  & > div {
    overflow: auto;
  }
`;

const HotelRow = React.memo(HotelRowComp);

function LazyLoadHotels({ hotels, className, ...rest }) {
  const cache = useMemo(() => {
    const value = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 252
    });

    return value;
  }, [hotels]);

  // Handle rendered events
  const em = useEvents();

  const handleRowsRendered = (values) => {
    const { startIndex, stopIndex } = values;

    const bounds = hotels
      .slice(startIndex, stopIndex)
      .map(item => item.address.coordinates)
      .map(coords => ({ lat: coords[1], lng: coords[0] }));

    em.emit('map:bounds', bounds);
  };

  const rowRenderer = ({ ...opts }) => {
    const { index, key } = opts;
    const item = hotels[index];
    return (
      <HotelRow
        className={className}
        key={item.id}
        rowKey={key}
        cache={cache}
        item={item}
        {...opts}
      >
        <HotelCard
          hotel={item}
          {...rest}
        />
      </HotelRow>
    );
  };

  return (
    <WindowScroller scrollElement={window}>
      {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <ScrollWrapper height={height} width={width}>
              <div ref={registerChild}>
                <List
                  ref={el => {
                    window.listEl = el;
                  }}
                  autoHeight
                  rowCount={hotels.length}
                  width={width}
                  height={height}
                  onScroll={onChildScroll}
                  deferredMeasurementCache={cache}
                  isScrolling={isScrolling}
                  rowHeight={cache.rowHeight}
                  onRowsRendered={handleRowsRendered}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  overscanRowCount={8}
                  scrollToIndex={0}
                  style={{ overflowX: false, overflowY: false }}
                />
              </div>
            </ScrollWrapper>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
}

LazyLoadHotels.propTypes = {
  className: PropTypes.string,
  hotels: PropTypes.arrayOf(PropTypes.object)
};

export default LazyLoadHotels;
