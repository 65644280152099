import React, { useState } from 'react';
// import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CommonPart from '../CommonPart';

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
  white-space: break-spaces;

  p {
    margin-top: 0;
  }
`;

const ShowMore = styled.div`
  font-size: 16px;
  cursor: pointer;
  line-height: 19px;
  margin-top: 15px;
  color: #3582db;
  background: none;
  border: none;
`;

const Wrapper = styled.div``;

function Description({ className, hotel: { site_description } }) {
  const about = site_description?.about;
  const shortText = site_description?.shortText;
  const hasShortText = site_description?.hasShortText;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const description = hasShortText ? shortText : about;

  return (
    <Wrapper itemProp='description' className={className}>
      <CommonPart title={t('Hotel.Details.Tabs.Description')}>
        <Text dangerouslySetInnerHTML={{ __html: open ? about : description }} />

        {hasShortText && !open && <ShowMore onClick={handleClick}>{t('Common.ShowMore')}</ShowMore>}
      </CommonPart>
    </Wrapper>
  );
}

Description.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default Description;
