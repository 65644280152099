import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hotels from './LazyLoadHotels';
import SideLeft from './SideLeft';
import SortField from './SortField';

const FiltersContainer = styled.div`
  width: 280px;
  height: 100%;
`;

const HotelsContainer = styled.div`
  width: 640px;
  height: 100%;
`;

const HotelsComponent = styled.div`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 163px);
  padding-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;

  ${FiltersContainer} + ${HotelsContainer} {
    margin-left: 20px;
  }

  ${SortField} {
    height: 42px !important;
    width: 220px;
  }
`;

function SideBar({ hotels, ...rest }) {
  return (
    <Wrapper {...rest}>
      <FiltersContainer>
        <SideLeft {...rest} />
      </FiltersContainer>

      <HotelsContainer>
        <SortField {...rest} />

        <HotelsComponent>
          {hotels.length > 0 && (
            <Hotels
              hotels={hotels}
              {...rest}
            />
          )}
        </HotelsComponent>
      </HotelsContainer>
    </Wrapper>
  );
}

SideBar.propTypes = {
  className: PropTypes.string,
  hotels: PropTypes.array.isRequired
};

export default styled(SideBar)``;
