import moment from 'utils/moment';

function coerceBaseDates({ tariff, check_in, check_out }) {
  const { stay_period, billing_hour } = tariff;

  const min_days = stay_period.min_days || 0;
  const billingShift = billing_hour === 'day' ? 1 : 0;

  const calcCheckOut = moment(check_in).clone().add(min_days - billingShift, 'day');

  const checkIn = moment(check_in).toDate();

  let checkOut = moment(check_out);
  checkOut = moment.max(checkOut, calcCheckOut).toDate();

  return { checkIn, checkOut };
}

function coercePackageDates({ tariff, check_in, check_out }) {
  const { stay_period, billing_hour } = tariff;

  const min_days = stay_period.min_days || 0;
  const billingShift = billing_hour === 'day' ? 1 : 0;

  const checkIn = moment(check_in).toDate();
  const checkOut = moment(check_in).clone().add(min_days - billingShift, 'day');

  return { checkIn, checkOut };
}

function coerceDatesByMinDays({ tariff, check_in, check_out }) {
  switch (tariff.type) {
    case 'base':
      return coerceBaseDates({ tariff, check_in, check_out });

    case 'package':
      return coercePackageDates({ tariff, check_in, check_out });

    default:
      return { checkIn: check_in, checkOut: check_out };
  }
}

export default coerceDatesByMinDays;
