import { createAsyncThunk } from '@reduxjs/toolkit';

import storage from '../storage';

export const addOne = createAsyncThunk('notifications/add', async (notification) => {
  const data = await storage.create(notification);
  return data;
});

export default addOne;
