import _map from 'lodash/map';

import { mergePayments } from 'utils/pricesPayment';
import groupMapper from '../groupMapper';

function groupsByReservation(reservations) {
  if (reservations.length === 0) {
    return { payment: null, groups: [] };
  }

  const groups = _map([reservations], groupMapper);

  const payment = mergePayments(groups.map(item => item.payment));

  return { payment, groups };
}

export default groupsByReservation;
