import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border: 1px solid #5ab2d6;
  border-radius: 4px;
  box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
  padding: 14px 0px;
  min-width: 400px;
`;

function Popover({ children, className }) {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  );
}

Popover.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(Popover)``;
