import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const IconContainer = styled.div`
  line-height: 12px;
  font-size: 14px;
  height: 15px;
`;

const TextContainer = styled.div`
  color: #323232;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 25px;
  border: 1px solid #D1D5DB;
  border-radius: 3px;
  background-color: #F6F8FB;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  width: max-content;
  padding: 0 10px;

  ${IconContainer} + ${TextContainer}{
    margin-left: 8px;
  }

  ${({ isFunction }) => isFunction && css`
    cursor: pointer;
    
    ${TextContainer} {
      color: #305A8B;
      font-family: "Myriad Pro";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
  `};
`;

function Tab({ className, tab, tab: { icon, onClick } }) {
  const content = typeof tab === 'string' ? tab : tab.content;
  const attributes = typeof tab === 'string' ? {} : tab.attributes;
  const isFunction = !!onClick;

  return (
    <Wrapper className={className} {...attributes} isFunction={isFunction} onClick={onClick}>
      {icon && (
        <IconContainer>
          {icon}
        </IconContainer>
      )}

      <TextContainer>
        {content}
      </TextContainer>
    </Wrapper>
  );
}

Tab.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.string,
      attributes: PropTypes.object
    })
  ])
};

export default styled(Tab)``;
