import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Tooltip from 'components/v2/ui/Tooltip';
import { t } from 'utils/localization';

const Overlay = styled.div``;

const Wrapper = styled.div`
  ${({ theme: { badge } }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${badge.text};
    font-size: ${badge.fontSize};
    font-weight: ${badge.fontWeight};
    line-height: ${badge.lineHeight};
    box-sizing: ${badge.boxSizing};
    height: ${badge.height};
    min-width: ${badge.minWidth};
    border-radius: ${badge.borderRadius};
    background-color: ${badge.background};
  `};
`;

class AvailableBeds extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.number
  };

  render() {
    const { className, count } = this.props;

    const overlaytext = (
      <Overlay dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.AvailableBeds', { count }) }} />
    );

    return (
      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <Wrapper className={className}>
          {count}
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(AvailableBeds)``;
