import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Name = styled.div`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Description = styled.div`
  color: #979BA0;
  font-size: 13px;
  letter-spacing: 0;
  width: 100px;
  line-height: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ size }) => size === 'large' && css`
    ${Name}, ${Description} {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
    }
  `};
`;

function Item({ className, item, size }) {
  const name = typeof item === 'string' ? item : item.name;

  return (
    <Wrapper className={className} size={size}>
      <Name>
        {name}
      </Name>

      <Description>
        {item.description}
      </Description>
    </Wrapper>
  );
}

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  size: PropTypes.string
};

export default styled(Item)``;
