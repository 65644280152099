function filterOccupation(occupation) {
  const result = {};

  for (const key in occupation) {
    const variants = occupation[key] || [];
    if (variants.length > 0) result[key] = variants;
  }

  return result;
}

export default filterOccupation;
