import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ImageError as ImageErrorIcon } from 'components/icons';

const Wrapper = styled.img`
  display: inline-block;
`;

const CorruptedImagePlaceholder = styled.div`
  ${({ width, height, theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.passive.secondary};
  `}
`;

@observer
class Image extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  @observable hasError = false;

  handleError = () => {
    this.hasError = true;
  }

  render() {
    const { ...rest } = this.props;

    if (this.hasError) {
      return (
        <CorruptedImagePlaceholder {...rest}>
          <ImageErrorIcon />
        </CorruptedImagePlaceholder>
      );
    }

    return (
      <Wrapper
        {...rest}
        onError={this.handleError}
      />
    );
  }
}

export default styled(Image)``;
