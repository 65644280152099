import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'redux/stores/usersSlice/actions';
import { selectAll } from 'redux/stores/usersSlice/selectors';

import List from './List';
import Add from './Add';
import Edit from './Edit';

const Wrapper = styled.div`
`;

function Users({ className }) {
  const dispatch = useDispatch();

  const users = useSelector(selectAll);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <Wrapper className={className}>
      <Switch>
        <Redirect
          exact
          from='/settings/users'
          to='/settings/users/add'
        />

        <Route
          exact
          path='/settings/users/add'
          component={Add}
        />

        <Route
          exact
          path='/settings/users/:id/edit'
          component={Edit}
        />
      </Switch>

      <List users={users} />
    </Wrapper>
  );
}

Users.propTypes = {
  className: PropTypes.string
};

export default styled(Users)``;
