import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Popover from './Popover';
import Groups from './Groups';

import { Location as LocationIcon } from 'components/icons';
import Input from 'components/forms/v2/Input';

import { hook as usePlaces } from 'stores/PlacesStore';
import { useTranslation } from 'react-i18next';

const InputContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;

  ${Popover} {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
  }

  ${LocationIcon} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 20px;
    z-index: 1;
    cursor: pointer;
  }

  ${Input} {
    ${({ hasSelected }) => hasSelected && css`
      padding-right: 36px;
    `}
  }
`;

function Places({ chars, onSelect, className, ...rest }) {
  // eslint-disable-next-line no-use-before-define
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(chars);
  }, [chars]);

  const hasSelected = useMemo(() => {
    return value.length > 0;
  }, [value]);

  const [{ data, register }, doFetch] = usePlaces();

  useEffect(() => {
    doFetch(value);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    doFetch(value);
  };

  const handleFocus = (e) => {
    inputRef.current.select();
    setActive(true);
  };

  const handleBlur = (e) => {
    setActive(false);
  };

  const handleSelect = (place) => {
    const { label } = place;

    register(place);
    setValue(label);
    onSelect(place);
    setActive(false);

    inputRef.current.blur();
  };

  return (
    <Wrapper className={className}>
      <InputContainer>
        <LocationIcon />
        <Input
          ref={inputRef}
          value={value}
          // eslint-disable-next-line no-use-before-define
          autoComplete='off'
          placeholder={t('Filters.Places.Placeholder')}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          hasSelected={hasSelected}
          {...rest}
        />
      </InputContainer>

      {data.length > 0 && active && (
        <Popover>
          <Groups
            groups={data}
            onSelect={handleSelect}
          />
        </Popover>
      )}
    </Wrapper>
  );
}

Places.propTypes = {
  className: PropTypes.string,
  chars: PropTypes.string,
  onSelect: PropTypes.func
};

Places.defaultProps = {
  chars: '',
  onSelect: () => {}
};

export default styled(Places)``;
