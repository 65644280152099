import fetchRegions from './fetchRegions';
import fetchHotels from './fetchHotels';
import fetchPopularRequests from './fetchPopularRequests';
import fetchRecentRequests, { registerData } from './fetchRecentRequests';

class PlacesStore {
  constructor(props = {}) {
    this.minChars = props.minChars || 3;
    this.maxRecents = props.maxRecents || 5;
  }

  fetch(chars) {
    const data = chars.length >= this.minChars
      ? this._fetchData(chars)
      : this._fetchEmpty(chars);

    return data;
  }

  fetchRegistered(chars, options) {
    return fetchRecentRequests(chars, options);
  }

  register(place) {
    registerData(place);
  }

  async _fetchData(chars) {
    const requests = [fetchRegions(chars), fetchHotels(chars)];
    const response = await Promise.all(requests);

    return response.filter(group => group.data.length > 0);
  }

  _fetchEmpty(chars) {
    const options = { maxRecents: this.maxRecents };

    const requests = [
      fetchRecentRequests(chars, options),
      fetchPopularRequests(chars, options)
    ];

    return Promise.all(requests);
  }
}

export default PlacesStore;
