import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Chip } from 'components/ui';

const Attention = styled.div`
`;

const TitleContainer = styled.div`

`;

const RoomTypeTitle = styled.div`
  color: #4A515C;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const TariffTitle = styled.div`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${TitleContainer} {
    flex-grow: 0;
  }

  ${TitleContainer} + ${Attention} {
    margin-left: 28px;
  }
`;

@withTranslation()
@inject('bookingForm')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    t: PropTypes.func
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm, t, ...rest } = this.props;

    const { hasHint, hint } = bookingForm;

    return (
      <Wrapper {...rest}>
        <TitleContainer>
          <RoomTypeTitle>
            {t('Hotel.RoomType.PriceCalendar.Title')}
          </RoomTypeTitle>

          <TariffTitle>
            {t('Hotel.RoomType.PriceCalendar.Title.Description')}
          </TariffTitle>
        </TitleContainer>

        {hasHint && (
          <Attention>
            <Chip type={hint.type}>
              {hint.message}
            </Chip>
          </Attention>
        )}
      </Wrapper>
    );
  }
}

export default styled(Header)``;
