// "data": {
//   "balance": {
//     "amount": -181500.0,
//     "currency": "RUB"
//   },
//   "asset_balance": {
//     "amount": 0.0,
//     "currency": "RUB"
//   },
//   "liability_balance": {
//     "amount": -181500.0,
//     "currency": "RUB"
//   },
//   "number": "AGN-01/1",
//   "name": "ЗАО СнабСнабСнаб",
//   "credit_limit": {
//     "amount": 0.0,
//     "currency": "RUB"
//   },
//   "debit_limit": {
//     "amount": 0.0,
//     "currency": "RUB"
//   }
// }

class BalanceStore {
  constructor() {
    this.data = undefined;
  }

  setBalance(data) {
    this.data = data;
  }

  getBalance() {
    return this.data;
  }
}

export default new BalanceStore();
