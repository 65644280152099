import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Triangle } from 'components/icons';

import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import './AgeField.css';

import DatePicker from 'rc-calendar/lib/Picker';
import moment from 'moment';

const DEFAULT_DATE = '12.04.1963';

const DEFAULT_MODE = 'year';

const VISIBLE_FORMAT = 'DD.MM.YYYY';

const STORE_FORMAT = 'YYYY-MM-DD';

const Input = styled.input`
  flex-grow: 1;
  max-width: 120px;
  border-radius: 3px;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid #D1D5DB;
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  font-size: 12px;

  ${Triangle} {
    position: absolute;
    font-size: 6px;
    left: 102px;
    top: 15px;
  }
`;

function AgeField({ field }) {
  const [calendarMode, setCalendarMode] = useState(DEFAULT_MODE);
  const [value, setValue] = useState('');
  const [defaultValue, setDefaultValue] = useState(moment(DEFAULT_DATE, VISIBLE_FORMAT));
  const { t } = useTranslation();

  useEffect(() => {
    if (!field.value) {
      return;
    }

    const newValue = moment(field.value);
    if (!newValue.isValid()) {
      return;
    }

    setDefaultValue(newValue);
    setValue(newValue.format(VISIBLE_FORMAT));
  }, [field.value]);

  const handlePanelChange = (moment, mode) => {
    switch (mode) {
      case 'year':
        setCalendarMode('year');
        break;

      case 'date':
        setCalendarMode('date');
        break;

      case null:
        setCalendarMode('month');
        break;

      default:
        break;
    }
  };

  const handleClearPanel = () => {
    setValue(null);
    field.set(null);
    setCalendarMode(DEFAULT_MODE);
  };

  const handleChange = (value) => {
    if (value) {
      setValue(value.format(VISIBLE_FORMAT));
      field.set(value.format(STORE_FORMAT));
      return;
    }

    handleClearPanel();
  };

  const calendar = useMemo(() => (
    <Calendar
      mode={calendarMode}
      onPanelChange={handlePanelChange}
      format={VISIBLE_FORMAT}
      defaultValue={defaultValue}
      onClear={handleClearPanel}
      showToday={false}
    />
  ), [calendarMode, defaultValue]);

  return (
    <DatePicker
      animation="slide-up"
      calendar={calendar}
      onChange={handleChange}
    >
      {
        () => (
          <Content>
            <Input
              style={{ width: 200 }}
              readOnly
              value={value}
              placeholder={t('Form.BirthDate')}
            />

            <Triangle />
          </Content>
        )
      }
    </DatePicker>
  );
}

AgeField.propTypes = {
  field: PropTypes.object
};

AgeField.defaultProps = {
  className: ''
};

export default styled(AgeField)``;
