import { t } from 'utils/localization';
import { DateTime } from 'luxon';

const format = 'yyyy-MM-dd';

function getResatrictionsRules({ restrictions, billing_hour }) {
  const {
    closed_to_arrival_by_date,
    closed_to_departure_by_date,
    max_days_before_check_in,
    max_length_of_stay,
    tour_attention,
    max_length_of_stay_arrival_based,
    min_days_before_check_in,
    min_length_of_stay,
    min_length_of_stay_arrival_based,
    package_attention
  } = restrictions;

  const data = [];

  if (closed_to_arrival_by_date?.value) {
    data.push({
      text: t('Tariff.Restrictions.ClosedArrival'),
      active: closed_to_arrival_by_date?.active
    });
  }

  if (package_attention?.value) {
    data.push({
      text: t('Tariff.Package.Attention', { unit: billing_hour, value: package_attention.value }),
      active: package_attention?.active
    });
  }

  if (tour_attention?.value) {
    data.push({
      text: t('Tariff.Tour.Attention', {
        checkIn: DateTime.fromFormat(tour_attention.value.from, format).toFormat('d MMMM'),
        checkOut: DateTime.fromFormat(tour_attention.value.to, format).toFormat('d MMMM')
      }),
      active: tour_attention?.active
    });
  }

  if (closed_to_departure_by_date?.value) {
    data.push({
      text: t('Tariff.Restrictions.ClosedDeparture'),
      active: closed_to_departure_by_date.active
    });
  }

  if (max_days_before_check_in?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MaxDaysBeforeCheckIn', { value: max_days_before_check_in.value, unit: billing_hour }),
      active: max_days_before_check_in.active
    });
  }

  if (min_days_before_check_in?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MinDaysBeforeCheckIn', { value: min_days_before_check_in.value, unit: billing_hour }),
      active: min_days_before_check_in.active
    });
  }

  if (max_length_of_stay?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MaxLengthOfStay', { count: max_length_of_stay.value, billing_hour }),
      active: max_length_of_stay.active
    });
  }

  if (min_length_of_stay?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MinLengthOfStay', { count: min_length_of_stay.value, billing_hour }),
      active: min_length_of_stay.active
    });
  }

  if (max_length_of_stay_arrival_based?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MaxLengthOfStayArrival', { count: max_length_of_stay_arrival_based.value, billing_hour }),
      active: max_length_of_stay_arrival_based.active
    });
  }

  if (min_length_of_stay_arrival_based?.value > 0) {
    data.push({
      text: t('Tariff.Restrictions.MinLengthOfStayArival', { count: min_length_of_stay_arrival_based.value, billing_hour }),
      active: min_length_of_stay_arrival_based.active
    });
  }

  const hasActiveRestriction = data.find(d => !!d.active);

  return { restrictions: data, hasActiveRestriction };
}

export default getResatrictionsRules;
