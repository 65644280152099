import { t } from 'utils/localization';
import moment from 'utils/moment';

const DEFAULT_AGE = 21;

const DEFAULT_TRAVELLER = {
  first_name: null,
  last_name: null,
  middle_name: null,
  age: DEFAULT_AGE,
  date_of_birth: null,
  gender: {
    value: 'male',
    label: t('Form.Gender.Male')
  }
};

const DEFAULT_RATE = {
  age_group: 'adult',
  age_period: null
};

function calculateAge(dateOfBirth) { // birthday is a date
  const ageDifMs = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function assignTravellers({ slots }) {
  return slots.map((slot) => {
    // Assign slots straveller
    let { date_of_birth, ...traveller } = slot.traveller || {};

    const dateOfBirth = moment(date_of_birth);

    const age = date_of_birth && dateOfBirth.isValid()
      ? calculateAge(dateOfBirth.toDate())
      : DEFAULT_AGE;

    traveller = {
      ...DEFAULT_TRAVELLER,
      ...traveller,
      ...({ age, date_of_birth })
    };

    // Assign slots rate
    const rate = slot.rate || DEFAULT_RATE;

    return { ...slot, rate, traveller };
  });
}

export default assignTravellers;
