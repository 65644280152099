import { types } from 'mobx-state-tree';

const Conference = types.model('Conference', {
  id: types.identifier,
  jid: types.string,
  name: types.string,
  password: types.string,
  server: types.string
});

export { Conference };
