export default {
  data: {
    contracts: {
      contracts: [
        {
          id: 'abc',
          created_at: '2019-10-21',
          end_at: '2020-12-21',
          format: 'Оферта',
          legal_entity: 'ООО Туртрип',
          contract_code: 'B2b-54121',
          start_at: '2019-11-01',
          type: 'Агентский'
        },
        {
          id: 'fwr489',
          created_at: '2019-10-21',
          end_at: '2020-12-21',
          format: 'Оферта',
          legal_entity: 'ООО Туртрип',
          contract_code: 'B2b-54121',
          start_at: '2019-11-01',
          type: 'Агентский'
        }
      ],

      legal_entities: [
        {
          id: '124dsf',
          accounting_code: '772901001',
          bank: 'ПАО «Сбербанк России»',
          bank_code: '044525225',
          correspondent_account: '30101810400000000225',
          legal_address: {
            location: '119571, Москва г, Земляной вал 27 ст.2',
            coordinates: [1, 2]
          },
          name: 'ООО Туртрип',
          payment_account: '40702810538000109483',
          phone: '+79144564543',
          position: 'Генеральный директор',
          reg_number: '1167746408829',
          real_address: {
            location: 'ул. 26 Бакинских Комиссаров, 7к6',
            coordinates: [1, 4]
          },
          signatory: 'Космодамианский Александр Константинович',
          tin: '9729004419'
        },
        {
          id: '3r4t23rw',
          accounting_code: '772901001',
          bank: 'ПАО «Сбербанк России»',
          bank_code: '044525225',
          correspondent_account: '30101810400000000225',
          legal_address: {
            location: '119571, Москва г, Земляной вал 27 ст.2',
            coordinates: [1, 2]
          },
          name: 'ООО Туртрип',
          payment_account: '40702810538000109483',
          phone: '+79144564543',
          position: 'Генеральный директор',
          reg_number: '1167746408829',
          real_address: {
            location: 'ул. 26 Бакинских Комиссаров, 7к6',
            coordinates: [1, 4]
          },
          signatory: 'Космодамианский Александр Константинович',
          tin: '9729004419'
        }
      ]
    },

    general: {
      autofill: 8,
      extra_charge: 2
    },

    users: [
      {
        id: '1',
        first_name: 'Виктор',
        last_name: 'Цыплаков',
        middle_name: 'Сергеевич',
        email: 'chicken@mail.ru',
        phone: '+79174304301',
        role: 'manager'
      },
      {
        id: '2',
        first_name: 'Александр',
        last_name: 'Павлов',
        middle_name: 'Григорьевич',
        email: 'potato@yandex.ru',
        phone: '+79904304301',
        role: 'admin'
      },
      {
        id: '3',
        first_name: 'Илья',
        last_name: 'Барышев',
        middle_name: 'Александрович',
        email: 'coagulant@gmail.ru',
        phone: '+79109011271',
        role: 'manager'
      }
    ]
  }
};
