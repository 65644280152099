import { Field } from 'mobx-react-form';
import { computed, reaction } from 'mobx';
import capitalize from 'utils/capitalize';

class TravellerField extends Field {
  constructor(props) {
    super(props);

    this.addHandlers();
  }

  @computed get isFilled() {
    return !this.$('first_name').error &&
      !this.$('last_name').error &&
      !this.$('age').error &&
      !this.$('gender').error;
  }

  addHandlers() {
    this._parseFullNameHandler = reaction(
      () => this.$('full_name').value,
      (fullName) => this.parseFullName(fullName)
    );

    this._setFullNameHandler = reaction(
      () => {
        const first_name = this.$('first_name').value;
        const last_name = this.$('last_name').value;
        const middle_name = this.$('middle_name').value;

        return { first_name, last_name, middle_name };
      },
      (values) => this.setFullName(values),
      { fireImmediately: true }
    );
  }

  removeHandlers() {
    this._parseFullNameHandler();
    this._setFullNameHandler();
  }

  setFullName({ first_name, last_name, middle_name }) {
    if (!first_name || !last_name || !middle_name) return;

    const fullName = [last_name, first_name, middle_name].join(' ');
    this.coerceFullName(fullName);
  }

  parseFullName(fullName) {
    const data = fullName.split(' ');
    if (data.length < 1) return;

    const [last_name, first_name, middle_name] =
      data.map(v => capitalize(v.trim()));

    this.set({ last_name, first_name, middle_name });
  }

  coerceFullName(fullName) {
    const full_name = fullName
      .split(' ')
      .map(v => capitalize(v.trim()))
      .join(' ');

    this.set({ full_name });
  }
}

export default TravellerField;
