import instance from 'connection/instance';

class TreatmentProfileStore {
  async fetch(params = {}) {
    const { data: { data } } = await instance.get('/api/filters/treatment_profiles', { params });
    return { meta: {}, data };
  }
}

export default TreatmentProfileStore;
