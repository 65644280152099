import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.div`
  color: #4A515C;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Description = styled.div`
  color: #979BA0;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 15px;
  cursor: pointer;

  &:hover {
    background-color: #C5D2E2;
  }
`;

function Item({ item, onSelect, className }) {
  const { label, description } = item;

  const handleClick = (e) => {
    e.preventDefault();
    onSelect(item);
  };

  return (
    <Wrapper
      className={className}
      onMouseDown={handleClick}
    >
      <Label>{label}</Label>

      {description && (
        <Description>
          {description}
        </Description>
      )}
    </Wrapper>
  );
}

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  onSelect: PropTypes.func
};

Item.defaultProps = {
  onSelect: () => {}
};

export default styled(Item)``;
