import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  box-sizing: border-box;
`;

class Block extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

Block.propTypes = {
  className: PropTypes.string,
  children: ReactComponentChildrenPropType.isRequired
};

Block.defaultProps = {
  className: ''
};

export default styled(Block)``;
