import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Tabs } from 'components/molecules';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;

  ${Tabs} {
    margin-top: 42px;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.active.secondary};
    ${typography(40, 48, 700)};
  `}
`;

function Top({ className }) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Title>
        {t('Reports.Title')}
      </Title>

      <Tabs
        tabs={[
          {
            to: '/reports/invoices',
            label: t('Reports.Invoices.Title')
          },
          {
            to: '/reports/revise',
            label: t('Reports.Revise.Title')
          },
          {
            to: '/reports/payment_history',
            label: t('Reports.PaymentHistory.Title')
          },
          {
            to: '/reports/closing_documents',
            label: t('Reports.ClosingDocuments.Title')
          },
          {
            to: '/reports/acts_of_residence',
            label: t('Reports.ActsOfResidence.Title')
          }
        ]}
      />
    </Wrapper>
  );
}

Top.propTypes = {
  className: PropTypes.string
};

export default styled(Top)``;
