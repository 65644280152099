import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TooltipItemTitle = styled.div``;

const TooltipItemValue = styled.div`
  font-weight: 600;
`;

const TooltipItem = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 4px;
  padding-right: 6px;
  background-color: ${props => props.isBenefit ? '#6B707B' : '#4A515C'};
  margin-left: ${props => props.isBenefit ? '-4px' : '0px'};
  padding-left: ${props => props.isBenefit ? '4px' : '0px'};
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  padding-right: 5px;
  ${TooltipItem} {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const TooltipPrice = ({
  t,
  net_price,
  gross_price,
  discount_in_percent,
  discount
}) => {
  return (
    <TooltipContent>
          <TooltipItem>
        <TooltipItemTitle>
          {t('Orders.Hotel.Badge.GrossPrice')}
        </TooltipItemTitle>
        <TooltipItemValue>
          {t('UI.Price.Sum', { price: gross_price })}
        </TooltipItemValue>
      </TooltipItem>

      <TooltipItem>
        <TooltipItemTitle>
          {t('Orders.Hotel.Badge.Comission')}
        </TooltipItemTitle>
        <TooltipItemValue>
          {t('UI.Percent', { value: discount_in_percent })}
        </TooltipItemValue>
      </TooltipItem>

      <TooltipItem>
        <TooltipItemTitle>
          {t('Orders.Hotel.Badge.NetPrice')}
        </TooltipItemTitle>
        <TooltipItemValue>
          {t('UI.Price.Sum', { price: net_price })}
        </TooltipItemValue>
      </TooltipItem>

      <TooltipItem isBenefit>
        <TooltipItemTitle>
          {t('Orders.Hotel.Badge.Benefit')}
        </TooltipItemTitle>
        <TooltipItemValue>
          {t('UI.Price.Sum', { price: discount })}
        </TooltipItemValue>
      </TooltipItem>
    </TooltipContent>
  );
};

TooltipPrice.propTypes = {
  net_price: PropTypes.object,
  gross_price: PropTypes.object,
  discount_in_percent: PropTypes.number,
  discount: PropTypes.number,
  t: PropTypes.func.isRequired
};

export default TooltipPrice;
