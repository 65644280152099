import { types } from 'mobx-state-tree';

const RoomType = types.model('RoomType', {
  id: types.identifier
});

const Tariff = types.model('Tariff', {
  id: types.identifier
});

const Meta = types.model('Meta', {
  check_in: types.string,
  check_out: types.string,
  room_type: RoomType,
  tariff: Tariff
});

export default Meta;
