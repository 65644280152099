import React from 'react';
import styled from 'styled-components';
import { computed } from 'mobx';
import { Provider, inject } from 'mobx-react';
import { PropTypes } from 'prop-types';

import { Header } from 'components/organisms';
import { FluidTemplate } from 'components/templates';
import ProfileForm, { fields } from 'forms/Auth/ProfileForm';
import { FormSections } from '.';

@inject('authStore')
class Profile extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    authStore: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    // ToDo: Use real ProfileStore
    this.profileForm = new ProfileForm(fields, { hooks });
    // this.profileStore = ProfileStore
    //   .create()
    //   .fetch()
    //   .then((store) => {
    //     this.profileForm.init(store.data.toJSON());
    //   });
    this.profileStore = this.props.authStore;
    this.profileForm.init({
      ...this.profileStore.data.toJSON(),
      phone: '+79164590371',
      extra_phone: '',
      email: 'vip@tutanota.com',
      login_1c: ''
    });
  }

  @computed get isAuthenticated() {
    const { authStore } = this.props;
    return authStore.isAuthenticated;
  }

  handleSuccess = (form) => {
    const { authStore } = this.props;
    const values = form.values();

    authStore.login(values);
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  render() {
    const { ...rest } = this.props;

    return (
      <Provider
        profileStore={this.profileStore}
        profileForm={this.profileForm}
      >
        <FluidTemplate
          {...rest}
          header={<Header />}
          body={<FormSections />}
        />
      </Provider>
    );
  }
}

export default styled(Profile)``;
