import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { NavItem } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  background: #e5e8ed;
  border: 1px solid #bfc6d3;
  border-radius: 3px 3px 0 0;
  user-select: none;
`;

const NavItems = styled.div`
  ${display('flex', 'center')}
  margin: -1px;
`;

@withTheme
class NavigationTabs extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    navigation: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        label: PropTypes.string,
        count: PropTypes.number,
        active: PropTypes.bool
      })
    ).isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { navigation, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <NavItems>
          {navigation.map(({ active, to, label, count }, index) => (
            <NavItem
              key={index}
              to={to}
              active={active}
              label={label}
              count={count}
            />
          ))}
        </NavItems>
      </Wrapper>
    );
  }
}

export default styled(NavigationTabs)``;
