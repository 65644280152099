import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import colors from 'theme/colors';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  display: flex;

  ${({ hasStripe }) => hasStripe && css`
    :nth-child(even) {
      background-color: ${colors.controls.lightBackground};
    }
  `}
`;

@withTheme
class TableRow extends React.PureComponent {
  static propTypes = {
    children: ReactComponentChildrenPropType.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.object,
    hasStripe: PropTypes.bool
  };

  render() {
    const { children, className, href, link, onClick, theme, hasStripe } = this.props;

    let elementType = 'div';

    if (href) {
      elementType = 'a';
    }

    if (link) {
      elementType = Link;
    }

    return (
      <Wrapper
        as={elementType}
        className={className}
        onClick={onClick || undefined}
        hasStripe={hasStripe}
        theme={theme}
      >
        {children}
      </Wrapper>
    );
  }
}

export default styled(TableRow)``;
