import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackLink } from 'components/ui';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;

  ${BackLink} {
    color: #979BA0;

    path {
      fill: #979BA0;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

@withTranslation()
class Top extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    backUrl: PropTypes.string
  };

  static defaultProps = {
    backUrl: '',
    className: ''
  };

  render() {
    const { backUrl, className } = this.props;

    return (
      <Wrapper className={className}>
        <FlexContainer>
          <BackLink to={backUrl} />
        </FlexContainer>
      </Wrapper>
    );
  }
}

export default styled(Top)``;
