import { useEffect, useState } from 'react';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';
import _uniqueId from 'lodash/uniqueId';
import fetchData from './utils/fetchData';

import { useHistory } from 'react-router-dom';

const DEFAULT_VALUE = {
  meta: {
    count: 0,
    total: {
      net_price: 0,
      gross_price: 0,
      fee_price: 0
    }
  },
  balance: {
    amount: 0,
    currency: 'RUB'
  },
  data: []
};

function loadInvoice(blob) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  let filename = _uniqueId('invoice-');
  filename = [filename, '.xlsx'].join('');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

function useInvoices() {
  const [data, setData] = useState(DEFAULT_VALUE);

  const history = useHistory();

  const doFetch = async () => {
    const data = await fetchData();
    setData(data);
  };

  const doInfo = (invoice) => {
    const { order: { id } } = invoice;
    history.push(`/orders/${id}`);
  };

  const doDownload = async (invoice) => {
    const { order: { id } } = invoice;
    const { status, data } = await instanceBlob.get(`/api/orders/${id}/invoice`);

    if (status === 200) {
      loadInvoice(data);
    }
  };

  const doPayFromAssetBalance = async (invoice) => {
    const { order: { id } } = invoice;

    const { status } = await instance.put(`/api/invoices/${id}/pay_from_asset_balance`);

    if (status === 200) {
      await doFetch();
    }
  };

  useEffect(() => {
    doFetch();
  }, []);

  return [data, { doPayFromAssetBalance, doInfo, doDownload }];
}

export default useInvoices;
