import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import dateFormat from 'utils/dateFormat';
import { shortName } from 'utils/personName';

import { NavLink } from 'react-router-dom';
import PaymentBadge from 'components/ui/PaymentBadge';
import Row from './Row';

const Guest = styled.div`
  line-height: 22px;
`;

const Guests = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
  "number invoices_at expired_at net_price manager chat";
  grid-auto-flow: row;
  grid-template-columns: 152px 135px 135px 150px 200px 1fr;
  column-gap: 6px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;

  ${({ type }) => type === 'trigger' && css`
    background-color: #F5F6F8;
  `}

  ${({ type }) => type === 'void' && css`
    background-color: #FDF1F1;
  `}

  ${({ type }) => type === 'pay' && css`
    background-color: #F5F6F8;
  `}

  ${({ type }) => type === 'refund' && css`
    background-color: #F5F6F8;
  `}
`;

function Position({ position, orderLink, className }) {
  const { t } = useTranslation();

  const {
    operation_action, order_number, check_in, check_out, travellers, hotel
  } = position;

  return (
    <Wrapper
      type={operation_action}
      className={className}
    >
      <Row
        variant='primary'
        label={t('Invoice.Position.Number')}
        value={
          <NavLink to={orderLink}>
            {order_number}
          </NavLink>
        }
      />

      <Row
        label={t('Invoice.Position.CheckIn')}
        value={dateFormat(check_in, 'DD-MM-YYYY')}
      />

      <Row
        label={t('Invoice.Position.CheckOut')}
        value={dateFormat(check_out, 'DD-MM-YYYY')}
      />

      <Row
        label={t('Invoice.Position.Guests')}
        value={
          <Guests>
            {travellers.map((t, idx) => (
              <Guest key={idx}>
                {shortName(t)}
              </Guest>
            ))}
          </Guests>
        }
      />

      <Row
        label={t('Invoice.Position.Hotel')}
        value={hotel.name}
      />

      <Row
        value={
          <PaymentBadge
            variant={operation_action}
          />
        }
      />
    </Wrapper>
  );
}

Position.propTypes = {
  className: PropTypes.string,
  position: PropTypes.object.isRequired,
  orderLink: PropTypes.string.isRequired
};

export default styled(Position)``;
