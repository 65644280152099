import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Slot from './Slot';

const Wrapper = styled.div`
  ${Slot} + ${Slot} {
    margin-top: 17px;
  }
`;

@inject('showState')
@observer
class Slots extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    reservation: PropTypes.object.isRequired,
    showCommission: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: '',
    showCommission: false
  };

  render() {
    const { reservation, t, showCommission, ...rest } = this.props;

    const { slots } = reservation;

    const listItems = slots.map(slot => (
      <Slot
        key={slot.id}
        slot={slot}
        showCommission={showCommission}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Slots))``;
