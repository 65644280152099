import getPricesForPeriod from './getPricesForPeriod';

function calculatePrice(params) {
  const { check_in, check_out, tariff, prices } = params;

  if (!check_in || !check_out) return { amount: 0, currency: 'RUB' };

  // Price
  const pricesPerDay = getPricesForPeriod({
    tariff, prices, check_in, check_out
  });

  let count = pricesPerDay.map(item => item.available);
  count = Math.min(...count);

  return count;
}

export default calculatePrice;
