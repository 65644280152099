import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { transition } from 'theme/mixins';
import getImage from 'utils/getImage';

import Button from 'components/forms/Button';

import AgentPrice from './AgentPrice';
import TuristPrice from './TuristPrice';
import Amenities from './Amenities';

import { Rating } from 'components/ui';
import Thumb from 'components/ui/Thumb';
import Stars from 'components/v2/ui/Stars';
import InstantBooking from 'components/ui/InstantBooking';
import ProviderIcon from 'components/ui/ProviderIcon';
import HotelPhoto from 'components/ui/HotelPhoto';
import { HotelInfoDialog } from '.';

const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #4A515C;
`;

const ExpandButton = styled.div`
  ${({ theme }) => css`
    position: relative;
    left: -8px;
    padding: 6px 8px;
    color: ${theme.colors.ui.primary};
    font-size: 13px;
    font-weight: 600;
    transition: color 0.25s;

    :hover {
      color: ${theme.colors.text.primary};
    }
  `}
`;

const PriceDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #979BA0;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 13px;
  white-space: nowrap;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Address = styled.div`
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.colors.button.primaryHover};
    font-size: 13px;
    font-weight: 600;
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Rating} {
    margin-left: 8px;
  }
`;

const Photo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 140px;
  padding-right: 14px;
`;

const Specialization = styled.li`
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpecializationItems = styled.ul``;

const Specializations = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
  `}
`;

const Details = styled.div`
  display: flex;
  padding: 6px 0;

  ${Specializations} {
    width: calc(100% - 114px);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  ${Details} {
    flex-grow: 1;
    margin-top: 2px;
  }
`;

const InfoContainer = styled.div`
  max-width: 440px;
  min-width: 440px;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

const ThumbContainer = styled.div`
  height: 19px;
  border-radius: 3px;
  background-color: #F5F5F5;
  box-sizing: border-box;
  padding: 0 6px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background: ${theme.colors.light};
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 0 6px 2px rgba(36, 95, 119, 0.2);
    transition: box-shadow 0.25s, border-color 0.25s;
  `}
`;

const PriceTop = styled.div`

`;

const PriceBottom = styled.div`

`;

const ReviewsCount = styled.div`
  color: #4A515C;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const Wrapper = styled.div.attrs(({ link }) => ({ to: link }))`
  ${({ active, theme }) => css`
    position: relative;
    width: 100%;
    min-height: 144px;
    cursor: pointer;

    ::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: calc(100% - 28px);
      height: 100%;
      background: rgba(19, 136, 184, 0.4);
      filter: blur(8px);
      opacity: 0;
      transition: top 0.25s, opacity 0.25s;
    }

    :hover {
      ${Container} {
        border-color: ${theme.colors.active.primary};
        box-shadow: none;
      }

      ::after {
        top: 8px;
        opacity: 1;
      }
    }

    ${active && css`
      ${Container} {
        border-color: ${theme.colors.active.primary};
        box-shadow: none;
      }

      ::after {
        top: 8px;
        opacity: 1;
      }
    `}
  `}

  ${transition()};

  ${Content} {
    flex-grow: 1;
  }

  ${AgentPrice} + ${TuristPrice} {
    margin-top: 10px;
  }

 ${TuristPrice} {
    margin-top: 63px;
  }

  ${AgentPrice} {
    margin-top: 12px;
  }

  ${InstantBooking} {
    margin-left: 8px;
    height: 22px;
    font-size: 22px;
  }

  ${Button} {
    width: 100%;
  }

  ${ThumbContainer} + ${Address} {
    margin-left: 6px;
  }

  ${Thumb} + ${ReviewsCount} {
    margin-left: 4px;
  }

  ${Amenities} + ${SpecializationItems} {
    margin-top: 12px;
  }
`;

@inject('commissionVisibility')
@withTranslation()
@withTheme
@observer
class HotelCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object,
    active: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    commissionVisibility: PropTypes.object
  }

  static defaultProps = {
    className: '',
    active: false,
    onClick: () => { }
  }

  @observable isOpened = false;

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    onClick(e);
  }

  handleDetailsDialogOpen = (event) => {
    event.stopPropagation();
    this.isOpened = true;
  }

  handleDetailsDialogClose = () => {
    this.isOpened = false;
  }

  render() {
    const {
      active,
      hotel,
      hotel: {
        review,
        tariff,
        amenities,
        id,
        instant_booking,
        provider,
        name,
        address: {
          region
        },
        rating,
        images,
        specializations,
        star
      },
      t,
      className,
      commissionVisibility: {
        visible
      }
    } = this.props;

    const thumbSize = { width: 280, height: 240 };
    const { src } = this.getImgPath(images[0], thumbSize);

    return (
      <>
        <Wrapper
          className={className}
          active={active}
        >
          <Container>
            <InfoContainer>
              {Number(star) > 1 && <Stars stars={Number(star)} />}

              <Header>
                <Title>
                  {name}
                </Title>
              </Header>

              <AddressContainer>
                {(rating > 0 || review?.total > 0) && (
                  <ThumbContainer>
                    {rating > 0 && <Thumb rating={rating} />}

                    {review?.total > 0 && <ReviewsCount>{t('Common.Reviews', { count: review.total })}</ReviewsCount>}
                  </ThumbContainer>
                )}

                {region && (
                  <Address>
                    {region}
                  </Address>
                )}
              </AddressContainer>

              <Content>
                <Details>
                  <Photo>
                    <HotelPhoto src={src} />
                  </Photo>

                  <Specializations>
                    {amenities?.length > 0 && <Amenities hotel={hotel} />}

                    <SpecializationItems>
                      {specializations.slice(0, 4).map(({ short_name }, index) => (
                        <Specialization key={index}>
                          {short_name}
                        </Specialization>
                      ))}
                    </SpecializationItems>
                  </Specializations>
                </Details>
              </Content>

              <ExpandButton onClick={this.handleDetailsDialogOpen}>
                {t('UI.Button.More')}
              </ExpandButton>
            </InfoContainer>

            <PriceContainer>
              <PriceTop>
                {tariff && (
                  <InfoSection>
                    <PriceDetail>
                      {t('Orders.Reservations.ForDuration', {
                        value: tariff.duration.value,
                        unit: tariff.billing_hour
                      })}
                      {' / '}
                      {t('UI.Guests', { count: tariff.guests_count })}
                    </PriceDetail>

                    {instant_booking && <InstantBooking variant='hotelCard' />}

                    <ProviderIcon
                      provider={provider}
                      variant='hotelCard'
                    />
                  </InfoSection>
                )}

                {visible && <AgentPrice hotel={hotel} />}

                <TuristPrice hotel={hotel} variant={visible ? 1 : 2}/>
              </PriceTop>

              <PriceBottom>
                <Button size='small' onClick={this.handleClick}>
                  {t('HotelCard.Action')}
                </Button>
              </PriceBottom>
            </PriceContainer>
          </Container>
        </Wrapper>

        <HotelInfoDialog
          id={id}
          onClick={this.handleClick}
          isOpened={this.isOpened}
          onClose={this.handleDetailsDialogClose}
        />
      </>
    );
  }
}

export default styled(HotelCard)``;
