import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from './Header';
import Invoice from '../Invoice';

const Body = styled.div`
  ${Invoice} + ${Invoice} {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function List({ children, className }) {
  return (
    <Wrapper className={className}>
      <Header />

      <Body>
        {children}
      </Body>
    </Wrapper>
  );
}

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(List)``;
