import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { Table } from 'components/ui';
import { Date } from 'components/ui/Date';

import { display } from 'theme/mixins';

const Wrapper = styled(Table.Row)``;

const TableBodyCellS = styled(Table.BodyCell)`
  ${display('flex', 'center')}
`;

class ReportItem extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    reportCellSizes: PropTypes.object.isRequired,
    report: PropTypes.shape({
      id: PropTypes.string,
      account_code: PropTypes.string,
      amount: PropTypes.number,
      created_at: PropTypes.string,
      payment_type: PropTypes.string,
      transaction_id: PropTypes.string,
      transaction_type: PropTypes.string
    }),
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      reportCellSizes,
      report: {
        account_code,
        amount,
        created_at,
        payment_type,
        transaction_id,
        transaction_type
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper
        {...rest}
        hasStripe
      >
        <TableBodyCellS width={`${reportCellSizes.transactionType}%`}>
          {transaction_type}
        </TableBodyCellS>

        <TableBodyCellS width={`${reportCellSizes.transactionId}%`}>
          {transaction_id}
        </TableBodyCellS>

        <TableBodyCellS width={`${reportCellSizes.createdAt}%`}>
          <Date date={created_at} />
        </TableBodyCellS>

        <TableBodyCellS width={`${reportCellSizes.paymentType}%`}>
          {payment_type}
        </TableBodyCellS>

        <TableBodyCellS width={`${reportCellSizes.amount}%`}>
          {t('UI.Price', { price: amount })}
        </TableBodyCellS>

        <TableBodyCellS width={`${reportCellSizes.accountCode}%`}>
          {account_code}
        </TableBodyCellS>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ReportItem))``;
