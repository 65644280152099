import { types } from 'mobx-state-tree';

import RoomType from './RoomType';

const Body = types.model('Body', {
  id: types.identifier,
  name: types.string,
  room_types: types.array(RoomType)
});

export default Body;
