import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ProgramContent = styled.div`
  margin-top: 8px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    min-width: 248px;
    padding: 3px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4a515c;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
`;

function Content({ className, program }) {
  return (
    <Wrapper className={className}>
      <ProgramContent dangerouslySetInnerHTML={{ __html: program.content }} />
    </Wrapper>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object
};

export default Content;
