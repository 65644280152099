import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';

import { Delete } from 'components/icons';
import { Age, Name, Gender } from '.';

import { display, flexChildActualWidth } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'center')}

  :not(:first-child) {
    margin-top: 10px;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Fields = styled.div`
  ${({ hasError, theme }) => css`
    ${display('flex', 'center')}
    width: 100%;
    height: 40px;
    background: ${theme.colors.light};
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
    transition: color 0.25s;

    ${hasError && css`
      border-color: ${theme.colors.error.primary};
    `}
  `}
`;

const Section = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}

    :not(:last-child)::after {
      content: "";
      ${flexChildActualWidth(1)}
      height: 28px;
      background: ${theme.colors.popup.primary};
    }
  `}
`;

const NameSection = styled(Section)`
  flex-grow: 1;

  ${Name} {
    width: 100%;
  }
`;

const GenderSection = styled(Section)`
  ${flexChildActualWidth(104)}

  ${Gender} {
    width: 100%;
  }
`;

const AgeSection = styled(Section)`
  ${flexChildActualWidth(72)}

  ${Age} {
    width: 100%;
  }
`;

const Controls = styled.div`
  padding: 0 16px;
`;

const DelectControl = styled(Delete)`
  cursor: pointer;
`;

@withTheme
@observer
class Traveller extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hasError: PropTypes.bool,
    traveller: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleDelete = (e) => {
    const { traveller } = this.props;
    traveller.onDel(e);
  };

  render() {
    const { traveller, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Fields hasError={!traveller.isFilled}>
          <NameSection>
            <Name field={traveller.$('last_name')} />
          </NameSection>

          <NameSection>
            <Name field={traveller.$('first_name')} />
          </NameSection>

          <NameSection>
            <Name field={traveller.$('middle_name')} />
          </NameSection>

          <GenderSection>
            <Gender field={traveller} />
          </GenderSection>

          <AgeSection>
            <Age field={traveller} />
          </AgeSection>
        </Fields>

        <Controls>
          <DelectControl onClick={this.handleDelete} />
        </Controls>
      </Wrapper>
    );
  }
}

export default styled(Traveller)``;
