import React from 'react';
import PropTypes from 'prop-types';

import { SelectX } from 'components/forms';
import styled from 'styled-components';
import { CircleArrow } from 'components/icons';
import { useTranslation } from 'react-i18next';

const DescIcon = styled(CircleArrow)`
  margin-right: 10px;
`;

const AscIcon = styled(DescIcon)`
  transform: rotate(180deg);
`;

const StyledSelect = styled(SelectX)`
  width: inherit;
`;

function SortField({ query, handleUpdate, ...rest }) {
  const { t } = useTranslation();

  const ratingItems = [...Array(10).keys()].map(item => ({
    rating: item + 1,
    label: t('Hotel.Search.Rating.Item', { from: item + 1, to: 10 })
  }));

  const ratingValue = ratingItems.find(s => s.rating === query.rating);

  const getIcon = () => {
    if (query.direction === 1) {
      return <AscIcon />;
    }

    if (query.direction === -1) {
      return <DescIcon />;
    }

    return null;
  };

  const handleChange = ({ rating }) => {
    handleUpdate({ rating });
  };

  return (
    <StyledSelect
      {...rest}
      onChange={handleChange}
      value={ratingValue}
      icon={getIcon()}
      items={ratingItems}
      labelKey='label'
      valueKey='rating'
      placeholder={t('Hotel.Search.Rating')}
    />
  );
}

SortField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default styled(SortField)``;
