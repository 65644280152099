import { createSlice } from '@reduxjs/toolkit';

import usersAdapter, { initialState } from './adapter';
import { fetchUsers, updateUser, blockUser, createUser, unblockUser } from './actions';

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        usersAdapter.setAll(state, action.payload);
        state.status = 'idle';
      })
      .addCase(createUser.fulfilled, usersAdapter.addOne)
      .addCase(updateUser.fulfilled, usersAdapter.updateOne)
      .addCase(blockUser.fulfilled, usersAdapter.updateOne)
      .addCase(unblockUser.fulfilled, usersAdapter.updateOne);
  }
});

export default usersSlice.reducer;
