import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { Show } from './containers';

@withRouter
@observer
class Statistics extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path='/statistics'
          component={Show}
        />
      </Switch>
    );
  }
}

export default Statistics;
