import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const GrossPrice = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  text-align: right;
  color: #979ba0;
  margin-right: 5px;
`;

const NetPrice = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #5ab2d6;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

@withTranslation()
@inject('commissionVisibility')
@observer
class Price extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    slot: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    commissionVisibility: PropTypes.object.isRequired
  };

  @computed get withCommission() {
    const { commissionVisibility } = this.props;
    return commissionVisibility.isAmountVisible;
  }

  render() {
    const { slot, t, className } = this.props;

    const net_price = slot.$('net_price').value;
    const gross_price = slot.$('gross_price').value;

    return (
      <Wrapper className={className}>
        {this.withCommission && <NetPrice>{t('UI.Price', { price: net_price })}</NetPrice>}

        <GrossPrice>{t('UI.Price', { price: gross_price })}</GrossPrice>
      </Wrapper>
    );
  }
}

export default styled(Price)``;
