import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { computed } from 'mobx';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${props => props.isGreen ? theme.colors.text.green : theme.colors.text.primary};
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
  `}
`;

class Rating extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    rating: PropTypes.number,
    isGreen: PropTypes.bool
  }

  static defaultProps = {
    rating: 0
  }

  @computed get rating() {
    const { rating } = this.props;

    return rating.toLocaleString('ru-RU');
  }

  render() {
    const { className, isGreen } = this.props;

    return (
      <Wrapper className={className} isGreen={isGreen}>
        {this.rating}
      </Wrapper>
    );
  }
}

export default styled(Rating)``;
