import React from 'react';
import { Block } from 'components/atoms';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display } from 'theme/mixins';

const PhotoBig = styled.div`
  width: 672px;
  height: 344px;
  border-radius: 4px;
  background: #88a4d3;
`;

const PhotoSmall = styled.div`
  width: 66px;
  height: 40px;
  border-radius: 4px;
  background: #3c5e33;
`;

const Wrapper = styled.div`
  ${Block} {
    margin-top: 24px;
    ${display('flex', 'center')};
  }

  ${PhotoSmall} {
    + ${PhotoSmall} {
      margin-left: 16px;
    }
  }
`;

class Gallery extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <PhotoBig />

        <Block>
          <PhotoSmall />
          <PhotoSmall />
          <PhotoSmall />
          <PhotoSmall />
          <PhotoSmall />
          <PhotoSmall />
          <PhotoSmall />
        </Block>
      </Wrapper>
    );
  }
}

export default styled(Gallery)``;
