import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

const PARSE_OPTIONS = {
  ignoreQueryPrefix: true
};

const DEFAULT_OPTIONS = {
  redirect_url: '/login'
};

function useResetPasswordToken({ redirect_url } = DEFAULT_OPTIONS) {
  const location = useLocation();
  const history = useHistory();

  const { reset_password_token } = qs.parse(location.search, PARSE_OPTIONS);

  if (!reset_password_token) {
    history.replace(redirect_url);
  }

  return [reset_password_token];
}

export default useResetPasswordToken;
