import React from 'react';
import PropTypes from 'prop-types';
import { Plus_20Icon, MinusIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  border-radius: 3px;
  border: 1px solid #d1d5db;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  ${typography(13, 15, 400)}
  ${(p) =>
    p.disableExtra &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const StyledPlus = styled(Plus_20Icon)`
  cursor: pointer;
  ${(p) =>
    p.maxCount &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const StyledMinus = styled(MinusIcon)`
  cursor: pointer;
  ${(p) =>
    p.minCount &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const Chip = ({ bedsCount, onSelect, isAvailableRates, selected, displayCount, isExtra }) => {
  const minCount = displayCount <= 1;
  const maxCount = displayCount === Math.min(isAvailableRates, bedsCount);
  const disableExtra = isExtra && selected !== Math.min(isAvailableRates, bedsCount);

  const handleIncrement = (e) => {
    if (!maxCount) {
      onSelect({ count: selected + 1 });
    }
  };

  const handleDecrement = (e) => {
    if (!minCount) {
      onSelect({ count: selected - 1 });
    }
  };

  return (
    <Wrapper disableExtra={disableExtra}>
      <StyledMinus onClick={(e) => handleDecrement(e)} minCount={minCount} />
      {displayCount}
      <StyledPlus onClick={(e) => handleIncrement(e)} maxCount={maxCount} />
    </Wrapper>
  );
};

Chip.propTypes = {
  bedsCount: PropTypes.number,
  onSelect: PropTypes.func,
  isAvailableRates: PropTypes.number,
  selected: PropTypes.number,
  displayCount: PropTypes.number,
  isExtra: PropTypes.bool
};

Chip.defaultProps = {
  onSelect: () => null
};

export default Chip;
