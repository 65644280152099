export const customizePhoneValue = (value) => {
  if (!value) return;

  let formattedPhone = value.replace(/\D/g, '');

  if (formattedPhone.startsWith('8')) {
    formattedPhone = '7' + formattedPhone.slice(1);
  }

  return '+' + formattedPhone;
};
