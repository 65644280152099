import { useState, useMemo } from 'react';

import { LazyStore, QueueStore } from '../../stores';

const getStatuses = (status) => {
  const isPending = status === 'pending';
  const isFetched = status === 'done';
  const isError = status === 'error';
  const isEmpty = status === 'empty';

  return { isPending, isFetched, isError, isEmpty };
};

const useSearch = ({ initialData, initialTotal, lazy } = { initialData: [], initialTotal: 0, lazy: true }) => {
  const store = useMemo(() => {
    return lazy ? new LazyStore() : new QueueStore();
  }, [lazy]);

  // Status of request
  const [status, setStatus] = useState(undefined);

  // Result data
  const [data, setData] = useState(initialData);
  const [total, setTotal] = useState(initialTotal);
  const [seo, setSeo] = useState();
  const [available, setAvailable] = useState(0);

  // Set hooks
  store.setHooks({
    onSetStatus: setStatus,
    onSetTotal: setTotal,
    onSetSeo: setSeo,
    onSetData: setData,
    onSetAvailable: setAvailable
  });

  const doFetch = (query) => {
    store.fetch({ data: query });
  };

  const doMore = () => store.fetchMore();

  return [{ total, available, seo, data, ...getStatuses(status) }, doFetch, doMore];
};

export default useSearch;
