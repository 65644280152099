import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { EventsContainer } from 'hooks/useEvents';

import Hotels from './components/Hotels';
import { useOrder } from 'stores/OrdersStore/hooks';

import { FluidTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import HotelsInfo from '../HotelsInfo';

import useQuery from 'hooks/useQuery';
import { useSearch } from 'stores/SearchStore';

const Wrapper = styled.div``;

function SearchNew({ className, header }) {
  const isMounted = useRef();

  const history = useHistory();
  const { id } = useParams();

  // Fetch Order
  const [order, { fetchOrder, updateOrder, buildEditUrl }] = useOrder();

  // Search query
  const [{ query }, { updateQuery }] = useQuery();
  const [{ data, isPending }, doFetch] = useSearch({ lazy: false, initialData: [] });

  useEffect(() => {
    const doSearch = async () => {
      const order = await fetchOrder(id);

      const { address, check_in, check_out } = order;
      updateQuery({ address, check_in, check_out });
    };

    doSearch();
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    doFetch(query);
  }, [query]);

  // Handlers
  const handleUpdateQuery = (values) => {
    updateQuery(values);
  };

  const handleUpdateOrder = async (values) => {
    await updateOrder(id, values);

    const editUrl = buildEditUrl(id, query);
    history.push(editUrl, query);
  };

  return (
    <EventsContainer>
      <Wrapper className={className}>
        <FluidTemplate
          hasOverflow={false}
          header={<Header {...header} />}
          body={(
            <Hotels
              order={order}
              isPending={isPending}
              hotels={data}
              place={query.address}
              query={query}
              updateOrder={handleUpdateOrder}
              handleUpdate={handleUpdateQuery} />
            )}
        />

        <HotelsInfo
          showHotelButton
          updateOrder={handleUpdateOrder}
        />
      </Wrapper>
    </EventsContainer>
  );
}

SearchNew.propTypes = {
  className: PropTypes.string,
  header: PropTypes.object,
  match: PropTypes.object
};

export default styled(SearchNew)``;
