import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { display } from 'theme/mixins';
import { Button } from 'components/forms';
import { NoPhoto } from 'components/icons';

const Wrapper = styled.div`
  ${display('flex', 'center')}
  padding: 12px 0;
`;

const Preview = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')}
    position: relative;
    width: 68px;
    height: 68px;
    margin-right: 24px;
    border: 2px solid ${theme.colors.active.primary};
    border-radius: 999px;
    background: ${theme.colors.passive.primary};
  `}
`;

const PreviewImage = styled.img`
  ${({ isDisplay }) => css`
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.25s, transform 0.25s;

    ${isDisplay && css`
      opacity: 1;
      transform: scale(1);
    `}
  `}
`;

const PreviewIcon = styled(NoPhoto)`
  width: 32px;
  height: 32px;
`;

const Controls = styled.div`
  ${display('flex', 'center')}

  ${Button} {
    margin: 0 8px;
  }
`;

@withTheme
@inject('profileForm')
@observer
class UploadPhoto extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    profileForm: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @observable src = null;

  handlePhotoChange = () => {
    this.src = 'https://mr-mem.ru/images/memes/smalls/uhmylyayuschiysya-putin_1496479941.jpg';
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Preview>
          <PreviewImage
            isDisplay={!!this.src}
            src={this.src}
          />

          <PreviewIcon />
        </Preview>

        <Controls>
          <Button
            type='button'
            rounded
            size='small'
            variant='outline'
            onClick={this.handlePhotoChange}
          >
            {t('Profile.UplaodPhoto.New')}
          </Button>

          <Button
            type='button'
            rounded
            size='small'
            variant='outline-critical'
          >
            {t('Profile.UplaodPhoto.Remove')}
          </Button>
        </Controls>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(UploadPhoto))``;
