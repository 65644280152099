import { initialState } from './useResale';

export const ACTIVATE = 'ACTIVATE';
export const DEACTIVATE = 'DEACTIVATE';
export const TOGGLE_SHOW_MODAL = 'TOGGLE_SHOW_MODAL';
export const TOGGLE_VISIBLE = 'TOGGLE_VISIBLE';
export const RESET_FORM = 'RESET_FORM';
export const SET_PENDING = 'SET_PENDING';
export const UPDATE_FORM = 'UPDATE_FORM';
export const SET_PRICE = 'SET_PRICE';
export const SET_DATE = 'SET_DATE';

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIVATE: {
      return { ...state, ...action.data };
    }

    case DEACTIVATE: {
      return { ...state, active: false, openModal: action.data };
    }

    case TOGGLE_SHOW_MODAL: {
      return { ...state, openModal: action.data };
    }

    case TOGGLE_VISIBLE: {
      return { ...state, visible: !state.visible };
    }

    case RESET_FORM: {
      return { ...state, ...initialState };
    }

    case SET_PENDING: {
      return { ...state, isPending: !state.isPending };
    }

    case UPDATE_FORM: {
      return { ...state, ...action.data };
    }

    case SET_PRICE: {
      return { ...state, price: action.data };
    }

    case SET_DATE: {
      return { ...state, available_from_date: action.data };
    }

    default: {
      return state;
    }
  }
};
