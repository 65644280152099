import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RamblerAvatar from 'rambler-ui/Avatar';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const AvatarS = styled(RamblerAvatar)``;

class Avatar extends React.PureComponent {
  render() {
    const { ...props } = this.props;

    return <AvatarS {...props} />;
  }
}

Avatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  iconBackgroundColor: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
  shape: PropTypes.oneOf(['circle', 'square', 'rounded']),
  href: PropTypes.string,
  container: ReactComponentChildrenPropType
};

Avatar.defaultProps = {
  className: '',
  size: 45,
  shape: 'circle'
};

export default styled(Avatar)``;
