import MockAdapter from 'axios-mock-adapter';

import { reportsStore, settingsStore } from './fixtures';

export function applyMocks(instance) {
  const mock = new MockAdapter(instance, { delayResponse: 500 });

  mock
    .onGet('/api/settings').reply(() => new Promise(
      (resolve) => {
        resolve([
          200,
          { ...settingsStore }
        ]);
      }
    ))

    .onGet('/api/reports').reply(({ params }) => new Promise(
      (resolve) => {
        const limit = 10;
        const page = +params.paginate.page || 1;
        const total = Math.ceil(reportsStore.data.length / limit);
        const reportsData = { ...reportsStore };

        if (page > 1) {
          reportsData.data = reportsData.data.slice(limit * (page - 1), limit * page);
        }

        reportsData.meta = {
          paginate: {
            limit,
            total,
            page
          }
        };

        resolve([
          200,
          reportsData
        ]);
      }
    ))

    .onAny().passThrough();
}
