import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { display } from 'theme/mixins';
import { BackLink } from 'components/ui';
import Button from 'components/forms/Button';

import {
  DatesField, GuestsField
} from './components';

const FormS = styled.div`
  width: 100%;
  ${display('flex', 'flex-start', 'flex-start')};
`;

const Actions = styled(Button)`
  width: 118px;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 42px;

  ${BackLink} + ${FormS} {
    margin-left: 28px;
  }

  ${DatesField} {
    height: 42px;
    width: 280px;
    overflow: hidden;
    border-radius: 3px;

    input {
      height: 42px !important;
    }
  }

  ${GuestsField} {
    width: 230px;
    margin-left: 5px;
  }
`;

function FilterBar({ addressFieldWidth, onSubmit, hasBackButton, ...rest }) {
  const { t } = useTranslation();

  const handleSubmit = (slug) => {
    onSubmit && onSubmit();
  };

  return (
    <Wrapper {...rest} addressFieldWidth={addressFieldWidth}>
      <FormS>
        <DatesField
          {...rest}
        />

        <GuestsField
          {...rest}
        />

        <Actions onClick={handleSubmit}>
          {t('Orders.OrderForm.SubmitButton')}
        </Actions>
      </FormS>
    </Wrapper>
  );
}

FilterBar.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  addressFieldWidth: PropTypes.string,
  hasBackButton: PropTypes.bool
};

FilterBar.defaultProps = {
  className: '',
  hasBackButton: true
};

export default styled(FilterBar)``;
