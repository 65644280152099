import data from './assets/data';
import { t } from 'utils/localization';

function fetchPopularRequests() {
  const title = t('Filter.Places.PopularQueries.Title');
  const items = data.ru || [];

  return Promise.resolve({ title, data: items });
}

export default fetchPopularRequests;
