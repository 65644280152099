import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'components/v2/ui/Tooltip';
import { t } from 'utils/localization';

import { BedIcon } from 'components/icons';

const Overlay = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  font-size: 8px;
  color: white;
  line-height: 8px;
  font-weight: 600;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  background-color: #E26161;
  padding: 0 3px;

  ${BedIcon} {
    margin-left: 6px;
    pointer-events: visiblePainted;
  }
`;

class RemainingBeds extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.number
  };

  render() {
    const { className, count } = this.props;
    const overlaytext = (
      <Overlay dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.OneFreeBed') }} />
    );

    return (
      <Tooltip
        placement='bottom'
        overlay={overlaytext}
      >
        <Wrapper className={className}>
          {count} <BedIcon />
        </Wrapper>
      </Tooltip>
    );
  }
}

export default styled(RemainingBeds)``;
