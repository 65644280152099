import { action, observable, computed, toJS } from 'mobx';
import moment from 'utils/moment';

import _pick from 'lodash/pick';
import _range from 'lodash/range';
import _findIndex from 'lodash/findIndex';
import _clone from 'lodash/clone';
import _last from 'lodash/last';

import hotelMapper from 'utils/hotelMapper';
import getRestrictionsData from 'utils/getRestrictionsData';
import getResatrictionsRules from 'utils/getResatrictionsRules';
import getOccupation from './utils/getOccupation';
import { updateParams } from './utils/paramsMapper';
import pricesPerDayForRates from './utils/pricesPerDayForRates';
import roomTypesSorting from './utils/roomTypesSorting';

import OrderStore from 'stores/OrdersStore/One';
import HotelsInfo from 'stores/HotelsStore/Info';
import HotelsPricing from 'stores/PricesStore';
import AvailabilityStore from 'stores/AvailabilityStore/AvailabilityStore';

const DEFAULT_TRAVELLERS = [
  {
    age_group: 'adult',
    age: 0
  }
];

class BookingState {
  constructor(props) {
    // Set InitialParams
    this.setInitialSearchQuery(props);

    // Initialize store with current order
    this.initOrderStore();
    this.fetchOrderStore(props);
  }

  // Init SearchOptions
  @observable.ref initialSearchQuery = {};
  @observable isPending = false;

  @action
  setIsPending(value) {
    this.isPending = value;
  }

  @action
  setInitialSearchQuery(params) {
    let { check_in, check_out, travellers } = params;
    travellers ||= DEFAULT_TRAVELLERS;

    this.initialSearchQuery = { check_in, check_out, travellers };
  }

  // Set editable mode for reservation
  @observable.ref reservation = undefined;

  @action
  setEditableMode({ order, reservation_id }) {
    if (!reservation_id) return;

    const reservation = order.reservations
      .find(r => r.id === reservation_id);

    this.reservation = toJS(reservation);
  }

  @action
  unsetEditableMode() {
    this.reservation = undefined;
  }

  @computed get isEditableMode() {
    return !!this.reservation;
  }

  // Form Id
  @computed get formId() {
    return [this.hotel.id, this.tariff.id, this.room_type.id].join('');
  }

  @computed get check_in() {
    if (this.isEditableMode) return this.reservation.check_in;

    const { initialSearchQuery, order, order: { reservations } } = this;

    const data = reservations.length > 0
      ? _last(reservations).check_in
      : (initialSearchQuery.check_in || order.check_in);

    return data;
  }

  @computed get check_out() {
    if (this.isEditableMode) return this.reservation.check_out;

    const { initialSearchQuery, order, order: { reservations } } = this;

    const data = reservations.length > 0
      ? _last(reservations).check_out
      : (initialSearchQuery.check_out || order.check_out);

    return data;
  }

  @computed get travellers() {
    let travellers = this.order.travellers;
    travellers = toJS(travellers);

    return travellers
      .filter(traveller => !traveller.with_seat);
  }

  @computed get isFetched() {
    return this.hasAvailability && this.hasDailyAvailabilities;
  }

  // Orders Store
  @observable.ref orderState = { code: undefined, description: '' }

  @action
  setOrderState(code) {
    this.orderState = { code, description: '' };
  }

  @action
  unsetOrderState() {
    this.orderState = { code: undefined, description: '' };
  }

  @observable.ref order = undefined;

  @computed get hasOrder() {
    return !!this.order;
  }

  @computed get orderIsPending() {
    return this.orderStore.isPending;
  }

  @action
  setOrder({ order }) {
    this.order = order;
    return this.order;
  }

  @action
  unsetOrder() {
    this.order = undefined;
    this.onUnsetOrder();

    return this.order;
  }

  @action
  initOrderStore() {
    this.orderStore = OrderStore.create();
    this.setOrderState('created');
    return this.orderStore;
  }

  @action
  async fetchOrderStore({ id, reservation_id }) {
    this.setOrderState('pending');

    const store = await this.orderStore.fetch({ id });

    const order = toJS(store.data);
    this.setOrder({ order });

    this.setEditableMode({ order, reservation_id });
    this.initHotelAndAvailabilities({ order });

    this.setOrderState('done');

    return store;
  }

  @action
  async orderUpdate(params) {
    const orderStore = this.orderStore;

    const values = updateParams({
      order: toJS(this.order),
      reservation: params
    });

    this.setIsPending(true);

    const store = await orderStore.update(values);

    this.setIsPending(false);

    const order = toJS(store.data);
    this.setOrder({ order });

    this.unsetEditableMode();

    return store;
  }

  @action
  async orderContractUpdate(contract) {
    const orderStore = this.orderStore;

    let values = _pick(
      this.order,
      ['id', 'check_in', 'check_out', 'address', 'hotel', 'travellers']
    );

    values = { contract, ...values };

    const store = await orderStore.update(values);

    const order = toJS(store.data);
    this.setOrder({ order });

    this.unsetEditableMode();

    return store;
  }

  @action
  async orderCancel() {
    const store = await this.orderStore.cancel();
    const order = toJS(store.data);
    this.setOrder({ order });

    this.unsetEditableMode();

    return store;
  }

  onSetOrder(order) {
    this.initHotelAndAvailabilities({ order });
  }

  onUnsetOrder() {}

  // Hotel Store
  @observable.ref hotelState = { code: undefined, description: '' }

  @action
  setHotelState(code) {
    this.hotelState = { code, description: '' };
  }

  @action
  unsetHotelState() {
    this.hotelState = { code: undefined, description: '' };
  }

  @observable.ref hotel = undefined;

  @computed get hasHotel() {
    return !!this.hotel;
  }

  @action
  setHotel({ hotel }) {
    this.hotel = hotel;
  }

  @action
  unsetHotel() {
    this.hotel = undefined;
  }

  async initHotelInfoStore({ order }) {
    const { id } = order.hotel;

    this.hotelsInfo = HotelsInfo.create();
    this.setHotelState('created');
    this.setHotelState('pending');

    const store = await this.hotelsInfo.fetch({ id });
    this.setHotel({ hotel: hotelMapper(toJS(store.data)) });
    this.setHotelState('done');
    return store;
  }

  // RoomTyoe and Tariffs as Availabilities
  @observable.ref availabilitiesState = { code: undefined, description: '' }

  @action
  setAvailabilitiesState(code) {
    this.availabilitiesState = { code, description: '' };
  }

  @action
  unsetAvailabilitiesState() {
    this.availabilitiesState = { code: undefined, description: '' };
  }

  @observable.ref availabilities = []

  @computed get hasAvailabilities() {
    const availabilities = this.availabilities || [];
    return availabilities.length > 0;
  }

  @observable.ref room_types = []

  @action
  setAvailabilities({ hotel }) {
    const { room_types } = hotel;

    const travellers = this.initialSearchQuery.travellers || DEFAULT_TRAVELLERS;
    const [roomTypes, availabilities] = roomTypesSorting({ hotel, room_types, travellers });

    this.availabilities = availabilities;
    this.room_types = roomTypes;

    this.onSetAvailabilities(availabilities);
  }

  @action
  unsetAvailabilities() {
    this.availabilities = [];
  }

  @action
  selectFirstAvailability({ availabilities }) {
    const availability = availabilities[0];
    if (!availability) return undefined;

    this.setAvailability({ availability });
  }

  @action
  selectAvailabilityForReservation(reservation) {
    const { hotel, room_type, tariff } = reservation;

    const availability = this.availabilities.find(a => (
      a.hotel.id === hotel.id &&
      a.room_type.id === room_type.id &&
      a.tariff.id === tariff.id
    ));

    if (availability) this.setAvailability({ availability });
  }

  async initHotelPricingStore({ order }) {
    const { hotel: { id } } = order;

    const travellers = this.initialSearchQuery.travellers || DEFAULT_TRAVELLERS;

    this.hotelsPricing = HotelsPricing.create();
    this.setAvailabilitiesState('created');
    this.setAvailabilitiesState('pending');

    const { check_in, check_out } = this;
    const params = { id, check_in, check_out, travellers };
    const store = await this.hotelsPricing.fetch(params);

    this.setAvailabilities({ hotel: toJS(store.data) });
    this.setAvailabilitiesState('done');

    return store;
  }

  async updateHotelPricingStore(query) {
    this.setAvailabilitiesState('pending');

    const store = await this.hotelsPricing.fetch(query);
    this.setInitialSearchQuery(query);
    this.setAvailabilities({ hotel: toJS(store.data) });
    this.setAvailabilitiesState('done');
  }

  onSetAvailabilities(availabilities) {
    if (this.isEditableMode) {
      const { reservation } = this;
      this.selectAvailabilityForReservation(reservation);

      if (!this.hasAvailability) {
        this.selectFirstAvailability({ availabilities });
      }

      return;
    }

    this.selectFirstAvailability({ availabilities });
  }

  onUnsetAvailabilities() {
    this.unsetOccupation();
  }

  // Selected availability
  @observable.ref availability = undefined;
  @observable.ref room_type = undefined;
  @observable.ref tariff = undefined;

  @computed get hasAvailability() {
    return !!this.availability;
  }

  @action
  setAvailability({ availability }) {
    const { room_type, tariff } = availability;

    this.availability = availability;
    this.room_type = room_type;
    this.tariff = tariff;

    this.onSetAvailability(availability);
  }

  @action
  unsetAvailability() {
    this.availability = undefined;
    this.room_type = undefined;
    this.tariff = undefined;

    this.onUnsetAvailabilities();
  }

  availabilitySelected({ availability }) {
    const { hotel, tariff, room_type } = availability;
    if (!this.hasAvailability) return false;

    return this.availability.tariff?.id === tariff.id &&
      this.availability.room_type?.id === room_type.id &&
      this.availability.hotel?.id === hotel.id;
  }

  onSetAvailability(availability) {
    this.setOccupation(availability);

    const order = this.order;
    this.initAvailabilityStore({ availability, order });
  }

  // Form Params
  @observable.ref formParams = undefined;

  @action
  setFormParams() {
    const options = this.isEditableMode
      ? this.setFormEditParams()
      : this.setFormNewParams();

    this.formParams = options;
  }

  @action
  setFormNewParams() {
    const { check_in, check_out, order, hotel, room_type, tariff, occupation } = this;

    const slots = [...toJS(tariff.slots)];

    const options = {
      order: toJS(order),
      check_in,
      check_out,
      slots,
      hotel,
      room_type,
      tariff,
      occupation,
      dailyPrices: this.getDailyPricesPerPeriod({ check_in, check_out })
    };

    return options;
  }

  setFormEditParams() {
    const {
      reservation: {
        id, check_in, check_out, slots
      }
    } = this;

    const travellers = [
      ...this.travellers,
      ...slots.map(slot => slot.traveller)
    ];

    const options = {
      order: toJS(this.order),
      id: id,
      check_in: check_in,
      check_out: check_out,
      hotel: this.hotel,
      room_type: this.room_type,
      tariff: this.tariff,
      dailyPrices: this.getDailyPricesPerPeriod({ check_in, check_out }),
      occupation: this.occupation,
      travellers: travellers,
      slots: slots.filter(slot => slot.state !== 'cancelled')
    };

    return options;
  }

  @action
  unsetFormParams() {
    this.formParams = undefined;
  }

  @action toggleAvailability({ availability }) {
    // this.unsetDailyAvailabilities();
    this.unsetAvailability();

    this.setAvailability({ availability });
  }

  // Occupations variations
  @observable.ref occupation = {};

  @computed get bedsCount() {
    return this.room_type ? this.room_type.beds : 1;
  }

  @computed get extraBedsCount() {
    return this.room_type ? this.room_type.extra_beds : 0;
  }

  @computed get mainOccupation() {
    const from = 1;
    const to = this.bedsCount + 1;

    return _range(from, to).map(number => {
      const rates = this.occupation[number] || [];
      return { number, rates };
    });
  }

  @computed get extraOccupation() {
    const from = this.bedsCount + 1;
    const to = from + this.extraBedsCount;

    return _range(from, to).map(number => {
      const rates = this.occupation[number] || [];
      return { number, rates };
    });
  }

  @action setOccupation({ tariff, room_type }) {
    const occupation = getOccupation({ tariff, room_type });
    this.occupation = occupation;
  }

  @action unsetOccupation() {
    this.occupation = {};
  }

  // Availabilities rates store
  @observable.ref dailyAvailabilities = undefined;

  @computed get currentDailyPeriod() {
    if (!this.hasDailyAvailabilities) return undefined;

    const { check_in, check_out } = this.dailyAvailabilities.meta.toJSON();
    return { check_in, check_out };
  }

  @computed get dailyAvailabilitiesId() {
    if (!this.dailyAvailabilities) return 1;

    return this.dailyAvailabilities.$treenode.nodeId;
  }

  @computed get hasDailyAvailabilities() {
    return !!this.dailyAvailabilities;
  }

  @action
  setDailyAvailabilities(data) {
    this.dailyAvailabilities = data;

    this.onSetDailyAvailabilities(data);
  }

  @action
  unsetDailyAvailabilities() {
    this.dailyAvailabilities = undefined;
  }

  async initAvailabilityStore({ order, availability }) {
    const travellers = this.initialSearchQuery.travellers || DEFAULT_TRAVELLERS;
    const { check_in, check_out } = this;

    this.createAvailabilityStore();

    const store = await this.fetchAvailabilityStore({
      checkIn: check_in,
      checkOut: check_out,
      travellers: travellers
    });

    this.setDailyAvailabilities(store);

    return store;
  }

  createAvailabilityStore() {
    this.availabilityStore = AvailabilityStore.create();
  }

  fetchAvailabilityStore({ checkIn, checkOut, travellers }) {
    const { provider } = this.hotel;
    const isOstrovok = provider === 'ostrovok';
    const check_in = isOstrovok ? moment(checkIn).format('YYYY-MM-DD') : moment(checkIn).startOf('month').format('YYYY-MM-DD');

    const check_out = isOstrovok
      ? moment(checkOut).format('YYYY-MM-DD')
      : moment.max([
        moment(check_in).add(1, 'month').endOf('month'),
        moment(checkOut).endOf('month').endOf('month')
      ]).format('YYYY-MM-DD');

    const options = _pick(
      { check_in, check_out, travellers, ...this.availability },
      ['check_in', 'check_out', 'hotel.id', 'room_type.id', 'tariff.id', 'travellers']
    );

    let { hotel, ...data } = options;
    data = this.isEditableMode
      ? { ...data, excludes: [this.reservation.id] }
      : data;

    return this.availabilityStore.fetch(hotel.id, { data });
  }

  prevDailyPeriod() {
    const travellers = this.initialSearchQuery.travellers || DEFAULT_TRAVELLERS;
    const { check_in } = this.availabilityStore.meta;

    const currentMonthStart = moment().startOf('month');
    const checkInMoment = moment(check_in).startOf('month');

    // Prevent fetching previous month if current month is displayed
    if (checkInMoment.isSameOrBefore(currentMonthStart, 'month')) {
      return Promise.resolve(); // No action needed
    }

    const checkIn = checkInMoment
      .subtract(1, 'month')
      .format('YYYY-MM-DD');

    // Set checkOut
    const checkOut = moment(checkIn)
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    return this.fetchAvailabilityStore({ checkIn, checkOut, travellers });
  }

  nextDailyPeriod() {
    const travellers = this.initialSearchQuery.travellers || DEFAULT_TRAVELLERS;
    const { check_in } = this.availabilityStore.meta;

    const checkIn = moment(check_in)
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    // Set checkOut
    const checkOut = moment(checkIn)
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    return this.fetchAvailabilityStore({ checkIn, checkOut, travellers });
  }

  getDailyPricesPerPeriod({ check_in, check_out }) {
    // Always set to 1 - and for day, and for night;
    // const { billing_hour } = this.tariff;
    const billingShift = 1;

    const data = _clone(this.dailyAvailabilities.data);

    const firstDate = moment(check_in).format('Y-MM-DD');
    const firstIndex = _findIndex(data, price => price.day === firstDate);
    if (firstIndex < 0) return [];

    const lastDate = moment(check_out).format('Y-MM-DD');
    const lastIndex = _findIndex(data, price => price.day === lastDate);
    if (lastIndex < 0) return [];

    return data.slice(firstIndex, lastIndex + billingShift);
  }

  getDailyPricesForRates({ rates, withCommission, range } = { rates: [], withCommission: false }) {
    const { data } = this.dailyAvailabilities;

    const { pricesList, pricesMap, pricesDisabled } = pricesPerDayForRates({
      rates, prices: toJS(data), withCommission, range
    });

    return { pricesList, pricesMap, pricesDisabled };
  }

  getRestrictions({ rates, withCommission, range } = { rates: [], withCommission: false, range: [] }) {
    if (!this.dailyAvailabilities) return;

    const { data } = this.dailyAvailabilities;

    const { pricesList } = pricesPerDayForRates({
      rates, prices: toJS(data), withCommission, range
    });

    const { mappedRestrictions } = getRestrictionsData({ range, pricesPerDay: pricesList, billing_hour: this.tariff.billing_hour, tariff: this.tariff });

    return getResatrictionsRules({ restrictions: mappedRestrictions, billing_hour: this.tariff.billing_hour, range });
  }

  onSetDailyAvailabilities(data) {
    this.setFormParams();
    this.setDisabledDays(data);
  }

  // Disabled dates
  @observable.ref disabledDays = [];

  @action
  setDisabledDays(data) {
    const today = moment().startOf('day').toDate();
    const days = toJS(data.data)
      .filter(day => day.available <= 0 || moment(day.day).isBefore(today)) // Disable days before today
      .map(day => moment(day.day).toDate());

    this.disabledDays = days;
  }

  //
  initHotelAndAvailabilities(options) {
    this.initHotelInfoStore(options);
    this.initHotelPricingStore(options);
  }

  // Handlers
  addHandlers() {}

  removeHandlers() {}
}

export default BookingState;
