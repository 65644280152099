import React from 'react';
import styled from 'styled-components';
import GeosuggestInput from 'react-geosuggest';

const Wrapper = styled(GeosuggestInput)`
  &&& {
    position: relative;

    .geosuggest__input {
      width: 100%;
      height: 36px;
      border: 1px solid #d1d5db;
      border-radius: 4px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 13px;
      line-height: 15px;
      background: #ffffff;
      box-sizing: border-box;
      font-family: inherit;
      transition: all 0.25s;

      &:focus {
        color: #4a515c;
        border-color: #abb3bf;
        box-shadow: none;
        outline: 0;

        &::placeholder {
          color: #d6d9df;
        }
      }

      &.geosuggest__input_has-error {
        border-color: #ff564e;
      }
    }

    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border: 1px solid #D1D5DB;
      background-color: #fff;
      box-shadow: 0 2px 14px -3px rgba(0,0,0,0.16);
      padding: 10px 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;

      .geosuggest__item {
        width: auto;
        height: 34px;
        padding: 0 20px;
        line-height: 34px;
        vertical-align: middle;
        font-size: 13px;
        font-family: 'Helvetica Neue', sans-serif;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover, &:focus {
          background-color: #C5D2E2;
          cursor: pointer;
        }
      }

      .geosuggest__item--active {
        background-color: #C5D2E2;
      }

      .geosuggest__item__matched-text {
        font-weight: bold;
      }
    }

    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border: none;
      box-shadow: none;
      padding: 0;
    }
  }
`;

class Input extends React.PureComponent {
  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
      />
    );
  }
}

export default styled(Input)``;
