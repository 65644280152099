import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  width: 282px;
  padding: 8px;
  z-index: 1;
  box-sizing; border-box;
  border-radius: 4px;
  background: #FFB3B3;
  line-height: 16px;
  color: #6B707B;
  font-size: 13px;
`;

const DisabledPopup = () => {
  return (
    <Wrapper>
      Заказ нельзя отправить на Перепродажу,<br />т.к. в нем более одного номера
    </Wrapper>
  );
};

DisabledPopup.propTypes = {

};

export default DisabledPopup;
