import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from 'components/v2/ui/Tooltip';
import { useTranslation } from 'react-i18next';

import { InstantBookingIcon } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ variant }) => variant === 'secondary' && css`
    width: 21px;
    height: 21px;
    font-size: 21px;

    ${InstantBooking} {
      path {
        fill: #5A87D6;
      }
    }
  `};

  ${({ variant }) => variant === 'hotelCard' && css`
    width: 18px;
    height: 18px;
    font-size: 14px;
  `};

  ${({ variant }) => variant === 'small' && css`
    width: 15px;
    height: 15px;
    font-size: 12px;
  `};

  ${InstantBookingIcon} {
    width: initial;
  }
`;

function InstantBooking({ className, variant }) {
  const { t } = useTranslation();

  return (
    <Wrapper
      className={className}
      variant={variant}
    >
      <Tooltip
        placement='bottom'
        overlay={t('Orders.Filters.InstantBooking')}
      >
        <InstantBookingIcon />
      </Tooltip>
    </Wrapper>
  );
}

InstantBooking.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string
};

export default styled(InstantBooking)``;
