import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GuestsPicker, DateRangePicker1 as DateRangePicker } from 'components/forms';

const Wrapper = styled.div`
  ${DateRangePicker} + ${GuestsPicker} {
    margin-top: 10px;
  }
`;

const Filters = ({ query, onChange, className }) => {
  const { check_in, check_out, travellers } = query;

  const handleChange = (value) => {
    const newValue = { ...query, ...value };
    onChange(newValue);
  };

  return (
    <Wrapper className={className}>
      <DateRangePicker
        value={[check_in, check_out]}
        onChange={(value) => handleChange({ check_in: value[0], check_out: value[1] })}
      />

      <GuestsPicker
        applyImmediatly={false}
        value={travellers}
        onSelect={(travellers) => handleChange({ travellers })}
      />
    </Wrapper>
  );
};

Filters.defaultProps = {
  onChange: () => {}
};

Filters.propTypes = {
  className: PropTypes.string,
  query: PropTypes.shape({
    check_in: PropTypes.string.isRequired,
    check_out: PropTypes.string.isRequired,
    travellers: PropTypes.array.isRequired
  }),
  onChange: PropTypes.func
};

export default styled(Filters)``;
