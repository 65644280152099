import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Photo } from '../';

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 16px;
  font-size: 0;
  text-align: center;
  white-space: nowrap;
  user-select: none;

  ${Photo} {
    display: inline-block;
    margin: 4px;
    vertical-align: middle;
  }
`;

const Inner = styled.div`
  ${({ leftOffset }) => css`
    transform: translateX(-${leftOffset}px);
  `}
`;

@observer
class Previews extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    carouselProps: PropTypes.object,
    currentIndex: PropTypes.number,
    views: PropTypes.arrayOf(PropTypes.object)
  };

  @observable leftOffset = 0;

  handleClick = (node, index) => {
    const { onClickThumbnail } = this.props.carouselProps;
    const { width } = node.current.getBoundingClientRect();
    this.leftOffset = node.current.offsetLeft - window.innerWidth / 2 - width / 2;

    if (onClickThumbnail) {
      onClickThumbnail(index);
    }
  }

  render() {
    const { views, currentIndex, ...rest } = this.props;

    if (views.length > 0) {
      return (
        <Wrapper {...rest}>
          <Inner
            leftOffset={this.leftOffset}
            ref={(node) => { this.previewsNode = node; }}
          >
            {views.map((image, index) => (
              <Photo
                key={index}
                src={image.src}
                isActive={currentIndex === index}
                onClick={node => this.handleClick(node, index)}
              />
            ))}
          </Inner>
        </Wrapper>
      );
    }

    return null;
  }
}

export default styled(Previews)``;
