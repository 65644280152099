import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { adultsAndChildsText } from 'utils/travellersText';
import { useTranslation } from 'react-i18next';

import { UserBlueIcon } from 'components/icons';
import useClickOutside from 'hooks/useClickOutside';

import Popover from './Popover';

const Input = styled.input`
  width: 100%;
  height: 42px;
  cursor: pointer;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding-left: 44px;
  padding-right: 15px;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.25s;

  &:focus {
    color: #4a515c;
    border-color: #abb3bf;
    box-shadow: none;
    outline: 0;

    &::placeholder {
      color: #d6d9df;
    }
  }

  ${({ hasSelected }) => hasSelected && css`
    padding-right: 36px;
  `}
`;

const InputContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Popover} {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    top: 46px;
  }

  ${UserBlueIcon} {
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

`;

function GuestsPicker({ value, onSelect, applyImmediatly, className }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef();

  const [newValue, setNewValue] = useState(value);

  useClickOutside(wrapperRef, () => {
    if (!opened) {
      return;
    }

    setOpened(false);
    onSelect(newValue);
  });

  const valueText = useMemo(() => {
    return adultsAndChildsText(value);
  }, [value]);

  const handleClick = e => {
    setOpened(!opened);
  };

  const handleSelect = (value) => {
    setNewValue(value);

    if (applyImmediatly) {
      onSelect(value);
    }
  };

  return (
    <Wrapper className={className} ref={wrapperRef}>
      <InputContainer>
        <UserBlueIcon />

        <Input
          readOnly
          icon={<UserBlueIcon />}
          value={valueText}
          // eslint-disable-next-line no-use-before-define
          autoComplete='off'
          placeholder={t('Filters.Places.Placeholder')}
          onClick={handleClick}
        />
      </InputContainer>

      {opened && (
        <Popover
          value={value}
          onSelect={handleSelect}
          onClose={() => setOpened(false)}
        />
      )}
    </Wrapper>
  );
}

GuestsPicker.propTypes = {
  className: PropTypes.string,
  applyImmediatly: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line no-use-before-define
      age_group: PropTypes.oneOf(['adult', 'child']).isRequired,
      age: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onSelect: PropTypes.func
};

GuestsPicker.defaultProps = {
  applyImmediatly: true,
  value: [],
  onSelect: () => { }
};

export default styled(GuestsPicker)``;
