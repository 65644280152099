import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Block, Text } from 'components/atoms';
import { display, typography } from 'theme/mixins';

const Wrapper = styled(Block)`
  ${display('flex', 'flex-start', 'space-between')};

  :not(:first-child) {
    margin-top: 8px;
  }

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Info = styled(Block)``;

const Traveller = styled(Text)`
  ${typography(14, 20)}
`;

const Description = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.text.disabled};
    ${typography(14, 20)}
  `}
`;

@withTheme
class Slot extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    slot: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      slot: {
        traveller,
        rate
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <Info>
          {traveller && (
            <Traveller>
              {traveller.first_name} {traveller.last_name}
              {', '}
              {t('Traveler.Age', { count: traveller.age })}
            </Traveller>
          )}

          <Description>
            {rate.description}
          </Description>
        </Info>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Slot))``;
