import _compact from 'lodash/compact';
import transformObjectKeys from '../transformObjectKeys';
import { camelCase } from 'change-case';

const DEFAULT_ATTRS = { first_name: '', last_name: '', middle_name: '' };

function fullName(attributes = DEFAULT_ATTRS) {
  const { firstName, lastName, middleName } = transformObjectKeys(attributes, camelCase);
  return _compact([lastName, firstName, middleName]).join(' ');
}

function shortName(attributes = DEFAULT_ATTRS) {
  const { firstName, lastName, middleName } = transformObjectKeys(attributes, camelCase);

  const initial = _compact([firstName, middleName])
    .map(item => item[0].toUpperCase())
    .map(item => `${item}.`)
    .join(' ');

  return [lastName, initial].join(' ');
}

export { fullName, shortName };
