import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${({ anchorPointY, isOpened, theme }) => css`
    position: relative;
    padding: 16px;
    background: ${theme.colors.light};
    border: 2px solid ${theme.colors.active.primary};
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s, margin-top 0.25s, margin-bottom 0.25s;

    ::after {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 4px);
      width: 8px;
      height: 8px;
      background: ${theme.colors.light};
      border-radius: 0 0 2px 0;
      border-right: 2px solid ${theme.colors.active.primary};
      border-bottom: 2px solid ${theme.colors.active.primary};
    }

    ${isOpened && css`
      opacity: 1;
      pointer-events: all;
    `}

    ${anchorPointY === 'top' && css`
      ::after {
        top: -6px;
        transform: rotate(225deg);
      }

      ${!isOpened && css`
        margin-top: -4px;
      `}
    `}

    ${anchorPointY === 'bottom' && css`
      ::after {
        bottom: -6px;
        transform: rotate(45deg);
      }
    `}
  `}
`;

class Bubble extends React.PureComponent {
  static propTypes = {
    anchorPointY: PropTypes.oneOf(['top', 'bottom']),
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    isOpened: PropTypes.bool
  };

  static defaultProps = {
    anchorPointY: 'top',
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Bubble)``;
