import _flatten from 'lodash/flatten';

import { mergePayments } from 'utils/pricesPayment';
import moment from 'utils/moment';
import { datesRange } from 'utils/dates';

function groupMapper(reservations, id) {
  const { tariff, room_type } = reservations[0];

  const payment = mergePayments(reservations.map(item => item.payment));

  let dates = reservations.map(({ check_in, check_out }) => (
    [moment(check_in).utcOffset(check_in).toDate(), moment(check_out).utcOffset(check_out).toDate()]
  ));
  dates = _flatten(dates);

  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);

  const dates_of_stay = datesRange(minDate, maxDate, 'D MMM YYYY');

  const rooms_count = reservations.length;
  const seats_count = reservations.reduce((sum, r) => sum + r.slots.length, 0);

  return { id, dates_of_stay, tariff, room_type, payment, rooms_count, seats_count, reservations };
}

export default groupMapper;
