import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Group from './Group';
import GroupBy from './GroupBy';

const Body = styled.div`
  ${Group} + ${Group} {
    margin-top: 13px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${GroupBy} + ${Body} {
    margin-top: 13px;
  }
`;

@observer
class Groups extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    groups: PropTypes.array.isRequired,
    view: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { view, groups, className } = this.props;

    const listItems = groups.map(group => (
      <Group
        key={group.id}
        view={view}
        group={group}
      />
    ));

    return (
      <Wrapper className={className}>
        <GroupBy />

        <Body>
          {listItems}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Groups))``;
