import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { createUser } from 'redux/stores/usersSlice/actions';
import { addOne as createNotification } from 'redux/stores/notificationsSlice/actions';

import UserForm from '../components/UserForm';
import { Button } from 'components/forms';

const Header = styled.div`
  color: #9CB4CA;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
`;

const Body = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  border: 2px solid #9CB4CA;
  border-radius: 6px;
  background-color: rgba(156,180,202,0.2);

  ${Header} + ${Body} {
    margin-top: 24px;
  }
`;

function Add({ className }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    await dispatch(createUser(values));
    setIsLoading(false);

    const notification = {
      type: 'success',
      time: 5,
      message: t('Settings.Users.Notifications.AddedSuccess')
    };

    dispatch(createNotification(notification));

    history.push('/settings/users');
  };

  return (
    <Wrapper className={className}>
      <Header>
        {t('Settings.Users.Add.Title')}
      </Header>

      <Body>
        <UserForm
          onSubmit={handleSubmit}
        >
          <Button
            size='small'
            type='submit'
            loading={isLoading}
          >
            {t('UI.Button.Add')}
          </Button>
        </UserForm>
      </Body>
    </Wrapper>
  );
}

Add.propTypes = {
  className: PropTypes.string
};

export default styled(Add)``;
