import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SideBar } from './components';
import Map from 'components/ui/Map';

// Moscow
const defaultCoordinates = [37.622504, 55.753215];

const SideBarS = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 144px);
  
  ${Map} {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100%;
  }
`;

function MapComponent({ hotels, query, updateOrder, className, ...rest }) {
  const [lng, lat] = query.address
    ? query.address.coordinates.map(c => Number(c))
    : defaultCoordinates;

  return (
    <Wrapper className={className}>
      <Map
        defaultCenter={{ lat, lng }}
        hotels={hotels}
        query={query}
      />

      <SideBarS>
        <SideBar
          query={query}
          hotels={hotels}
          updateOrder={updateOrder}
          {...rest}
        />
      </SideBarS>
    </Wrapper>
  );
}

MapComponent.propTypes = {
  className: PropTypes.string,
  hotels: PropTypes.array,
  query: PropTypes.object,
  updateOrder: PropTypes.func
};

MapComponent.defaultProps = {
  hotels: []
};

export default styled(MapComponent)``;
