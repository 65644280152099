import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import { display, typography } from 'theme/mixins';

const Wrapper = styled(Link)`
  ${({ active, theme }) => css`
    ${display('flex', 'center')}
    height: 64px;
    padding: 0 48px;
    border-top: 2px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 3px 3px 0 0;

    ${active && css`
      background: ${theme.colors.passive.secondary};
      border-top-color: ${theme.colors.active.primary};
      border-left-color: ${theme.colors.popup.primary};
      border-right-color: ${theme.colors.popup.primary};
      cursor: default;
    `}
  `}
`;

const Inner = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    position: relative;
    color: ${theme.colors.text.secondary};
    ${typography(16, 20, 700)}
  `}
`;

const Count = styled.div`
  ${({ active, theme }) => css`
    ${display('flex', 'center', 'center')}
    position: absolute;
    top: -8px;
    right: -18px;
    width: 14px;
    height: 14px;
    margin: 1px;
    background: ${theme.colors.text.secondary};
    border-radius: 3px;
    color: ${theme.colors.light};
    ${typography(10, 12, 700)}

    ${active && css`
      background-color: ${theme.colors.active.primary};
    `}
  `}
`;

@withTheme
class NavigationTabs extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    label: PropTypes.string,
    count: PropTypes.number,
    active: PropTypes.bool
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { to, label, count, active, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        as={!!active && 'div'}
        active={active}
        to={to}
      >
        <Inner>
          {label}

          {count && (
            <Count active={active}>
              {count}
            </Count>
          )}
        </Inner>
      </Wrapper>
    );
  }
}

export default styled(NavigationTabs)``;
