import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation, useTranslation } from 'react-i18next';

import { Tabs as BaseTabs } from 'components/organisms';
import { Procedures, Programs, Description, Rules, TreatmentPrograms, Meal, Amenities } from '.';

const Wrapper = styled(BaseTabs)`
  &&& {
    a {
      font-weight: 400;

      .active {
        font-weight: 700;
      }
    }
  }

  ${Procedures},
  ${Programs},
  ${Meal},
  ${Amenities},
  ${Description} {
    padding-top: 20px;
  }
`;

function Tabs({ className, hotel, ...rest }) {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('Hotel.Details.Tabs.Rules'),
      value: 'rules',
      component: Rules
    },
    {
      label: t('Hotel.Details.Tabs.Programs'),
      value: 'programs',
      component: Programs
    },
    {
      label: t('Hotel.Details.Tabs.TreatmentPrograms'),
      value: 'treatmentPrograms',
      component: TreatmentPrograms
    },
    {
      label: t('Hotel.Details.Tabs.Procedures'),
      value: 'procedures',
      component: Procedures
    },
    {
      label: t('Hotel.Details.Tabs.Meal'),
      value: 'meal',
      component: Meal
    },
    {
      label: t('Hotel.Details.Tabs.Infrastructure'),
      value: 'infrastructure',
      component: Amenities
    },
    {
      label: t('Hotel.Details.Tabs.Description'),
      value: 'description',
      component: Description
    }
  ];

  return <Wrapper {...rest} tabs={tabs} hotel={hotel} />;
}

Tabs.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object.isRequired
};

export default styled(withTranslation()(Tabs))``;
