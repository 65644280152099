import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import _isFinite from 'lodash/isFinite';

const Count = styled.a`
  width: 18px;
  height: 18px;
  border-radius: 9999px;
  color: #6B707B;
  font-size: 16px;
  text-align: center;
  background-color: #D1D5DB;
  cursor: pointer;
`;

const Value = styled.input`
  width: 24px;
  text-align: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${Value} {
    margin: 0 16px;
  }
`;

const Header = styled.div`
  font-size: 13px;
  color: #4A515C;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 42px;
  padding: 0 12px;
  border: 2px solid #D1D5DB;
  border-radius: 9999px;
  box-sizing: border-box;
  user-select: none;

  ${Header} + ${Body} {
    margin-left: 24px;
  }
`;

@withTranslation()
@observer
class RoomsCounter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    t: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: '',
    maxValue: 1,
    minValue: 1
  };

  @observable count = 1;

  @action
  setValue(value) {
    const { field } = this.props;
    field.set(value);
  }

  @action
  getValue() {
    const { field } = this.props;
    return field.value;
  }

  handleTurnUp = (e) => {
    e.preventDefault();

    const { maxValue } = this.props;
    const currentValue = this.getValue();

    const value = Math.min(maxValue, currentValue + 1);
    this.setValue(value);
  }

  handleTurnDown = (e) => {
    e.preventDefault();

    const { minValue } = this.props;
    const currentValue = this.getValue();

    const value = Math.max(minValue, currentValue - 1);
    this.setValue(value);
  }

  handleChange = (e) => {
    const value = Number(e.target.value);

    if (!_isFinite(value)) return undefined;

    const { minValue, maxValue } = this.props;
    if (value < minValue) return undefined;
    if (value > maxValue) return undefined;

    this.setValue(value);
  }

  render() {
    const { field, t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Header>
          {t('Form.RoomsCounter.Label')}:
        </Header>

        <Body>
          <Count onClick={this.handleTurnDown}>
            –
          </Count>

          <Value
            {...field.bind({ onChange: this.handleChange })}
          />

          <Count onClick={this.handleTurnUp}>
            +
          </Count>
        </Body>
      </Wrapper>
    );
  }
}

export default styled(RoomsCounter)``;
