import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useQuery from 'hooks/useQuery';

import Selected from './Selected';
import Filters from './Filters';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  padding: 0 0 10px;
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21)

  ${Selected}, ${Filters} {
    flex-shrink: 0;
  }

  ${Filters} {
    flex-grow: 1;
  }

  ${Selected} + ${Filters} {
    margin-top: 20px;
  }
`;

function SideLeft({ showSelected, showFilters, className }) {
  // Search query
  const [{ query, total }, { updateQuery, clearQuery, applyQuery }] = useQuery();

  const isMounted = useRef();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    applyQuery();
  }, [query]);

  const handleChange = (value) => {
    updateQuery(value);
  };

  const handleClear = () => {
    clearQuery();
  };

  return (
    <Wrapper className={className}>
      {showSelected && total > 0 && (
        <Selected
          total={total}
          onClear={handleClear}
        />
      )}

      {showFilters && (
        <Filters
          query={query}
          onChange={handleChange}
        />
      )}
    </Wrapper>
  );
}

SideLeft.propTypes = {
  className: PropTypes.string,
  showSelected: PropTypes.bool,
  showFilters: PropTypes.bool
};

SideLeft.defaultProps = {
  showSelected: true,
  showFilters: true
};

export default styled(React.memo(SideLeft))``;
