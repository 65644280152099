import { useReducer } from 'react';

import instance from 'connection/instance';
import moment from 'utils/moment';
import {
  ACTIVATE,
  DEACTIVATE,
  reducer,
  RESET_FORM,
  SET_DATE,
  SET_PENDING,
  SET_PRICE,
  TOGGLE_SHOW_MODAL,
  TOGGLE_VISIBLE,
  UPDATE_FORM
} from './reducer';

const format = 'yyyy-MM-DD';

export const initialState = {
  visible: false,
  price: null,
  available_from_date: moment().format(format),
  active: false,
  isPending: false,
  openModal: false
};

const useResale = (order, updateOrder) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...order.resale });

  const updatedState = {
    price: state.price,
    available_from_date: moment(state.available_from_date).format(format),
    active: state.active,
    visible: state.visible,
    openModal: false
  };

  const activateState = {
    price: order.resale.price ? order.resale.price : order.price,
    available_from_date: order.resale.available_from_date
      ? moment(order.resale.available_from_date).format(format)
      : moment().format(format),
    active: true,
    visible: order.resale.visible ? order.resale.visible : false,
    openModal: true
  };

  const showModal = (data) => dispatch({ type: TOGGLE_SHOW_MODAL, data });
  const toggleVisible = () => dispatch({ type: TOGGLE_VISIBLE });
  const activate = () => dispatch({ type: ACTIVATE, data: activateState });
  const resetForm = () => dispatch({ type: RESET_FORM });
  const setPrice = (data) => dispatch({ type: SET_PRICE, data });
  const setDate = (data) => dispatch({ type: SET_DATE, data });
  const saveForm = async () => {
    await updateResale(updatedState);
  };
  const updateForm = async (data) => {
    dispatch({ type: UPDATE_FORM, data });
  };
  const deactivate = async () => {
    dispatch({ type: DEACTIVATE });
    await updateResale(initialState);
  };

  const updateResale = async (options) => {
    dispatch({ type: SET_PENDING });

    try {
      const {
        data: {
          data: { resale }
        }
      } = await instance.patch(`/api/orders/${order.id}/resale_update`, { data: { resale: options } });
      updateForm(resale);
      updateOrder();
    } catch (error) {
      console.log('resale error', error);
      resetForm();
    } finally {
      dispatch({ type: SET_PENDING });
    }
  };

  return [state, { activate, deactivate, saveForm, resetForm, setPrice, setDate, toggleVisible, showModal }];
};

export default useResale;
