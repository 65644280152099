import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/forms/Button';
import { OrderCancelDialog } from 'pages/Orders/components';

import useQuery from 'hooks/useQuery';

import { HotelFilterBar } from 'components/ui/FilterBar';

const FilterContainer = styled.div`
  box-sizing: border-box;
  height: 56px;
  border: 2px solid #9CB4CA;
  border-radius: 6px;
  background-color: rgba(156,180,202,0.2);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 7px;
  margin-left: 30px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  ${Button} {
    width: 144px;
    color: #9CB4CA;
    padding: 0 12px;
  }
`;

const Title = styled.div`
  color: #9CB4CA;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1222px
`;

function Header({ className, onChange }) {
  const [isOrderCancelDialogOpened, setIsOrderCancelDialogOpened] = useState();
  const history = useHistory();
  const { t } = useTranslation();
  const [{ query }, { updateQuery, applyQuery }] = useQuery();

  const handleSubmit = () => {
    applyQuery('', { replace: true });

    onChange(query);
  };

  const handleCancel = () => {
    history.push('/orders');
  };

  return (
    <Wrapper className={className}>
      <LeftContainer>
        <Title>{t('Orders.NewOrder')}</Title>

        <FilterContainer>
          <HotelFilterBar
            query={query}
            handleUpdate={updateQuery}
            onSubmit={handleSubmit}
          />
        </FilterContainer>
      </LeftContainer>

      <RightContainer>
        <Button
          outline
          variant='secondary'
          onClick={() => setIsOrderCancelDialogOpened(true)}
        >
          {t('Order.Cancel')}
        </Button>
      </RightContainer>

      <OrderCancelDialog
          isOpened={isOrderCancelDialogOpened}
          onClose={() => setIsOrderCancelDialogOpened(false)}
          onCancel={handleCancel}
        />
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func
};

Header.defaultProps = {
  className: ''
};

export default styled(Header)``;
