import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';
import { withTranslation } from 'react-i18next';

import BedSelector from '../BedSelector';
import Slot from '../Slot';

const SlotWrapper = styled.div`
  margin-bottom: 33px;
`;

const ExtraBedsText = styled.div`
  opacity: 0.6;
  color: #979BA0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 15px;
`;

const BedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 33px;
`;

const Wrapper = styled.div``;

@withTranslation()
@inject('bookingForm', 'bookingState')
@observer
class MainSeats extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired,
    t: PropTypes.func
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { bookingForm, bookingState, t, ...rest } = this.props;

    const { bedsCount, mainOccupation, extraBedsCount } = bookingState;

    const { mainSlots, selectedMainCount } = bookingForm;

    let subTitle = pluralize(bedsCount, 'место', 'места', 'мест');
    subTitle = [bedsCount, subTitle, 'максимум'].join(' ');

    const bedSelector = {
      title: 'Основных мест',
      subTitle: subTitle,
      bedsCount: bedsCount,
      selected: selectedMainCount,
      occupation: mainOccupation,
      onSelect: bookingForm.setSlots,
      displayCount: selectedMainCount,
      isExtra: false
    };

    const listItems = mainSlots
      .map(o => <Slot key={o.field.id} {...o} />);

    return (
      <Wrapper {...rest}>
        <BedContainer>
          <BedSelector {...bedSelector} />

          {extraBedsCount > 0 && (
            <ExtraBedsText>
              {t('Hotel.RoomType.Guests.ExtraBedsHit', { count: extraBedsCount })}
            </ExtraBedsText>
          )}
        </BedContainer>

        <SlotWrapper>
          {listItems}
        </SlotWrapper>
      </Wrapper>
    );
  }
}

MainSeats.propTypes = {
  className: PropTypes.string
};

MainSeats.defaultProps = {
  className: ''
};

export default styled(MainSeats)``;
