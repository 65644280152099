import { action, computed, observable } from 'mobx';

class CommissionVisibility {
  @observable visible = true

  @observable amountVisible = true

  @observable percentVisible = true

  @computed get isAmountVisible() {
    return this.amountVisible && !!this.visible;
  }

  @computed get isPercentVisible() {
    return this.percentVisible && !!this.visible;
  }

  @computed get isVisible() {
    return !!this.visible;
  }

  @action
  showCommission() {
    this.visible = true;
  }

  @action
  hideCommission() {
    this.visible = false;
  }

  @action
  toggleCommission() {
    this.visible = !this.visible;
  }
}

export default CommissionVisibility;
