const ADULT_GROUP = 'adult';

// const CHILD_GROUP = 'child';

function setChilds({ travellers, childs }) {
  const adults = travellers.filter(t => t.age_group === ADULT_GROUP);

  return [...adults, ...childs];
}

export default setChilds;
