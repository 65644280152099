import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const start = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 20px;
    opacity: 1;
  }
`;

const progressLinearMovement = keyframes`
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: #3aa6d2;
  transition: transform 0.2s linear;
`;

const BarOne = styled(Bar)`
  animation: ${progressLinearMovement} 2.5s infinite;
  animation-delay: 0;
`;

const BarTwo = styled(Bar)`
  left: -100%;
  animation: ${progressLinearMovement} 2.5s infinite;
  animation-delay: 0.7s;
`;

const LinearProgress = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(146, 214, 208, 0.5);
  overflow: hidden;
  animation: ${start} 0.3s ease-in;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 4px;
`;

@observer
class LinearLoader extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        <LinearProgress>
          <BarOne />
          <BarTwo />
        </LinearProgress>
      </Wrapper>
    );
  }
}

export default styled(LinearLoader)``;
