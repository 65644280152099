import { t } from 'utils/localization';

const fields = [
  'chars',
  'filters[]',
  'filters[].type',
  'filters[].label',
  'filters[].value'
];

const placeholders = {
  chars: t('Orders.SearchOrder')
};

const values = {
  chars: '',
  filters: []
};

const types = {
  'filters[].type': 'string',
  'filters[].label': 'string',
  'filters[].value': 'string'
};

const rules = {
  chars: 'string',
  filters: 'array|min:1'
};

export default {
  fields,
  rules,
  types,
  values,
  placeholders
};
