import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem } from 'rambler-ui/Menu';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled(MenuItem)``;

class Item extends React.PureComponent {
  static propTypes = {
    children: ReactComponentChildrenPropType.isRequired,
    className: PropTypes.string
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Item)``;
