import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import styled from 'styled-components';

const Content = styled.div`
  margin: ${(p) => (p.currentHeight > 930 ? '20px 0' : 'auto 0')};
`;

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  cursor: auto;

  ${Content} {
    align-self: flex-start;
    display: flex;
    outline: none;
  }
`;

class Modal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { contentHeight: 0 };
  }

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    opened: PropTypes.bool,
    onClose: PropTypes.func
  };

  refOverlay = React.createRef();
  refContent = React.createRef();

  static defaultProps = {
    className: '',
    opened: false,
    onClose: () => null
  };

  state = {
    contentHeight: 0
  };

  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp);

    if (this.refContent?.current?.clientHeight > 0) {
      this.setState({
        contentHeight: this.refContent?.current?.clientHeight
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp);

    this.setState({
      contentHeight: this.refContent?.current?.clientHeight
    });
  }

  onClickOverlay = (e) => {
    if (e.target === this.refOverlay.current) {
      e.preventDefault();

      this.close(e);
    }
  };

  close = (e) => {
    const { onClose } = this.props;

    if (onClose) {
      onClose(e);
    }
  };

  onKeyUp = (e) => {
    const { opened } = this.props;

    if (opened && e.key === 'Escape') {
      this.close(e);
    }
  };

  render() {
    const { children, opened, ...rest } = this.props;
    const { onClickOverlay, refOverlay, refContent } = this;

    return (
      !!opened && (
        <Portal>
          <Wrapper {...rest} ref={refOverlay} onClick={onClickOverlay}>
            <Content currentHeight={this.state.contentHeight} ref={refContent}>
              {children}
            </Content>
          </Wrapper>
        </Portal>
      )
    );
  }
}

export default styled(Modal)``;

export const modal = (Component) =>
  class extends React.PureComponent {
    static displayName = `modal(${Component.displayName})`;

    render() {
      return (
        <Modal {...this.props}>
          <Component {...this.props} />
        </Modal>
      );
    }
  };
