import { types } from 'mobx-state-tree';

import Restrictions from './Restrictions';

const Rate = types.model('Rate', {
  type: types.string,
  price: types.number,
  net_price: types.number,
  gross_price: types.number
});

const DailyAvailability = types.model('DailyAvailability', {
  day: types.identifier,
  in_all: types.number,
  busy: types.number,
  restriction: types.number,
  available: types.number,
  stop_sales: types.optional(types.boolean, false),
  rates: types.array(Rate),
  available_beds: types.number,
  restrictions: Restrictions
});

export default DailyAvailability;
