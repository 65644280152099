import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CommonPart from '../CommonPart';
import DropDown from './DropDown';

const DropdownComponent = styled(DropDown)``;

const Content = styled.div``;

const Wrapper = styled.div`
  ${DropdownComponent} + ${DropdownComponent} {
    margin-top: 20px;
  }
`;

function Procedures({ className, hotel: { manipulation_programs } }) {
  const { t } = useTranslation();
  const [openedProgramId, setOpenedProgramId] = useState();

  const handleOpen = (id) => () => {
    if (openedProgramId === id) {
      setOpenedProgramId(null);
      return;
    }

    setOpenedProgramId(id);
  };

  const procedures = manipulation_programs.map((program) => (
    <DropdownComponent
      program={program}
      key={program.id}
      onClick={handleOpen(program.id)}
      opened={program.id === openedProgramId}
    />
  ));

  return (
    <Wrapper className={className}>
      <CommonPart title={t('Hotel.Details.Tabs.Procedures')}>
        <Content>{procedures}</Content>
      </CommonPart>
    </Wrapper>
  );
}

Procedures.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default Procedures;
