import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const Name = styled.div`
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const Position = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 58px;
  padding: 0 14px;
  background-color: #E5E8ED;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
`;

@withTranslation()
@inject('authStore')
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    className: PropTypes.string
  }

  render() {
    const { authStore, t, className } = this.props;
    const { first_name } = authStore.data;

    return (
      <Wrapper className={className}>
        <Name>
          {first_name}
        </Name>
        <Position>
          {t('Conversation.Header.Position')}
        </Position>
      </Wrapper>
    );
  }
}

export default styled(Header)``;
