export const calculatePenaltyPrice = (rule, price, slots) => {
  switch (rule?.penalty?.unit) {
    case 'percent': {
      return (price * rule?.penalty?.amount) / 100;
    }

    case 'rub': {
      return rule?.penalty?.amount;
    }

    case 'first_day': {
      return slots.reduce((sum, slot) => {
        const slotPrices = slot?.prices?.slice(0, rule?.penalty?.amount).reduce((acc, item) => acc + item?.price, 0);

        return sum + slotPrices;
      }, 0);
    }

    default: {
      return price;
    }
  }
};
