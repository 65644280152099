import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import Reservation from './Reservation';

const Wrapper = styled.div`
`;

@observer
class Reservations extends React.Component {
  static propTypes = {
    reservations: PropTypes.array.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { reservations, ...rest } = this.props;

    const listItems = reservations.map(item => (
      <Reservation
        key={item.id}
        reservation={item}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservations))``;
