import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

import moment from 'utils/moment';
import { mergePayments } from 'utils/pricesPayment';
import groupMapper from '../groupMapper';

function datesIdx(reservation) {
  const { check_in, check_out } = reservation;

  return [
    moment(check_in).utcOffset(check_in).format(),
    moment(check_out).utcOffset(check_out).format()
  ].join('-');
}

function groupsByDates(reservations) {
  let groups = _groupBy(reservations, datesIdx);
  groups = _map(groups, groupMapper);

  const payment = mergePayments(groups.map(item => item.payment));

  return { payment, groups };
}

export default groupsByDates;
