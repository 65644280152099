import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.h1`
  display: flex;
  flex-direction: column;
  color: #9CB4CA;
  font-family: "Helvetica Neue";
  font-size: 24px;
  font-weight: bold;
`;

const SubHeader = styled.span`
  color: #9CB4CA;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 8px;
`;

const Content = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 22px;

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 28px;
    margin-bottom: 24px;
  }

  b {
    font-weight: bold;
  }

  p {
    text-align: justify;
  }

  p + p {
    margin-top: 24px;
  }

  ul {
    padding: 12px;

    li {
      list-style-type: disc;
    }

    li + li {
      margin-top: 12px;
    }
  }

  a {
    color: #3AA6D2;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 48px;

  ${Header} {
    margin-top: 48px;
    margin-bottom: 24px;
  }
`;

function Body({ className }) {
  return (
    <Wrapper>
      <Header>
        АГЕНТСКИЙ ДОГОВОР-ОФЕРТА ООО «ЭКСПЕРТНО-МЕДИЦИНСКИЙ ЦЕНТР»

        <SubHeader>
          редакция от 01.07.2021
        </SubHeader>
      </Header>

      <Content>
        <p>
          Настоящий документ является официальным предложением <b>Общества с
          ограниченной ответственностью «Экспертно-медицинский центр»</b> (далее – «Принципал),
          адресованным российским юридическим лицам и индивидуальным предпринимателям,
          зарегистрированным в установленном законом порядке (далее – «Агент»),
          заключить агентский договор на нижеперечисленных условиях.
        </p>

        <p>
          В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации
          настоящий документ является публичной офертой.
        </p>

        <p>
          Настоящий агентский договор-оферта (далее Договор) заключается в особом порядке: путем акцепта Агентом настоящей публичной оферты, содержащей все существенные условия Договора, без подписания сторонами. Настоящий Договор имеет юридическую силу в соответствии со ст. 434 Гражданского Кодекса Российской Федерации и является равносильным договору, подписанному сторонами.
        </p>

        <p>
          Настоящая Оферта является бессрочной. Принципал может в любой момент отозвать Оферту, что не должно влиять на Договоры, которые были заключены с Агентом до даты такого отзыва.
        </p>

        <p>
          Принципал вправе в одностороннем порядке вносить изменения в настоящую Оферту (во избежание сомнений, включая, но не ограничиваясь в условия Договора, заключенного посредством акцепта Оферты) путем опубликования новой версии Оферты по следующей ссылке в сети Интернет: <a href='https://agency.sanatory.ru'>https://agency.sanatory.ru</a>/info/offer  .Обновленная версия Оферты вступает в законную силу с момента ее опубликования по указанной выше ссылке в сети Интернет. Агент, в случае заключения Договора, обязуется регулярно ознакамливаться с настоящей Офертой на предмет внесения в нее изменений.
        </p>

        <p>
          Настоящий договор-оферта является Договором присоединения.
        </p>

        <p>
          Фактом, подтверждающим полное и безоговорочное принятие изложенных ниже условий и акцептом настоящей публичной оферты со стороны Агента является выполнение хотя бы одного из нижеперечисленных действий:
        </p>

        <ul>
          <li>
            <p>
              нажатие Агентом на кнопку «Я ознакомился, согласен и полностью принимаю условия Оферты» (или аналогичной по смыслу), доступную в процессе регистрации компании Агента на ресурсе (веб интерфейсе) Принципала <a href='https://agency.sanatory.ru'>https://agency.sanatory.ru</a> и нажатия Агентом кнопки «Создать кабинет», в момент появления на экране Агента всплывающего окна, подтверждающего получение Акцепта Агента Принципалом. При отсутствии такого всплывающего окна (вследствие технической ошибки) Акцепт Агента не считается полученным Принципалом, а настоящий Договор не считается заключенным.
            </p>
            <p>
              Агент предоставляет полные, точные и достоверные документы и информации, запрашиваемых в ходе регистрации, а также иные документов и информации, если такие документы и информация запрашиваются в Личном кабинете Агента. В случае, если Принципалом будет обнаружено предоставление неполных и/или неточных и/или недостоверных данных и документов, Принципал может предложить Агенту исправить предоставленную информацию или отказать в заключении Договора.
            </p>
          </li>
          <li>
            <p>
              нажатие Агентом кнопки «Принять оферту» из письма, отправленного Принципалом Агенту по электронной почте, указанной как основной адрес в процессе регистрации кабинета компании Агента на сайте <a href='https://agency.sanatory.ru'>https://agency.sanatory.ru</a>
            </p>
          </li>
          <li>
            <p>
              формирование Агентом счёта на оплату Услуг в Личном кабинете (как определено далее) на сумму от 1 000 (одной тысячи) руб. и оплаты указанного счета с расчетного счета Агента не позднее 5 (пяти) календарных дней с момента формирования такого счёта
или оплата счета, выставленного Принципалом за забронированные в Личном кабинете услуги, с расчетного счета Агента на счёт Принципала в срок, указанный в счёте.
            </p>
          </li>
        </ul>

        <p>
          Акцепт Агентом настоящей Оферты в порядке, предусмотренном выше, порождает заключение Договора в порядке, предусмотренном ст. 438 ГК РФ, на условиях настоящей Оферты.
        </p>

        <p>
          Акцепт является подтверждением полного и безоговорочного согласия Агента с условиями настоящей Оферты. Акцепт Оферты с изъятиями/дополнениями не допускается.
        </p>

        <p>
          Акцептуя настоящую Оферту, лицо, совершающее акцепт Оферты в предусмотренном порядке, подтверждает и заверяет, что оно уполномочено на совершение такого действия от имени Агент и обладает всеми необходимыми правами и разрешениями, необходимыми для совершения такого действия от имени Агента. В случае нарушения такого заверения, лицо, акцептовавшее Оферту, обязуется возместить убытки Принципала по письменному требованию.
        </p>

        <p>
          Заключенный Агентом Договор-оферта в дальнейшем будет размещен в Личном кабинете;
          Принципал и Агент далее совместно именуются «Стороны», а по отдельности – «Сторона».
          Условия Договора, заключаемого посредством акцепта Оферты:
        </p>

        <h2>
          ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
        </h2>

        <p>
          <b>Сайт</b> – информационный ресурс в сети Интернет, размещенный по адресу <a href='https://agency.sanatory.ru'>https://agency.sanatory.ru</a>  а также версии и приложения Сайта, предназначенные для мобильных устройств.
        </p>

        <p>
          <b>Система</b> - Сервис бронирования туристических услуг для предпринимателей, размещенный по адресу в сети Интернет на Сайте; программа для ЭВМ, представляющая из себя многофункциональную информационно-технологическую платформу бронирования туристических услуг; комплекс программных и аппаратных средств, (а) предоставляющих доступ к информации об Услугах, а также об условиях предоставления, приобретения и аннуляции Услуг, (б) обеспечивающих возможность Агенту и зарегистрированным Агентом Пользователям самостоятельно осуществлять выбор требуемых Услуг, рассчитывать их стоимость, бронировать и/или приобретать Услуги; (в) а также обеспечивающих возможность получения Агентом дополнительной информации и аналитических отчётов о забронированных и приобретенных Услугах;
        </p>

        <p>
          <b>Пользователь</b> – физическое лицо, уполномоченное выступать перед Принципалом от имени Агента, совершать от имени Агента сделки в Системе, бронировать Услуги и осуществлять иные полномочия.
        </p>

        <p>
          Принципал наделяет полномочиями Агента путем выдачи Логина одному или нескольким Пользователям Агента по письменному запросу Агента.
        </p>

        <p>
          <b>Личный кабинет</b> - подраздел Сайта, Кабинет, содержащий статистическую и финансовую информацию, связанную с пользованием Агентом Системой, в том числе информацию об Агенте, количестве оформленных Заказов, их стоимости, данные статистики и иную информацию в отношении услуг, определенных по Договору, а также который предоставляет возможность удаленного взаимодействия Сторон в рамках Договора, в том числе для поддержания перечня Пользователей Личного кабинета в актуальном состоянии, доступный Агенту после авторизации на Сайте с использованием логина и пароля Агента с соответствующими правами доступа.
        </p>

        <p>
          <b>Заказ</b> - заявка на Услуги, оформляемая Агентом в Системе, а также в порядке, предусмотренном п. 2.4. настоящего Договора.
        </p>

        <p>
          <b>Информационная база данных</b> - представленная в объективной форме и являющаяся частью Системы определенным образом организованная совокупность данных, включающая в себя содержательное описание Услуг, включая, в частности, статичный и динамичный контент, многоязычные описания, фото, видео, описания тарифов, геоданные, политики отмены или изменения Заказов, информацию о доступности Услуг и иную информацию и данные, связанные с Услугами.
        </p>

        <p>
          <b>Возвратный тариф</b> – стоимость Заказа, который может быть отменен Агентом с частичным удержанием денежных средств Принципалом или без такового.
        </p>

        <p>
          <b>Невозвратный тариф</b> - стоимость Заказа, который может быть отменен Агентом исключительно с полным удержанием денежных средств Принципалом.
        </p>

        <p>
          <b>Услуги</b> - отдельная туристская услуга или несколько туристских услуг, не формирующие Туристский продукт, указанные в п. 1 настоящего Договора, на которые Агентом оформляется Заказ по заданию туриста и (или) иного заказчика.
        </p>

        <p>
          <b>Правила бронирования</b> – размещенные в Системе Правила бронирования отдельных видов Услуг, содержащие условия, которыми Стороны обязаны руководствоваться в целях исполнения принятых на себя обязательств.
        </p>

        <p>
          <b>Тайм-лимит (Time Limit)</b> – время, по истечению которого оформление Заказа или Услуги должно быть подтверждено Агентом; в противном случае, заявка на Заказ или отдельную Услугу Заказа аннулируется.
        </p>

        <p>
          <b>Туристский продукт</b> – сформированный Принципалом комплекс услуг по перевозке и размещению, оказываемых за общую цену (независимо от включения в общую цену стоимости экскурсионного обслуживания и (или) других услуг).
        </p>

        <p>
          <b>Условия бронирования и реализации туристского продукта</b> - являющиеся неотъемлемой частью настоящего Договора и размещенные в Системе правила, регламентирующие порядок бронирования и реализации туристского продукта, содержащие условия, которыми Стороны обязаны руководствоваться в целях исполнения принятых на себя обязательств.
        </p>

        <p>
          <b>Условия бронирования и реализации туристского продукта</b> - являющиеся неотъемлемой частью настоящего Договора и размещенные в Системе правила, регламентирующие порядок бронирования и реализации туристского продукта, содержащие условия, которыми Стороны обязаны руководствоваться в целях исполнения принятых на себя обязательств.
        </p>

        <h2>
          1. ПРЕДМЕТ ДОГОВОРА. ПРАВА И ОБЯЗАННОСТИ СТОРОН
        </h2>

        <p>
          1.1. В соответствии с настоящим Договором Агент обязуется от своего имени, но за счет Принципала осуществлять юридические и фактические действия, направленные на продвижение и реализацию Услуг, в состав которых может включаться:
        </p>

        <ul>
          <li>
            бронирование гостиничных номеров и размещения в санаториях в России и за рубежом;
          </li>
          <li>
            бронирование гостиничных услуг и/или иных туристских услуг в России и за рубежом;
          </li>
          <li>
            бронирование услуг реабилитационно-восстановительного лечения;
          </li>
          <li>
            заказ транспортного обслуживания в России и за рубежом (авиа-, ж/д билеты, бронирование трансферов, аренды автомобиля, перевозка иным видом транспорта);
          </li>
          <li>
            услуги по оформлению документов при получении виз для граждан РФ и других государств;
          </li>
          <li>
            оформление полисов медицинского страхования для лиц, выезжающих за рубеж, на период поездки;
          </li>
          <li>
            организация туристских программ (в том числе, экскурсионных);
          </li>
          <li>
            организация проведения кейтеринга, бизнес-завтраков и иных мероприятий;
          </li>
          <li>
            аренда конференц-залов;
          </li>
          <li>
            прочие виды туристских услуг, на которые Агент оформляет Заказ.
          </li>
        </ul>

        <p>
          1.2. Агент осуществляет от своего имени реализацию Услуг, предоставленных Принципалом по настоящему Договору, путем заключения с третьими лицами соответствующих договоров. При этом такие договоры должны соответствовать действующему законодательству в области защиты прав потребителей, а также полностью информировать туристов и (или) иных заказчиков о потребительских свойствах Услуг, правилах оказания Услуг, условиях их изменения и отмены.
        </p>

        <p>
          1.2.1 Оформляя Заказ в системе для третьих лиц (туристов), Агент подтверждает, что обладает должными полномочиями и осуществлять обработку персональных данных третьих лиц (туристов) таких как: фамилия, имя, телефон, адрес электронной почты, дата рождения, данные банковской карты, файлы cookie и иные персональные данные, указание которых необходимо для бронирования в Системе. Агент обеспечивает защиту обрабатываемых персональных данных туристов от несанкционированного доступа и разглашения, неправомерного использования или утраты в соответствии с требованиями Федерального закона от 27 июля 2006 г. № 152- ФЗ «О персональных данных» и Приказа ФСТЭК России от 18.02.2013 N 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных».
        </p>

        <p>
          1.2.2. Агент обязуется предоставлять туристам и (или) иным заказчикам полную и достоверную информацию о потребительских свойствах Услуг, бронируемых у Принципала, предусмотренном действующим законодательством, о перечне необходимых документов, предоставляемых Принципалу для оказания Услуг, правилах оказания Услуг, условиях их изменения и отмены. Агент несет полную ответственность при взаимодействии с туристами и (или) иными заказчиками, с которыми вступает в договорные отношения от своего имени.
        </p>

        <p>
          1.2.3. Агент обязан уточнять и получать у Принципала информацию об Услугах, на которые Агентом оформляется Заказ. Агент вправе перед отправкой Заказа получить устно или письменно (по электронной почте) интересующую его справочную информацию, в том числе о существующих маршрутах, тарифах, правилах и ограничениях по ним и наличии мест, а также информацию о наличии мест в отелях и их стоимости. В связи с периодическим изменением тарифов перевозчиками, отелями, другими исполнителями и поставщиками Услуг, а также в связи с изменением курсов валют все справки о стоимости перевозок, местах в отелях и пр., предоставленные Принципалом Агенту, действительны только на дату получения таких сведений.
        </p>

        <p>
          1.3. Агент также вправе от имени и за счет Принципала осуществлять реализацию сформированного Принципалом Туристского продукта посредством заключения с туристом и (или) иным заказчиком договора о реализации туристского продукта. В случае заказа Агентом Туристского продукта к отношениям Сторон также применяются условия, изложенные в являющихся неотъемлемой частью настоящего Договора и размещенных в Системе Условиях бронирования и реализации Туристского продукта. Агент, при этом, является Турагентом, а Принципал – Туроператором.
        </p>

        <p>
          1.4. Неотъемлемой частью настоящего Договора являются Правила бронирования. В случае противоречия между условиями настоящего Договора, Правилами бронирования и Условиями бронирования и реализации Туристского продукта, данные документы применяются в следующем порядке: 1. Условия бронирования и реализации Туристского продукта, 2. Правила бронирования, 3. условия Договора.
        </p>

        <p>
          1.5. Агент обязан самостоятельно и своевременно знакомиться с изменениями и дополнениями в Правилах и условиях бронирования и реализации Туристского продукта. Данные изменения публикуются и размещаются в Системе, а также передаются Принципалом в информационных рассылках;
        </p>

        <p>
          1.6. Оформляя Заказ в Системе, Агент подтверждает, что ознакомлен с информацией об условиях отмены заказанных Услуг (возврата и обмена билетов, аннуляции номеров в отелях и т.п.), соглашается со всеми установленными исполнителями и поставщиками Услуг правилами и условиями оказания Услуг, в том числе отмены и изменения Заказа, а также подтверждает ознакомление с опубликованной в Системе информацией и действующими правилами соответствующего исполнителя и поставщика Услуг. Предусмотренные соответствующими правилами и условиями удержания (и/или иные санкции) применяются к Агенту независимо от факта передачи последнему оформленных документов на Услуги (билетов, ваучеров на отель и т.д.).
        </p>

        <p>
          1.7. Агент обязан передавать Принципалу все полученные денежные средства по сделкам, совершенным во исполнение поручения Принципала при исполнении условий настоящего Договора и своевременно осуществлять перевод причитающихся Принципалу по настоящему Договору денежных средств на расчетный счет Принципала на условиях, предусмотренных настоящим Договором. Агент не вправе удерживать находящиеся у него денежные средства, которые подлежат передаче Принципалу, в обеспечение своих требований по настоящему Договору;
        </p>

        <p>
          1.8. Агент вправе осуществлять продвижение и реализацию услуг Принципала, в том числе с использованием информационных и ознакомительных материалов, предоставленных Принципалом.
        </p>

        <p>
          1.9. Принципал обязан своевременно и в полном объеме исполнять взятые на себя обязательства, оформлять и предоставлять необходимые документы и информацию.
        </p>

        <p>
          1.10. Принципал не возмещает Агенту расходы, понесенные Агентом при исполнении настоящего Договора, если иное отдельно не согласовано Принципалом в письменном виде.
        </p>

        <h2>
          2. ВЗАИМОДЕЙСТВИЕ СТОРОН ПО ДОГОВОРУ
        </h2>

        <p>
          2.1. Принципал предоставляет Агенту доступ в Личный кабинет путем регистрации электронной почты Агента в качестве логина в Личный кабинет. Пароль для входа в Личный кабинет Агент устанавливает самостоятельно, перейдя по ссылке из письма, отправленного по электронной почте при регистрации. Агент может указать несколько сотрудников – Пользователей Личного кабинета посредством изменения настроек в Личном кабинете; при этом каждому ответственному Пользователю присваивается персональный логин; внесение данных ответственного сотрудника в Личный кабинет уполномочивает сотрудников Агента действовать от его имени. Персональным логином ответственного сотрудника является электронная почта такого сотрудника.
        </p>

        <p>
          Стороны признают действия в Системе, совершенные с использованием пароля и логина Агента и/или ответственных сотрудников Агента (Пользователями Личного кабинета), действиями Агента.
        </p>

        <p>
          Заказы на Услуги, а также иные действия, совершенные вне Системы, но посредством электронных адресов, которые, согласно Личному кабинету, являются логинами ответственных сотрудников Агента и/или Агента, признаются действиями Агента.
        </p>

        <p>
          Агент обязан постоянно следить за техническим состоянием своей электронной почты и телефонной линии, в целях немедленного устранения любых перерывов в сообщениях, а также актуальностью данных, указанных в Личном кабинете. Агент обязан содержать списки доступов и уровни выданных прав доступов в Личном кабинете в актуальном состоянии, в частности, своевременно удалять доступы при увольнении сотрудников.
        </p>

        <p>
          2.2. Агент оформляет Заказы на Услуги посредством Системы и на основании представленной в Информационной базе данных информации об Услугах, в том числе их стоимости и доступности. Оформление Заказа на Услуги в Системе означает ознакомление и согласие Агента с изложенными в Системе правилами и условиями Заказа Услуг, их изменения и отмены.
        </p>

        <p>
          2.3. Агент ни при каких обстоятельствах не должен допускать фактов дублирующего бронирования или дублировать бронирование, сделанное в Системе, данные о котором сохранены в Личном кабинете. Ответственность за проверку наличия другого активного Бронирования на имя одного и того же туриста и (или иного) заказчика несёт Агент.
        </p>

        <p>
          2.4. После оформления Заказа или после поступления оплаты по Заказу на Услуги (по усмотрению Принципала) Принципал направляет на адрес электронной почты Агента/его ответственного сотрудника подтверждение Заказа на Услуги (ваучер, билет, маршрут-квитанция, иной подтверждающий Заказ Услуг документ) или делает последний доступным для Агента в Личном кабинете.
        </p>

        <p>
          2.5. Оффлайн-заявки. Агент также вправе подавать оффлайн-заявки на Услуги в адрес Принципала, посредством направления запроса по форме обратной связи в Личном кабинете или по электронной почте reservation@sanatory.ru . В ответ на поступивший запрос Принципал направляет в адрес Агента перечень предлагаемых к заказу Услуг и их описание (включая стоимость и условия применения тарифа). Оффлайн-заявка считается согласованной Сторонами, а Услуги по ней – подлежащими исполнению и оплате, при получении от Агента (а именно с адреса электронной почты, являющейся логином к Личному кабинету Агента или ответственного в соответствии с п. 2.1. настоящего Договора сотрудника Агента) подтверждения о заказе Услуг на предложенных Принципалом условиях. Оффлайн-заявка должна быть подана Агентом с использованием электронной почты, указанной в Личном кабинете, в соответствии с п. 2.1. Договора. В случае отсутствия электронной почты, с которой направляется запрос, в Личном кабинете Принципал оставляет за собой право отказать в рассмотрении такой оффлайн-заявки по существу.
        </p>

        <p>
          Принципал предъявляет Агенту электронную или факсимильную копию счета для оплаты Услуг, оформленных по Оффлайн-заявке, в течение 3 (трех) рабочих дней с момента заказа Услуги или с момента ее согласования и подтверждения готовности оформить Услугу со стороны Агента. Электронная или факсимильная копия счета признается Сторонами действительной для оплаты Агентом.
        </p>

        <p>
          2.6. В отношении заказа перевозки любым видом транспорта, применяются положения, изложенные в Правилах бронирования.
        </p>

        <p>
          2.7. Стороны согласовали, что Принципал имеет право производить замену Услуг, предоставляемых туристу и (или) иному заказчику с сохранением класса Услуг по ранее оплаченной категории или с предоставлением Услуг более высокого класса без дополнительной оплаты.
        </p>

        <h2>
          3. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ
        </h2>

        <p>
          3.1. Стоимость Услуг и возможных взимаемых сборов Принципала за бронирование Услуг указывается в Системе в российских рублях. Расчеты между Сторонами производятся в российских рублях в безналичной форме путем перечисления денежных средств на расчетный счет Принципала.
        </p>

        <p>
          3.2. Если иное не предусмотрено Правилами бронирования, Агент перечисляет в адрес Принципала причитающиеся последнему денежные средства в течение 3 (трех) рабочих дней с даты оформления Заказа в Системе;
        </p>

        <p>
          3.3. Размер Вознаграждения Агента отражается в Системе при поиске и бронировании Услуг, или сообщается Принципалом при согласовании предложения по оффлайн-заявке Агента.
        </p>

        <p>
          Окончательный размер Вознаграждения Агента указывается в Отчете и служит основанием для взаиморасчетов Сторон. В любом случае, Вознаграждение Агента не может составлять менее 50 (пятидесяти) рублей за Заказ; Вознаграждение, выраженное в процентном соотношении к стоимости Заказа, – не менее 1 (одного) процента от стоимости Услуг.
        </p>

        <p>
          Агент самостоятельно удерживает причитающееся ему по настоящему Договору Вознаграждение из денежных средств, полученных от туристов и (или) иных заказчиков. Полученная при реализации Услуг дополнительная выгода принадлежит Агенту в полном объеме.
        </p>

        <p>
          3.4. Агент считается исполнившим поручение Принципала по оформленному Заказу в момент окончания оказания Услуг туристу и (или) иному заказчику, с которым Агентом был заключен договор о реализации таких Услуг. В отношении заказа перевозки любым видом транспорта, Услуга считается оказанной в дату оформления Заказа.
        </p>

        <p>
          3.5. Агент посредством Системы и на основании представленной в ней информации формирует Отчет Агента и/или иные отчетные документы в соответствии с видом заказанных Услуг и направляет их подписанные скан-копии Принципалу на электронную почту finance@sanatory.ru. Агент имеет право осуществлять данные действия в следующие сроки:
          <b>на десятый рабочий день месяца, следующего за отчетным</b> календарным месяцем;
        </p>

        <p>
          Конкретный порядок и сроки формирования и направления Отчета устанавливаются при регистрации в Системе и отображаются в Личном кабинете. Последующее изменение установленного порядка допускается только путём заключения соответствующего дополнительного соглашения между Сторонами.
        </p>

        <p>
          3.6. Принципал обязан в течение 5 (пяти) рабочих дней с даты получения (или загрузки из Личного кабинета) Отчета Агента рассмотреть и принять полученный Отчет. Агент имеет право осуществить проверку данных, которые будут содержаться в Отчете Агента, в Личном кабинете до окончания отчетного месяца. В случае если Стороны не представили в указанные сроки возражение, Отчет Агента считается принятым в полном объеме. Скан-копия подписанного Принципалом Отчета Агента хранится в Личном кабинете. При наличии возражений по Отчету Агента Стороны обязаны устранить разногласия в Отчете Агента в течение 5 (пяти) рабочих дней с даты выявления возражений.
        </p>

        <p>
          Стороны могут договориться об использовании электронного документооборота при обмене юридически значимыми документами и применении электронной подписи при оформлении таких документов путем подписания Дополнительного соглашения к Договору.
        </p>

        <p>
          3.7. Датой оплаты Заказа считается день зачисления денежных средств на расчетный счет Принципала.
        </p>

        <p>
          3.8. Принципал также оформляет по запросу Агента информационный акт на Услуги по каждому Заказу после фактического оказания Услуг, а также при условии полной оплаты Агентом заказанных Услуг.
        </p>

        <p>
          3.9. При поступлении от Агента денежных средств в счет перечисления причитающихся Принципалу по настоящему Договору денежных средств (в рамках основной задолженности), денежные средства засчитываются в счет оплаты Заказов, срок оплаты которых наступил раньше. При этом указание Агентом, в счет исполнения какого именно обязательства им перечислены денежные средства, не препятствует обозначенному выше порядку распределению денежных средств.
        </p>

        <h2>
          БАЛАНС КАБИНЕТА И ЛИМИТ ОФОРМЛЕНИЯ ЗАКАЗОВ В СИСТЕМЕ
        </h2>

        <p>
          4.1. В целях обеспечения надлежащего исполнения Агентом обязательств по настоящему Договору оформление услуг в Личном кабинете Агента возможно при наличии положительного Баланса Кабинета. При этом суммы средств на Балансе должно быть достаточно для покрытия стоимости будущего Заказа.
        </p>

        <p>
          Остаток Баланса отражается в Личном кабинете и при его расчёте учитываются Кредитный лимит, Переплаты и Задолженности по счетам.
        </p>

        <p>
          4.2. Принципал вправе в одностороннем порядке устанавливать в Личном кабинете Кредитный лимит, в пределах которого Агент может заказывать Услуги без осуществления предварительной оплаты, а также приобретать услуги перевозки любым видом транспорта без предварительного внесения депозита (далее – «Лимит»). Кредитный лимит увеличивает размер Баланса кабинета. По достижении Агентом Лимита возможность оформления Заказов приостанавливается вплоть до полного погашения Агентом задолженности.
        </p>

        <p>
          4.3. При превышении Агентом Лимита доступ Агента к оформлению Заказов приостанавливается Системой. Возобновление доступа, а равно и пользование Системой возможно только в случае, когда Агент полностью выплатит задолженность в адрес Принципала.
        </p>

        <p>
          4.4. Исходя из своевременности и полноты перечисления Агентом причитающихся Принципалу денежных средств по настоящему Договору, размер Лимита может быть изменен Принципалом как в сторону уменьшения, так и в сторону увеличения, путем публикации соответствующей информации в Личном кабинете и/или информирования Агента по электронной почте.
        </p>

        <h2>
          5. ОТМЕНА И ИЗМЕНЕНИЕ ЗАКАЗА НА УСЛУГИ
        </h2>

        <p>
          5.1. Отмена Заказа осуществляется Агентом согласно условиям тарифа, с которыми Агент был ознакомлен в Системе при оформлении Заказа, а при согласовании оффлайн-заявки в порядке, предусмотренном п. 2.5. настоящего Договора, - по электронной почте.
        </p>

        <p>
          5.2. В соответствии с установленными непосредственными исполнителями или поставщиками Услуг правилами и политиками, в случае отмены Заказа, с Агента может быть удержана полная или частичная стоимость заказанных Услуг. Информация об условиях отмены Заказов указывается в Системе, а также сообщается Агенту по электронной почте в случае оформления Агентом оффлайн-заявки в порядке, предусмотренном п. 2.5. настоящего Договора.
        </p>

        <p>
          5.3. Изменение Заказа на Услуги возможно в виде изменения Заказа целиком или частично, если такая возможность по забронированным и подтвержденным Услугам предусмотрена в Системе, или в виде отмены Заказа целиком или частично и совершения нового Заказа с применением соответствующих последствий отмены, если иные условия не установлены соответствующими Правилами бронирования.
        </p>

        <p>
          5.4. Изменение, отмену Заказа, а также запрос на добавление Услуг по Заказам, Агент обязуется производить посредством обращения к функционалу Системы, если Заказ был оформлен в Системе, или посредством запроса к Принципалу по электронной почте, если Заказ был оформлен через Оффлайн-заявку. Агент обязуется уведомить туристов\иных заказчиков о необходимости получения предварительного письменного согласия Принципала через Агента. Любые изменения, дополнения и согласования, совершенные Агентом, туристом/иным заказчиком напрямую с исполнителем Услуги без получения согласования Принципала, не создают обязанностей для Принципала юридической силы и исполняются туристом/иным заказчиком за свой счет.
        </p>

        <p>
          5.5 В случае не заезда туристов и (или) иного заказчика в гостиницу, санаторий, или иное средство размещения, или неприбытия туристов и (или) иного заказчика к месту начала оказания услуги до установленного Поставщиком времени, Принципал не несет ответственности за действия Поставщика (Поставщик имеет право отказать в предоставлении Услуг, указанных в Заказе и потребовать уплаты штрафа).
        </p>

        <p>
          5.6. При наличии неоплаченной Агентом задолженности по любому из Заказов, Принципал вправе приостановить доступ Агента к Системе, аннулировать любые Заказы на Услуги, в том числе, но не ограничиваясь, Заказы будущих периодов, уведомив об этом Агента по электронной почте. Агент принимает на себя все негативные последствия неисполнения обязанностей по оплате Услуг, в том числе возмещает Принципалу все расходы, понесенные Принципалом в связи с аннуляцией Заказа на Услуги.
        </p>

        <p>
          5.7. Агент обязан информировать Принципала о времени прибытия туриста и (или) иного заказчика к месту начала оказания Услуг не позднее чем за 48 (сорок восемь) часов до начала оказания заказанных в Системе Услуг. В случае неисполнения Агентом данной обязанности, Агент принимает на себя риски невозможности оказания Услуг туристу и (или) иному заказчику, в том числе компенсирует все расходы Принципала, туриста и (или) иного заказчика, понесенные ими в связи с заменой Услуг, и (или) наложенные непосредственным исполнителем или поставщиком Услуг за непредоставление соответствующей информации. Положения настоящего пункта не применяются к услугам перевозки любым видом транспорта.
        </p>

        <p>
          5.8. Принципал не несет ответственность по возмещению денежных затрат Агенту, туристу и (или) иному заказчику за оплаченные Услуги, если турист и (или) иной заказчик в период обслуживания по своему усмотрению или в связи со своими интересами не воспользовался всеми или частью Услуг. Принципал также не возмещает любые расходы, выходящие за рамки оговоренных в настоящем Договоре.
        </p>

        <p>
          5.9. В случае отмены Агентом Заказа на Услуги, по условиям которого стоимость Услуг подлежит возврату Агенту полностью или частично, денежные средства за такой отмененный Заказ аккумулируются и учитываются в Системе, и могут быть засчитаны в счет оформления и оплаты будущих Заказов. Денежные средства за отмененные Заказы также могут быть перечислены Агенту по его письменному заявлению в течение 5 (пяти) рабочих дней с момента уведомления об этом Принципала. При этом, в случае аннуляции оплаченного Заказа и необходимости удержания полной и/или части стоимости Заказа на Услуги в соответствии с установленными непосредственными исполнителями или поставщиками Услуг правилами и политиками, возврат средств, перечисленных Агентом Принципалу, производится за вычетом удержанной суммы, указанной в Системе.
        </p>

        <h2>
          6. ОТВЕТСТВЕННОСТЬ СТОРОН
        </h2>

        <p>
          6.1. За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору Стороны несут ответственность, предусмотренную настоящим Договором и действующим законодательством РФ.
        </p>

        <p>
          6.2. За просрочку перечисления Агентом причитающихся по настоящему Договору денежных средств в сроки, установленные настоящим Договором, Принципал оставляет за собой право начислять пени в размере 0,1% (ноль целых одна десятая процента) с просроченной суммы за каждый календарный день просрочки. Уплата пени не освобождает Агента от исполнения иных обязательств по настоящему Договору.
        </p>

        <p>
          6.3. Принципал не несет ответственность перед Агентом, туристом и (или) иным заказчиком в случае невозможности осуществления принятых на себя обязательств вследствие недостоверности, недостаточности и/или несвоевременности сведений и документов, представленных Агентом, а также туристом и (или) иным заказчиком. В случае предоставления неполных, неточных и/или некорректных данных туриста и (или) иного заказчика, Агент самостоятельно несёт полную ответственность за любые убытки, возникшие в результате предоставления таких сведений.
        </p>

        <p>
          6.4. Информация об Услугах, содержащаяся в Информационной базе данных, получена Принципалом от непосредственных исполнителей или поставщиков Услуг. Принципал не изменяет полученную от данных лиц информацию, а предоставляет ее Агенту «как есть». В частности, Принципал не гарантирует точности фотографий, относящихся к гостиницам, санаториям, а также описаний, списков услуг гостиниц и санаториев и другой описательной информации.
        </p>

        <p>
          6.5. Принципал не несет ответственность за негативные последствия и убытки, возникшие у Агента, туриста и (или) иного заказчика, в результате событий и обстоятельств, находящихся вне сферы компетенции Принципала, а также за действия (бездействие) третьих лиц, в том числе:
        </p>

        <ul>
          <li>
            в случае, если турист и (или) иной заказчик не сможет совершить путешествие или воспользоваться отдельными услугами по причине действий российской или зарубежной таможенных служб, российского или зарубежного пограничного контроля, либо иных действий официальных органов или властей России или зарубежных стран, в том числе вследствие ограничения права туриста и (или) иного заказчика на выезд из РФ компетентными органами;
          </li>
          <li>
            вследствие отмены или изменения времени отправления авиарейсов, поездов и иных видов транспорта, а также за любые другие действия перевозчиков;
          </li>
          <li>
            вследствие потери, утраты, кражи личного багажа, ценностей и документов туриста и (или) иного заказчика в период поездки;
          </li>
          <li>
            в случае, если вследствие отсутствия надлежащих документов или нарушения правил поведения в общественных местах, решением властей или ответственных лиц туристу и (или) иному заказчику отказано в возможности выезда из страны или въезда в страну, в возможности полета по авиабилету, проживании в забронированном средстве размещения, оказании иных туристских услуг;
          </li>
          <li>
            за качество оказания заказанных Услуг (поскольку Принципал не является их непосредственным исполнителем), а также за несоответствие Услуг субъективной оценке туриста и (или) иного заказчика;
          </li>
          <li>
            за отказ, задержку и другие действия посольств при оформлении виз туристу и (или) иному заказчику по маршруту поездки;
          </li>
          <li>
            за отсутствие у туриста и (или) иного заказчика документов, необходимых для потребления Услуг;
          </li>
          <li>
            за опоздание туриста и (или) иного заказчика к месту сбора группы, отправления поезда, вылета самолета в назначенное время;
          </li>
          <li>
            за ведение в непосредственной близости от отеля строительных, инженерных работ, возведении коммуникаций и оборудования, расположение магазинов, ресторанов, дискотек, автостоянок, других организаций, осветительных мачт, пешеходных, автомобильных, железных дорог и т.д., в результате чего возможно возникновение нежелательных шумовых, визуальных эффектов, запахов, вибраций и т. д.
          </li>
        </ul>

        <p>
          6.6. Если в подтверждении заказа услуг (ваучере) не указано иное, стоимость Услуг не включает в себя стоимость расходов, которые может понести турист и (или) иной заказчик в связи с оказанием Услуг (например, телефонные расходы, обслуживание в гостинице, местные городские и туристические налоги и сборы отеля и прочие понесенные издержки, которые не были включены в подтверждение, переданное Принципалом при оформлении Заказа); турист и (или) иной заказчик несет единоличную ответственность за оплату таких дополнительных расходов.
        </p>

        <p>
          6.7. Принципал вправе отказаться от принятых на себя в соответствии с Договором обязательств и аннулировать Заказ Агента в случае нарушения сроков оплаты (неоплаты) туристского продукта, отдельных Услуг и / или несвоевременной передачи документов Принципалу, необходимых для выполнения принятых на себя обязательств. При этом Агент возмещает Принципалу все понесенные расходы и самостоятельно несет ответственность перед туристом и (или) иным заказчиком.
        </p>

        <p>
          6.8. Принципал не компенсирует стоимость услуг, которыми турист и (или) иной заказчик не воспользовался по своему усмотрению .В случае отказа туриста и (или) иного заказчика от всех или части заказанных Услуг, организованных и оплаченных на основании настоящего Договора в момент их предоставления по любым причинам, а также вынужденного прерывания поездки по любым основаниям, связанным с действиями туриста и (или) заказчика, в том числе нарушения норм и правил поведения, компенсация Принципалом не производится.
        </p>

        <h2>
          7. СИСТЕМА И ИНФОРМАЦИОННАЯ БАЗА ДАННЫХ
        </h2>

        <p>
          7.1. Принципал обладает законным правом использования Системы и Информационной базы данных (далее «Объекты интеллектуальной собственности») на основании соответствующих лицензионных договоров с правом предоставления сублицензии.
        </p>

        <p>
          7.2. В связи с тем, что доступ Агента к Системе и Информационной базе данных является необходимым условием работы Сторон по настоящему Договору, Принципал на безвозмездной основе предоставляет Агенту простую (неисключительную) сублицензию на право использования Системы и Информационной базы данных способами, необходимыми в целях исполнения настоящего Договора, на срок действия настоящего Договора.
        </p>

        <p>
          7.3. Агент не имеет права самостоятельно или с привлечением третьих лиц:
        </p>

        <ul>
          <li>
            осуществлять самостоятельно или с привлечением третьих лиц вскрытие технологии, эмуляцию, создание новых версий, декомпиляцию, дизассемблиризацию, дешифровку или иные действия с кодом Объекта интеллектуальной собственности, имеющие целью нарушение системы защиты Объекта интеллектуальной собственности от несанкционированного использования, справочно-информационных и прочих материалов баз данных, а также получения информации о реализации алгоритмов и технологий, используемых в Объекте интеллектуальной собственности;
          </li>
          <li>
            создавать программные или справочно-информационные продукты и/или сервисы с использованием Объекта интеллектуальной собственности, а также включаемые в их состав баз данных или извлеченных (извлекаемых) из них текстовых и справочно-информационных материалов.
          </li>
          <li>
            извлекать из баз данных, включенных в состав Объекта интеллектуальной собственности, любые справочно-информационные и прочие материалы и осуществлять их последующее использование в любой форме и любым способом.
          </li>
        </ul>

        <h2>
          8. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ
        </h2>

        <p>
          8.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору, если докажут, что это было вызвано возникновением обстоятельств непреодолимой силы (война, стихийные бедствия, решения органов государственной власти и управления, перебои в работе Системы, вызванные перебоями в электроснабжении или действиями третьих лиц, а также другие обстоятельства, не зависящие от воли сторон и не поддающиеся их контролю).
        </p>

        <p>
          8.2. Сторона, ссылающаяся на действие обстоятельств непреодолимой силы, должна в течение 5 (пяти) календарных дней в письменном виде посредством факса, электронной почты, почты или курьера уведомить другую Сторону о наступлении и прекращении таких обстоятельств, с приложением документа, подтверждающего наличие указанных обстоятельств, выданного уполномоченным на то органом.
        </p>

        <p>
          8.3. Если Сторона, ссылающаяся на действие обстоятельств непреодолимой силы, не уведомила другую сторону о наступлении таких обстоятельств в порядке, предусмотренном п. 9.2. настоящего Договора, такая Сторона утрачивает право ссылаться на действие вышеуказанных обстоятельств в случае неисполнения (ненадлежащего исполнения) своих обязанностей по Договору.
        </p>

        <p>
          8.4. Если наступление форс-мажорных обстоятельств непосредственно повлияло на исполнение Сторонами обязательств в срок, установленный Договором, этот срок соразмерно продлевается на время действия соответствующих обстоятельств.
        </p>

        <h2>
          9. КОНФИДЕНЦИАЛЬНОСТЬ И ПЕРСОНАЛЬНЫЕ ДАННЫЕ
        </h2>

        <p>
          9.1. Стороны обязуются принимать все необходимые и достаточные меры, направленные на защиту персональных данных физических лиц в соответствии с требованиями, установленными Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных». Стороны обязуются осуществлять обработку персональных данных физических лиц в строгом соответствии с установленными законом принципами и правилами. Передаваемые друг другу Сторонами персональные данные физических лиц относятся к конфиденциальной информации. Стороны обязуются использовать персональные данные физических лиц исключительно для целей, необходимых для исполнения Договора. Обрабатываемые персональные данные физических лиц подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей.
        </p>

        <p>
          9.1.1. Агент подтверждает, что получил необходимое согласие туриста и (или) иного заказчика на обработку переданных Принципалу персональных данных, если такая обязанность предусмотрена действующим законодательством РФ. В случае предъявления претензий (в том числе судебных исков) со стороны третьих лиц к Принципалу, в связи с нарушением действующего законодательства в области обработки персональных данных, которые в соответствии с Договором были переданы Агентом Принципалу, Агент обязуется разрешить указанные претензии своими силами и за свой счет. Агент также обязуется возместить Принципалу убытки, причиненные Принципалу вследствие удовлетворения таковых претензий или исков.
        </p>

        <p>
          9.2. Стороны обязуются соблюдать конфиденциальный характер коммерческой и финансовой информации, полученной от другой Стороны в течение срока действия Договора, а также в течение 2 (двух) лет после его прекращения по любым обстоятельствам.
        </p>

        <p>
          9.3. Стороны пришли к соглашению, что любые сведения, содержащиеся в Системе, а также в Личном кабинете Агента (в том числе, но не ограничиваясь, указанная в Системе стоимость Услуг) являются конфиденциальной информацией.
        </p>

        <p>
          9.4. Стороны обязуются не разглашать без предварительного письменного согласия другой Стороны любую информацию конфиденциального характера, ставшую известной в ходе исполнения настоящего Договора, не передавать ее третьим лицам и не использовать иначе, чем для выполнения своих договорных обязательств.
        </p>

        <p>
          9.5. Сторона, виновная в нарушении условий о конфиденциальности, возмещает другой Стороне все понесенные ею убытки, а также несет предусмотренную законом дисциплинарную, гражданско-правовую, административную или уголовную ответственность в соответствии с законодательством Российской Федерации. Нарушение Агентом условий о конфиденциальности также является основанием для немедленного отказа Принципала от Договора.
        </p>

        <h2>
          10. СРОК ДЕЙСТВИЯ И ПРЕКРАЩЕНИЕ ДОГОВОРА
        </h2>

        <p>
          10.1. Настоящий Договор вступает в силу с момента подписания Сторонами и заключен на неопределенный срок.
        </p>

        <p>
          10.2. Каждая из Сторон имеет право отказаться от исполнения настоящего Договора в одностороннем порядке при условии направления другой Стороне письменного уведомления за 30 (тридцать) календарных дней до даты такого отказа.
        </p>

        <p>
          10.3. Несмотря на вышесказанное, Принципал имеет право немедленно отказаться от исполнения Договора, отправив соответствующее уведомление Агенту, при нарушении Агентом условий перечисления причитающихся Принципалу денежных средств, а также при ином существенном нарушении Агентом своих обязательств.
        </p>

        <p>
          10.4. В случае прекращения Договора независимо от причин прекращения обязательства Сторон, возникшие до даты прекращения Договора (в том числе, обязательства по уплате штрафных санкций), должны быть исполнены Сторонами в полном объеме. При прекращении настоящего Договора Агент в 10-дневный календарный срок с момента прекращения Договора перечисляет в адрес Принципала все причитающиеся ему денежные средства, а также предоставляет необходимые отчетные документы. Стороны также в вышеуказанный срок проводят сверку задолженности и производят взаиморасчеты.
        </p>

        <h2>
          11. РАЗРЕШЕНИЕ СПОРОВ
        </h2>

        <p>
          11.1. Отношения Сторон по настоящему Договору регулируются нормами действующего законодательства Российской Федерации.
        </p>

        <p>
          11.2. Для разрешения споров, связанных с нарушением Сторонами своих обязательств по настоящему Договору либо иным образом вытекающих из Договора, применяется обязательный досудебный (претензионный) порядок разрешения споров. Сторона, права которой нарушены, до обращения в суд обязана предъявить другой Стороне письменную претензию с изложением своих требований. При необходимости к претензии прилагаются документы, подтверждающие выявленные нарушения, и документы, удостоверяющие полномочия представителя Стороны – отправителя претензии.
        </p>

        <p>
          11.3. Претензия может быть направлена Сторонами друг другу одним из нижеперечисленных способов:
        </p>

        <ul>
          <li>
            письмом на адрес электронной почты, указанный в реквизитах настоящего Договора; при этом подтверждением такого направления является сохраненная отправившей Стороной в ее электронном почтовом ящике скан-копия претензии в формате PDF, JPEG, TIFF или PNG, а также распечатанная бумажная версия отправленного сообщения; датой получения претензии при этом является календарный день, следующий за днем отправки письма;
          </li>

          <li>
            заказным письмом с уведомлением о вручении по юридическому или почтовому адресу Стороны; датой получения претензии при этом является дата прибытия отправления в место вручения;
          </li>

          <li>
            передаче уполномоченному представителю Стороны под роспись; датой получения претензии при этом является дата передачи претензии.
          </li>

        </ul>

        <p>
          Претензия считается доставленной Стороне и в тех случаях, если она была направлена надлежащим образом одним из способов, предусмотренных настоящим пунктом, но по обстоятельствам, зависящим от Стороны, не была ей вручена либо Сторона не ознакомилась с ней.
        </p>

        <p>
          11.4. Срок рассмотрения претензии – 20 (двадцать) календарных дней со дня, когда претензия считается полученной в соответствии с положениями п. 11.3 настоящего Договора. По истечении указанного срока, спор, не урегулированный в претензионном порядке, может быть передан на рассмотрение в Арбитражный суд города Москвы, уполномоченный рассматривать все споры, вытекающие из отношений Сторон по настоящему Договору.
        </p>

        <h2>
          12. ИНЫЕ УСЛОВИЯ
        </h2>

        <p>
          12.1. Стороны обязаны в письменном виде по электронной почте информировать друг друга об изменении своих адресов и иных реквизитов не позднее 5 (пяти) рабочих дней с даты такого изменения. Любые сообщения, направленные по последнему известному Стороне адресу, будут считаться надлежаще отправленными.
        </p>

        <p>
          12.2. Ни одна из Сторон не вправе уступать свои права или обязанности по Договору третьей стороне полностью или в части без предварительного письменного согласия другой Стороны, за исключением права Принципала в соответствии со ст. 392.3 ГК РФ осуществить передачу Договора своему аффилированному лицу, с обязательным последующим уведомлением Агента, которое может быть сделано, в том числе путем опубликования информации о передаче Договора в Системе. В случае уступки требования по Договору третьим лицам без предварительного письменного согласия Принципала Агент уплачивает Принципалу штраф в размере 20% от суммы заказанных Агентом Услуг за весь срок действия Договора.
        </p>

        <p>
          12.3. Агент согласен на использование его наименования и/или товарного знака (логотипа) в маркетинговой деятельности Принципала в течение срока действия Договора.
        </p>

        <p>
          12.4. Принципал вправе в одностороннем порядке вносить изменения в Правила бронирования, Условия бронирования и реализации Туристского продукта путем обязательного опубликования соответствующих изменений в Системе и/или уведомления Агента путем рассылки на электронную почту, указанную в Личном кабинете, не позднее 10 (десяти) календарных дней до даты вступления соответствующих изменений в силу. В случае если Агент не согласен с внесенными изменениями, он обязан письменно уведомить об этом Принципала не позднее даты вступления изменений в силу; в этом случае Договор считается расторгнутым по соглашению Сторон в дату вступления изменений в силу. В противном случае отношения Сторон регулируются новой редакцией соответствующих документов.
        </p>

        <p>
          12.5. Стороны договорились считать переписку по указанным в Системе адресам электронной почты официальной и учитывать при решении возможных претензий/споров, возникших из или в связи с исполнением Договора. Стороны договорились считать такую переписку, а также любые документы, полученные посредством факсимильной, электронной или иной связи, в том числе с использованием информационно-телекоммуникационной сети «Интернет», надлежащим и допустимым доказательством по делу в случае возникновения между Сторонами судебных споров. При этом наличие электронной цифровой подписи на указанных выше документах не является обязательным условием признания таких документов надлежащим и допустимым доказательством по делу.
        </p>

        <p>
          12.6. Во всем остальном, что не предусмотрено настоящим Договором, Стороны руководствуются действующим законодательством Российской Федерации.
        </p>
      </Content>
    </Wrapper>
  );
}

Body.propTypes = {
  className: PropTypes.string
};

export default Body;
