import { t } from 'utils/localization';
import React from 'react';
import arrayToTree from 'array-to-tree';
import _partition from 'lodash/partition';

import { UsersIcon, RoomIcon } from 'components/icons';
import { getIcon } from 'utils/icons';

function getTabs({ roomType, roomType: { area, rm_count } }) {
  const guestsCount = roomType.beds + roomType.extra_beds;
  const roomValue = [t('Hotel.RoomTypes.RoomCount', { count: rm_count }), t('Hotel.RoomTypes.RoomArea', { count: area })].join(' ');

  const amenitiesTree = arrayToTree(roomType.amenities, {
    parentProperty: 'parent_tag',
    customID: 'tag'
  });

  // eslint-disable-next-line no-unused-vars
  const [popularAmenitiesList, _] = _partition(amenitiesTree, a => !!getIcon(a.tag));

  const popularAmenities = popularAmenitiesList.map(a => ({ content: a.name, icon: getIcon(a.tag) }));
  const [commonAmenities, amenities] = _partition(amenitiesTree, a => !a.children && !a.parent_tag);
  const commonAmenity = {
    tag: 'common',
    patent_tag: null,
    name: t('Hotel.RoomTypes.Modal.Amenities.Common'),
    icon: null,
    children: commonAmenities
  };

  const tabs = {
    mainAmenities: [
      {
        content: t('Hotel.RoomTypes.GuestsMax', { count: guestsCount }),
        icon: <UsersIcon />,
        attributes: {
          itemProp: 'occupancy'
        }
      },
      {
        content: roomValue,
        icon: <RoomIcon />,
        attributes: {
          itemProp: 'floorSize',
          content: `${area} MTK`
        }
      },
      ...popularAmenities
    ],
    popularAmenities,
    amenities: [
      commonAmenity,
      ...amenities
    ]
  };

  return tabs;
}

export default getTabs;
