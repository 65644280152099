import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, Field } from 'components/v2/forms';
import { t } from 'utils/localization';
import useBankInfo from '../../hooks/useBankInfo';
import Loader from '../../Loader';

const InputComponent = styled(Input)`
  height: 40px;
`;

const FieldComponent = styled(Field)`
  &:nth-child(1),
  &:nth-child(2) {
    width: 132px;
  }

  &:nth-child(3),
  &:nth-child(4) {
    width: 196px;
  }
`;

const Wrapper = styled.div`
  background: ${({ error }) => error && '#fdf1f1'};
  padding: ${({ error }) => error && '16px'};
  border-radius: ${({ error }) => error && '4px'};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Fields = styled.div`
  display: flex;
  gap: 16px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const ErrorText = styled.p`
  margin-top: -8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #e26464;
`;

const items = [
  {
    name: 'legal_entity.bank_details.bik',
    label: t('Auth.Register.LegalEntity.Requisites.Label2'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.bank_details.kpp',
    label: t('Auth.Register.LegalEntity.Requisites.Label3'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.bank_details.ras_schet',
    label: t('Auth.Register.LegalEntity.Requisites.Label4'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  },
  {
    name: 'legal_entity.bank_details.kor_schet',
    label: t('Auth.Register.LegalEntity.Requisites.Label5'),
    placeholder: t('Auth.Register.LegalEntity.Requisites.Placeholder2')
  }
];

const Requisites = ({ className }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  const { data, loading, error } = useBankInfo(getValues()?.legal_entity?.bank_details?.bik);

  useEffect(() => {
    if (!data) return;

    setValue('legal_entity.bank_details', data?.legal_entity?.bank_details);
  }, [data]);

  return (
    <Wrapper error={error} className={className}>
      {loading && <Loader />}

      <Title>Банковские реквизиты</Title>

      <Fields>
        {items.map(({ name, label, placeholder }) => {
          const [entity, key, value] = name.split('.');

          return (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field }) => (
                <FieldComponent required error={errors?.[entity]?.[key]?.[value]} label={label}>
                  <InputComponent {...field} placeholder={placeholder} />
                </FieldComponent>
              )}
            />
          );
        })}
      </Fields>

      <Controller
        control={control}
        name='legal_entity.bank_details.name'
        render={({ field }) => (
          <Field
            required
            error={errors?.legal_entity?.bank_details?.name}
            label={t('Auth.Register.LegalEntity.Requisites.Label')}>
            <InputComponent {...field} placeholder={t('Auth.Register.LegalEntity.Requisites.Placeholder')} />
          </Field>
        )}
      />

      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

Requisites.propTypes = {
  className: PropTypes.string
};

export default Requisites;
