import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ForgotPasswordForm from 'pages/Auth/components/ForgotPasswordForm';
import { Button } from 'components/forms';
import Heading from '../../components/Heading';

const Title = styled.div`
  color: #9cb4ca;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.div`
  color: #4f5660;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 23px;
  max-width: 358px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      color: #62cb94;
    `}

  ${({ isError }) =>
    isError &&
    css`
      color: #e26464;
    `}

  ${Button} {
    display: inline-flex;
    align-self: center;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  width: 640px;
  padding: 97px 128px 81px;
  background: #fff;
`;

const Logo = styled(Heading)`
  margin: 0 0 110px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
  justify-content: center;
  align-content: center;

  ${ForgotPasswordForm} {
    margin-top: 34px;
    width: 100%;
  }
`;

function ForgotPassword({ onSubmit, className }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [result, setResult] = useState({ status: 'idle', message: null });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      await onSubmit(values);
      setIsLoading(false);

      setResult({
        status: 'success',
        values,
        message: t('Auth.ForgotPassword.Messages.Success', values)
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      setResult({
        status: 'error',
        values,
        message: t('Auth.ForgotPassword.Messages.Error', values)
      });
    }
  };

  const handleReset = () => {
    history.replace('/login');
  };

  const handleRepeat = (e) => {
    setResult({ status: 'idle', message: null });
  };

  const handleReturn = (e) => {
    history.replace('/login', { user: result.values });
  };

  return (
    <Wrapper className={className}>
      <Logo />

      <Content>
        <Title>{t('Auth.ForgotPassword.Title')}</Title>

        <Description>{t('Auth.ForgotPassword.Description')}</Description>

        {result.status === 'success' && (
          <Message isSuccess>
            {result.message}

            <Button size='large' variant='secondary' compact onClick={handleReturn}>
              {t('Auth.ForgotPassword.Actions.SignIn')}
            </Button>
          </Message>
        )}

        {result.status === 'error' && (
          <Message isError>
            {result.message}

            <Button size='large' variant='secondary' compact onClick={handleRepeat}>
              {t('Auth.ForgotPassword.Actions.Repeat')}
            </Button>
          </Message>
        )}

        {result.status === 'idle' && (
          <ForgotPasswordForm onSubmit={handleSubmit} onReset={handleReset}>
            <Button size='large' type='submit' loading={isLoading}>
              {t('Auth.ForgotPassword.Actions.Submit')}
            </Button>

            <Button size='large' variant='secondary' type='reset' compact>
              {t('Auth.ForgotPassword.Actions.Reset')}
            </Button>
          </ForgotPasswordForm>
        )}
      </Content>
    </Wrapper>
  );
}

ForgotPassword.defaultProps = {
  onSubmit: () => {}
};

ForgotPassword.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func
};

export default styled(ForgotPassword)``;
