import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

const Wrapper = styled.div``;

const Items = styled.ul``;

const Item = styled.li`
  line-height: 1.5em;
`;

const ExpandableList = styled.div`
  ${({ height, isExpanded }) => css`
    height: 0;
    overflow-y: hidden;

    ${isExpanded && css`  
      height: ${height}px;
    `}
  `}
`;

const Expander = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.button.primary};
    cursor: pointer;
    line-height: 1.5em;
    transition: color 0.25s;
    user-select: none;

    :hover {
      color: ${theme.colors.primaryDark};
    }
  `}
`;

@withTheme
@observer
class ExpandableTextList extends React.Component {
  @observable extraItemsHeight = 0;
  @observable isExpanded = false;

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    maxItemsCount: PropTypes.number,
    t: PropTypes.func
  };

  static defaultProps = {
    maxItemsCount: 2
  };

  countExtraItemsHeight = (node) => {
    const { items, maxItemsCount } = this.props;

    if (node && items.length >= maxItemsCount) {
      const { height: extraItemsHeight } = node.getBoundingClientRect();
      this.extraItemsHeight = extraItemsHeight;
    }
  }

  handleListExpand = (e) => {
    e.stopPropagation();
    this.isExpanded = true;
  }

  render() {
    const { items, maxItemsCount, t } = this.props;
    const previewItems = items
      .slice(0, maxItemsCount)
      .map((name, id) => ({ id, name }));
    const extraItems = items
      .slice(maxItemsCount)
      .map((name, id) => ({ id, name }));

    return (
      <Wrapper>
        <Items>
          {previewItems.map(({ id, name }) => (
            <Item key={id}>
              {name}
            </Item>
          ))}
        </Items>

        {extraItems.length > 0 && (
          <>
            <ExpandableList
              height={this.extraItemsHeight}
              isExpanded={this.isExpanded}
            >
              <Items ref={this.countExtraItemsHeight}>
                {extraItems.map(({ id, name }) => (
                  <Item key={id}>
                    {name}
                  </Item>
                ))}
              </Items>
            </ExpandableList>

            {!this.isExpanded && (
              <Expander onClick={this.handleListExpand}>
                {t('Orders.TravellersList.Expand', {
                  count: extraItems.length
                })}
              </Expander>
            )}
          </>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ExpandableTextList))``;
