import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';
import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')}
    padding: 5px 20px;
    background: ${theme.colors.active.secondary};
    border-radius: 13px;
    color: ${theme.colors.text.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
  `}
`;

@withTheme
class Status extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Status)``;
