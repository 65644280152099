import instance from 'connection/instance';

class AccommodationFeaturesStore {
  async fetch(params = {}) {
    const { data: { data } } = await instance.get('/api/filters/accommodation_features', { params });
    return { meta: {}, data };
  }
}

export default AccommodationFeaturesStore;
