import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

const Label = styled.label``;

const CheckboxElement = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    color: #6b707b;
    font-size: 13px;
    display: flex;
    align-items: center;
    line-height: 19px;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    height: 16px;
    min-width: 16px;
    display: block;
    width: 16px;
    border-radius: 3px;
    background: white;
    border: 1px solid #dddde6;
    border-radius: 3px;
    box-sizing: border-box;
  }

  &:checked + label:before {
    background-color: #5ab2d6;
    border: 1px solid #5ab2d6;
    transition: all 0.3s linear;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function CustomCheckbox({ className, children, ...props }) {
  const fieldId = _uniqueId('field-');

  return (
    <Wrapper className={className}>
      <CheckboxElement id={fieldId} type='checkbox' {...props} />

      <Label htmlFor={fieldId}>{children}</Label>
    </Wrapper>
  );
}

CustomCheckbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default styled(CustomCheckbox)``;
