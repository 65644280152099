import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { shortName } from 'utils';

import { UserBlueIcon } from 'components/icons';

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #3AA6D2;
  font-size: 16px;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
`;

const Manager = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${Avatar} + ${Manager} {
    margin-left: 10px;
  }
`;

@observer
class Creator extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { order: { creator }, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Avatar>
          <UserBlueIcon />
        </Avatar>

        <Manager>
          {shortName(creator)}
        </Manager>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Creator))``;
