import _pick from 'lodash/pick';

import { UNKNOWN_RATE_ERROR } from 'utils/errors';

const DEFAULT_PAYMENT_METHOD = {
  type: 'bank_transfer'
};

const DEFAULT_CASH = false;

function calcFloatPrice({ price_attr, prices }) {
  return prices
    .reduce((sum, p) => sum + p[price_attr], 0);
}

function calcFixedPrice({ price_attr, prices }) {
  return prices[0][price_attr] * prices.length;
}

function calcPrice({ price_attr, price_calculation_rule, prices }) {
  return price_calculation_rule === 'fixed'
    ? calcFixedPrice({ price_attr, prices })
    : calcFloatPrice({ price_attr, prices });
}

function dailyRatePrice({ rateType, dailyPrice }) {
  const { day, rates } = dailyPrice;

  const rate = rates
    .find(r => r.type === rateType);

  if (!rate) {
    throw Error(UNKNOWN_RATE_ERROR);
  }

  const { price, net_price, gross_price } = rate;
  const payment_method = DEFAULT_PAYMENT_METHOD;

  return { day, price, net_price, gross_price, payment_method };
}

function dailyRatePrices({ rateType, prices }) {
  return prices.map(dailyPrice => (
    dailyRatePrice({ rateType, dailyPrice })
  ));
}

function assignPaymentMethod({ previousPrices = [], currentPrices = [] }) {
  const previousPricesMap = new Map(previousPrices.map(item => (
    [item.day, _pick(item, 'payment_method')]
  )));

  const currentPricesMap = new Map(currentPrices.map(item => (
    [item.day, item]
  )));

  const result = currentPrices.map(({ day }) => {
    const currentItem = currentPricesMap.get(day);
    const previousItem = previousPricesMap.get(day) || { payment_method: DEFAULT_PAYMENT_METHOD };

    return { ...currentItem, ...previousItem };
  });

  return result;
}

function assignBaseSlotPrices({ slot, billing_hour, price_calculation_rule, prices }) {
  const { prices: previousPrices, rate: { type: rateType } } = slot;

  const shift = billing_hour === 'night' ? 1 : 0;
  const sliceBy = [0, prices.length - shift];

  let currentPrices = dailyRatePrices({ rateType, prices });
  currentPrices = assignPaymentMethod({ previousPrices, currentPrices });

  const coercedPrices = currentPrices.slice(...sliceBy);

  const price = calcPrice({
    price_attr: 'price',
    prices: coercedPrices,
    price_calculation_rule
  });

  const net_price = calcPrice({
    price_attr: 'net_price',
    prices: coercedPrices,
    price_calculation_rule
  });

  const gross_price = calcPrice({
    price_attr: 'gross_price',
    prices: coercedPrices,
    price_calculation_rule
  });

  return { ...slot, cash: DEFAULT_CASH, price, net_price, gross_price, prices: currentPrices };
}

function assignPackageSlotPrices({ slot, billing_hour, prices }) {
  const { prices: previousPrices, rate: { type: rateType } } = slot;

  let currentPrices = dailyRatePrices({ rateType, prices });
  currentPrices = assignPaymentMethod({ previousPrices, currentPrices });

  const price = currentPrices[0].price;
  const net_price = currentPrices[0].net_price;
  const gross_price = currentPrices[0].gross_price;

  return { ...slot, cash: DEFAULT_CASH, price, net_price, gross_price, prices: currentPrices };
}

function assignSlotsPrices({ slots, billing_hour, type, price_calculation_rule, prices }) {
  const data = slots.map(slot => {
    try {
      // return assignSlotPrices({ slot, billing_hour, prices });
      switch (type) {
        case 'base':
          return assignBaseSlotPrices({ slot, billing_hour, price_calculation_rule, prices });
        case 'package':
          return assignPackageSlotPrices({ slot, billing_hour, price_calculation_rule, prices });
        default:
          return { ...slot, price: 0, prices: [] };
      }
    } catch (error) {
      switch (error.message) {
        case UNKNOWN_RATE_ERROR:
          return { ...slot, price: 0, net_price: 0, gross_price: 0, prices: [] };
        default:
          throw error;
      }
    }
  });

  return data;
}

export default assignSlotsPrices;
