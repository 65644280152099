import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

import { mergePayments } from 'utils/pricesPayment';
import groupMapper from '../groupMapper';

function groupsByTariff(reservations) {
  let groups = _groupBy(reservations, 'tariff.id');
  groups = _map(groups, groupMapper);

  const payment = mergePayments(groups.map(item => item.payment));

  return { payment, groups };
}

export default groupsByTariff;
