import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import styled, { css } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { typography } from 'theme/mixins';

const Wrapper = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${typography(16, 20, 700)}
  `}
`;

class SubTitle extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    count: PropTypes.number
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { count, children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(SubTitle)``;
