import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SelectedWrapper as Marker } from '../Marker';

function RawGroup({ items, renderItem }) {
  return items.map(item => (
    <Marker
      key={item.id}
      item={item}
      position={{
        lat: item.address.coordinates[1],
        lng: item.address.coordinates[0]
      }}
    >
      {renderItem({ hotel: item })}
    </Marker>
  ));
}

RawGroup.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  renderItem: PropTypes.func.isRequired
};

RawGroup.defaultProps = {
  items: [],
  renderItem: () => {}
};

export default styled(RawGroup)``;
