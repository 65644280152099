import React from 'react';
import styled, { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Info, UploadPhoto } from '../General';

const Wrapper = styled.div``;

@withTheme
@inject('profileForm')
@observer
class General extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    profileForm: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <UploadPhoto />
        <Info />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(General))``;
