import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useBalance from './hooks/useBalance';
import formatPrice from 'utils/formatPrice';

const Top = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
  white-space: nowrap;
`;

const Bottom = styled.div`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 52px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  background: #6a8dae;
  transition: background-color 0.25s;

  &:hover {
    background-color: hsl(209.10000000000002,29.6%,49.4%);
  }
`;

function Balance({ className }) {
  const { t } = useTranslation();
  const [balanceData] = useBalance();

  if (!balanceData) {
    return null;
  }

  let balanceValue = Object.values(balanceData.balance);
  balanceValue = formatPrice(...balanceValue);

  return (
    <Wrapper className={className}>
      <Top>
        {t('Balance.Value', { value: balanceValue })}
      </Top>
      <Bottom>
        {balanceData.number} - {balanceData.name}
      </Bottom>
    </Wrapper>
  );
}

Balance.propTypes = {
  className: PropTypes.string
};

export default styled(Balance)``;
