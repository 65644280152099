import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { HotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import Input from './Input';

import { GoArrow } from 'components/icons';

const Button = styled.button`
  min-width: 44px;
  height: 44px;
  color: #FFFFFF;
  border-radius: 0 3px 3px 0;
  background-color: #5AB2D6;
  cursor: pointer;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 14px 24px;

  ${Input} {
    flex-grow: 1;
  }
`;

@inject('chatForm')
@observer
class Control extends Component {
  static propTypes = {
    chatForm: PropTypes.object.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { chatForm } = this.props;
    chatForm.onSubmit(e);
  }

  render() {
    const { chatForm } = this.props;

    const keyMap = {
      sendMsg: 'enter'
    };

    const handlers = {
      sendMsg: this.handleSubmit
    };

    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <Wrapper
          as={Wrapper}
          onSubmit={this.handleSubmit}
        >
          <Input
            field={chatForm.$('text')}
          />

          <Button type='submit'>
            <GoArrow />
          </Button>
        </Wrapper>
      </HotKeys>
    );
  }
}

export default styled(Control)``;
