import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useEvents from 'hooks/useEvents';

import HotelCard from 'components/v2/ui/HotelCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 144px;
`;

function HotelCardPreview({ hotel, className, updateOrder }) {
  const em = useEvents();

  const handleMouseOver = (e) => {
    // eslint-disable-next-line no-use-before-define
    em.emit('map:marker:mouseover', hotel.id);
  };

  const handleMouseOut = (e) => {
    // eslint-disable-next-line no-use-before-define
    em.emit('map:marker:mouseleave', hotel.id);
  };

  const handleHotelClick = (e) => {
    updateOrder({ hotel });
  };

  return (
    <Wrapper
      className={className}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <HotelCard
        hotel={hotel}
        onClick={handleHotelClick}
      />
    </Wrapper>
  );
}

HotelCardPreview.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object,
  updateOrder: PropTypes.func
};

HotelCardPreview.defaultProps = {
  className: '',
  updateOrder: () => {}
};

export default styled(HotelCardPreview)``;
