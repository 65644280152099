import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTranslation } from 'react-i18next';

import {
  BadgeChargeIcon,
  BadgeVoidIcon,
  BadgePayIcon,
  BadgeRefundIcon
} from 'components/icons';

const IconSVG = {
  trigger: BadgeChargeIcon,
  void: BadgeVoidIcon,
  pay: BadgePayIcon,
  refund: BadgeRefundIcon
};

const Icon = styled.div`
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  height: 28px;
  padding: 0 12px;
  background-color: #FFF;
  border-radius: 9999px;

  ${Icon} + ${Text} {
    margin-left: 6px;
  }

  ${({ variant }) => variant === 'trigger' && css`
    ${Icon}, ${Text} {
      color: #3AA6D2;
    }
  `}

  ${({ variant }) => variant === 'void' && css`
    ${Icon}, ${Text} {
      color: #E26161;
    }
  `}

  ${({ variant }) => variant === 'pay' && css`
    ${Icon}, ${Text} {
      color: #35A068;
    }
  `}

  ${({ variant }) => variant === 'refund' && css`
    ${Icon}, ${Text} {
      color: #979BA0;
    }
  `}
`;

function PaymentBadge({ variant, className }) {
  const { t } = useTranslation();

  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      <Icon as={IconSVG[variant]} />

      <Text>
        {t('UI.PaymentBadge.Label', { value: variant })}
      </Text>
    </Wrapper>
  );
}

PaymentBadge.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['trigger', 'void', 'pay', 'refund']).isRequired
};

export default styled(PaymentBadge)``;
