import React from 'react';
import PropTypes from 'prop-types';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import { Body } from './components';
import HotelsInfo from '../HotelsInfo';

import BookingState from 'forms/Orders/BookingState';

@withRouter
@observer
class Edit extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    // BookingState
    const {
      match: { params },
      location: { state }
    } = this.props;
    this.bookingState = new BookingState({ ...params, ...state });
  }

  render() {
    return (
      <Provider bookingState={this.bookingState} orderStore={this.bookingState.orderStore}>
        <>
          <DefaultTemplate header={<Header />} body={<Body />} />

          <HotelsInfo />
        </>
      </Provider>
    );
  }
}

export default Edit;
