import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

// TODO: Refactoring this
import { updateParams } from 'forms/Orders/BookingState/utils/paramsMapper';

import { Prompt } from 'components/ui';
import { Modal } from 'components/molecules';
import { Button } from 'components/forms';

@inject('bookingCart', 'orderStore')
@observer
class ReservationRemoveDialog extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isOpened: PropTypes.bool,
    onAccept: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    reservation: PropTypes.object.isRequired,
    bookingCart: PropTypes.object,
    orderStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: '',
    isOpened: true
  };

  @computed get isLoading() {
    const { orderStore } = this.props;
    return orderStore.isPending;
  }

  handleAccept = async () => {
    const {
      bookingCart,
      reservation,
      orderStore,
      onAccept,
      onClose
    } = this.props;

    const { order } = bookingCart;

    let params = bookingCart.destroyReservation(reservation.id);
    params = updateParams({ order, reservation: params });

    await orderStore.update(params);

    if (onAccept) onAccept();
    if (onClose) onClose();
  }

  handleDecline = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  render() {
    const { reservation, isOpened, t, ...rest } = this.props;

    return (
      <Modal
        opened={isOpened}
        onClose={this.handleDecline}
      >
        <Prompt {...rest}>
          <Prompt.Content>
            <Prompt.Title>
              {t('Order.Reservation.Remove.Caption')}
            </Prompt.Title>
          </Prompt.Content>

          <Prompt.Footer>
            <Prompt.Buttons>
              <Button
                onClick={this.handleAccept}
                rounded
                variant='danger'
                loading={this.isLoading}
              >
                {t('Order.Reservation.Remove.Accept')}
              </Button>

              <Button
                onClick={this.handleDecline}
                rounded
                variant='secondary'
              >
                {t('Order.Reservation.Remove.Cancel')}
              </Button>
            </Prompt.Buttons>
          </Prompt.Footer>
        </Prompt>
      </Modal>
    );
  }
}

export default styled(withTranslation()(ReservationRemoveDialog))``;
