import { t } from 'utils/localization';

const fields = [
  'text'
];

const placeholders = {
  text: t('Conversation.Text.Placeholder')
};

const rules = {
  text: 'required|string'
};

export default {
  fields,
  placeholders,
  rules
};
