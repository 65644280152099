import { useState, useEffect } from 'react';
import instance from 'connection/instance';
import store from './store';

function useBalance() {
  const [balance, setBalance] = useState(store.getBalance());

  const fetchBalance = async () => {
    const response = await instance.get('/api/organizations/balance');
    if (response.status !== 200) {
      return;
    }

    const { data: { data } } = response;
    store.setBalance(data);
    setBalance(store.getBalance());
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  return [balance, fetchBalance];
}

export default useBalance;
