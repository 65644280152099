import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

import { display, flexChildActualWidth, typography } from 'theme/mixins';
import { Magnify as MagnifyIcon } from 'components/icons';

const Overlay = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')}
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: inherit;
    color: ${theme.colors.light};
    ${typography(12, 15, 500)};
    opacity: 0;
    transition: opacity 0.25s;
  `}
`;

const MagnifyIconS = styled(MagnifyIcon)`
  width: 24px;
  height: 24px;
`;

const Wrapper = styled.div`
  ${({ hasOverlayCaption, hasMagnifier, isActive, theme, height, width }) => css`
    position: relative;
    ${flexChildActualWidth(width)}
    height: ${height}px;
    border: 1px solid transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.25s, opacity 0.25s;

    ${isActive && css`
      border-color: ${theme.colors.active.primary};
    `}

    ${hasOverlayCaption && css`
      ${Overlay} {
        opacity: 1;
      }
    `}

    ${hasMagnifier && css`
      ${MagnifyIconS} {
        transform: scale(3);
        transition: transform 0.25s;
      }

      ${Overlay}:hover {
        opacity: 1;

        ${MagnifyIconS} {
          transform: scale(1);
        }
      }
    `}

    ${!hasMagnifier && !hasOverlayCaption && !isActive && css`
      opacity: 0.5;

      :hover {
        opacity: 1;
      }
    `}
  `}
`;

const Image = styled.img.attrs(({ src, width, height }) => ({
  src,
  width: width,
  height: height
}))`
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
`;

@withTheme
class Photo extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    hasMagnifier: PropTypes.bool,
    onClick: PropTypes.func,
    overlayCaption: PropTypes.string,
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number
  };

  static defaultProps = {
    className: '',
    height: 46,
    width: 70
  };

  node = React.createRef();

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(this.node);
    }
  }

  render() {
    const { isActive, width, height, overlayCaption, hasMagnifier, src, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        width={width}
        height={height}
        ref={this.node}
        isActive={isActive}
        hasMagnifier={hasMagnifier}
        hasOverlayCaption={!!overlayCaption}
        onClick={this.handleClick}
      >
        <Image src={src} width={width} height={height} />

        <Overlay>
          {overlayCaption}
          {hasMagnifier && (
            <MagnifyIconS />
          )}
        </Overlay>
      </Wrapper>
    );
  }
}

export default styled(Photo)``;
