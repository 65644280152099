import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BlueCkeck from 'assets/icons/blue_check.svg?defaultExtension=svg';

const NameContainer = styled.div`
  padding-left: 28px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #4a515c;

  &:before {
    position: absolute;
    content: '';
    background-image: url('${BlueCkeck}');
    background-repeat: no-repeat;
    background-position: center;
    left: -2px;
    width: 20px;
    height: 20px;
    top: 8px;
    border-radius: 50%;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  letter-spacing: 0;
`;

function Treatment({ className, treatment }) {
  return (
    <Wrapper className={className}>
      <NameContainer>{treatment.name}</NameContainer>
    </Wrapper>
  );
}

Treatment.propTypes = {
  className: PropTypes.string,
  treatment: PropTypes.object
};

export default Treatment;
