import { types } from 'mobx-state-tree';

import { Hotel } from '../Hotel';
import { Traveller } from '../Traveller';
import { Reservation } from '../Reservation';

import { Address, ProviderState, PlatformState, InvoiceState, Creator } from 'stores/models';

const OrderItem = types.model('OrderItem', {
  id: types.identifier,
  address: types.maybeNull(Address),
  check_in: types.string,
  check_out: types.string,
  created_at: types.string,
  updated_at: types.string,
  creator: Creator,
  hotel: types.maybeNull(Hotel),
  order_code: types.string,
  price: types.maybeNull(types.number),
  provider_state: ProviderState,
  platform_state: PlatformState,
  invoice_state: InvoiceState,
  reservations: types.optional(types.array(Reservation), []),
  travellers: types.optional(types.array(Traveller), [])
});

export default OrderItem;
