export default {
  data: [
    {
      id: '123',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '456',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '789',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'abcd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1fewfef23',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '45423ff436',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '78fwf329',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'f3f3f3abcd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1t3423',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'grege434',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '7t4389',
      account_code: 'fwrfw-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'abt34cd',
      account_code: 'f34fre-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1f34tewfef23',
      account_code: 'fwf4g3',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '4542t433ff436',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '78ftwetewwf329',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'f3f3f3tweetwabcd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'r2few123',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '4r2356',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '7few89',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'abfwecd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1fewfewef23',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '4542fw3ff436',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '78fwf2f329',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'f3ff423f3abcd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1tf2f3423',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'gr2ege434',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '7tf4389',
      account_code: 'fwrfw-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'a4bt34cd',
      account_code: 'f34fre-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '1f341tewfef23',
      account_code: 'fwf4g3',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'f4542t433ff436',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: '7few8ftwetewwf329',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    },
    {
      id: 'fef3f3f3tweetwabcd',
      account_code: 'ABC-123',
      amount: 132543,
      created_at: '2019-08-29',
      payment_type: 'Банковский перевод',
      transaction_id: 'oue-901',
      transaction_type: 'Оплата'
    }
  ]
};
