import { types } from 'mobx-state-tree';
import { Seo } from './Seo';
import { BestReviews } from './BestReviews';
import { BreadCrumbs } from './BreadCrumbs';
import { Paginate } from './Paginate';

export const Meta = types.model('Meta', {
  seo: types.optional(Seo, {}),
  total: types.maybeNull(types.number),
  best_review: types.maybeNull(types.array(BestReviews)),
  breadcrumbs: types.maybeNull(types.array(BreadCrumbs)),
  paginate: types.optional(Paginate, {})
});
