import React from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { addOne } from 'redux/stores/notificationsSlice/actions';

import Content from './Content';

function HookWrapper(props) {
  const dispatch = useDispatch();

  const handleSuccess = (message) => {
    const data = {
      type: 'success',
      message,
      time: 5
    };

    dispatch(addOne(data));
  };

  const handleError = (message) => {
    const data = {
      type: 'error',
      message,
      time: 5
    };

    dispatch(addOne(data));
  };

  return (
    <Content
      {...props}
      onSuccess={handleSuccess}
      onError={handleError}
    />
  );
}

export default styled(HookWrapper)``;
