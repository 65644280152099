import styled, { css } from 'styled-components';
import { typography } from 'theme/mixins';
import colors from 'theme/colors';

export default styled.div`
  position: relative;
  padding: 12px;
  ${typography(12, 16, 500)}
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ width }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: ${typeof width === 'number' ? `${width}px` : width};
  `}

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${colors.controls.lightBackground};
  }

  :first-child::after {
    width: calc(100% - 12px);
    right: 0;
    left: auto;
  }

  :last-child::after {
    width: calc(100% - 12px);
  }
`;
