import { configureStore } from '@reduxjs/toolkit';

import usersReducer from '../usersSlice';
import notificationsReducer from '../notificationsSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    notifications: notificationsReducer
  }
});

export default store;
