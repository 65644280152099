import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Error = styled.div``;

class Errors extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    className: '',
    error: null,
    errors: []
  };

  render() {
    const { error, errors, ...rest } = this.props;

    const hasError = error && errors.length;

    if (!hasError) {
      return null;
    }

    return (
      <Wrapper {...rest}>
        <Error>{error}</Error>

        {errors.map((error, key) => (
          <Error key={`error_${key}`}>{error}</Error>
        ))}
      </Wrapper>
    );
  }
}

export default styled(Errors)``;
