import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Input from '../Input';

import CheckboxElement from './CheckboxElement';

import { CircleArrow } from 'components/icons';

const EmptyMessageContainer = styled.div`
  width: 228px;
  opacity: 0.6;
  color: #4C4C4C;
  font-family: "Myriad Pro";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

const TitleContainer = styled.div`
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 12px;
`;

const InputContainer = styled.div`
  margin-bottom: 18px;
`;

const MoreButtonContainer = styled.div`
  color: #3C6F9D;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
  margin-top: 22px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-left: 10px;
  font-size: 9px;
  fill: #2B5688;
  display: flex;
  align-items: center;

  ${({ opened }) => opened && css`
    transform: rotate(180deg);
  `};
`;

const Wrapper = styled.div``;

function Checkbox({
  value,
  options,
  onChange,
  valueKey,
  labelKey,
  title,
  hasSearch,
  moreText,
  hideText,
  placeholder,
  emptyMessage,
  asRadiobutton
}) {
  // Filter options by chars
  const [chars, setChars] = useState('');

  const handleCharsChange = (e) => {
    setChars(e.target.value);
  };

  // Hide / Show more options
  const [opened, setOpened] = useState(false);

  const handleMore = () => {
    setOpened(!opened);
  };

  const hasHidden = options
    .filter(element => element.visible === false)
    .length > 0;

  const hasChars = chars.length > 0;

  const hasMoreButton = hasHidden && !hasChars;

  const items = useMemo(() => {
    const nameIncludesChars = (name) => {
      // eslint-disable-next-line no-use-before-define
      const regexp = new RegExp(chars, 'i');
      return regexp.test(name);
    };

    const value = chars.length > 0
      ? options.filter(item => nameIncludesChars(item[labelKey]))
      : opened
        ? options
        : options.filter(element => element.visible === true);

    return value;
  }, [chars, opened, options]);

  const handleChange = (option) => {
    const currentValue = value || [];
    const key = option[valueKey];

    const newValue = currentValue.includes(key)
      ? currentValue.filter(v => v !== key)
      : [...currentValue, key];

    onChange(newValue);
  };

  return (
    <Wrapper>
      <TitleContainer>
        {title}
      </TitleContainer>

      {hasSearch && (
        <InputContainer>
          <Input
            onChange={handleCharsChange}
            placeholder={placeholder}
          />
        </InputContainer>
      )}

      {items.map((item, index) => (
        <CheckboxElement
          key={index}
          item={item}
          checked={value.includes(item[valueKey])}
          onChange={handleChange}
        >
          {item[labelKey]}
        </CheckboxElement>
      ))}

      {items.length === 0 && (
        <EmptyMessageContainer>
          {emptyMessage}
        </EmptyMessageContainer>
      )}

      {hasMoreButton && !opened && (
        <MoreButtonContainer onClick={handleMore}>
          {moreText}

          {false && (
            <IconContainer>
              <CircleArrow />
            </IconContainer>
          )}
        </MoreButtonContainer>
      )}

      {hasMoreButton && opened && (
        <MoreButtonContainer onClick={handleMore}>
          {hideText}

          {false && (
            <IconContainer opened>
              <CircleArrow />
            </IconContainer>
          )}
        </MoreButtonContainer>
      )}
    </Wrapper>
  );
}

Checkbox.propTypes = {
  title: PropTypes.string,
  hasSearch: PropTypes.bool,
  moreText: PropTypes.string,
  hideText: PropTypes.string,
  placeholder: PropTypes.string,
  emptyMessage: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  asRadiobutton: PropTypes.bool
};

Checkbox.defaultProps = {
  value: [],
  // eslint-disable-next-line no-use-before-define
  valueKey: 'id',
  // eslint-disable-next-line no-use-before-define
  labelKey: 'name',
  options: [],
  onChange: () => { },
  asRadiobutton: false,
  emptyMessage: ''
};

export default Checkbox;
