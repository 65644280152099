import BaseState from 'forms/BaseState';

import { fields } from '.';

class StateForm extends BaseState {
  setup() {
    return fields;
  }
}

export default StateForm;
