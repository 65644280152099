import qs from 'qs';

function parseQueryString(asPath) {
  const [, queryStr] = asPath.split('?');
  if (!queryStr) return {};

  return qs.parse(queryStr);
}

export default parseQueryString;
