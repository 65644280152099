import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import { toJS } from 'mobx';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';
import cleanDeep from 'clean-deep';

import { destroyParams } from './paramsMapper';

import { Order } from './Order';
import { Reservation } from './Reservation';

const OrderStore = types
  .model('OrderStore', {
    data: types.maybeNull(Order),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState('pending');

      const { id, ...options } = params;

      return instance.get(`/api/orders/${id}`, { options })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    create(values = {}) {
      self.setState('pending');

      const data = cleanDeep(values, { emptyArrays: false });

      return instance.post('/api/orders', { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    update(values = {}) {
      self.setState('pending');

      const { id, ...data } = cleanDeep(values, { emptyArrays: false });

      return instance.put(`/api/orders/${id}`, { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    confirm(params = {}) {
      self.setState('pending');

      const { id } = params;

      return instance.post(`/api/orders/${id}/confirm`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    cancel() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/cancel`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    archive() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/archive`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    cancelReservation(id) {
      const reservation = resolveIdentifier(Reservation, self, id);

      const values = destroyParams({
        order: toJS(self.data),
        reservation: toJS(reservation)
      });

      return self.update(values);
    },

    syncOnPlatform() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/sync_on_platform`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    downloadVoucher() {
      self.setState('pending');

      const { id } = self.data;

      return instanceBlob.get(`/api/orders/${id}/download_voucher`)
        .then((response) => self.loadVoucher(response.data))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    loadVoucher(data) {
      const blob = new Blob(
        [data],
        { type: 'application/pdf' }
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = ['voucher_', self.data.order_code, '.pdf'].join('');

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },

    downloadInvoice() {
      self.setState('pending');

      const { id } = self.data;

      return instanceBlob.get(`/api/orders/${id}/invoice`)
        .then((response) => self.loadInvoice(response.data))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    loadInvoice(blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = ['invoice', '.xlsx'].join('');

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    }
  }));

export default OrderStore;
