import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { flexChildActualWidth, typography } from 'theme/mixins';

import UserCard from './UserCard';

const Wrapper = styled.div``;

const User = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 10px 0;

    ::before {
      content: counter(slot) '.';
      counter-increment: slot;
      ${flexChildActualWidth(24)}
      color: ${theme.colors.text.primary};
      ${typography(14, 16, 700)};
    }
  `}
`;

const Users = styled.div`
  counter-reset: slot;
  margin: 32px 0 8px;

  ${UserCard} {
    width: 100%;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    margin: 16px 0 8px;
    padding: 24px 0 16px;
    border-bottom: 1px solid ${theme.colors.popup.primary};
    color: ${theme.colors.text.disabled};
    font-family: ${theme.typography.h2.fontFamily};
    font-size: ${theme.typography.h2.fontSize}px;
    font-weight: ${theme.typography.h2.fontWeight};
    line-height: ${theme.typography.h2.lineHeight}px;
  `}
`;

function UsersList({ users, className }) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Title>
        {t('Settings.Users.AddedUsers.Title')}
      </Title>

      {users && (
        <Users>
          {users.map(user => (
            <User key={user.id}>
              <UserCard user={user} />
            </User>
          ))}
        </Users>
      )}
    </Wrapper>
  );
}

UsersList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default styled(UsersList)``;
