import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { BlockShadow } from 'components/atoms';
import { Top } from '.';
import Credit from './Credit';
import Debit from './Debit';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 980px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

const Row = styled(BlockShadow)`
  ${display('flex', null, 'space-between')}

  :not(:first-child) {
    margin-top: 10px;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

@withTheme
class Finances extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Top />

        <Row>
          <Credit />
        </Row>

        <Row>
          <Debit />
        </Row>
      </Wrapper>
    );
  }
}

export default styled(Finances)``;
