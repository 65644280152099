import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #4A515C;
  font-size: 13px;
  line-height: 15px;
`;

@withRouter
@inject('showState')
@observer
class NotAvailableActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        {t('Order.Actions.NotAvailable')}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(NotAvailableActions))``;
