import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import ReactLoading from 'react-loading';

const LoadingIcon = styled(ReactLoading).attrs(() => ({
  type: 'bubbles',
  color: 'currentColor',
  width: '2.5em',
  height: '2.5em'
}))`
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  opacity: 1 !important;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

@observer
class Loader extends Component {
  static propTypes = {
    className: PropTypes.string
  }

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        <LoadingIcon />
      </Wrapper>
    );
  }
}

export default Loader;
