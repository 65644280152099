import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _uniqueId from 'lodash/uniqueId';

const Label = styled.label``;

const CheckboxElement = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    color: #6B707B;
    font-size: 13px;
    display: flex;
    line-height: 19px;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    height: 16px;
    min-width: 16px;
    display: block;
    width: 16px;
    border-radius: 3px;
    background: white;
    border: 1px solid #DDDDE6;
    border-radius: 3px;
    box-sizing: border-box;
  }

  // Box hover
  &:hover + label:before {
    background-color: #00BB58;
  }

  // Box checked
  &:checked + label:before {
    background-color: #5AB2D6;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Wrapper = styled.div`
  line-height: 0;

  &:hover label:before {
    background-color: #5AB2D6;
  }
`;

function Checkbox({ className, children, checked, onChange, item }) {
  const fieldId = _uniqueId('field-');

  const handleChange = (e) => {
    item ? onChange(item) : onChange(!checked);
  };

  return (
    <Wrapper className={className}>
      <CheckboxElement
        id={fieldId}
        type='checkbox'
        checked={checked}
        onChange={handleChange}
      />

      <Label htmlFor={fieldId}>
        {children}
      </Label>
    </Wrapper>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  item: PropTypes.object
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => { }
};

export default styled(Checkbox)``;
