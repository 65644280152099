import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import styled from 'styled-components';

import RawGroup from './RawGroup';

const ZOOM_MAPPER = {
  city: 12,
  region: 9,
  country: 5
};

const Wrapper = styled(MapContainer)`
  position: relative;
  width: 100%;
  height: calc(100vh - 144px);
  
  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-container {
    height: 100%;
  };

  .marker-cluster {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2E7CD6;
    background-color: #FFF;
    border-radius: 50%;
    color: #4C4C4C;
    cursor: pointer;
  };

  .leaflet-popup-content {
    width: 490px !important;
    margin: 0;
  }

  .leaflet-popup {
    width: auto;
    height: auto;
    transform: traslateX(-50%);
    bottom: 4px !important;
  };

  img.leaflet-marker-icon {
    cursor: pointer;
  }

  .leaflet-marker-shadow {
    display: none;
  }

  .leaflet-popup-tip {
    width: 13px;
    height: 13px;
  }
`;

function Map({ hotels, query, tileBaseUrl, renderItem, defaultCenter, className }) {
  // Map reference
  const mapRef = useRef(null);

  const handleCreate = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (hotels.length < 1) {
      return;
    }

    const bounds = hotels.slice(0, 1).map(hotel => {
      const [lng, lat] = hotel.address.coordinates;
      return { lat, lng };
    });

    const scope = query?.place?.scope;
    const zoom = ZOOM_MAPPER[scope] || 10;

    mapRef.current.fitBounds(bounds, {
      duration: 1.5,
      maxZoom: zoom,
      padding: [150, 150]
    });
  }, [hotels, mapRef.current]);

  return (
    <Wrapper
      className={className}
      center={defaultCenter}
      zoom={7}
      minZoom={5}
      duration={1}
      zoomControl={false}
      useFlyTo
      boundsOptions={{ maxZoom: 13 }}
      whenCreated={handleCreate}
    >
      <TileLayer url={tileBaseUrl} />

      <ZoomControl position='bottomright' />

      <RawGroup
        items={hotels}
        renderItem={renderItem}
      />
    </Wrapper>
  );
}

Map.propTypes = {
  className: PropTypes.string,
  defaultCenter: PropTypes.arrayOf(PropTypes.number.isRequired),
  tileBaseUrl: PropTypes.string,
  hotels: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  query: PropTypes.object
};

Map.defaultProps = {
  items: [],
  renderItem: () => { },
  // eslint-disable-next-line no-use-before-define
  tileBaseUrl: 'https://tiles.worldota.net/api/v1/t/ostrovok/ru/{z}/{x}/{y}.png'
};

export default styled(Map)``;
