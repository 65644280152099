import { types } from 'mobx-state-tree';

const AdvanceBookingRestrictions = types.model('AdvanceBookingRestrictions', {
  max_days_before_check_in: types.maybeNull(types.number),
  min_days_before_check_in: types.maybeNull(types.number)
});

const ClosedByDateRestrictions = types.model('ClosedByDateRestrictions', {
  closed_to_arrival: types.boolean,
  closed_to_departure: types.boolean
});

const ClosedByWeekDayRestrictions = types.model('ClosedByWeekDayRestrictions', {
  closed_to_arrival: types.boolean,
  closed_to_departure: types.boolean
});

const LengthOfStayArrivalBasedRestrictions = types.model('LengthOfStayArrivalBasedRestrictions', {
  max_length_of_stay: types.maybeNull(types.number),
  min_length_of_stay: types.maybeNull(types.number)
});

const LengthOfStayRestrictions = types.model('LengthOfStayRestrictions', {
  max_length_of_stay: types.maybeNull(types.number),
  min_length_of_stay: types.maybeNull(types.number)
});

const Restrictions = types.model('Restrictions', {
  length_of_stay_restrictions: LengthOfStayRestrictions,
  length_of_stay_arrival_based_restrictions: LengthOfStayArrivalBasedRestrictions,
  closed_by_week_day_restrictions: ClosedByWeekDayRestrictions,
  closed_by_date_restrictions: ClosedByDateRestrictions,
  advance_booking_restrictions: AdvanceBookingRestrictions
});

export default Restrictions;
