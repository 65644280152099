import { types } from 'mobx-state-tree';

export const Taxes = types.model('Taxes', {
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  unit: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  payment: types.maybeNull(types.string),
  returnable: types.maybeNull(types.boolean)
});
