import * as yup from 'yup';

export const PASSWORD = 'password';
export const PASSWORD_CONFIRMATION = 'password_confirmation';

const validationSchema = yup.object({
  [PASSWORD]: yup.string().min(6).trim().required('Required'),
  [PASSWORD_CONFIRMATION]: yup.string().min(6).trim().oneOf([yup.ref('password'), null]).required('Required')
});

export const defaultValues = {};

export default validationSchema;
