import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'utils/moment';

import { Rating, Stars } from 'components/ui';

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
`;

const ReviewsTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #4a515c;
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 7px;
  gap: 3px;
  background: #dff7ea;
  border-radius: 13px;
  cursor: pointer;
`;

const CloseButton = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #3c6f9d;
  cursor: pointer;
`;

const ReviewContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
`;

const Avatar = styled.img`
  width: 33px;
  min-width: 33px;
  height: 33px;
  border-radius: 50%;
  background-image: url(data:image/png;base64, ${(p) => p.avatar});
`;

const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ReviewContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4a515c;
  width: auto;
`;

const Date = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #979ba0;
  width: auto;
  text-align: right;
`;

const ReviewsText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a515c;
  margin-top: 4px;
`;

const ReviewWrapper = styled.div``;

function Reviews({ rating, total, setOpenReview, reviews }) {
  const { t } = useTranslation();

  const handeClose = () => {
    setOpenReview(false);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>
          <ReviewsTitle>{`${t('Hotel.Details.Tabs.Comments')} (${total})`}</ReviewsTitle>
          <RatingContainer>
            <Stars stars={1} />
            {rating > 0 && <Rating rating={rating} isGreen />}
          </RatingContainer>
        </Header>
        <CloseButton onClick={handeClose}>{t('Common.Close')}</CloseButton>
      </HeaderWrapper>
      <ReviewWrapper>
        {reviews?.length > 0 &&
          reviews.map(({ avatar, user_name, created_at, description, id, description_original }) => {
            const descriptionData = description || description_original;
            return (
              <ReviewContainer key={id}>
                {avatar && <Avatar avatar={avatar} />}
                <ReviewContent>
                  <ReviewContentHeader>
                    {user_name && <Name>{user_name}</Name>}
                    {created_at && <Date>{moment(created_at).format('D MMMM')}</Date>}
                  </ReviewContentHeader>
                  {descriptionData && <ReviewsText dangerouslySetInnerHTML={{ __html: descriptionData }} />}
                </ReviewContent>
              </ReviewContainer>
            );
          })}
      </ReviewWrapper>
    </Wrapper>
  );
}

Reviews.propTypes = {
  rating: PropTypes.number,
  total: PropTypes.number,
  reviews: PropTypes.array,
  setOpenReview: PropTypes.func
};

Reviews.defaultProps = {
  className: ''
};

export default styled(Reviews)``;
