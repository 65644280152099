import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useBalance } from 'components/ui/Balance';
import { useTranslation } from 'react-i18next';

import Statistic from 'components/ui/Statistic';
import formatPrice from 'utils/formatPrice';

const Header = styled.div`
  color: #4A515C;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
`;

const Cell = styled.div`
  padding: 5px 24px 15px;
  border-left: 1px solid #e8eaec;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${Cell}:first-child {
    padding-left: 0px;
    border-left: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  ${Header} + ${Body} {
    margin-top: 20px;
  }
`;

function Debit({ className }) {
  const { t } = useTranslation();

  const [balanceData] = useBalance();

  if (!balanceData) {
    return null;
  }

  let liabilityValue = Object.values(balanceData.liability_balance);
  liabilityValue = formatPrice(...liabilityValue);

  return (
    <Wrapper className={className}>
      <Header>
        {t('Finances.Debit')}
      </Header>
      <Body>
        <Cell>
          <Statistic
            label={t('Finances.Balance.PotentialDebit')}
            value={liabilityValue}
            variant='danger'
          />
        </Cell>
        <Cell>
          <Statistic
            label={t('Finances.Balance.RealDebit')}
            value={liabilityValue}
            variant='danger'
          />
        </Cell>
        <Cell>
          <Statistic
            label={t('Finances.Balance.OverdueDebit')}
            value='0 ₽'
            variant='danger'
          />
        </Cell>
      </Body>
    </Wrapper>
  );
}

Debit.propTypes = {
  className: PropTypes.string
};

export default styled(Debit)``;
