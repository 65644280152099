import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Spinner } from 'components/ui';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
  flex-direction: column;
`;

const Label = styled.div`
  padding: 8px 0;
`;

class Fetch extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string
  };

  render() {
    const { label, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Spinner />

        <Label>
          {label}
        </Label>
      </Wrapper>
    );
  }
}

export default styled(Fetch)``;
