import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Close } from 'components/icons';

import { display, typography, flexChildActualWidth } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('inline-flex', 'center')}
    position: relative;
    max-width: 360px;
    height: 36px;
    padding: 0 12px;
    background: ${theme.colors.filter.primary};
    border-radius: 4px;
    ${typography(13, 15)};

    ${Close} {
      ${flexChildActualWidth(10)}
      height: 10px;
      margin-left: 16px;
      cursor: pointer;
    }
  `}
`;

const Caption = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.disabled};
  `}
`;

const Value = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.secondary};
  `}
`;

@withTheme
class Filter extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleFilterRemove = () => {
    const { filter, onRemove } = this.props;
    onRemove(filter);
  }

  render() {
    const { filter, onRemove, ...rest } = this.props;
    const [label, value] = filter.value.label.split(': ');

    return (
      <Wrapper {...rest}>
        <Caption>
          <Label>
            {label}:
          </Label>
          {' '}
          <Value>
            {value}
          </Value>
        </Caption>

        <Close onClick={this.handleFilterRemove} />
      </Wrapper>
    );
  }
}

export default styled(Filter)``;
