import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';

import { Cancel } from 'pages/Orders/containers';
import { Button } from 'components/forms';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${Button}:not(:first-child),
  ${Cancel} {
    margin-left: 16px;
  }
`;

@inject('showState', 'ordersStore')
@observer
class Actions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    showState: PropTypes.object.isRequired,
    ordersStore: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get showArchiveBtn() {
    const { showState } = this.props;
    return showState.isCancelled;
  }

  handleArchive = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;

    const promise = showState.archive();

    const { order } = showState;
    this.removeOrder(order);

    return promise;
  };

  @computed get showEditBtn() {
    const { showState } = this.props;
    return !showState.isCancelled;
  }

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;
    return showState.edit();
  };

  @computed get showCancelBtn() {
    const { showState } = this.props;
    return !showState.isCancelled;
  }

  handleCancel = async (e) => {
    const { showState } = this.props;

    const promise = await showState.cancel();

    const { order } = showState;
    this.patchOrder(order);

    return promise;
  };

  @computed get showDownloadVoucherBtn() {
    const { showState } = this.props;
    return showState.isConfirmed;
  }

  handleDownloadVoucher = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;
    return showState.downloadVoucher();
  };

  @computed get showDownloadBillBtn() {
    const {
      showState: { order }
    } = this.props;
    return order.has_invoice;
  }

  handleDownloadBill = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { showState } = this.props;
    return showState.downloadInvoice();
  };

  patchOrder = (order) => {
    const { ordersStore } = this.props;
    const { id, provider_state } = order;

    ordersStore.patchItem(id, { provider_state });
  };

  removeOrder = (order) => {
    const { ordersStore } = this.props;

    ordersStore.removeItem(order.id);
  };

  render() {
    const {
      t,
      className,
      showState: { order, history }
    } = this.props;

    const isResalePage = history?.location?.pathname === '/resale';
    const isActiveResale = order?.resale?.active;
    const disableCancelBtn = isResalePage && isActiveResale;

    return (
      <Wrapper className={className}>
        {this.showArchiveBtn && (
          <Button outline rounded size='small' onClick={this.handleArchive}>
            {t('UI.Button.Archive')}
          </Button>
        )}

        {this.showEditBtn && (
          <Button outline rounded size='small' onClick={this.handleEdit}>
            {t('UI.Button.Edit')}
          </Button>
        )}

        {this.showCancelBtn && (
          <Cancel
            title={t('Order.Remove.Caption')}
            acceptText={t('Order.Remove.Accept')}
            declineText={t('Order.Remove.Cancel')}
            onAccept={this.handleCancel}
            onDecline={() => {}}
            disableCancelBtn={disableCancelBtn}>
            <Button disabled={disableCancelBtn} outline rounded size='small' variant='danger'>
              {t('Order.Cancel')}
            </Button>
          </Cancel>
        )}

        {this.showDownloadVoucherBtn && (
          <Button compact size='small' onClick={this.handleDownloadVoucher}>
            {t('Order.DownloadVoucher')}
          </Button>
        )}

        {this.showDownloadBillBtn && (
          <Button compact size='small' onClick={this.handleDownloadBill}>
            {t('Order.DownloadBill')}
          </Button>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Actions))``;
