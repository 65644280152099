import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import List from '../List';

import { calculatePenaltyPrice } from 'pages/Orders/containers/Edit/utils/calculatePenaltyPrice';

const backFormat = 'yyyy-MM-dd';
const format = 'dd MMMM yyyy г.';

const Wrapper = styled.div``;

function Cancellation({ className, tariff, bookingForm, checkInTime, ...rest }) {
  const { t } = useTranslation();

  const { cancellation_policy } = tariff;

  const cancellationPolicy = useMemo(() => {
    const cancellationDate =
      DateTime.fromFormat(bookingForm?.check_in, backFormat, { locale: 'ru-RU' }).toFormat(format) + ` ${checkInTime}`;

    if (!cancellation_policy) return [t('Tariffs.Cancellation.FullRefund', { date: cancellationDate })];

    if (cancellation_policy.has_full_refund) {
      return [t('Tariffs.Cancellation.FullRefund', { date: cancellationDate })];
    }

    if (cancellation_policy.has_non_refund) {
      return [t('Tariffs.Cancellation.NonRefund', { price: bookingForm?.price })];
    }

    return cancellation_policy.policy_rules
      .sort((a, b) => b?.deadline?.amount - a?.deadline?.amount)
      .map((rule) => {
        const penaltyDate =
          DateTime.fromFormat(bookingForm?.check_in, backFormat, { locale: 'ru-RU' })
            .minus({
              day: rule?.deadline?.amount
            })
            .toFormat(format) + ` ${checkInTime}`;

        return t('Tariffs.CancellationPolicy.Deadline', {
          check_in: penaltyDate,
          price: calculatePenaltyPrice(rule, bookingForm?.price, bookingForm?.slots)
        });
      });
  }, [tariff, bookingForm]);

  return (
    <Wrapper className={className}>
      <List title={t('Tariff.Cancellation.Title')} loading={!bookingForm.price} items={cancellationPolicy} {...rest} />
    </Wrapper>
  );
}

Cancellation.propTypes = {
  className: PropTypes.string,
  checkInTime: PropTypes.string,
  bookingForm: PropTypes.object,
  tariff: PropTypes.object
};

export default styled(Cancellation)``;
