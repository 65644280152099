import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Value = styled.div`
  color: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 14px;

  ${({ code }) => code === 'created' && css`
    color: #6B707B;
    background-color: #CCD5E1;
  `}

  ${({ code }) => code === 'booked' && css`
    color: #76A6D3;
    background-color: #DFF0FF;
  `}

  ${({ code }) => code === 'confirmed' && css`
    color: #519370;
    background-color: #CBF5DF;
  `}

  ${({ code }) => code === 'cancelled' && css`
    color: #979BA0;
  `}

  ${({ code }) => code === 'archived' && css`
    color: #979BA0;
  `}
`;

function ProviderState({ code, className }) {
  const { t } = useTranslation();

  return (
    <Wrapper
      code={code}
      className={className}
    >
      <Value>
        {t('UI.ProviderState.Value', { value: code })}
      </Value>
    </Wrapper>
  );
}

ProviderState.propTypes = {
  className: PropTypes.string,
  code: PropTypes.oneOf(['created', 'booked', 'confirmed', 'cancelled', 'archived'])
};

export default styled(ProviderState)``;
