import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { CapitalText } from 'components/ui';

const Wrapper = styled(CapitalText)``;

const Inner = styled.span`
  ${({ active, theme }) => css`
    color: inherit;
    letter-spacing: 2px;

    ${!active && css`
      transition: color 0.25s;

      :hover {
        color: ${theme.colors.text.primary};
      }
    `}
  `}
`;

@withRouter
class Item extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    url: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  render() {
    const { label, isActive, url, className } = this.props;

    return (
      <Wrapper className={className}>
        <Inner
          as={!isActive && Link}
          active={isActive}
          to={url}
        >
          {label}
        </Inner>
      </Wrapper>
    );
  }
}

export default styled(Item)``;
