import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExtraSlots from './ExtraSlots';
import MainSlots from './MainSlots';

const Wrapper = styled.div`
  padding: 0;
`;

class Slots extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <MainSlots />
        <ExtraSlots />
      </Wrapper>
    );
  }
}

export default styled(Slots)``;
