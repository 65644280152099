import pricesPayment, { mergePayments } from 'utils/pricesPayment';

function getPayment({ tariff, slots }) {
  const payments = slots.map(({ prices }) => (
    pricesPayment({ tariff, prices })
  ));

  return mergePayments(payments);
}

export default getPayment;
