import { useState, useEffect } from 'react';

import Store from '../MealOptionsStore';

const getStatuses = (status) => {
  const isPending = status === 'pending';
  const isFetched = status === 'done';
  const isError = status === 'error';
  const isEmpty = status === 'empty';

  return { isPending, isFetched, isError, isEmpty };
};

const useMealOptions = (props) => {
  const store = new Store();

  // Status of request
  const [status, setStatus] = useState(undefined);

  // Data
  const [data, setData] = useState([]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        setStatus('pending');
        const { data } = await store.fetch();
        setData(data);
        setStatus('done');
      } catch {
        setStatus('error');
        setData([]);
      }
    };

    doFetch();
  }, []);

  return [{ data, ...getStatuses(status) }];
};

export default useMealOptions;
