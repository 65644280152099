import pricesPayment, { mergePayments, oldPricesPayment } from 'utils/pricesPayment';
import { datesRange } from 'utils/dates';
import { duration as getDuration } from 'utils/helpers';
import { shortName } from 'utils/personName';

import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';

function seatMapper(slot) {
  const { rate: { occupation, age_group }, traveller } = slot;

  const guest = traveller
    ? shortName(traveller)
    : '—';

  return { occupation, age_group, guest };
}

function seats({ slots }) {
  const count = slots.length;

  let data = slots.map(slot => seatMapper(slot));

  const default_occupations = { main: [], extra: [] };
  data = { ...default_occupations, ..._groupBy(data, 'occupation') };

  data = _reduce(data, (result, items, occupation) => {
    const count = items.length;
    let groups = _groupBy(items, 'age_group');

    groups = _reduce(groups, (result, items, age_group) => {
      const groups = items.map(item => item.guest);
      result[age_group] = [...groups, ...result[age_group]];

      return result;
    }, { adult: [], child: [] });

    result[occupation] = { count, ...groups };

    return result;
  }, { main: {}, extra: {} });

  return { seats: { count, ...data } };
}

function reservationMapper(reservation) {
  let { tariff: { billing_hour }, check_in, check_out, slots } = reservation;
  slots = slotsMapper({ reservation, slots });

  const dates_of_stay = datesRange(check_in, check_out, 'D MMMM YYYY');
  const rooms_count = 1;
  const seats_count = slots.length;
  const duration = getDuration({ check_in, check_out, billing_hour });

  const payment = mergePayments(slots.map(item => item.payment));

  return {
    ...reservation,
    ...seats(reservation),
    dates_of_stay,
    seats_count,
    rooms_count,
    duration,
    payment,
    slots
  };
}

function slotsMapper({ reservation, slots }) {
  return slots.map(slot => slotMapper({ reservation, slot }));
}

function slotMapper({ reservation, slot }) {
  const { tariff } = reservation;
  const { prices } = slot;

  const payment = slot.prices.length > 0
    ? pricesPayment({ tariff, prices })
    : oldPricesPayment({ reservation, slot });

  return { ...slot, payment };
}

function reservationsMapper(reservations) {
  return reservations.map(r => reservationMapper(r));
}

export default reservationsMapper;
